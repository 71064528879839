// Provides helper methods that can calculate the time a user spends on a page.
//  -> All units are in MS
//  -> methods such as 'pause' allow controllers to capture active time
//
// Example usage:
// var stopwatch = new Stopwatch();
// stopwatch.start();
// stopwatch.pause();
// stopwatch.timeInMS();
//
// To reset, just instantiate a new object: stopwatch = new Stopwatch();
app.factory('StopwatchFactory', [
  '$rootScope',
  function ($rootScope) {
    function Stopwatch() {
      var self = this;

      // local variables
      var lastStart;
      var timeSpentMS = 0;
      var isPaused = true;

      // Returns how much time has passed in MS
      this.timeInMS = function () {
        var timeSinceLastPause = 0;
        // If the timer hasn't been started, return 0 and a warning
        if (typeof lastStart === 'undefined') {
          return 0;
        }
        // If the timer is not paused, add the current time passed
        if (!isPaused) {
          timeSinceLastPause = Math.floor(performance.now()) - lastStart;
        }
        return timeSpentMS + timeSinceLastPause;
      };

      // Starts the stopwatch.
      //  -> Can be called multiple times, but will kick up a warning
      this.start = function () {
        // If the timer has already been started and is still running (i.e. we hit start twice in a row), send a warning
        if (typeof lastStart != 'undefined' && !isPaused) {
          console.log('Warning: Stopwatch has already been started.');
        }
        lastStart = Math.floor(performance.now());
        isPaused = false;
        return self;
      };

      // Pauses the Stopwatch
      //  -> Can be called multiple times, but will kick up a warning
      this.pause = function () {
        if (typeof lastStart === 'undefined') {
          console.log("Warning: Cannot pause Stopwatch, it hasn't been started.");
          return self;
        }
        if (isPaused) {
          console.log('Warning: Stopwatch is already paused.');
          return self;
        }
        timeSpentMS = timeSpentMS + (Math.floor(performance.now()) - lastStart);
        isPaused = true;
        return self;
      };

      // Is the Stopwatch still running?
      //   -> BOOL
      this.isRunning = function () {
        return !isPaused;
      };
    }

    return Stopwatch;
  },
]);
