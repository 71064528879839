import { getDatabase, ref, set, update, child, get, onValue } from 'firebase/database';

// Stores and retrieves user settings using getters/setters and firebase

// To use UserSettings within a controller:
//  1. Use a resolve property in app.js (ui-router) to get a user setting location: UserSettings.get("practice_timed_writing");
//  2. Now this property object (in resolve) will be passed into controller. If there's trouble, make sure the controller is defined
//        in ui-router (see practice_timed_writing state as an example)
//  3. Use the orDefault method on the returned object to get data. This allows you to pass an argument with a default object and will check
//        for properties that are one level deep.

app.service('UserSettings', [
  '$q',
  'Auth2',
  '$rootScope',
  '$window',
  function ($q, Auth2, $rootScope, $window) {
    var self = this;

    this.db = getDatabase();
    let firebaseUserRef;
    let firebaseUserSettingsRef;

    this.AuthCurrentUser = Auth2.currentUser();

    // initializes UserSettings when user logs in (attached in app.js using .run method to run when app loads)
    //  watches for changes to settings, and updates the internal _settings object
    $rootScope.$on('devise:login', function (e, currentUser) {
      if (typeof firebaseUserRef === 'undefined' || typeof firebaseUserSettingsRef === 'undefined') setupReferences(currentUser);
      // console.log("UserSettings active for " + currentUser.first_name);
      get(firebaseUserSettingsRef).then(function (snapshot) {
        if (snapshot.val() === null) {
          set(firebaseUserSettingsRef, { active: true });
        }
      });
    });

    // sets a user setting to: users/:id/settings/:childPath
    //  returns a promise
    //  objects are retrieved through their child paths
    //  Ex: UserService.set("practice_timed_writing/something", {time: "20", score: "15"})
    this.set = function (childPath, obj) {
      var setObj = $.extend({}, obj);
      delete setObj.orDefault;
      const updateRef = child(firebaseUserSettingsRef, childPath);
      return update(updateRef, setObj);
    };

    // returns a promise with the object that lives at users/:id/settings/:childPath
    //   Best practice: Use this in resolve when enterring a controller
    //   childPath should not contain "/" characters (this enables caching for fast access)
    this.get = function (childPath) {
      return checkForReferences()
        .then(function () {
          return get(child(firebaseUserSettingsRef, childPath));
        })
        .then(function (snapshot) {
          return userSetting(snapshot.val());
        });
    };

    // 1. sets these sessions/unique_session_id in firebase on login.
    //    - when a user login is detected, we update with the unique_session_id.
    //    - a unique_session_id changes without us knowing, it means another browser as signed in and we should logout.
    // 2. watches for changes to the sessions object.
    //    - If sessions/refreshBrowser changes, we refresh the browser.
    //    - If sessions/logoutUser changes, we logout all users
    var refreshBrowser;
    var logoutUser;
    var setDefaults = false;
    var autoLogoutTriggered = false;
    $rootScope.$on('devise:login', function (e, currentUser) {
      // set the unique_session_id to the user settings
      console.log('Security Service: Ensuring only a single computer can access this account at a given time.');
      return self.set('sessions', { unique_session_id: currentUser.unique_session_id }).then(function () {
        // watch for changes
        return checkForReferences().then(function () {
          return onValue(child(firebaseUserSettingsRef, 'sessions'), (snapshot) => {
            if (snapshot.val() == null) return;

            // make sure we only set these once on login
            if (!setDefaults) {
              refreshBrowser = snapshot.val()['refreshBrowser'];
              logoutUser = snapshot.val()['logoutUser'];
              setDefaults = true;
            }
            logoutIfSigninOnFromDifferentComputer(snapshot, currentUser);
            // If user logs out on another tab, we refresh the browser
            checkIfRefreshBrowser(snapshot, refreshBrowser);
            checkIfLogoutUser(snapshot, logoutUser);
          });
        });
      });
    });

    // How we manage our sessions such that:
    // - Only one browser can be logged in at a time.
    // - When user signs out, all tabs on same computer sign out too (by refreshing)
    //
    // What we want:
    // 1. User signs in on a different computer, trigger log out the other computer.
    //      -> set unique_session_id. This triggers logout on the other computer.
    // 2. User signs out on a different tab, refresh the other tab.
    //      -> set refreshBrowser. Triggers other browser to refresh.

    // If we're being automatically signed out (i.e. scenario #1), we do not need
    //  to trigger a refresh in other tabs in the same browser.
    // Examples:
    // 1. Matt signs in on his home PC, goes to school and signs in there.
    //      -> unique_session_id changes, refreshBrowser does not change.
    //      -> devise:logout is triggered
    // 2. Matt is logged in at home and has three tabs open, signs out on one tab.
    //      -> unique_session_id does not change, refreshBrowser does change.
    //      -> devise:logout is not triggers, browser refreshes

    // When we logout, we invalidate the session. Thus, we set the refreshBrowser
    // token to something random so that other tabs in the same browser will refresh
    // and since the session cookie is no longer valid, will be signed out
    $rootScope.$on('devise:logout', function () {
      // set the global refreshBrowser so that the current tab does
      // not refresh (since we only refresh if unequal)
      if (autoLogoutTriggered) {
        $window.location.reload();
        return true;
      } else {
        refreshBrowser = Math.random().toString(36).substring(2, 15);
        return self.set('sessions', { refreshBrowser: refreshBrowser }).then(() => {
          // When a user logs out, this ensures all Service variables are destroyed
          //  so that other users can't access if they were to login on the same computer
          console.log('Destroying services...');
          $window.location.reload();
        });
      }
    });

    // Private Functions ------------------------------------------------------------------------
    function logoutIfSigninOnFromDifferentComputer(snapshot, currentUser) {
      // if there's a change, check if the unique_session_id matches,
      //  other wise logout the user
      if (snapshot.val()['unique_session_id'] !== currentUser.unique_session_id) {
        console.log('Security Service: Another login detected. Logging out this computer.');
        autoLogoutTriggered = true;
        Auth2.logout();
      }
    }

    function checkIfRefreshBrowser(snapshot, refreshBrowser) {
      // if there's a change, check if the unique_session_id matches,
      //  other wise logout the user
      if (snapshot.val()['refreshBrowser'] !== refreshBrowser) {
        console.log('Security Service: Refreshing the browser.');
        $window.location.reload();
      }
    }

    function checkIfLogoutUser(snapshot, logoutUser) {
      // if there's a change, check if the unique_session_id matches,
      //  other wise logout the user
      if (snapshot.val()['logoutUser'] !== logoutUser) {
        console.log('Security Service: Logging out current user.');
        Auth2.logout();
      }
    }

    // Returns a userSetting object with helper function bound
    //  ONLY CHECKS THE FIRST LEVEL OF PROPERTIES Ie:
    //    works with: { practice: {matt: true, pam: true }}
    //    does not work with: { practice: {pam: {yes: true, no: false}}, matt: true}
    function userSetting(data) {
      data = data || {}; // assign an empty object if needed so we can provide the orDefault property

      data.orDefault = function (defaultObj) {
        // 1st. Assert that the setting has all of the default properties
        for (var property in defaultObj) {
          if (Object.prototype.hasOwnProperty.call(defaultObj, property)) {
            if (!Object.prototype.hasOwnProperty.call(this, property)) {
              this[property] = defaultObj[property];
            }
            // if the property is an object, check that we have all the nested properties within that object
            if (typeof defaultObj[property] === 'object') {
              for (var prop in defaultObj[property]) {
                if (Object.prototype.hasOwnProperty.call(defaultObj[property], prop)) {
                  if (!Object.prototype.hasOwnProperty.call(this[property], prop)) {
                    this[property][prop] = defaultObj[property][prop];
                  }
                }
              }
            }
          }
        }
        return this;
      };
      return data;
    }

    // checks to make sure our references are defined. Returns a promise when they are.
    function checkForReferences() {
      if (typeof firebaseUserRef === 'undefined' || typeof firebaseUserSettingsRef === 'undefined') {
        return self.AuthCurrentUser.then(setupReferences);
      } else {
        return $q.resolve();
      }
    }

    // Sets up references to firebase locations
    //    returns a promise that resolves when complete
    function setupReferences(currentUser) {
      firebaseUserRef = ref(self.db, `users/${currentUser.id}`);
      firebaseUserSettingsRef = child(firebaseUserRef, 'settings');
    }

    return this;
  },
]);
