import { getDatabase, ref, push, child, update } from 'firebase/database';

// Provides an interface to join/unjoin, create/destroy classes
app.service('Certificates', [
  '$q',
  function ($q) {
    const db = getDatabase();
    var firebaseUsersPath = ref(db, 'users');

    // Creates certificates for users and sends them a notification.
    // user_ids and certificates should be arrays of the same length.
    this.createMultiple = function (userIds, certificates) {
      if (userIds.length < 1) return $q.reject('No users provided.');

      var firebaseContent = {};
      var key;
      for (var i = 0; i < userIds.length; i++) {
        if (Number(userIds[i]) <= 0) continue;
        const certificatesRef = child(firebaseUsersPath, `${userIds[i]}/certificates`);
        key = push(certificatesRef).key;
        firebaseContent[String(userIds[i]) + '/certificates/' + key] = certificates[i];
      }
      // save to the database.
      return update(firebaseUsersPath, firebaseContent); // we use update as 'set' may overwrite objects
    };

    this.userCertificateRef = function (userId) {
      return child(firebaseUsersPath, `${userId}/certificates`);
    };

    return this;
  },
]);
