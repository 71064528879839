app.controller('LevelController', [
  '$scope',
  'Level',
  '$state',
  '$timeout',
  '$q',
  function ($scope, Level, $state, $timeout, $q) {
    // Scroll to top when lessons view loads
    window.scrollTo(0, 0); // scrolls to top of page

    $scope.level = Level;
    $scope.view = {
      showDeleteProgress: false,
      deleteProgressConfirmed: false,
      showStars: false,
      // Local flash message just for the lessons list part of the page
      flashMessage: undefined,
    };

    // Retrieves level structure (i.e. all the lessons), an then user data for these lessons
    $q.all([$scope.level.update($state.params.level), minDelay(800)])
      .then(() => {
        // 1. Clear flash messages
        $scope.view.flashMessage = undefined;
        $scope.view.showStars = true;

        // 2. Once level data is gathered, update the total stars summary
        $scope.level.calculateTotalStarsSummary();
      })
      .catch(() => {
        // 3. If cannot get level data or lesson progress (eg. request timed out), display error message localized to the lessons list part of the page
        $scope.view.flashMessage = {
          message:
            "Typist could not load your lesson data from our servers. There's no need to worry, all of your data is safe and secure! If you refresh your browser everything should work great :)",
          error: true,
        };
      });

    $scope.successErrorMessage = {
      success: true,
      message:
        'Welcome to your training lessons! These lessons will introduce keys one by one until you have mastered the keyboard. Complete at least a bronze star on each lesson before moving to the next!',
    };

    $scope.hideSuccessErrorMessages = function () {
      $scope.successErrorMessage = null;
    };

    $scope.go = function (challenge) {
      $state.go('app.keyboarding.challenge', {
        level: challenge.levelNumber,
        row: challenge.row,
        column: challenge.column,
      });
    };

    // activate tooltips
    $timeout(function () {
      // Code snippet from docs https://getbootstrap.com/docs/5.1/components/tooltips/#example-enable-tooltips-everywhere
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
      });
    }, 1000);

    // returns a promise that resolves after ms
    function minDelay(ms) {
      var deferred = $q.defer();
      const timer = $timeout(() => deferred.resolve(), ms);
      $scope.$on('$destroy', function () {
        $timeout.cancel(timer);
      });
      return deferred.promise;
    }
  },
]);
