import { firebaseObjToArray } from '../../../components/Navigation/Notifications/FirebaseNotificationHelpers';
import { getDatabase, ref, child, onValue, off, update, serverTimestamp } from 'firebase/database';

// Keyboarding Competition Results
app.controller('ResultsController', [
  '$scope',
  '$stateParams',
  'contest',
  '$interval',
  '$timeout',
  '$http',
  'currentUser',
  function ($scope, $stateParams, contest, $interval, $timeout, $http, currentUser) {
    $scope.contest = contest;
    console.log('CONTEST', contest);
    $scope.accessCode = contest.code;
    $scope.contestStarted = false;
    $scope.isFinished = false;
    $scope.startButtonText = 'Start Contest!';
    $scope.sortedParticipants = [];
    $scope.userIsContestCreator = currentUser.id === contest.user_id;
    $scope.disableStartContestButton = !$scope.userIsContestCreator || contest.started;

    const db = getDatabase();
    const contestRef = ref(db, `games/contests/${$stateParams['id']}`);

    // load watch for changes to participants
    onValue(child(contestRef, 'participants'), (snapshot) => {
      contest.participants = firebaseObjToArray(snapshot.val());
      contest.sortedParticipants = contest.participants.filter(removeInactive).sort(customParticipantsSort);
      $scope.$applyAsync(() => {
        $scope.sortedParticipants = [...contest.sortedParticipants];
        console.log('PARTICIPANTS', contest.participants);
        console.log('SORTED PARTICIPANTS', $scope.sortedParticipants);
      });
    });

    // filter applied to the array. attached to view.
    // detects and removes inactive participants
    function removeInactive(participant) {
      const now = Date.now();
      const contestStartTime = $scope.contestStarted;
      const contestDurationMs = contest.durationInSeconds * 1000;
      const contestEndTime = contestStartTime + contestDurationMs + 18000;

      // If the contest hasn't started, show everyone.
      if (!$scope.contestStarted) return true;
      // First 25 seconds (countdown/instructions phase), show everyone.
      if (now < contestStartTime + 25000) return true;
      // It's been more than 25 seconds since the contest started.
      // If the contest is ongoing, show participants who have responded in the last 7 seconds
      // or those who have finished.
      if (now <= contestEndTime + 10000) return now - participant.updatedAt < 10000 || participant.finished;
      // The contest has been over for more than 10 seconds.
      // Show only participants who finished
      return participant.finished;
    }

    // orders the participants on the scoreboard
    // sort first on having met contest accuracy, otherwise sort based on speed
    function customParticipantsSort(a, b) {
      const requiredAccuracy = parseFloat(contest.accuracy) || 0;

      const speedA = parseFloat(a[contest.type]) || 0;
      const speedB = parseFloat(b[contest.type]) || 0;
      const accuracyA = parseFloat(a.accuracy) || 0;
      const accuracyB = parseFloat(b.accuracy) || 0;

      const metAccuracyA = accuracyA >= requiredAccuracy;
      const metAccuracyB = accuracyB >= requiredAccuracy;

      if (metAccuracyA && !metAccuracyB) return -1;
      if (!metAccuracyA && metAccuracyB) return 1;

      return speedB - speedA;
    }

    // starts the contest for participants if the user is the originator
    $scope.startContest = function () {
      $scope.disableStartContestButton = true;
      $scope.contestStarted = true;
      if ($scope.userIsContestCreator) {
        console.log('STARTING CONTEST');
        // this takes 18 seconds to complete
        // Note: Updating to a timestamp seems to do this twice
        update(contestRef, { started: serverTimestamp() });
      }
    };

    // If the contest has started, check every second for how much time is remaining.
    let intervalPromise; // define outside the function closure to make sure we have only one running
    onValue(child(contestRef, 'started'), (snapshot) => {
      const contestStartTime = snapshot.val();
      if (!contestStartTime) return;
      $scope.contestStarted = contestStartTime;

      // we add 18000 since the messages shown to participants take 18000 milliseconds to complete
      const timePassedSinceStart = Date.now() - 18000 - contestStartTime;
      const contestComplete = timePassedSinceStart > contest.durationInSeconds * 1000;
      // if we're returning to an old contest, no need to set an interval
      if (contestComplete) {
        $scope.countdownStatus = null;
        $scope.startButtonText = 'Contest Complete!';
        return;
      }
      // if the contest has started, update the timer
      // Note: Firebase updates the started value twice, with two successive timestamps (weird. I know.)
      //  for this reason we have to make sure intervalPromise is only defined once.
      if (contestStartTime && !intervalPromise) {
        $scope.contestStarted = contestStartTime;
        intervalPromise = $interval(function () {
          console.log('Contest Started Interval: ', timePassedSinceStart);
          if (timePassedSinceStart < -12000) {
            // hasn't started yet
            $scope.startButtonText = 'Giving instructions...';
          } else if (timePassedSinceStart < -2000) {
            $scope.startButtonText = 'Starting Countdown...';
          } else if (timePassedSinceStart < 0) {
            $scope.startButtonText = 'Contest Starting!';
          } else if (timePassedSinceStart <= contest.durationInSeconds * 1000) {
            $scope.startButtonText = null;
            // contest underway!
            let secondsRemaining = Math.ceil(contest.durationInSeconds - timePassedSinceStart / 1000.0);
            secondsRemaining = Math.max(0, secondsRemaining);
            $scope.countdownStatus = timeToString(secondsRemaining);
          } else if (contestComplete) {
            // contest finished!
            $scope.countdownStatus = timeToString(0);
            $timeout(function () {
              $scope.countdownStatus = null;
              $scope.startButtonText = 'Contest Complete!';
              $interval.cancel(intervalPromise); // all done with this interval
            }, 2000);
          }
        }, 1000);
        $scope.$on('$destroy', function () {
          $interval.cancel(intervalPromise);
        });
      }
    });

    // At a regular interval, check if all the participants have finished.
    //  If they have, update their placements and stop the interval.
    const placementIntervalPromise = $interval(() => {
      console.log('placementInterval');

      // Don't proceed if we've already updated the placements
      if ($scope.isFinished) {
        $interval.cancel(placementIntervalPromise);
        return;
      }

      // Make a copy of sortedParticipants to avoid mutation issues
      const sortedParticipantsCopy = [...contest.sortedParticipants];

      // don't do anything if we don't have any active participants
      if (sortedParticipantsCopy.length < 1) return;
      if (!everyoneHasFinished(sortedParticipantsCopy)) return;
      if (hasExistingPlacements(sortedParticipantsCopy)) {
        $scope.isFinished = true;
        $interval.cancel(placementIntervalPromise);
        return;
      }

      // Only attempt to update placements if we haven't done so already
      $scope.isFinished = true;
      pushPlacementDataToFirebase(sortedParticipantsCopy);
      saveDataToPostgres(sortedParticipantsCopy);

      $interval.cancel(placementIntervalPromise);
    }, 2000);

    $scope.$on('$destroy', function () {
      off(child(contestRef, 'participants'));
      off(child(contestRef, 'started'));
      $interval.cancel(placementIntervalPromise);
    });

    // True only if the contest has started AND all students have finished.
    function everyoneHasFinished(sortedParticipantsCopy) {
      // Check if every participant has finished
      return sortedParticipantsCopy.every((p) => p.finished);
    }

    // Check if any participants already have placements set
    function hasExistingPlacements(sortedParticipantsCopy) {
      return sortedParticipantsCopy.some((p) => p.placement);
    }

    // updates placements (i.e. 1st, 2nd, 3rd) for students
    function pushPlacementDataToFirebase(sortedParticipantsCopy) {
      // Generate the placement updates for each participant
      const updates = sortedParticipantsCopy.reduce((acc, participant, index) => {
        const placement = index + 1;
        return {
          ...acc,
          [`${participant.firebaseKey}/placement`]: placement,
          [`${participant.firebaseKey}/placementSuffix`]: placementSuffix(placement),
        };
      }, {});

      // Push the updates to Firebase
      update(child(contestRef, 'participants'), updates);
    }

    function placementSuffix(place) {
      var str = String(place);
      var len = str.length;
      // 10th, 20th, 30th...
      if (str[len - 1] === '0') {
        return 'th';
      }
      // 1st, 11th, 21st, 31st
      if (str[len - 1] === '1') {
        if (len > 1 && str[len - 2] === '1') {
          return 'th';
        } // 11th, 111th
        else {
          return 'st';
        } // 21st, 31st....
      }
      if (str[len - 1] === '2') {
        if (len > 1 && str[len - 2] === '1') {
          return 'th';
        } // 12th, 112th
        else {
          return 'nd';
        } // 2nd, 22nd....
      }
      if (str[len - 1] === '3') {
        if (len > 1 && str[len - 2] === '1') {
          return 'th';
        } // 13th, 113th
        else {
          return 'rd';
        } // 2nd, 22nd....
      }
      return 'th';
    }

    function timeToString(time) {
      // Hours, minutes and seconds
      var hrs = ~~(time / 3600);
      var mins = ~~((time % 3600) / 60);
      var secs = time % 60;

      // Output like "1:01" or "4:03:59" or "123:03:59"
      let ret = '';

      if (hrs > 0) ret += '' + hrs + ':' + (mins < 10 ? '0' : '');

      ret += '' + mins + ':' + (secs < 10 ? '0' : '');
      ret += '' + secs;
      return ret;
    }

    function saveDataToPostgres(sortedParticipantsCopy) {
      // add to javascript_data endpoint
      const contestId = contest.postgresId;
      const resultsData = {
        contest: {
          javascript_data: {
            note: 'sortedParticipantsCopy is what was used to calculate results',
            sortedParticipantsCopy: sortedParticipantsCopy,
            contest: contest,
          },
        },
      };

      // Save data asynchronously to the javascript_data endpoint
      $http
        .patch(`/contest/${contestId}/update_javascript_data`, resultsData)
        .then((response) => console.log('Contest results saved successfully:', response.data))
        .catch((error) => console.error('Error saving contest results:', error));
    }
  },
]);
