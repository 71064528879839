// Provides help functions for UploadControllerS3
app.service('S3UploadService', [
  '$http',
  '$q',
  function ($http, $q) {
    var self = this;

    this.formSelectorID;
    this.s3DirectPost = {};

    // To simulate an instructor, pass a permission_id of null into the init function.
    //  -> when we check_permission, our backend will identify the user as an instructor
    //      and approve the upload
    this.init = function (formSelectorID, permission_id, openAssignment, document_assignment_id) {
      this.formSelectorID = formSelectorID;
      this.permission_id = permission_id;
      this.openAssignment = openAssignment || false;
      this.document_assignment_id = document_assignment_id;

      // If we're not treating this as an open assignment, we must have a
      //  permission_id defined
      if (!this.openAssignment && typeof permission_id === 'undefined') {
        throw new Error('Error: permission_id is undefined and is required by S3UploadService.init');
        // If we are treating this as an open assignment, we need a document_assignment_id
        //  to send to the server
      } else if (this.openAssignment && typeof document_assignment_id === 'undefined') {
        throw new Error('Error: document_assignment_id is undefined and is required by S3UploadService.init');
      }

      // JQuery Magic. upload() function initializes the jQuery upload form with defaults
      //  No network request at this point
      $(function () {
        fileElement = self.fileinput()[0];
        if (typeof fileElement === 'undefined' || fileElement.length === 0) {
          throw new Error('Error: Unable to initialize S3UploadService. Cannot locate file input field with ID ' + fileElement);
        }
        upload($(fileElement));
      });
      return self;
    };

    // checks that the file length and format are correct
    this.validateFile = function () {
      var validFileFormats = ['docx']; // note: this is also validated in S3
      var maximumByteSize = 2097152; // note: this is also defined in document_submission as content_length_range
      var file = self.fileinput().prop('files')[0];
      // Only proceed if the file exists, otherwise calling file.name will throw an error
      if (!file) {
        return $q.reject({ data: { errors: 'Error: No file selected for upload.' } });
      }
      var filename = file.name;
      var fileExtension = filename.split('.').pop();

      if (validFileFormats.indexOf(fileExtension.toLowerCase()) < 0) {
        return $q.reject({
          data: {
            errors:
              'Error: File upload must be have a DOCX file extension. Please upload the correct file or ask your instructor for help.',
          },
        });
      }

      if (file.size > maximumByteSize) {
        return $q.reject({
          data: {
            errors: 'Error: File exceeds the maximum size limit. Please review the instructions or ask your instructor for help.',
          },
        });
      }

      return isFilenameValid(filename);
    };

    // checks that the user still has permission to upload the assignment document
    // return a promise
    this.checkPermissionGetPresignedPost = function () {
      var filename = self.fileinput().prop('files')[0].name;
      var presignedPostPromise;
      // we define openAssignment in the init function. In effect, if a DocumentAssignment does
      //  not require permission, or if the user is an instructor, they should be able to upload
      //  a solution without the need for a permission.
      if (self.openAssignment) {
        presignedPostPromise = $http.post('api/document_submissions/check_permission_no_section', {
          document_assignment_id: self.document_assignment_id,
          filename: filename,
        });
      } else {
        presignedPostPromise = $http.post('api/document_submissions/check_permission', {
          permission_id: self.permission_id,
          filename: filename,
        });
      }

      return presignedPostPromise
        .then(function (res) {
          self.s3DirectPost = res.data;
          console.log('Retrieved permission to upload');
          console.log(self.s3DirectPost);
          return true;
        })
        .catch(function (err) {
          // propogate the error
          return $q.reject(err);
        });
    };

    // initiates the file upload
    // returns a promise
    this.sendToS3 = function () {
      var deferred = $q.defer();

      var fileList = self.fileinput().prop('files');

      var filename = fileList[0].name;
      var contentType = fileList[0].type;

      console.log(filename, contentType);
      console.log(fileList[0]);
      fileElement = self.fileinput()[0];

      var upload = $(fileElement)
        .fileupload('send', {
          files: fileList,
          url: self.s3DirectPost.url,
          type: 'POST',
          // autoUpload:       true, // we want to check user still has permission.
          formData: self.s3DirectPost.fields,
          paramName: 'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
          dataType: 'XML', // S3 returns XML if success_action_status is set to 201
        })
        .then(function (result, textStatus, jqXHR) {
          deferred.resolve(result);
          console.log('success');
        })
        .catch(function (jqXHR, textStatus, errorThrown) {
          deferred.reject(errorThrown);
          console.log('error');
        });
      // upload.complete(function (result, textStatus, jqXHR) { console.log('complete'); });

      return deferred.promise;
    };

    this.createDocumentSubmission = function (generated_variable_id) {
      var document_submission = self.s3DirectPost;

      delete document_submission['fields'];
      delete document_submission['url'];
      return $http.post('document_submissions', {
        document_submission: {
          ...document_submission,
          generated_variable_id: generated_variable_id,
        },
      });
    };

    this.fileinput = function () {
      return $(self.formSelectorID).find("input[type='file']");
    };

    this.destroy = function () {
      return self.fileinput().fileupload('destroy');
    };

    function upload(elem) {
      var fileInput = elem;
      console.log(fileInput);

      // fileupload() initiates the upload
      fileInput.fileupload({
        fileInput: fileInput,
        //   url:             s3DirectPost.url,
        type: 'POST',
        //   autoUpload:       true, // we want to check user still has permission.
        //   formData:         s3DirectPost.fields,
        paramName: 'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
        dataType: 'XML', // S3 returns XML if success_action_status is set to 201
        replaceFileInput: false, // must be false. removing causes things to break

        // NOTE: add function must be here other wise the file will auto-upload! Boooo!
        add: function (e, data) {},
      });

      return fileInput;
    } // end upload

    // Promise that resolves if the given filename string is allowed or not
    // Filtering out names with double periods, special characters, etc
    // Promise is rejected if filename is invalid
    function isFilenameValid(filenameString) {
      // Block list certain patterns that can be found in a filename (eg. '..docx')
      // Regular expression looks for 2 or more periods in succession
      let badPattern1 = /[\.]{2,}/g;
      if (badPattern1.test(filenameString)) {
        return $q.reject({
          data: {
            errors:
              'Error: File name cannot have two or more periods in succession. Please upload file with a different filename or ask your instructor for help.',
          },
        });
      }
      // Unix allows a wide variety of characters in their filenames, and we are ignoring them in the safelist
      // but to avoid filenames like '-date.docx', ' date.docx', or '.date.docx', we will enforce all files to start with either an underscore,
      // letter or number
      let badPattern2 = /^[0-9a-zA-Z_]{1}/g;
      if (!badPattern2.test(filenameString)) {
        return $q.reject({ data: { errors: 'Error: File name can only start with an underscore, letter, or number' } });
      }

      // This regex only safelists characters, but does not cover cases of 2 periods before extension
      //      eg. 'file.name.docx' is allowed, and so is 'file.name..docx'
      // NOTE: [0-9a-zA-Z_ ] is equivalent to \w, but this way it is more explicit
      let safelist = /^[0-9a-zA-Z_\. \-\(\)\[\]+\.{1}[0-9a-zA-Z]+$/g;
      // This regex allows for:
      // (string of letters, numbers, underscore, space, hyphen, periods and brackets)
      // + a string of letters or numbers for extension
      if (!safelist.test(filenameString)) {
        return $q.reject({
          data: {
            errors:
              'Error: File name can only contain alphanumeric characters, underscores, spaces, hyphens, periods and brackets. Please upload file with a different filename or ask your instructor for help.',
          },
        });
      }
      return $q.resolve();
    }

    return this;
  },
]);
