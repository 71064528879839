// Syncs user data for levels/challenges
// USED EXCLUSIVELY IN LEVEL SERVICE
app.service('UserLessonData', [
  '$http',
  function ($http) {
    // accepts a level object (with level.challenges where challenges have lessons)
    // adds data to the level, challenges, and lessons
    // returns a promise that resolves to a boolean whether the student completed a star
    this.update = (level) => {
      const challenges = level.challenges;
      const challenge_ids = challenges.map((challenge) => challenge.id);
      const url = '/challenge/results.json';

      // actually GETTING data here... used POST in order to have array as a param (pain with a GET request)
      return $http.get(url, { params: { 'challenge_ids[]': challenge_ids } }).then(function (response) {
        var didUserCompleteALessonStar = updateLessonsWithUserData(level, response.data);
        return didUserCompleteALessonStar;
      });
    };

    // updates lesson objects with:
    //  timesComplete : int
    //  currentLevel : "bronze" || "silver" || "gold"
    function updateLessonsWithUserData(level, userData) {
      const { challenges } = level;

      // Flatten lessons from all challenges
      const lessons = challenges.map((challenge) => challenge.lessons).reduce((a, b) => a.concat(b), []);

      var previousLessonStarLevel;
      var didAdvanceToNextLessonLevel = false;
      for (let i = 0; i < lessons.length; i++) {
        previousLessonStarLevel = lessons[i].currentLevel || 'none';
        lessons[i].timesCompleted = userData[lessons[i].id] || 0;
        if (lessons[i].timesCompleted < lessons[i].requirements.bronze.reps) {
          lessons[i].currentLevel = 'none';
          lessons[i].nextLevel = 'bronze';
          lessons[i].completionsNeededForNextLevel = lessons[i].requirements['bronze'].reps - lessons[i].timesCompleted;
        } else if (lessons[i].timesCompleted < lessons[i].requirements.bronze.reps + lessons[i].requirements.silver.reps) {
          lessons[i].currentLevel = 'bronze';
          lessons[i].nextLevel = 'silver';
          lessons[i].completionsNeededForNextLevel =
            lessons[i].requirements['bronze'].reps + lessons[i].requirements['silver'].reps - lessons[i].timesCompleted;
        } else if (
          lessons[i].timesCompleted <
          lessons[i].requirements.bronze.reps + lessons[i].requirements.silver.reps + lessons[i].requirements.gold.reps
        ) {
          lessons[i].currentLevel = 'silver';
          lessons[i].nextLevel = 'gold';
          lessons[i].completionsNeededForNextLevel =
            lessons[i].requirements['bronze'].reps +
            lessons[i].requirements['silver'].reps +
            lessons[i].requirements['gold'].reps -
            lessons[i].timesCompleted;
        } else {
          lessons[i].currentLevel = 'gold';
          lessons[i].nextLevel = 'gold';
          lessons[i].completionsNeededForNextLevel = 0;
          lessons[i].practiceMode =
            lessons[i].timesCompleted >
            lessons[i].requirements['bronze'].reps +
              lessons[i].requirements['silver'].reps +
              lessons[i].requirements['gold'].reps;
        }
        didAdvanceToNextLessonLevel = didAdvanceToNextLessonLevel || previousLessonStarLevel !== lessons[i].currentLevel;
      }
      // on level homescreen, determine what color star to display.
      //  set currentLevel and nextLevel
      //  set numberOfStars to display
      //  add half stars
      for (let i = 0; i < challenges.length; i++) {
        if (
          challenges[i].lessons.length ===
          challenges[i].lessons.filter(function (l) {
            return l.currentLevel === 'gold';
          }).length
        ) {
          challenges[i].currentLevel = 'gold';
          challenges[i].nextLevel = 'gold';
          challenges[i].numberOfStars = 3;
        } else if (
          challenges[i].lessons.length ===
          challenges[i].lessons.filter(function (l) {
            return l.currentLevel === 'silver' || l.currentLevel === 'gold';
          }).length
        ) {
          challenges[i].currentLevel = 'silver';
          challenges[i].nextLevel = 'gold';
          challenges[i].numberOfStars = 2;
          // add a half-star if there's at least 1 lesson with a gold
          if (
            challenges[i].lessons.filter(function (l) {
              return l.currentLevel === 'gold';
            }).length > 0
          ) {
            challenges[i].numberOfStars += 0.5;
          }
        } else if (
          challenges[i].lessons.length ===
          challenges[i].lessons.filter(function (l) {
            return l.currentLevel === 'bronze' || l.currentLevel === 'silver' || l.currentLevel === 'gold';
          }).length
        ) {
          challenges[i].currentLevel = 'bronze';
          challenges[i].nextLevel = 'silver';
          challenges[i].numberOfStars = 1;
          // add a half-star if there's at least 1 lesson with a silver or gold
          if (
            challenges[i].lessons.filter(function (l) {
              return l.currentLevel === 'silver' || l.currentLevel === 'gold';
            }).length > 0
          ) {
            challenges[i].numberOfStars += 0.5;
          }
        } else {
          challenges[i].currentLevel = 'none';
          challenges[i].nextLevel = 'bronze';
          challenges[i].numberOfStars = 0;
          // add a half-star if there's at least 1 lesson with a bronze, silver or gold
          if (
            challenges[i].lessons.filter(function (l) {
              return l.currentLevel === 'bronze' || l.currentLevel === 'silver' || l.currentLevel === 'gold';
            }).length > 0
          ) {
            challenges[i].numberOfStars += 0.5;
          }
        }
      }
      // Set the tooltips for each challenge
      var incompleteLessons = [];
      for (let i = 0; i < challenges.length; i++) {
        challenges[i].starTooltips = { bronze: null, silver: null, gold: null };
        incompleteLessons = [];
        if (challenges[i].numberOfStars <= 0.5) {
          incompleteLessons = challenges[i].lessons.filter(function (l) {
            return l.currentLevel === 'none';
          });
          challenges[i].starTooltips.bronze =
            "Complete '" +
            incompleteLessons[0].name +
            "'" +
            (incompleteLessons.length > 1 ? " and '" + incompleteLessons[1].name + "' " : ' ') +
            'in this challenge to a minimum level of bronze to achieve this star!';
        } else if (challenges[i].numberOfStars <= 1.5) {
          incompleteLessons = challenges[i].lessons.filter(function (l) {
            return l.currentLevel === 'none' || l.currentLevel === 'bronze';
          });
          challenges[i].starTooltips.silver =
            "Complete '" +
            incompleteLessons[0].name +
            "'" +
            (incompleteLessons.length > 1 ? " and '" + incompleteLessons[1].name + "' " : ' ') +
            'in this challenge to a minimum level of silver to achieve this star!';
        } else if (challenges[i].numberOfStars <= 2.5) {
          incompleteLessons = challenges[i].lessons.filter(function (l) {
            return l.currentLevel === 'none' || l.currentLevel === 'bronze' || l.currentLevel === 'silver';
          });
          challenges[i].starTooltips.gold =
            "Complete '" +
            incompleteLessons[0].name +
            "'" +
            (incompleteLessons.length > 1 ? " and '" + incompleteLessons[1].name + "' " : ' ') +
            'in this challenge to gold level to achieve this star!';
        }
      }

      // Determine whether user has permission to start each challenge
      //  If they have achieved at least a bronze on all lessons above the row, they may start
      var maxRow = Math.max.apply(
        null,
        challenges.map(function (challenge) {
          return challenge.row;
        })
      );
      var minRow = Math.min.apply(
        null,
        challenges.map(function (challenge) {
          return challenge.row;
        })
      );
      var c_row; // an array of arrays
      var hasPermissionForNextRow = true;
      // debugger;
      for (var i = minRow; i <= maxRow; i++) {
        // 1st: Get all the challenges with that row
        c_row = challenges.filter(function (c) {
          return c.row === i;
        });
        for (var j = 0; j < c_row.length; j++) {
          if (i === minRow || hasPermissionForNextRow) {
            c_row[j].userHasPermissionToStart = true;
          } else {
            // CHANGED FROM FALSE TO ALLOW USERS TO START ANY LESSON
            c_row[j].userHasPermissionToStart = true;
          }
        }
        // 2nd: Do we have permission for the NEXT row?
        hasPermissionForNextRow =
          hasPermissionForNextRow &&
          c_row.length ===
            c_row.filter(function (c) {
              return c.currentLevel !== 'none';
            }).length;
      } // end of for-loop

      // Can we go to the next level?
      level.userHasPermissionToStartNext = false;
      var lastRow = challenges.filter(function (c) {
        return c.row === maxRow;
      });
      if (
        lastRow.length ===
        lastRow.filter(function (c) {
          return c.currentLevel !== 'none';
        }).length
      ) {
        level.userHasPermissionToStartNext = true;
      }
      // Detects whether user has just completed a lesson-level
      return didAdvanceToNextLessonLevel;
    }
  },
]);
