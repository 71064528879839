// creates
app.service('DownloadCSV', [
  '$filter',
  function ($filter) {
    var self = this;
    var swapableColumnMap = {
      top_scores: { name: 'Best Score', numTimings: 5 },
      avg_top_3: { name: 'Avg. Top 3', numTimings: 3 },
      avg_top_4: { name: 'Avg. Top 4', numTimings: 4 },
      avg_top_5: { name: 'Avg. Top 5', numTimings: 5 },
    };

    // creates an anchor element with data attached, clicks it, then removes the element
    // data can be:
    // var data = [
    //   ['name1', 'city1', 'some other info'],
    //   ['name2', 'city2', 'more info']
    // ];
    this.save = function (data, fileName, mimeType) {
      var a = document.createElement('a');
      mimeType = mimeType || 'application/octet-stream';

      // Scan all cells for commas and escape them
      // Any cell that has double quote: " => ""
      // All cells should be wrapped in double quotes to escape any commas or other terminal chars
      const cleanedData = data.map((row) => row.map((cell) => `"${String(cell).replaceAll('"', '""')}"`));

      // content is a large string, separated by ';' and '/n'
      var content = convertDataToString(cleanedData);

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && 'download' in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
      }
    };

    // This function generates rows of data based on the doc prod gradesheet view and call
    // .save() method with it to export the table as csv
    // user is an object in the form of { first_name: 'Bob', ...}
    // section is an object in the form of { name: 'Section name', ...}
    // courseStructure is in the form [{name: 'week 1, courseWork:[{id: 1, type: 'Document Production', copiedName: 'Demo', options: {base_points: 10}}]}]
    this.saveDocProdClasslist = function (user, section, courseStructure) {
      // Get a list of all assignments across all weeks
      const allAssignments = courseStructure.reduce((acc, week) => {
        return [...acc, ...week.courseWork];
      }, []);

      // ------------------------------
      // 1. File Info Rows (eg. instructor name, course name, etc)
      // ------------------------------

      // data is an array of arrays that we'll output to as a CSV
      const fileInfoRows = [
        // First 4 rows that contain some file info and a vertical spacer
        ['Course:', section.name],
        ['Instructor:', user.first_name + ' ' + user.last_name],
        ['Download:', $filter('date')(new Date(), 'MMM d y'), $filter('date')(new Date(), 'h:mm:ss a')],
        ['', ''],
      ];

      // ------------------------------
      // 2. Table headings (week names, document assignment names, etc)
      // ------------------------------

      let weekHeadingsRow = ['', '', ''];
      courseStructure.forEach((week) => {
        // pad-right the week name with empty cells so it matches the assignments row below
        // each assignment will take up 3 columns, so multiply number of assignments per week by 3 and subtract 1
        // since the week name takes up a column
        const emptyCells = Array(week.courseWork.length * 3 - 1).fill('');
        weekHeadingsRow.push(week.name, ...emptyCells);
      });

      // the columns on the next row will contain First name, Last name, and Student Number
      let assignmentHeadingsRow = ['Students', '', ''];
      // The subheadings row will contain first name, last name, and student number headings, as well as first, best, and all column titles
      // repeated for every assignment
      let assignmentSubHeadingsRow = ['First Name', 'Last Name', 'Student Number'];
      allAssignments.forEach(({ options, copiedName, type }) => {
        // add an assignment name and 2 empty columns for each assignment (each student row below will have 3 columns for each assignment)
        if (type === 'Document Production') {
          assignmentHeadingsRow.push(`${copiedName} (${options.base_points})`, '', '');
        } else {
          assignmentHeadingsRow.push(`${copiedName}`, '', '');
        }
        assignmentSubHeadingsRow.push('First', 'Best', 'All');
      });

      // Combine the 3 headings rows
      const tableHeadingsRows = [weekHeadingsRow, assignmentHeadingsRow, assignmentSubHeadingsRow];

      // ------------------------------
      // 3. Student rows
      // ------------------------------
      const students = sortStudents(section);

      // Go through each student and generate a student row with first name, last name, student number,
      // and 3 values for each assignment: first, best, and all grades
      const studentRows = students.map(({ first_name, last_name, last_name_code, student_number, document_assignments }) => {
        const infoColumns = [first_name, `${last_name}${last_name_code ? ' ' + last_name_code : ''}`, student_number || ''];
        let gradeColumns = [];
        allAssignments.forEach(({ id, type }) => {
          // Using the order of assignments in allAssignments array,
          // pull the corresponding assignments from each user (which contain submissions)
          const studentAssignment = document_assignments[id];
          // create empty columns for first, best, and all grades, if student has no submissions for this assignment
          // Also empty columns for non doc prod assignments
          if (!studentAssignment || type !== 'Document Production') {
            gradeColumns.push('', '', '');
            return;
          }
          const allSubmissions = studentAssignment.document_submissions || [];

          // Extract the grades and generate column values
          // NOTE: make sure the grades being saved are integers and 0 values can go through (hence why using isInt helper)
          const firstGrade = allSubmissions.length > 0 && isInt(allSubmissions[0].points) ? `${allSubmissions[0].points}` : '';
          const bestGrade = isInt(studentAssignment.bestGradeSubmission.points)
            ? `${studentAssignment.bestGradeSubmission.points}`
            : '';
          // NOTE: for commas to be allowed in a csv file, they must be wrapped in double quotes
          const allGrades = allSubmissions.length > 0 ? allSubmissions.map((grade) => grade.points).join(', ') : '';

          // Generate the 3 columns
          gradeColumns.push(firstGrade, bestGrade, allGrades);
        });
        return [...infoColumns, ...gradeColumns];
      });

      // Put all the rows together for the final csv output
      const data = [...fileInfoRows, ...tableHeadingsRows, ...studentRows];

      var filename = section.name + ' - d - ' + $filter('date')(new Date(), 'yyyy-MM-dd') + '.csv';
      self.save(data, filename);
      return true;
    };

    // helper method for classlist download.
    //  placed here to help dry up the ClasslistController
    this.saveClasslist = function (user, section, view) {
      const swapableColumn = section.view.swapableColumnSelector;

      const minPracticeTimeMinutes = section.view.minPracticeTimeMinutes;
      const numberOfTimingsOnCSV = swapableColumnMap[swapableColumn].numTimings;
      const numPracticeDays = 9;

      // data is an array of arrays that we'll output to as a CSV
      var data = [
        ['Course:', section.name],
        ['Instructor:', user.first_name + ' ' + user.last_name],
        ['Download:', $filter('date')(new Date(), 'MMM d y'), $filter('date')(new Date(), 'h:mm:ss a')],
        // ['Practice:', `${minPracticeTimeMinutes} mins.`],
        ['', ''],
      ];

      // Heading Row -------
      let tableHeadings = ['Students', '', '', 'Timings'];
      // add empty cells for each timed writing
      for (let i = 0; i < numberOfTimingsOnCSV; i++) tableHeadings.push('');
      // Practice Times
      tableHeadings.push('Practice');
      tableHeadings.push(`Min. ${minPracticeTimeMinutes}m`);
      for (let i = 0; i < numPracticeDays - 2; i++) tableHeadings.push('');
      tableHeadings.push(`Lessons`);
      data.push(tableHeadings);
      // End Heading Row -----

      var lessonNames = view.lessonNames;
      var headerRow = ['First Name', 'Last Name', 'Student Number'];
      for (let i = 0; i < numberOfTimingsOnCSV; i++) {
        headerRow.push('Timing ' + String(i + 1));
      }
      headerRow.push(swapableColumnMap[swapableColumn].name);
      // add practice times - [Yesterday, 2 Days Ago, ..., 9 Days Ago, Raw Data]
      headerRow = headerRow.concat(practiceTimeHeadings(numPracticeDays));
      // adding lesson names
      headerRow = headerRow.concat(
        lessonNames.map(function (lesson) {
          return lesson.display;
        })
      );
      data.push(headerRow);

      var lessonKeys = lessonNames.map(function (lesson) {
        return lesson.key;
      });
      // filter below makes sure students are sorted in the same order as on the classlist sheet
      var students = sortStudents(section);
      var arrTemp;
      var completionLevel;
      var progress;

      // add timings, 'Avg. Top 3', and 'Avg. Top 5'
      var bestTimings;
      for (let i = 0; i < students.length; i++) {
        const student = students[i];
        const { first_name, last_name, last_name_code, student_number } = student;
        arrTemp = [first_name, `${last_name}${last_name_code ? ' ' + last_name_code : ''}`, student_number || ''];
        // add the timed writings
        bestTimings = getBestTimedWritings(student, section.test.type, numberOfTimingsOnCSV);
        arrTemp = arrTemp.concat(bestTimings);

        // add swapable column
        if (swapableColumn === 'top_scores') {
          arrTemp.push(student[swapableColumn][0][section.test.type]);
        } else if (swapableColumn === 'avg_top_3') {
          arrTemp.push(String(student['avg_top_3'][0].speed));
        } else if (swapableColumn === 'avg_top_4') {
          arrTemp.push(String(student['avg_top_4'][0].speed));
        } else if (swapableColumn === 'avg_top_5') {
          arrTemp.push(String(student['avg_top_5'][0].speed));
        } else {
          console.log('Warning: Trying to export an invalid swapable column heading.');
        }

        // add practice time
        arrTemp = arrTemp.concat(formatPracticeTime(student, minPracticeTimeMinutes, numPracticeDays));

        // add lessons
        lessonKeys.forEach(function (key) {
          progress = student.lesson_progress[0][key];
          if (progress.gold.percentage == 100) {
            completionLevel = 'Gold';
          } else if (progress.silver.percentage == 100) {
            completionLevel = 'Silver';
          } else if (progress.bronze.percentage == 100) {
            completionLevel = 'Bronze';
          } else {
            completionLevel = '--';
          }
          arrTemp.push(completionLevel);
        });
        data.push(arrTemp);
      }

      var filename = section.name + ' - ' + $filter('date')(new Date(), 'yyyy-MM-dd') + '.csv';
      self.save(data, filename);
      return true;
    };

    // helper functions ------------------------------------------------------------------
    function getBestTimedWritings(student, speedMetric, n) {
      let timing;
      var tempArr = [];
      for (var i = 0; i < n; i++) {
        timing = student.timed_writings.filter(function (el) {
          return el.test_ranking_by_user === i + 1;
        })[0];
        // only display the number if they achieved the minimum accuracy
        if (timing && timing.achieved_min_accuracy) {
          tempArr.push(String(timing[speedMetric]));
        } else {
          tempArr.push('0');
        }
      }
      return tempArr;
    }

    // returns an array that's numHeadings+1 long and looks like:
    // [Yesterday, 2 Days Ago, ..., 9 Days Ago, Raw Data]
    function practiceTimeHeadings(numHeadings) {
      let arr = [];
      for (let i = 0; i < numHeadings; i++) {
        arr.push(addDays(new Date(), -(i + 1)));
      }
      arr = arr.map((el) => el.toDateString());
      // arr.push('Raw Data')
      return arr;
    }

    // formats practice times for out put to gradesheet
    function formatPracticeTime(student, minPracticeTimeMinutes, numDays) {
      // first day is removed in mapPracticeTime, we slice to limit the number of days
      const practiceTimes = mapPracticeTime(student).slice(0, numDays);

      // adds something like: ['X', '', '', '', 'X', '', '', '', '']
      let studentPracticeTimes = [];
      studentPracticeTimes = studentPracticeTimes.concat(practiceTimes);

      // transform the studentPracticeTimes
      studentPracticeTimes = studentPracticeTimes.map((pt) => (pt >= minPracticeTimeMinutes ? `/ / / / /  ${pt}` : `${pt}`));

      // summary column: 0 0 23 4 52 0 0 0
      // studentPracticeTimes.push(practiceTimes.join(' '));

      return studentPracticeTimes;
    }

    // returns an array of practice times to add to the gradesheet
    // removes the first day
    function mapPracticeTime(student) {
      // creates an array: [0, 41, 0, 0, 0, 21, 0, 0, 0, 0]
      // but slices the first element: [41, 0, 0, 0, 21, 0, 0, 0, 0]
      // note: we slice the first element as it's TODAY's practice time, and could confuse instructors
      //  if the student practices later in the day but they use this for evaluation
      return student.practice_time[0].practice_time.map((pt) => pt.minutes).slice(1);
    }

    // Checks if the provided value is an integer
    // Same function as one used in Student Factory for grades
    function isInt(value) {
      return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
    }

    // converts array of arrays (data) into a string that can be written-out
    function convertDataToString(data) {
      // Building the CSV from the Data two-dimensional array
      // Each column is separated by ";" and new line "\n" for next row
      var csvContent = '';
      data.forEach(function (infoArray, index) {
        const dataString = infoArray.join(',');
        csvContent += index < data.length ? dataString + '\n' : dataString;
      });

      return csvContent;
    }

    function sortStudents(section) {
      return $filter('orderBy')(
        section.students,
        [section.view.sortType, section.view.sortType === 'first_name' ? 'last_name' : 'first_name', 'last_name_code'],
        section.view.sortReverse
      );
    }

    // returns a new Date object
    // Ex: addDays(new Date(), -1);
    function addDays(date, days) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + days,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      );
    }

    return this;
  },
]);
