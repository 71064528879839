// shows all the course templates for all colleges
// clicking on one leads to the course builder
app.controller('CourseTemplatesAdminController', [
  '$scope',
  '$http',
  function ($scope, $http) {
    // loaded from the server
    $http.get('api/admin/templates').then(function (res) {
      // Note: the last line removes duplicates from our array
      $scope.organizationNames = res.data.courseTemplates
        .map(function (ct) {
          return ct.organization_name;
        })
        .reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, []);

      // need organizations for reassignment of course templates
      $scope.organizations = res.data.organizations;

      $scope.courseTemplates = res.data.courseTemplates;
      return res.data;
    });
  },
]);
