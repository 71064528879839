import availableDocumentAssignmentFilters from '../document_production/assignment_creator/availableDocumentAssignmentFilters';
import sortDocumentAssignmentCategories from '../document_production/assignment_creator/sortDocumentAssignmentCategories';
import customDocumentAssignmentSort from '../document_production/assignment_creator/customDocumentAssignmentSort';
import CourseTemplate from './CourseTemplate';

app.controller('CourseBuilderControllerV2', [
  '$scope',
  '$timeout',
  'CourseBuilderDataService',
  '$state',
  'courseTemplate',
  'allAssignments',
  '$stateParams',
  'organizations',
  function ($scope, $timeout, CourseBuilderDataService, $state, courseTemplate, allAssignments, $stateParams, organizations) {
    $scope.allAssignments = allAssignments;
    $scope.courseTemplate = new CourseTemplate(courseTemplate, allAssignments);
    $scope.organizations = organizations;
    const allDocumentAssignments = allAssignments.filter((a) => a.type == 'Document Production');
    $scope.allKeyboardingAssignments = allAssignments.filter((a) => a.type == 'Keyboarding');

    $scope.addMultipleWeeks = function (weekIndex, content) {
      const isSuccess = $scope.courseTemplate.addMultipleWeeks(weekIndex, content);
      if (isSuccess) {
        // clear the input
        $scope.view.addMultipleWeeks[weekIndex] = '';
        // hide the form
        const hideShowButtonId = weekIndex === undefined ? `addMultipleWeeksButton` : `addMultipleWeeks${weekIndex}Button`;
        document.getElementById(hideShowButtonId).click();
      }
    };

    // Used to filter options available when selecting "Document Assignments  2.2 Canada"
    // structure:
    //  groupedAssignments[country][style_guide_version] = [{}, {}, {}, ...]
    //  groupedAssignments[null][null] returns all assignemnts
    $scope.groupedDocumentAssignmentOptions =
      CourseBuilderDataService.groupDocumentAssignmentsByCountryAndStyleGuideVersion(allDocumentAssignments);

    // Adds a _selected property to each option in the dropdowns to indicate which assignments have been used
    function updateSelectedDocumentAssignments() {
      $scope.courseTemplate.structure.forEach((week) => {
        week.courseWork.forEach(({ id, type, country, style_guide_version }) => {
          if (type == 'Document Production') {
            $scope.groupedDocumentAssignmentOptions[country][style_guide_version].find((a) => id == a.id)._selected = true;
          } else if (type == 'Keyboarding') {
            $scope.allKeyboardingAssignments.find((a) => id == a.id)._selected = true;
          }
        });
      });
    }
    updateSelectedDocumentAssignments();

    // We add new courseWork in two steps:
    // 1. Clicking 'Add New Course Work' calls `addEmptyCourseWorkToWeek`. This presents a select box for the admin to choose the
    //    type of course work, such as a Document Assignment, Keyboarding Assignment, etc. These can be filtered using
    //    filterOptions.
    // 2. Next, a select box is presented with the filtered assignment options. Selecting one of these options calls
    //    `selectCourseWork` which then adds it to the week.
    // Adds a new empty assignment at the given week and assignment index
    $scope.addEmptyCourseWorkToWeek = function (week, weekIndex, courseWorkIndex, type, filterOptions = {}) {
      $scope.view.assignmentSelectFilter = filterOptions;
      delete filterOptions.$$hashKey; // remove the angularjs added key
      // insert an empty document assignment in week weekIndex at position courseWorkIndex
      const originalCourseWork = week.courseWork;
      const emptyAssignment = { type, ...filterOptions };
      $scope.courseTemplate.structure[weekIndex].courseWork = [
        ...originalCourseWork.slice(0, courseWorkIndex + 1),
        emptyAssignment,
        ...originalCourseWork.slice(courseWorkIndex + 1),
      ];
    };

    $scope.deleteWeek = function (weekIndex) {
      $scope.courseTemplate.deleteWeek(weekIndex);
    };

    $scope.deleteCourseWorkFromWeek = function (week, weekIndex, courseWorkIndex) {
      $scope.courseTemplate.deleteCourseWorkFromWeek(week, weekIndex, courseWorkIndex);
    };

    // Inserts the selected assignment, including copying over a base set of properties, into the
    // courseTemplate.
    $scope.selectCourseWork = function (courseWork, _idStr, weekIndex, courseWorkIndex) {
      $scope.courseTemplate.addCourseWorkToWeek(courseWork, _idStr, weekIndex, courseWorkIndex);
      $scope.flashMessages = [];
    };

    const sortedDocumentAssignmentCategories = sortDocumentAssignmentCategories(allDocumentAssignments);
    $scope.view = {
      addMultipleWeeks: [], // used by "+ Add Mulitple Weeks" form
      // This is for displaying a list of buttons that user can click to filter document assignments by
      availableFilters: availableDocumentAssignmentFilters,
      // Sorts document assignments in the options in the same way as the assignment creator
      customDocumentAssignmentSort,
      // returns an ordered list of documentAssignment categories for rendering optgroup elements in view
      // we display this category list and once the user selects one we use it as a filter for the assignments
      categories: (documentAssignments) => {
        if (!documentAssignments) return;
        // Extract unique categories from document assignments
        const categories = [...new Set(documentAssignments.map((da) => da.category))];
        // Filter out categories that do not exist in the document assignments
        return sortedDocumentAssignmentCategories.filter((category) => categories.includes(category));
      },
    };

    // Used to populate the select options. Provide a documentAssignment with a country and style_guide_version
    // and this will return all other document assignments from the same country and style guide version
    // let documentAssignmentCache = {};
    $scope.documentAssignmentOptions = ({ country, style_guide_version }) => {
      if (country && style_guide_version) {
        return allDocumentAssignments.filter((da) => da.country == country && da.style_guide_version == style_guide_version);
      } else {
        return allDocumentAssignments;
      }
    };

    // Array for storing flash messages that are displayed at the top of the page
    $scope.flashMessages = $stateParams.flashMessages || [];

    // activate tooltips
    $timeout(function () {
      // Code snippet from docs https://getbootstrap.com/docs/5.1/components/tooltips/#example-enable-tooltips-everywhere
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
      });
    }, 1000);

    /* *****
     * Functions - scope mapping
     * *****/
    $scope.expandAll = expandAll;
    $scope.collapseAll = collapseAll;

    /* *****
     * Functions definitions
     * *****/

    // collapse all collapsible levels
    function collapseAll(currentLevelObj) {
      Object.keys(currentLevelObj).forEach(function (elem) {
        currentLevelObj[elem] = true;
      });
    }

    // expand all collapsible levels
    function expandAll(currentLevelObj) {
      Object.keys(currentLevelObj).forEach(function (elem) {
        currentLevelObj[elem] = false;
      });
    }

    // Submit the course template to the server. Handles create and update
    $scope.updateCourseTemplate = function () {
      const newTemplate = !$scope.courseTemplate.id;

      CourseBuilderDataService.updateCourseTemplate($scope.courseTemplate)
        .then(({ flashMessages, courseTemplate }) => {
          if (flashMessages) $scope.flashMessages = flashMessages;
          if (courseTemplate) $scope.courseTemplate = new CourseTemplate(courseTemplate, allAssignments);
          if (courseTemplate) updateSelectedDocumentAssignments();
        })
        .then(() => {
          // if a new courseTemplate was created, redirect
          if (newTemplate && $scope.courseTemplate) {
            $state.go('course_builder.show', {
              id: $scope.courseTemplate.id,
              flashMessages: [
                {
                  text: 'Success! New course template has been created',
                  error: false,
                },
              ],
            });
          }
        });
    };

    // Removes the clicked-on flash message, based on index in the array
    $scope.clearFlashMessage = function (idx) {
      $scope.flashMessages.splice(idx, 1);
    };

    $scope.moveWeek = function (weekIndex, direction) {
      $scope.courseTemplate.moveWeek(weekIndex, direction);
    };

    // Adds a new week to the currentCourseTemplate
    $scope.addWeekToCourse = function (index) {
      $scope.courseTemplate.addWeekToCourse(index);
    };
  },
]);
