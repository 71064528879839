/* global app, angular */
app.factory('DocumentVariablesList', [
  'RandomDate',
  'RandomText',
  'RandomContact',
  'ContactsList',
  '$q',
  '$http',
  function (RandomDate, RandomText, RandomContact, ContactsList, $q, $http) {
    // Class that manages a list of randomization variables for a document assignment
    // Properties found in 'props' will be transfered to the instance object (this is how this class
    // is instantiated with extisting variables). `documentAssignmentId` is the assignment variables belong to
    // 'jsonSolutionKey' is used to fetch the parsed solution json file and extract variable names, so they can be shown to the user
    // `contactsDirectoryUrl` is a string web address from where the contacts directory is loaded from
    function DocumentVariablesList(props, documentAssignmentId, jsonSolutionKey, contactsDirectoryUrl, generatedVariables = {}) {
      this._props = props;
      this.contactsDirectoryUrl = contactsDirectoryUrl;
      this.view = {
        // Generated flat object of documentVariables and randomization params
        jsonPreview: '',

        // Generated randomized preview of documentVariables
        randomizedJsonPreview: '',

        // Indicator of how many different objects we can actually generate given current randomization params
        // this gets populated by running this.runGenerateManyTimes() function
        randomizationStats: {}, //eg. {uniqueGenerated: 1, totalGenerated: 2000}

        // List of all variable names of type 'contact' and type 'date', respectively
        // gets updated as variables are added/removed to the list
        // this is the dropdown list of contacts/dates that other contacts/dates can
        // reference (eg. company, anchorDate) in 'same as another contact'/'same as another date'
        contactRefOptions: [],
        dateRefOptions: [],
        // dropdown list of industries user can pick for a contact variable
        industryOptions: [],
        // dropdown list of companies user can pick for a contact variable
        companyOptions: [],
        flashMessage: null, // eg. null or {message: '', error: false}
        loading: false, // this determines when to disable save button and show loading animation
        // variables names extracted from the solution file
        solutionFileVariableNames: {
          loading: false,
          // an object when defined, where the value is a boolean
          //(whether the variable has been covered by the random variables form). eg. {'contact.first_name': true},
          collection: null,
        },
        // expands/collapse the form for generating document variables from presets
        showPresetsGeneratorForm: false,
      };

      // ng-model for the document variables variables generator form
      this.presetsGenerator = {
        internalCompany: '',
      };

      // Holds the list of variable objects. Even if props passed in contain existing variables,
      // this factory must be first initialized via .init() method to populate this instance variable
      this.documentVariables = [];

      // Generated variables persisted in the db for current user + section
      // These are displayed next to `Variables in Solution File` in Assignment Creator
      this.persistedGeneratedVariables = generatedVariables.variables;

      // Used to fetch the parsed json solution file for variable names
      this.jsonSolutionKey = jsonSolutionKey;

      this.document_assignment_id = documentAssignmentId;

      this.contactsList = new ContactsList();

      //ng-model for creating a new variable name
      this.newVariableName = '';

      // a copy of document variables list (object representation) as it is currently in the database.
      // Used to prevent saving the list if no actual changes have been made to it in the front end form
      // (this is to prevent the version attribute from accidentally incrementing and causing all users to re-generate their variables)
      this.documentVariablesListSnapshot = {};
    }

    // Async initializes the factory by assigning passed in props to self
    // (and instantiating variable types factories, eg. RandomContact)
    // after the contacts directory has been async loaded (necessary for instantiating RandomContact)
    // This function will populate the internal documentVariables property
    // returns a promise
    DocumentVariablesList.prototype.init = function () {
      const self = this;
      return this.contactsList
        .loadContactsDirectory(self.contactsDirectoryUrl, self.document_assignment_id)
        .then(() => {
          this.view.industryOptions = this.contactsList.generateIndustryOptions();
          this.view.companyOptions = this.contactsList.generateInternalCompanyOptions();
          // This will load and instantiate existing variables. Contacts dir must be already loaded,
          // since it will be passed in to variables of type 'contact'
          this.assignAllPropertiesToSelf(self._props);
          // update list of dropdown options for 'same as another contact'
          this.updateContactRefOptions();
          this.updateDateRefOptions();
          this.updateAllDocumentVariableForms();

          // Update the snapshot with what's currently in the database
          this.documentVariablesListSnapshot = this.toObj();
          return this.getVariableNames();
        })
        .catch(() => {
          this.view.flashMessage = {
            message:
              'Could not load the document variables widget. Make sure a valid Contacts Directory Url is provided. If the problem persists, please contact Typist support.',
            error: true,
          };
          window.scrollTo(0, 0); // scrolls to top of page
          // No need to propagate the error because this is called in the state's resolve, and
          // we want the page to render and display ther errors instead
        });
    };

    // Fetches the parsed solution json file and extracts the variable names associated with the document
    // Stores the results in this.view.solutionFileVariableNames and determines if the current document variables form
    // has defined the variables in the solution file
    DocumentVariablesList.prototype.getVariableNames = function () {
      if (!this.jsonSolutionKey) {
        return $q.resolve();
      }
      this.view.solutionFileVariableNames.loading = true;
      $http
        .get(`${this.jsonSolutionKey}`)
        .then((res) => {
          this.view.solutionFileVariableNames.loading = false;
          const variableNames = res.data.variableNames || [];
          this.view.solutionFileVariableNames.collection = variableNames.length
            ? variableNames.reduce((acc, name) => {
                return {
                  ...acc,
                  [name]: false,
                };
              }, {})
            : null;
          // Go over the variables and compare to what we have defined in the document variables form
          this.areFileVariableNamesDefined();
        })
        .catch((e) => {
          this.view.solutionFileVariableNames.loading = false;
          this.view.flashMessage = {
            // This message might appear if an attempt to fetch
            // the variable names happens too soon after uploading new solution file
            message: 'Error getting variable names defined in the solution file. Please try again by refreshing the page.',
            error: true,
          };

          window.scrollTo(0, 0); // scrolls to top of page
          return $q.reject(e);
        });
    };

    // Runs the generate function to generate a randomized object which can be used to
    // compare the solutionFileVariableNames collection. The collection is updated
    // with true values for each key if same key exists in a generated object
    DocumentVariablesList.prototype.areFileVariableNamesDefined = function () {
      if (!this.view.solutionFileVariableNames.collection) return;

      const randomizedObject = placeholderTypistInitials(this.generate());
      const newCollection = {};
      for (const varName in this.view.solutionFileVariableNames.collection) {
        if (Object.prototype.hasOwnProperty.call(this.view.solutionFileVariableNames.collection, varName)) {
          newCollection[varName] = Object.prototype.hasOwnProperty.call(randomizedObject, varName);
        }
      }
      this.view.solutionFileVariableNames.collection = newCollection;
    };

    // This function will go through the documentVariables list of objects
    // and using each objects' parameters around randomization, will generate a flattened list of placeholder replacements
    // with randomized values. eg. { 'contact1.first_name': 'Bob', 'contact1.last_name': 'Ross', dollar_amount: '100'}
    DocumentVariablesList.prototype.generate = function () {
      // 1. Sort contact variables such that a referenced contact exists earlier in the stack
      // throw error if not
      const sortedDocumentVariables = sortVariablesByContactReference(this.documentVariables);

      // 2. Generate randomized variables. Keep the nested object structure (eg. {'contact1':{first_name: 'Bob'} }) because
      // much easier to pass these objects as referenceContact
      const randomizedVariables = sortedDocumentVariables.reduce((acc, documentVariable) => {
        let referenceContact;
        let referenceDate;
        // 2. Extract referenced contact and/or date if company/anchorDate is using a reference
        if (documentVariable.type === 'contact') {
          if (documentVariable.params.company.ref) {
            referenceContact = acc[documentVariable.params.company.ref];
          }
          return {
            ...acc,
            [documentVariable.name]: documentVariable.generate(referenceContact, acc),
          };
        } else if (documentVariable.type === 'date') {
          if (documentVariable.params.anchorDate.ref) {
            referenceDate = acc[documentVariable.params.anchorDate.ref];
          }
          return {
            ...acc,
            [documentVariable.name]: documentVariable.generate(referenceDate),
          };
        }
        return {
          ...acc,
          [documentVariable.name]: documentVariable.generate(),
        };
      }, {});

      // Flatten the randomized variables object, so that variables of type `contact`
      // have the keys collapsed eg. { 'contact1.first_name': 'Bob' }. This is the format
      // that the document analyzer will take as a param
      // Note adding Typist initials here, because they are actually being merged in the backend, so they are
      // technically not part of document variables list
      return flattenObject(randomizedVariables);
    };

    // Returns an object representation of documentVariablesList, with
    // all the randomization rules for each variable
    DocumentVariablesList.prototype.toObj = function () {
      // Need to deep clone the documentVariable objects because if we use angular.equal() (used to see if there were any changes made to object since last save)
      // the function stops checking the attributes of a nested object if it strictly equals another. In our case,
      // the nested .params object stays the same between updates, but its nested attributes change. In order to deep compare the values of the
      // .params object, we do need to deep clone the entire thing
      return this.documentVariables.map((documentVariable) => angular.merge({}, documentVariable.toObj()));
    };

    // Runs the validations on the variables list and each variable
    // returns true if all validations pass, throws errors otherwise
    DocumentVariablesList.prototype.validate = function () {
      // Make sure there are no duplicate variable names
      const areNamesUnique = uniqueArray(this.documentVariables.map((dv) => dv.name)).length === this.documentVariables.length;
      if (!areNamesUnique) throw new Error('Variables names must be unique.');

      return this.documentVariables.every((documentVariable) => documentVariable.validate());
    };

    // Generates a randomized instance of the document variables
    // sets the result in this.view.randomizedJsonPreview
    DocumentVariablesList.prototype.generatePreview = function () {
      this.view.flashMessage = null;
      try {
        this.validate();
        this.view.randomizedJsonPreview = JSON.stringify(placeholderTypistInitials(this.generate()), null, 2);
      } catch (e) {
        this.view.flashMessage = {
          message: e.message,
          error: true,
        };

        window.scrollTo(0, 0); // scrolls to top of page
      }
    };

    // Function generates thousands of randomized instances of the document variables and
    // reports on the number of uniquely generated vs total generated. The result
    // is stored in this.view.randomizationStats object
    DocumentVariablesList.prototype.runGenerateManyTimes = function (numSimulations = 1000) {
      // 1. run this.generate10000 times, collect and stringify results
      let results = [];

      for (let i = 0; i < numSimulations; i++) {
        const generated = this.generate();
        results.push(JSON.stringify(generated));
      }
      // 2. run uniqueness check and report the stats
      this.view.randomizationStats = {
        uniqueGenerated: uniqueArray(results).length,
        totalGenerated: numSimulations,
      };
    };

    // On-click event for generating JSON representation of the document variables
    // result is stored in this.view.jsonPreview as a string
    DocumentVariablesList.prototype.generateJsonPreview = function () {
      this.view.jsonPreview = JSON.stringify(this.toObj(), null, 4);
    };

    // This function will add a new variable to this.documentVariables, instantiating a factory
    // that corresponds to the variableType (a string, eg. 'contact') with a provided variableName (string).
    DocumentVariablesList.prototype.addVariable = function (variableName, variableType, params) {
      this.view.flashMessage = null;
      // 1a. Make sure there are no duplicate variableName values and variableName is not an empty string
      if (!isNameUnique(variableName, this.documentVariables)) {
        this.view.flashMessage = {
          message: `Variable with name "${variableName}" already exists. Please use a different name.`,
          error: true,
        };
        window.scrollTo(0, 0); // scrolls to top of page
        throw new Error('Could not add variable');
        // return;
      }

      //1b. If invalid characters, display error. Make sure the regexp allows same characters as document analyzer
      if (!/^[A-Za-z0-9._]+$/.test(variableName)) {
        this.view.flashMessage = {
          message: 'Variable names can only contain letters, numbers, periods, and underscores.',
          error: true,
        };
        window.scrollTo(0, 0); // scrolls to top of page
        // return ;
        throw new Error('Could not add variable');
      }

      // 1c. Make sure the variable name is not a variable being dynamically included in the backend (eg. typist_initials)
      if (variableName === 'typist_initials') {
        this.view.flashMessage = {
          message: 'This variable name is a reserved word. "typist_initials" is already auto-included based on user preferences.',
          error: true,
        };
        window.scrollTo(0, 0); // scrolls to top of page
        throw new Error('Could not add variable');
        // return;
      }

      // 2. Instantiate and add a variable factory depending on the type
      switch (variableType) {
        case 'contact':
          this.documentVariables = [
            new RandomContact(
              {
                name: variableName,
                ...params,
              },
              this
            ),
            ...this.documentVariables,
          ];
          this.updateContactRefOptions();
          break;
        case 'text':
          this.documentVariables = [
            new RandomText({
              name: variableName,
              ...params,
            }),
            ...this.documentVariables,
          ];
          break;
        case 'date':
          this.documentVariables = [
            new RandomDate({
              name: variableName,
              ...params,
            }),
            ...this.documentVariables,
          ];
          this.updateDateRefOptions();
          break;
        default:
          break;
      }

      // 3. Clear newVariableName so the create function doesn't get spammed
      this.newVariableName = '';
    };

    // This function will remove a specicic variable from documentVariables
    // variableInstance param is the variable instance being removed
    DocumentVariablesList.prototype.removeVariable = function (variableInstance) {
      // Note: using the instance, instead of idx because if angular does any sorting,
      // the index will be relative to the sort, not the original array

      // 1. Find the index of the variableInstance in the original array to be removed
      const variableIdx = this.documentVariables.findIndex((docVar) => docVar.name === variableInstance.name);

      // 2. Remove the item
      this.documentVariables.splice(variableIdx, 1);
      // 3. Check if any other contact is referencing the deleted variableInstance, and clear that reference
      this.documentVariables.forEach((docVar) => {
        if (docVar.type === 'contact') docVar.removeContactReference(variableInstance.name);
      });

      this.updateContactRefOptions();
      this.updateDateRefOptions();
    };

    // update dropdown list of contacts that other contacts can
    // reference (eg. company) in 'same as another contact'
    DocumentVariablesList.prototype.updateContactRefOptions = function () {
      this.view.contactRefOptions = this.documentVariables
        .filter((docVar) => docVar.type === 'contact')
        .map((docVar) => docVar.name);
    };

    // update dropdown list of contacts that other dates can
    // reference (eg. anchorDate) in 'same as another date'
    DocumentVariablesList.prototype.updateDateRefOptions = function () {
      this.view.dateRefOptions = this.documentVariables.filter((docVar) => docVar.type === 'date').map((docVar) => docVar.name);
    };

    // This function will communicate with server and save the current documentVariablesList
    // returns a promise
    DocumentVariablesList.prototype.save = function () {
      const self = this;
      this.view.flashMessage = null;
      this.view.loading = true;

      // 1. Run validations and display error messages
      try {
        // a. Running this will catch any errors with referencing integrity
        sortVariablesByContactReference(this.documentVariables);
        this.validate();
        // b. check to make sure all variables found in solution are covered by the form
        this.areFileVariableNamesDefined();
        if (
          this.view.solutionFileVariableNames.collection &&
          !Object.values(this.view.solutionFileVariableNames.collection).every((v) => v)
        ) {
          // get rid of this error if we don't want to block the save button
          throw new Error('One ore more variables found in the solution file is not covered by the random variables form.');
        }
      } catch (e) {
        this.view.loading = false;
        this.view.flashMessage = {
          message: e.message,
          error: true,
        };
        return $q.reject();
      }

      // Check if any changes have been made to the variables list since initial load
      // Note: displaying a flash message (quicker solution) instead of disabling the save button because can't easily detect
      // form changes of variables from within DocumentVariablesList factory (will have to have callbacks within each variable factory)
      const documentVariablesListObj = this.toObj();
      // angular.equals performs a deep object comparison
      if (angular.equals(documentVariablesListObj, this.documentVariablesListSnapshot)) {
        this.view.flashMessage = {
          message: 'The variables were not saved because no new changes have been detected.',
          warning: true,
        };
        window.scrollTo(0, 0); // scrolls to top of page
        this.view.loading = false;
        return $q.resolve();
      }

      // 2. Attempt to save on the server. If an id exists on this doc var list,
      // then we switch to updating rather than creating from scratch
      return $http({
        method: self.id ? 'PUT' : 'POST',
        url: self.id ? `/api/document_variables_lists/${self.id}.json` : `/api/document_variables_lists.json`,
        data: {
          document_variables_list: {
            document_assignment_id: this.document_assignment_id,
            variables: documentVariablesListObj,
          },
        },
      })
        .then((res) => {
          this.assignAllPropertiesToSelf({
            id: res.data.id,
            documentVariables: res.data.variables,
            version: res.data.version,
          });

          this.updateAllDocumentVariableForms();

          this.view.flashMessage = {
            message: 'Success! The variables have been saved.',
            error: false,
          };
          this.view.loading = false;
          window.scrollTo(0, 0); // scrolls to top of page

          // Update the snapshot with what's now in the database
          this.documentVariablesListSnapshot = this.toObj();
        })
        .catch((e) => {
          this.view.flashMessage = {
            message: e.data.errors || 'Could not save the document variables. Please contact Typist support.',
            error: true,
          };
          window.scrollTo(0, 0); // scrolls to top of page
          this.view.loading = false;
          // propagate the error so the calling function knows the save failed
          return $q.reject(e);
        });
    };

    // this function will copy over every key-value pair in props and
    // assign it to self. Special types of objects (eg. 'contact' type variable in documentVariables)
    // will be instantiated using respective variable factories
    DocumentVariablesList.prototype.assignAllPropertiesToSelf = function (props) {
      var self = this;
      for (var prop in props) {
        if (Object.prototype.hasOwnProperty.call(props, prop)) {
          if (prop === 'documentVariables') {
            props[prop] = props[prop].map((documentVariable) => {
              switch (documentVariable.type) {
                case 'contact': {
                  return new RandomContact(documentVariable, this);
                }
                case 'date': {
                  return new RandomDate(documentVariable);
                }
                case 'text': {
                  return new RandomText(documentVariable);
                }
                default: {
                  // TODO: throw error if unrecognized type?
                  console.error('Error: unrecognized document variable type.');
                  return documentVariable;
                }
              }
            });
          }
          self[prop] = props[prop];
        }
      }
    };

    // Run form related updates on all variables stored in documentVariables array.
    // eg. currently this method is called from within RandomContact's onInputChange method,
    // such that if one variable updates it's form selections, all 'contact' variables will update their
    // job titles/positions dropdown options accordingly
    DocumentVariablesList.prototype.updateAllDocumentVariableForms = function () {
      this.documentVariables.forEach((dv) => {
        if (dv.type === 'contact') dv.updateDropdownOptions();
      });
    };

    // ng-submit callback for the preset variables generator
    // given a user chosen internal company, it will pre-populate the variables list with send_date, recipient,
    // sender, cc1, cc2 variables
    DocumentVariablesList.prototype.generatePresetVariables = function () {
      // Exit early if variables already exist
      if (this.documentVariables.length > 0) {
        this.view.flashMessage = {
          message: 'Delete any existing variables before using the generator',
          error: true,
        };
        window.scrollTo(0, 0); // scrolls to top of page
        return;
      }

      // List of jobTitles across internal companies (non Legal)
      // TODO: trim the list
      const permittedJobTitles = {
        internalSender: [
          'Account Executive',
          // 'Brand Manager',
          // 'Chartered Accountant',
          'Chief Executive Officer',
          // 'Chief Marketing Officer',
          // 'Chief Technology Officer',
          'Client Relationship Manager',
          'Customer Support Manager',
          // 'Director of Accounting',
          // 'Director of Awards',
          // 'Director of Communications',
          // 'Director of Digital Marketing',
          // 'Director of Human Resources',
          // 'Director of IT',
          // 'Director of Marketing',
          // 'Director of Operations',
          'Director of Sales',
          'Diversity and Equity Coordinator',
          'Events Manager',
          // 'Facilities Director',
          // 'Office Manager',
          // 'Operations Manager',
          'Outreach Coordinator',
          // 'Parking Coordinator',
          // 'Payroll Manager',
          // 'Payroll and Benefits Director',
          // 'President',
          // 'Procurement Officer',
          'Project Manager',
          // 'Recruiter',
          // 'Senior Bookkeeper',
          // 'Social Media Manager',
          // 'Systems Administator',
          // 'Technology Support Manager',
          // 'Training Manager',
          'Vice-President',
        ],
        internalCC: [
          'Account Executive',
          // 'Brand Manager',
          'Chartered Accountant',
          'Chief Executive Officer',
          // 'Chief Marketing Officer',
          // 'Chief Technology Officer',
          'Client Relationship Manager',
          'Customer Support Manager',
          'Director of Accounting',
          'Director of Awards',
          // 'Director of Communications',
          // 'Director of Digital Marketing',
          'Director of Human Resources',
          // 'Director of IT',
          'Director of Marketing',
          'Director of Operations',
          'Director of Sales',
          'Diversity and Equity Coordinator',
          'Events Manager',
          // 'Facilities Director',
          'Office Manager',
          'Operations Manager',
          'Outreach Coordinator',
          // 'Parking Coordinator',
          // 'Payroll Manager',
          // 'Payroll and Benefits Director',
          'President',
          'Procurement Officer',
          'Project Manager',
          // 'Recruiter',
          'Senior Bookkeeper',
          'Social Media Manager',
          // 'Systems Administator',
          'Technology Support Manager',
          'Training Manager',
          'Vice-President',
        ],
      };

      // NOTE: listing all params because the params object being provided replaces the existing params object that contains defaults
      try {
        console.log('Added the following variables:');
        this.addVariable('sender', 'contact', {
          params: {
            jobTitle: { type: 'specific', specificJobTitles: permittedJobTitles.internalSender },
            company: { ref: '', type: 'specific', specificCompanies: [this.presetsGenerator.internalCompany] },
            industry: { type: '', specificIndustry: '' },
            internal: false,
            addressFeatures: '',
          },
        });

        this.addVariable('cc_1', 'contact', {
          params: {
            jobTitle: { type: 'specific', specificJobTitles: permittedJobTitles.internalCC },
            company: { specificCompanies: [], type: 'reference', ref: 'sender' },
            industry: { type: '', specificIndustry: '' },
            internal: false,
            addressFeatures: '',
          },
        });

        this.addVariable('cc_2', 'contact', {
          params: {
            jobTitle: { type: 'specific', specificJobTitles: permittedJobTitles.internalCC },
            company: { specificCompanies: [], type: 'reference', ref: 'sender' },
            industry: { type: '', specificIndustry: '' },
            internal: false,
            addressFeatures: '',
          },
        });

        this.addVariable('recipient', 'contact', {
          params: {
            jobTitle: { type: 'specific', specificJobTitles: [] },
            industry: { type: '', specificIndustry: '' },
            company: { type: '', ref: '', specificCompanies: [] },
            internal: false,
            addressFeatures: '',
          },
        });

        this.addVariable('send_date', 'date', {
          params: {
            rangeMin: 0,
            rangeMax: 14,
            anchorDate: { type: '', ref: '', specificDate: null },
          },
        });
      } catch (e) {
        this.view.flashMessage = {
          message: 'Error generating preset variables',
          error: true,
        };
        window.scrollTo(0, 0); // scrolls to top of page
        return;
      }

      // Clean-up
      this.view.showPresetsGeneratorForm = false;
      this.presetsGenerator = {};
      this.view.flashMessage = {
        message: 'Preset variables have been successfully generated! Make sure to check over the form and save the changes.',
        error: false,
      };
      window.scrollTo(0, 0); // scrolls to top of page
    };

    /**-----------------
   * Helper functions
  --------------------*/

    // Takes a name (string) and array of document variables ([{name: '', }, ...])
    // Returns true if name is unique
    function isNameUnique(name, documentVariables = []) {
      return documentVariables.findIndex((docVar) => docVar.name === name) === -1;
    }

    // Takes a documentVariables array, and sorts the contact and date variables
    // into the end of the array, such that a contact/date variable being referenced
    // is located earlier in the stack. Throws an error if the array cannot be sorted
    // due to contact/date reference inconsistencies (eg. both contact/date variables reference each other)
    function sortVariablesByContactReference(documentVariables) {
      // 1. Extract all contact variables
      let referencingVars = [],
        otherVars = [];
      documentVariables.forEach((dv) => {
        if (dv.type === 'contact' || dv.type === 'date') {
          referencingVars.push(dv);
        } else {
          otherVars.push(dv);
        }
      });

      let referencingVarsSorted = [];
      let _maxAttempts = referencingVars.length;
      let _prevReferencingVarsSortedLength = -1;

      // Algorithm overview: keep going through referencingVars list (variables like Date and Contact that can reference other variables), and at every iteration
      // move contact/date variables to the referencingVarsSorted list, if
      // a. these contact/date vars are not referencing other contacts
      // b. the contact/date they are referencing is already in the referencingVarsSorted list
      // The loop stopping point is if we exceeded max attempts, the original referencingVars list is depleted (means all the contacts/dates have been sorted)
      // or if there is no difference in referencingVarsSorted length between loops (would happen if there is a sorting issue. Every iteration of the loop
      // is expected to move at least one contact into the sorted array)
      while (
        referencingVars.length > 0 &&
        _maxAttempts > 0 &&
        _prevReferencingVarsSortedLength !== referencingVarsSorted.length
      ) {
        _prevReferencingVarsSortedLength = referencingVarsSorted.length;
        // include items that did not leave original referencingVars list
        let _newReferencingVars = [];
        // include items that are moving to sorted referencingVars
        let _newReferencingVarsSorted = [];
        referencingVars.forEach((item) => {
          // Check if contact/date has no ref defined OR ref is defined and can be found in the sorted array
          let refFound;
          if (item.type === 'date') {
            refFound =
              !item.params.anchorDate.ref || referencingVarsSorted.findIndex((el) => el.name === item.params.anchorDate.ref) >= 0;
          } else if (item.type === 'contact') {
            refFound =
              !item.params.company.ref || referencingVarsSorted.findIndex((el) => el.name === item.params.company.ref) >= 0;
          }

          if (refFound) {
            _newReferencingVarsSorted.push(item);
          } else {
            _newReferencingVars.push(item); // Add it back to the unsorted referencingVars list
          }
        });
        // Update the lists
        referencingVars = _newReferencingVars;
        referencingVarsSorted = [...referencingVarsSorted, ..._newReferencingVarsSorted];
        _maxAttempts--;
      }

      // If no errors with referencing, all variables from referencingVars are expected to be transfered to referencingVarsSorted
      if (referencingVars.length > 0)
        throw new Error(
          'Issues with referencing another contact - make sure all contacts can be generated using their randomization options.'
        );

      return [...otherVars, ...referencingVarsSorted];
    }

    // Helper function that adds a typist_intials placeholder to the generated variables
    // The actual typist initials are dynamically added in the backend, using user preferences table
    function placeholderTypistInitials(generatedVariables = {}) {
      return {
        ...generatedVariables,
        typist_initials: '[auto generated]',
      };
    }

    // Takes a nestedObj and returns a flattened version (max 1 level deep), where the
    // keys are merged into a string with '.' as a separator
    // eg. {contact1:{first_name: 'Bob'}} --> {'contact1.first_name': 'Bob'}
    function flattenObject(nestedObj) {
      return Object.entries(nestedObj).reduce((acc, [varName, varValue]) => {
        // If value is an object, this object will need to be flattened
        if (typeof varValue === 'object') {
          const flattenedVarValue = Object.entries(varValue).reduce((acc2, [propName, propValue]) => {
            const newPropName = `${varName}.${propName}`;
            return {
              ...acc2,
              [newPropName]: propValue,
            };
          }, {});
          return {
            ...acc,
            ...flattenedVarValue,
          };
        }
        // Otherwise, copy over the object with no changes
        return {
          ...acc,
          [varName]: varValue,
        };
      }, {});
    }

    // Returns the passed in array of items without duplicates.
    // Items in original array must be primitives, otherwise uniqueness is not guaranteed
    function uniqueArray(arrWithDups) {
      return [...new Set(arrWithDups)];
    }
    return DocumentVariablesList;
  },
]);
