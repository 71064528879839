app.controller('InstructionsController', [
  '$scope',
  'documentAssignment',
  'permission',
  'currentUser',
  function ($scope, documentAssignment, permission, currentUser) {
    $scope.documentAssignment = documentAssignment;

    // we use user in the view to display some instructions only to instructors,
    //  and others only to students
    $scope.user = currentUser;
  },
]);
