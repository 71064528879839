// Shows classlist (ie student names), allows instructors to give tests.
//  -> ok written, but no need for a rewrite
//  -> to add a modal here, scroll down to the modal section
app.controller('ClasslistController', [
  'Section',
  '$scope',
  '$state',
  '$stateParams',
  'ModalSequence',
  'ModalSequenceArray',
  '$timeout',
  'CacheService',
  'UserSettings',
  'contestSettings',
  'viewSettings',
  'Contest',
  'DefaultSettings',
  'DownloadCSV',
  'Environment',
  'DocumentAssignmentCreatorService',
  '$interval',
  'TestLinks',
  'ClasslistViewDefaults',
  'TooltipService',
  'testLinksSettings',
  function (
    Section,
    $scope,
    $state,
    $stateParams,
    ModalSequence,
    ModalSequenceArray,
    $timeout,
    CacheService,
    UserSettings,
    contestSettings,
    viewSettings,
    Contest,
    DefaultSettings,
    DownloadCSV,
    Environment,
    DocumentAssignmentCreatorService,
    $interval,
    TestLinks,
    ClasslistViewDefaults,
    TooltipService,
    testLinksSettings
  ) {
    // shows buttons if we're in development mode
    $scope.environment = Environment;

    // define view variables
    $scope.view = {
      // show expanded view for Give Assignments
      startAssignments: false,
      // show expanded view for Release Assignment Grades
      releaseGrades: false,
      // show expanded view for Cancel Due Dates
      cancelDueDates: false,
      documentProduction: {
        // true when we are waiting for response from server
        disableForms: false,
        waitingForServer: false,
      },
    };

    // -----------------------------

    // create a new contest object
    $scope.contest = new Contest(
      contestSettings.orDefault({
        duration: '3',
        type: 'netWPM',
        accuracy: 98,
        status: 'observer',
        expiresAfterMinutes: '20',
        practice_text_ids: '',
      })
    );

    // Create a new section.
    //  -> Tries to retrieve the section from our cache, makes sure it's less than 5 minutes old
    //  -> creates a section.test object
    //  -> binds firebase listeners to the section
    $scope.section = CacheService.get($stateParams.code);
    if ($scope.section && $scope.section.msSinceUpdate() < 300000) {
      $scope.section.refreshFirebaseReferences();

      // Instantiate TestLinks factory
      $scope.testLinks = new TestLinks({
        section: $scope.section,
        user: $scope.user,
        settings: testLinksSettings,
      });
    } else {
      $scope.section = new Section($stateParams.code, $scope);

      $scope.section.init().then(function () {
        // Instantiate TestLinks factory
        $scope.testLinks = new TestLinks({
          section: $scope.section,
          user: $scope.user,
          settings: testLinksSettings,
        });
      });
    }

    // Used for sorting ------------

    // this function is attached to the toggleable columns. It returns a string that is picked up by ng-repeat's sort filter
    $scope.sortColumn = function (sortField) {
      // sorting by first/last name, for example, will pass in an argument of 'first_name'. The if statement below
      //  is for the toggleable columns to properly sort.
      if (typeof sortField === 'undefined') {
        var speedType = $scope.section.test.type || 'netWPM';
        sortField =
          $scope.section.view.swapableColumnSelector +
          ($scope.section.view.swapableColumnSelector === 'top_scores' ? '[0].' + speedType : '[0].speed');
      }
      // save this
      $scope.section.view.sortReverse = !$scope.section.view.sortReverse;
      $scope.section.view.sortType = sortField;
      // save the view
      UserSettings.set('class/' + $stateParams.code + '/view', $scope.section.view);
    };

    // This function is attached to the min practice time minutes in the _test_criteria_form
    // It updates the current view settings with Firebase
    $scope.updateMinPracticeTimeMinutes = function () {
      // Make sure the time is a valid integer 0 or greater
      let minPracticeTimeMinutes = parseInt($scope.section.view.minPracticeTimeMinutes, 10);
      if (!isNaN(minPracticeTimeMinutes) && minPracticeTimeMinutes >= 0) {
        // save the view (view contains the property minPracticeTimeMinutes)
        UserSettings.set('class/' + $stateParams.code + '/view', $scope.section.view);
        // Get the practice time array stored in each student object (of current section) to update the stats
        $scope.section.updatePracticeTimeStats();
        $scope.section.updateTooltips();
      }
    };

    $scope.viewStudent = function (student) {
      // cache the data so that the student view can be opened quickly
      CacheService.set($scope.section, $scope.section.code);
      CacheService.set($scope.section.test, 'currentTest' + $scope.section.code);
      $state.go('app.instructor.section.studentView', {
        code: $stateParams.code,
        student_id: student.id,
      });
    };

    // forces tooltips to update. See Section.updateTooltips for documentation... and why this was a major pain.
    $scope.changeSwapableColumn = function () {
      // 1. Set these as default for next time (via Firebase)
      UserSettings.set('class/' + $stateParams.code + '/view', $scope.section.view);
      // 2. update the tooltips so they don't show wrong values.
      $scope.section.updateTooltips();
    };

    // Saves the last time limit (i.e. 110 minutes) that an instructor sets
    $scope.testlinksTutorialCheckboxChange = function () {
      UserSettings.set('class/' + $stateParams.code + '/view/testLinksTutorial', $scope.section.view.testLinksTutorial);
    };

    $scope.scrollToTop = function () {
      window.scrollTo(0, 0);
    };

    // actions row ------------------------------------------------------------
    //  these are the buttons to start a new test, competition, etc.

    // Need to refresh modal references since child views won't have the HTML on the page to bind to
    var createTestModal = new ModalSequence('#create-test-modal');
    var createContestModal = new ModalSequence('#create-contest-modal');
    var modalSequenceArray = new ModalSequenceArray([createTestModal, createContestModal]);
    $scope.$on('$stateChangeStart', function (event, toState) {
      // when someone clicks on the "create test" button
      if (toState.name) {
        createTestModal = new ModalSequence('#create-test-modal');
        createContestModal = new ModalSequence('#create-contest-modal');
        modalSequenceArray = new ModalSequenceArray([createTestModal, createContestModal]);
      }
    });

    $scope.openTestModal = function () {
      createTestModal.start();
      $scope.view.showTestColumn = true;
    };

    // when someone clicks on the "start contest" button on the classlist
    $scope.openContestModals = function () {
      createContestModal.start();
    };

    $scope.createCertificates = function () {
      var instructor = $scope.user.first_name + ' ' + $scope.user.last_name;
      var organization = $scope.user.organization.name;
      var sectionName = $scope.section.name;
      var students = $scope.section.students.map(function (student) {
        if (student.top_scores.length < 1) {
          return {
            id: student.id,
            first_name: student.first_name,
            last_name: student.last_name,
            instructor: instructor,
            organization: organization,
            sectionName: sectionName,
            best_attempt: null,
          };
        } else {
          return {
            id: student.id,
            first_name: student.first_name,
            last_name: student.last_name,
            instructor: instructor,
            organization: organization,
            sectionName: sectionName,
            best_attempt: student.top_scores[0],
          };
        }
      });
      $state.go('app.instructor.certificates/create', {
        students: students || [],
      });
    };

    // modals ------------------------------------------------------------------------------
    // all modals
    // this is the exit button and 'x' in the top right corner
    $scope.exitModal = function () {
      if (modalSequenceArray.isActive()) {
        modalSequenceArray.getActiveModalSequence().exit();
      }
    };
    $scope.nextModal = function () {
      if (modalSequenceArray.isActive()) {
        modalSequenceArray.getActiveModalSequence().next();
      }
    };

    // create test modal -----------------
    // When instructor closes create_test_modal
    $scope.selectStudents = function () {
      $scope.section.students.map(function (student) {
        student.view.buttonDisabled = false;
        student.view.numTestsGiven = 0;
        student.view.buttonMessage = 'Give Test';
        return student;
      });

      // Update the filter, student results...
      $scope.section.test.updateCriteriaFromTestParams();
      $scope.section.updateStatistics($scope.section.test);

      $scope.section.test.view.disableTestAllButton = false;
      $scope.section.test.view.testAllButtonMessage = 'Test All';
      $scope.section.test.view.enableTestAllSelectedButton = false;

      $scope.exitModal();
      $scope.view.allowTestAssignment = true;
      return;
    };

    // start contest modal -----------------
    // When instructor completes first contest modal
    $scope.startContest = function () {
      // protect against doubleclicks
      $scope.disableContestButton = true;
      $timeout(function () {
        $scope.disableContestButton = false;
      }, 5000);

      // update defaults
      // Save defaults to UserSettings!
      UserSettings.set('class/' + $stateParams.code + '/contest', $scope.contest.data);

      // create a new contest in firebase & pg database. returns a promise.
      $scope.contest.set($scope.section).then(function (contest) {
        // $state.href("championship", { notification_id: contest.id }).substring(2)
        $scope.contest.createNotification($scope.section, contest);
        $scope.nextModal();
      });
    };

    $scope.redirectToContest = function () {
      // advance instructor to either the scoreboard or the participation screen
      if ($scope.contest.data.status === 'observer') {
        // send to scoreboard
        $state.go('app.keyboarding.competitionResults', {
          id: $scope.contest.firebaseId,
        });
      } else if ($scope.contest.data.status === 'participant') {
        // send to compete in the contest
        // TODO: Implement this functionality
      }

      return;
    };

    // view controllers --------------------------------------------------------------------
    $scope.downloadClasslistData = function () {
      // Note: even though this option is attached to an input field with ng-change on it, changing the value
      // programmatically does not actually initiate
      // Reset the current practice time option to default (Last 10 days)
      $scope.section.practice.startDate = null;
      $scope.section.updateStatistics().then(function () {
        return DownloadCSV.saveClasslist($scope.user, $scope.section, $scope.view);
      });
    };

    // On-click event for saving the doc prod gradesheet data as a csv file
    $scope.downloadDocProdClasslistData = function () {
      $scope.section.updateStatistics().then(function () {
        return DownloadCSV.saveDocProdClasslist($scope.user, $scope.section, $scope.section.course_template.structure);
      });
    };

    // view controllers --------------------------------------------------------------------
    $scope.currentStudent = null;

    // Needed to update the view to prompt user to switch between keyboarding, document production and other views.
    $scope.$on('$stateChangeStart', function (event, toState) {
      $scope.view.stateName = toState.name; // working
      // Reset the open/closed states of display options:
      $scope.view.docProdGradeDisplaySettings.showExplanation = false;
    });

    $scope.view = ClasslistViewDefaults.generateViewDefaults($state.current.name, $scope.user.organization_id);

    // Every 6 hours we check if the day has advanced
    let intervalPromise = $interval(
      // ensure we're not showing previous days in our options menu
      () => {
        $scope.view.documentAssignmentExpiresOnDaysOptions = ClasslistViewDefaults.next14Days();
      },
      1000 * 60 * 60 * 6
    );
    $scope.$on('$destroy', () => {
      $interval.cancel(intervalPromise);
    });

    $scope.mouseover = function (student) {
      $scope.currentStudent = student;
    };

    // Document Production Helpers ------------------------------------------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // Note: Ideally much of this would be refactored into the section factory. However, the section factory is getting
    //  so large that it should really be refactored itself.

    // Set some default attributes in the view
    $scope.section.onInit().then(function () {
      // Exit if we don't have any document production assignments in course template
      if (!$scope.section.hasDocumentProduction) return true;

      // 2. Set the active week to the first week where there are document assignments
      var eligibleWeeks;
      var activeWeek;
      try {
        var weekKeys = Object.keys($scope.section.course_template.structure);
        eligibleWeeks = weekKeys.filter(function (key) {
          return (
            $scope.section.course_template.structure[key].courseWork.filter(function (work) {
              return work.type === 'Document Production';
            }).length > 0
          );
        });
        eligibleWeeks = eligibleWeeks.map(function (weekNum) {
          return parseInt(weekNum, 10);
        });
        // use the saved activeWeekIndex loaded from defaultSettingsService
        //  this will start at 0 by default
        activeWeek = eligibleWeeks[$scope.section.view.documentProduction.activeWeekIndex];
      } catch (err) {
        activeWeek = 0;
      }

      $scope.view.documentProduction = $scope.view.documentProduction || {};
      $scope.view.documentProduction.activeWeek = parseInt(activeWeek, 10);
      // $scope.view.documentProduction.activeWeekIndex = 0; // moved to DefaultSettingsService
      $scope.view.docProdSelectedCount = 0;
      $scope.view.docProductionOptions = {
        accommodation: false,
      };
      // the value below is used to that we cannot decrese below
      // TODO: CHANGE THIS!
      $scope.view.documentProduction.eligibleWeeks = eligibleWeeks;
    });

    // Increases the week to the next document production week
    $scope.increaseActiveWeek = function () {
      var eligibleWeeksArr = $scope.view.documentProduction.eligibleWeeks;
      var activeWeekIndex = $scope.section.view.documentProduction.activeWeekIndex;
      // don't change if we're at our limits
      if (activeWeekIndex === eligibleWeeksArr.length - 1) {
        return true;
      } else {
        // reset view messages
        $scope.view.docProdSuccessMessage = null;
        $scope.view.docProdErrorMessage = null;
        // increase the index
        $scope.section.view.documentProduction.activeWeekIndex++;
        UserSettings.set('class/' + $stateParams.code + '/view/documentProduction', $scope.section.view.documentProduction);
        // move to the next week with document production activities
        $scope.view.documentProduction.activeWeek = eligibleWeeksArr[$scope.section.view.documentProduction.activeWeekIndex];
      }
      TooltipService.updateTooltips();
    };

    // triggered by the onchange event when setting grade display settings (the checkboxes)
    $scope.documentGradesheetDisplayOpts = function () {
      UserSettings.set('class/' + $stateParams.code + '/view/documentProduction', {
        gradeDisplaySettings: $scope.section.view.documentProduction.gradeDisplaySettings,
      });
    };

    // triggered by the onchange event when setting an documentExpiresOnDay
    $scope.documentExpiresOnDayChanged = function () {
      const expiresOnDay = $scope.view.documentExpiresOnDay;
      // has the user set a day? if yes, nothing to do
      if (typeof expiresOnDay === 'undefined' || expiresOnDay === null) return true;
      // if instructor tries to click 'confirm' without selecting a date, we show an error
      // this method is triggered when they select a date, and if they have selected a date
      // we hide the error message
      $scope.view.docProdErrorMessage = null;
    };

    // Users can swap between setting a time limit (2h) or setting an expiry time (11:55 p.m.)
    $scope.toggleUseTimeLimitDocAssignment = function () {
      $scope.section.view.documentProduction.useTimeLimit = !$scope.section.view.documentProduction.useTimeLimit;
      $scope.view.docProductionOptions.accommodation = false; // false by default
      UserSettings.set('class/' + $stateParams.code + '/view/documentProduction', {
        useTimeLimit: $scope.section.view.documentProduction.useTimeLimit,
      });
    };

    // Users can swap between setting a time limit (2h) or setting an expiry time (11:55 p.m.)
    $scope.saveDocumentAssignmentdocumentExpiresOn = function () {
      const expiresOn = $scope.section.view.documentProduction.documentExpiresOn;
      // Note: We're deliberately not saving the day here
      UserSettings.set('class/' + $stateParams.code + '/view/documentProduction/documentExpiresOn', {
        hour: expiresOn.hour,
        minute: expiresOn.minute,
        period: expiresOn.period,
      });
    };

    // Saves the last time limit (i.e. 110 minutes) that an instructor sets
    $scope.saveDocumentAssignmentTimeLimit = function () {
      UserSettings.set('class/' + $stateParams.code + '/view/documentProduction', {
        documentTimeLimit: $scope.section.view.documentProduction.documentTimeLimit,
      });
    };

    // Decrease the week to the next document production week
    $scope.decreaseActiveWeek = function () {
      var eligibleWeeksArr = $scope.view.documentProduction.eligibleWeeks;
      var activeWeekIndex = $scope.section.view.documentProduction.activeWeekIndex;
      // don't change if we're at our limits
      if (activeWeekIndex === 0) {
        return true;
      } else {
        // reset view messages
        $scope.view.docProdSuccessMessage = null;
        $scope.view.docProdErrorMessage = null;
        // decrease the index
        $scope.section.view.documentProduction.activeWeekIndex--;
        // move to the next week with document production activities
        UserSettings.set('class/' + $stateParams.code + '/view/documentProduction', $scope.section.view.documentProduction);
        $scope.view.documentProduction.activeWeek = eligibleWeeksArr[$scope.section.view.documentProduction.activeWeekIndex];
      }
      TooltipService.updateTooltips();
    };

    // toggles all the student.documents_assignments[documentAssignment.id].selected
    //  for the selected documentAssignment
    $scope.docProdSelectAll = function (documentAssignment) {
      if (documentAssignment.type !== 'Document Production') {
        console.warn('Warning: Preventing "Select All" from adding permissions to keyboarding assignments.');
        return true;
      }
      if ($scope.view.documentProduction.disableForms) return true;

      var eligibleStudents = [];
      // 1. Get the eligible students.
      //  If view.startAssignment, this means students where hasActivePermission is false
      //    AND hasSubmission is false
      //  If view.releaseGrades, this means students where hasSubmission is true
      //    AND gradeReleased is false
      if ($scope.view.startAssignments) {
        eligibleStudents = $scope.section.students.filter(function (student) {
          return (
            !student.document_assignments[documentAssignment.id].hasActivePermission &&
            !student.document_assignments[documentAssignment.id].hasSubmission
          );
        });
      } else if ($scope.view.cancelDueDates) {
        eligibleStudents = $scope.section.students.filter(function (student) {
          return (
            student.document_assignments[documentAssignment.id].hasActivePermission &&
            !student.document_assignments[documentAssignment.id].hasSubmission
          );
        });
      } else if ($scope.view.releaseGrades) {
        eligibleStudents = $scope.section.students.filter(function (student) {
          return (
            student.document_assignments[documentAssignment.id].hasSubmission &&
            !student.document_assignments[documentAssignment.id].gradeReleased
          );
        });
      }

      // 2. Count how many are already selected.
      var alreadySelected = eligibleStudents.filter(function (student) {
        return student.document_assignments[documentAssignment.id].selected;
      });
      //  If all the eligible students are already selected, make all the eligible students
      //    not selected.
      if (alreadySelected.length === eligibleStudents.length) {
        eligibleStudents.forEach(function (student) {
          student.document_assignments[documentAssignment.id].selected = false;
        });
        // If less than the eligible students are selected, make them all selected.
      } else if (alreadySelected.length < eligibleStudents.length) {
        eligibleStudents.forEach(function (student) {
          return (student.document_assignments[documentAssignment.id].selected = true);
        });
      }

      // counts how many students are selected and adds to
      // $scope.view.docProdSelectedCount
      $scope.updateSelectedCount();

      return true;
      // view.startAssignments
      // view.releaseGrades
    };

    // toggles all the student[selectProp]
    //  pass a custom property for selectProp if desired
    $scope.timedWritingSelectAll = function (students, selectProp = 'selectedForTimedWriting') {
      // 2. Count how many are already selected.
      var alreadySelected = students.filter(function (student) {
        return student[selectProp];
      });
      //  If all the eligible students are already selected, make all the eligible students
      //    not selected.
      if (alreadySelected.length === students.length) {
        return students.forEach(function (student) {
          student[selectProp] = false;
        });
        // If less than the eligible students are selected, make them all selected.
      } else if (alreadySelected.length < students.length) {
        return students.forEach(function (student) {
          student[selectProp] = true;
        });
      }
    };

    // alternates between document production and keyboarding states
    $scope.switchStates = function () {
      if ($state.current.name === 'app.instructor.section.document_production') {
        $state.go('app.instructor.section.keyboarding');
      } else if ($state.current.name === 'app.instructor.section.keyboarding') {
        $state.go('app.instructor.section.document_production');
      }
    };

    // Switching between keyboarding and document views causes tooltips to be disabled.
    // add this code to update tooltips on change
    $scope.$on('$stateChangeSuccess', () => {
      TooltipService.updateTooltips();
    });

    $scope.startDocProdAssignment = function () {
      $scope.view.documentProduction.disableForms = true;
      $scope.view.documentProduction.waitingForServer = true;
      $scope.view.docProdErrorMessage = null;
      const expiresOnDay = $scope.view.documentExpiresOnDay;
      return DocumentAssignmentCreatorService.startDocProdAssignment(
        $scope.section,
        expiresOnDay,
        $scope.view.docProductionOptions
      )
        .then(
          $scope.section.updateStatistics.bind(null, {
            statistics_params: ['document_production'],
          })
        )
        .then(() => {
          $scope.view.documentProduction.waitingForServer = false;
        })
        .then(
          displayTimedMessage.bind(null, {
            status: 'success',
            message: 'Success!',
          })
        )
        .then(resetDocProdView)
        .catch((msg) => {
          $scope.view.docProdErrorMessage = msg;
          $scope.view.documentProduction.disableForms = false;
          $scope.view.documentProduction.waitingForServer = false;
        });
    };

    $scope.releaseDocProdAssignment = function () {
      $scope.view.documentProduction.disableForms = true;
      $scope.view.documentProduction.waitingForServer = true;
      return DocumentAssignmentCreatorService.releaseDocProdAssignments($scope.section)
        .then(
          $scope.section.updateStatistics.bind(null, {
            statistics_params: ['document_production'],
          })
        )
        .then(() => {
          $scope.view.documentProduction.waitingForServer = false;
        })
        .then(
          displayTimedMessage.bind(null, {
            status: 'success',
            message: 'Success!',
          })
        )
        .then(resetDocProdView)
        .catch((msg) => {
          $scope.view.docProdErrorMessage = msg;
          $scope.view.documentProduction.disableForms = false;
          $scope.view.documentProduction.waitingForServer = true;
        });
    };

    // Cancel one ore more due dates for one or more students
    $scope.cancelDocProdDueDates = function () {
      $scope.view.documentProduction.disableForms = true;
      $scope.view.documentProduction.waitingForServer = true;
      return DocumentAssignmentCreatorService.cancelDocProdDueDates($scope.section)
        .then(
          $scope.section.updateStatistics.bind(null, {
            statistics_params: ['document_production'],
          })
        )
        .then(() => {
          $scope.view.documentProduction.waitingForServer = false;
        })
        .then(
          displayTimedMessage.bind(null, {
            status: 'success',
            message: 'Success!',
          })
        )
        .then(resetDocProdView)
        .catch((msg) => {
          $scope.view.docProdErrorMessage = msg;
          $scope.view.documentProduction.disableForms = false;
          $scope.view.documentProduction.waitingForServer = false;
        });
    };

    // on-click event for selecting/de-selecting checkbox when giving an assignment, cancelling, or releasing grades
    $scope.selectDocument = function (student, docAssignmentId) {
      if ($scope.view.documentProduction.disableForms) return;

      student.document_assignments[docAssignmentId].selected = !student.document_assignments[docAssignmentId].selected;
      $scope.updateSelectedCount();
    };

    $scope.deselectAllDocumentAssignments = function () {
      return DocumentAssignmentCreatorService.deselectAllAssignments($scope.section);
    };

    function resetDocProdView() {
      $scope.view.docProdSuccessMessage = null;
      $scope.view.docProdErrorMessage = null;
      $scope.view.documentProduction.disableForms = false;
      $scope.view.startAssignments = false;
      $scope.view.cancelDueDates = false;
      $scope.view.releaseGrades = false;
      $scope.view.showDocProdHelp = false;
      $scope.deselectAllDocumentAssignments();
      $scope.updateSelectedCount();
      $scope.view.docProductionOptions.accommodation = false;
    }

    // counts how many selected assignments we have.
    //  used to enable/disable buttons, display messages, etc.
    $scope.updateSelectedCount = function () {
      $scope.view.docProdSelectedCount = DocumentAssignmentCreatorService.countSelectedAssignments($scope.section);
    };

    $scope.giveAssignment = function () {
      $scope.view.startAssignments = !$scope.view.startAssignments;
      $scope.deselectAllDocumentAssignments();
      $scope.view.cancelDueDates = false;
      $scope.view.releaseGrades = false;
      $scope.view.showDocProdHelp = false;
      $scope.view.docProdErrorMessage = null;
    };

    $scope.releaseAssignmentGrades = function () {
      $scope.view.releaseGrades = !$scope.view.releaseGrades;
      $scope.deselectAllDocumentAssignments();
      $scope.view.cancelDueDates = false;
      $scope.view.startAssignments = false;
      $scope.view.showDocProdHelp = false;
      $scope.view.docProdErrorMessage = null;
    };

    $scope.cancelDueDates = function () {
      $scope.view.cancelDueDates = !$scope.view.cancelDueDates;
      $scope.deselectAllDocumentAssignments();
      $scope.view.startAssignments = false;
      $scope.view.releaseGrades = false;
      $scope.view.showDocProdHelp = false;
      $scope.view.docProdErrorMessage = null;
    };

    function displayTimedMessage(options) {
      const status = options.status;
      const message = options.message;
      if (status === 'success') {
        $scope.view.docProdErrorMessage = null;
        $scope.view.docProdSuccessMessage = message;
      } else if (status === 'error') {
        $scope.view.docProdSuccessMessage = null;
        $scope.view.docProdErrorMessage = message;
      }
      return $timeout(function () {
        $scope.view.docProdErrorMessage = null;
        $scope.view.docProdSuccessMessage = null;
      }, 1000);
    }
  },
]);
