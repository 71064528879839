// designed to be included and accessed through the Instruction class
app.factory('StarterFileBrowser', [
  function () {
    const companiesDirectoryUSA = [
      {
        name: 'Murray Bandet and Associates LLP',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/MurrayBandet/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/MurrayBandet/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Alanis Shaw and Easter LLP',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/AlanisShawEaster/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/AlanisShawEaster/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Stanley Park Healthcare',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/StanleyParkHealthcare/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/StanleyParkHealthcare/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'First in My Family Education',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/FirstInyMyFamily/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/FirstInyMyFamily/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Lavender Farms and Essential Oils',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/LavenderFarms/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/LavenderFarms/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Jive Events',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/JiveEvents/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/JiveEvents/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Rebalance Energy',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/RebalanceEnergy/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/RebalanceEnergy/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Big Bank Accounting and Financial Services',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/BigBank/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/BigBank/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Socially Forward',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/SociallyForward/USA/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/SociallyForward/USA/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
    ];
    const companiesDirectoryCanada = [
      {
        name: 'Murray Bandet and Associates LLP',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/MurrayBandet/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/MurrayBandet/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Alanis Shaw and Easter LLP',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/AlanisShawEaster/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/AlanisShawEaster/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Stanley Park Healthcare',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/StanleyParkHealthcare/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/StanleyParkHealthcare/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'First in My Family Education',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/FirstInyMyFamily/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/FirstInyMyFamily/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Lavender Farms and Essential Oils',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/LavenderFarms/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/LavenderFarms/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Jive Events',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/JiveEvents/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/JiveEvents/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Rebalance Energy',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/RebalanceEnergy/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/RebalanceEnergy/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Big Bank Accounting and Financial Services',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/BigBank/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/BigBank/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
      {
        name: 'Socially Forward',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/SociallyForward/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/SociallyForward/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/email.docx',
          },
        ],
      },
    ];

    const companiesDirectoryCanadaAccessible = [
      {
        name: 'Murray Bandet and Associates LLP',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/MurrayBandet/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/MurrayBandet/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
      {
        name: 'Alanis Shaw and Easter LLP',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/AlanisShawEaster/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/AlanisShawEaster/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
      {
        name: 'Stanley Park Healthcare',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/StanleyParkHealthcare/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/StanleyParkHealthcare/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
      {
        name: 'First in My Family Education',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/FirstInMyFamily/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/FirstInMyFamily/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
      {
        name: 'Lavender Farms and Essential Oils',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/LavenderFarms/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/LavenderFarms/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
      {
        name: 'Jive Events',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/JiveEvents/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/JiveEvents/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
      {
        name: 'Rebalance Energy',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/RebalanceEnergy/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/RebalanceEnergy/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
      {
        name: 'Big Bank Accounting and Financial Services',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/BigBank/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/BigBank/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
      {
        name: 'Socially Forward',
        starterFiles: [
          {
            name: 'letterhead.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/SociallyForward/letterhead.docx',
          },
          {
            name: 'memo.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/SociallyForward/memo.docx',
          },
          {
            name: 'report.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/report.docx',
          },
          {
            name: 'email.docx',
            downloadLink: 'https://document-production-public.s3.amazonaws.com/v3/email.docx',
          },
        ],
      },
    ];

    // Constructor for this class
    // country param is the country current Document Assignment belongs to
    let StarterFileBrowser = function (country, styleGuideVersion) {
      // Instance variables for controlling the vie
      this.view = {
        // Keep track of which Company is currently displayed
        currentCompanyName: null,
        companiesDirectory: [],
        currentStaterFilesList: null,
      };

      // Assign the appropriate list of starter files, depending on the country Document Assignment belongs to
      if (country === 'CA') {
        // NOTE: in the future, might need to update this if all versions above 3.0 will be accessible (will need to parseInt since the versions are stored as strings)
        if (styleGuideVersion === '3.0') {
          this.view.companiesDirectory = companiesDirectoryCanadaAccessible;
        } else {
          this.view.companiesDirectory = companiesDirectoryCanada;
        }
      } else if (country === 'US') {
        this.view.companiesDirectory = companiesDirectoryUSA;
      }
    };

    // on click event for selecting a company in the starter file browser
    StarterFileBrowser.prototype.selectCompany = function (companyName) {
      // Set the currently selected company so that it can be visible in the address bar of the widget
      this.view.currentCompanyName = companyName;
      const selectedCompany = this.view.companiesDirectory.find((company) => {
        return company.name === companyName;
      });
      // Only set the starter files list if the current company is found
      this.view.currentStaterFilesList = selectedCompany ? selectedCompany.starterFiles : null;
    };

    // happens when user decideds to click back to list of companies
    StarterFileBrowser.prototype.clearSelectedCompany = function () {
      // Remove current company from address bar in widget
      this.view.currentCompanyName = null;
      // Clear the list of starter files, which would cause the widget to display company names again
      this.view.currentStaterFilesList = null;
    };

    return StarterFileBrowser;
  },
]);
