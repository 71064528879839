// Creates the animations for signin and register in the Welcome Controller
app.factory('ModalSequence', [
  '$timeout',
  function ($timeout) {
    // TODO KNOWN ERROR: When closing one ModalSequence and opening another ModalSequence, the
    //                      .modal-open class does not get added to the body tag, as it should.
    //                   A monkey-patch solution is to set a delay, then add the class manually:
    //                      setTimeout(function() { $("body").addClass('modal-open'); }, 500);

    // options:
    //  1. modalNames: ["firstModalName", "secondModal...", ...] -> enables ModalSequence.prototype.activeModal
    //        to return the name of the active modal.
    var ModalSequence = function (selector, options) {
      var self = this;

      this.modals = getModalPromise(selector, this); // a promise that resolves to modal elements
      this.state = this.modals; // use this for promise chaining. object representing current state.
      this.selector = selector;
      this.length = 0;
      this.activeIndex = 0; // keeps track of which modal is active
      this.isActive = false; // is this modal displayed (active)?
      this.callback = null; // set when calling the start() method
      $timeout(function () {
        // timeout allows the page, so that self.length can find the page elements
        self.length = $('my-modals' + selector)
          .find('my-modal')
          .find('.modal').length;
      });
      this.options = options || {}; // accepts modalNames: ["homeRowTech", "lessonComplete", etc.]
      // this.start = start;
    };

    // public methods ---------------------------------------------------------------------------------------
    // shows the first modal
    ModalSequence.prototype.start = function (callback) {
      var self = this;
      this.activeIndex = 0;
      this.isActive = true;
      this.callback = callback;

      this.state = this.modals.then(
        function (modals) {
          // throw an error if we have more modals than modal names, or vice versa.
          if (
            self.options &&
            self.options.modalNames &&
            self.options.modalNames &&
            self.options.modalNames.length !== modals.length
          ) {
            throw 'ModalSequence must have the same number of modalNames as modals. To add a new modal into a sequence, add the html on the page and modify the modalSequence object created in the modal contoller.';
          }
          return $timeout(function () {
            // timeout is necessary to ensure directives have rendered
            bootstrap.Modal.getOrCreateInstance(modals.eq(self.activeIndex).get(0), { keyboard: false }).show();
            return modals;
          });
        },
        function () {
          console.log('error. cannot start sequence');
        }
      );
      return this;
    };

    // displays the next modal
    ModalSequence.prototype.next = function () {
      var self = this;

      // if this is the last modal, exit it
      if (this.activeIndex + 1 >= this.length) {
        return this.exit();
      }

      this.activeIndex += 1;
      this.state = this.state.then(function (modals) {
        swapModalsWithIndex(modals, self.activeIndex, self.activeIndex - 1);
        return modals;
      });
      return this;
    };

    // displays the previous modal
    ModalSequence.prototype.previous = function () {
      var self = this;
      this.state = this.state.then(function (modals) {
        if (self.activeIndex === 0) return modals;
        self.activeIndex -= 1;
        swapModalsWithIndex(modals, self.activeIndex, self.activeIndex + 1);
        return modals;
      });
      return this;
    };

    // exits the modal sequence
    ModalSequence.prototype.exit = function () {
      var self = this;
      this.isActive = false;

      this.state = this.state
        .then(function (modals) {
          bootstrap.Modal.getOrCreateInstance(modals.eq(self.activeIndex).get(0)).hide();
          return modals;
        })
        .then(function (modals) {
          if (self.callback) self.callback();
          self.activeIndex = 0;
          return modals;
        });
      return this;
    };

    // returns the name of the current active modal
    //  requires options.modalNames to be set on instantiation. Other wise, returns the index.
    ModalSequence.prototype.activeModal = function () {
      if (!this.isActive) return false;

      if (typeof this.options.modalNames === 'object') {
        return this.options.modalNames[this.activeIndex];
      } else {
        console.log('ERROR in ModalSequence. options.modalNames was not defined!');
        return this.activeIndex;
      }
    };

    // private methods -------------------------------------------------------------------------------------
    // returns a promise that resolves to jQuery array of modal elements
    function getModalPromise(selector) {
      return $timeout(function () {
        var modals = $('my-modals' + selector)
          .find('my-modal')
          .find('.modal');
        // consistence check
        if (modals.length <= 0) {
          // console.log("Error: Cannot find any modals within my-modals element with id: " + selector);
          return {};
        }
        return modals;
      });
    }

    // swaps content between the current modal and next modal
    function swapModalsWithIndex(modals, newIndex, oldIndex) {
      // Step 1: Remove 'fade' class from all modals.
      for (var i = 0; i < modals.length; i++) {
        modals.eq(i).removeClass('fade');
      }
      // Step 2: Hide/Show the modals
      bootstrap.Modal.getOrCreateInstance(modals.eq(oldIndex).get(0)).hide();
      if (newIndex < modals.length) bootstrap.Modal.getOrCreateInstance(modals.eq(newIndex).get(0)).show();
      return;
    }

    return ModalSequence;
  },
]);
