app.controller('AdminDashboardController', [
  '$scope',
  '$http',
  function ($scope, $http) {
    $scope.gregsCommission = {
      'Centennial College': 0,
      'Mohawk College': 0.0569,
      'Fanshawe College': 0,
      'Confederation College': 0.14,
      'Seneca College': 0.0569,
      'Algonquin College': 0,
      'Bow Valley College': 0,
      'Humber College': 0,
      'My organization is not listed': 0,
    };

    // gets all the data
    $http.get('admin/dashboard').then(function (response) {
      $scope.data = response.data;
    });
  },
]);
