app.controller('SubscriptionController', [
  '$scope',
  'StripePaymentFactory',
  'currentUser',
  'product',
  '$state',
  '$timeout',
  function ($scope, StripePaymentFactory, currentUser, product, $state, $timeout) {
    $scope.view = {};
    $scope.currentUser = currentUser;
    $scope.product = product;

    // // called either from the UI if we have multiple program_products at an organization,
    // //  or if we just have 1 it's called from the controller.
    const setProduct = () => {
      //validate that we have the proper pricing, country and state information for the organization
      if (
        !(typeof $scope.product === 'object') ||
        !(typeof $scope.product.price === 'number') ||
        !(typeof $scope.product.tax === 'object')
      ) {
        console.log('Missing pricing and tax information');
        return $state.go('app.home');
      }

      // Stripe Payment is bound to HTML. When a user submits the Stripe form,
      //  stipePayment intercepts the successful payment, adds the stripeTokens to the
      //  user data, and sends to our server to charge the card and update the account.
      $scope.stripePayment = new StripePaymentFactory('#stripe-form', 'update', $scope.currentUser, $scope.product);
      $scope.stripePayment.paymentSuccess.promise.then(() => {
        return $timeout(() => {
          // refresh the browser to force the user object to update
          location.reload();
        }, 4000);
      });
    };

    setProduct(product);
  },
]);
