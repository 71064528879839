import Bugsnag from '@bugsnag/js';

// Note: there are more options that can be passed in as config, this is a subset
// of most relevant ones. See Bugsnag docs for more options
const defaultConfig = {
  apiKey: '06281f16c87450c526a61a7911b4a0cd',
  appType: 'browser', // default 'browser'
  autoDetectErrors: true, // true by default, Bugsnag will be notified of any uncaught errors, this disables it
  autoTrackSessions: false, // true by default. Used for comparing stability scores between releases. If enabled, will keep periodically sending requests to Bugsnag with session data.
  collectUserIp: true, // true by default. Will show as clientIp: [REDACTED] in dashboard
  generateAnonymousId: false, // generates an anonymous id and persists it in local storage so that user stability scores can be calculated
  enabledBreadcrumbTypes: [
    // default options. Set to [] to disable
    'error', // left when error event is sent
    'log', // messages written to console. This is disabled in 'development' environment
    'navigation', // left on page loads, etc
    'request', // left for network requests via XMLHttpRequest and fetch() calls
    'user', // left when the user clicks/touches the page
  ],
  // enabledReleaseStages: ['production'], // by default, Bugsnag gets notified of events in any release stage
  maxBreadcrumbs: 25, // default is 25
  maxEvents: 10, // default is 10. Max number of events that can be sent per page (reset each time location changes)
  // metadata: {}, // custom data that can be sent with all captured events
  // onBreadcrumb: function (breadcrumb) {
  //   // callback to modify breadcrumbs before they are recorded
  // },
  // onError: function (event) {
  //   // callback to modify or discard error events before they are sent to bugsnag
  // },
  // onSession: function (session) {
  //   // callback to modify or discard sessions before they are sent to Bugsnag
  // },
  redactedKeys: [], // sets which values should be removed from any metadata before sending to Bugsnag. Could be a stirng or a regex.
  // releaseStage: 'development', // distinguish between different stages of development. By default, if url contains 'localhost', this is set to development.
  // trackInlineScripts: true, // default true. Sends source code of async executed code
  // user: {
  // // set global user data that gets sent with all captured events. Properties to include are id, name and email. In browser, can alternatively call Bugsnage.setUser('1', 'user@email.com', 'John Smith' )
  //     id: '', // string
  //     name: '',
  //     email: '',
  //   },
};

// Start bugsnag
Bugsnag.start(defaultConfig);

// Note: unhandled errors can work unreliably if enabled. Bugsnag docs mention there might be issues with webpack settings
// (eg. `devtool` eval vs source-maps for instance) to affect it

// Note: if daily quote for bugsnag notify has exceeded, error reports will be sampled instead (the .notify() request might succeed,
// but the error will not show up on the Bugsnag dashboard).

export default Bugsnag;
