app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'AuthInterceptProvider',
  function ($stateProvider, $urlRouterProvider, AuthInterceptProvider) {
    var scrollToTop = [
      function () {
        window.scrollTo(0, 0); // scrolls to top of page
        return true;
      },
    ];

    $stateProvider

      .state('app.keyboarding.technique_hub', {
        url: '/staging/technique_hub',
        controller: 'TechniqueHubController',
        // params: {notification_id: null},  // must be present for notification_id to show up
        templateUrl: 'technique_hub/_technique_hub.html',
        resolve: {
          userLoggedIn: [
            'Auth',
            '$state',
            '$rootScope',
            function (Auth, $state, $rootScope) {
              return Auth.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onEnter: function () {
          window.scrollTo(0, 0);
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('finger_animations_modal', {
        // designeed to test a large json post
        url: '/staging/finger_animations_modal',
        // params: {notification_id: null},  // must be present for notification_id to show up
        templateUrl: 'technique_hub/finger_animations/_animated_keyboard_in_modal.html',
        resolve: {
          userLoggedIn: [
            'Auth',
            '$state',
            '$rootScope',
            function (Auth, $state, $rootScope) {
              return Auth.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      });
  },
]);
