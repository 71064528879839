app.controller('DocProductionStudentCourseScheduleController', [
  '$scope',
  '$timeout',
  'Auth2',
  'StudentCourseScheduleDataService',
  '$state',
  '$stateParams',
  '$http',
  '$q',
  function ($scope, $timeout, Auth2, StudentCourseScheduleDataService, $state, $stateParams, $http, $q) {
    // When we load the controller:
    //  1. Grab the user
    //  2. Pull the course_template from the db
    //  3. Pull user data from the db
    //  4. Create student.document_assignments by combining student data with coruseTemplate
    //      object in student.mapDocumentAssignmentsToCourseTemplate()
    Auth2.currentUser().then(function (user) {
      var section_id = $stateParams.section_id;

      $scope.student = user;
      $scope.section = $scope.student.sections.filter(function (section) {
        return section.id === parseInt(section_id, 10);
      })[0];
      // get the course template...
      var courseTemplate = getCourseTemplate($scope.section);
      var docProdResults = getStudentDocProdResults($scope.section);
      $q.all([courseTemplate, docProdResults]).then(function (data) {
        $scope.section.course_template = data[0];
        $scope.section.course_template.weeksWithDocProd = $scope.section.course_template.structure.filter(function (week) {
          return (
            week.courseWork.filter(function (cw) {
              return cw.type === 'Document Production';
            }).length > 0
          );
        });
        $scope.student.mapDocumentAssignmentsToCourseTemplate($scope.section.course_template);
        // Andrey, some notes for you:
        //  1. $scope.student.document_assignments is the object we want to work with. It's an
        //      object where each key is the document_assignment_id, and the value is an object containing an array
        //      of permissions and document_submissions.
        //  2. Note: There may be many document_submissions. They are sorted by the server, and the first
        //      element in the array is the only one we care about (where count_as_graded is true).
        //  3. We should only display the grades if 'grade_released' is true. I need to add some logic around this
        //      today on the back end, but for now, let's take care of it on the front.
        //  4. FYI: I do plan on moving much of this code into the StudentDashboard on the home screen.
        //
        // console.log($scope.student);
      });

      // get the student results...
      // layer the student results on the course template...
    });

    //
    function getCourseTemplate(section) {
      // return the course_template if we already have it
      if (section.course_template) {
        return $q.resolve(section.course_template);
      }

      // get the course template
      return $http.get(`api/templates/${$scope.section.course_template_id}.json`).then(function (res) {
        return res.data;
      });
    }

    function getStudentDocProdResults(section) {
      return $scope.student.getStatsForSection(section, ['document_production'], {});
    }

    if ($state.current.name === 'course_builder.new') {
      ('OMFG!!!!!');
    }
    /* *****
     * Variables
     * *****/

    // Array that holds the course structure. Fetched using the CourseBuilderDataService
    $scope.courseSchedule = [];
    $scope.priorityCourseWork = [];

    /* *****
     * Initialize
     * *****/

    // Fetches a course description from CourseBuilderDataService and populates the currentCourseTemplate with it
    // StudentCourseScheduleDataService.getStudentCourseSchedule().then(function(courseSchedule){
    // $scope.courseSchedule = courseSchedule;
    // $scope.priorityCourseWork = extractPriorityCourseWork(courseSchedule)
    // debugger;
    // })

    /* *****
     * Functions - scope mapping
     * *****/
    $scope.createArrayFromNumber = createArrayFromNumber;
    $scope.calcTotalScore = calcTotalScore;

    /* *****
     * Functions definitions
     * *****/

    // Extract priority course work assignments from currentCourseTemplate.structure. Takes courseTemplate as an input, output is an array of courseWork items
    // Currently a mock implementation
    function extractPriorityCourseWork(courseSchedule) {
      return courseSchedule.structure
        .reduce(function (acc, el) {
          return acc.concat(el.courseWork);
        }, [])
        .filter(function (el) {
          return el.active;
        });
    }

    function calcTotalScore(week) {
      var result = week.courseWork.reduce(
        function (acc, el) {
          var score = parseInt(el.score, 10) || 0;
          // TODO: Revert to summing up the points once they are defined in the course template. For now assume each course work is 100 points
          // var points = parseInt(el.options.points, 10) || 0;
          var points = 100;
          return Object.assign(acc, {
            score: acc.score + score,
            total: acc.total + points,
          });
        },
        { score: 0, total: 0 }
      );
      return result;
    }

    // Generate an array with number of empty elements equal to the number passed in (useful for ng-repeat)
    function createArrayFromNumber(num) {
      return Array.apply(null, new Array(num));
    }
  },
]);
