// TimedWritingModalController
//
// Launches a modal automatically when loaded.
// Listens for the following events:
//  -> TimedWritingModals:start        : launches a new modal sequence (such as when a lesson completes)
//  -> homeRowTech:modalComplete : indicates that the homeRowTech modal completes (this is needed for HomeRowTech to work)
//
// On complete, broadcasts:
//  -> TimedWritingModals:complete : modals are all finished
//
// -------
// Implementation details:
//  -> handles the keydown events if they're required to advance modals
//  -> HomeRowTech requires a few extra methods to be included
//  -> keydown listener is activated on lessonModals:start broadcast, and is
//        deregistered when the modal completes, or controller goes out of scope
//
// HomeRowTech Modal Integration
//  To install HomeRowTech on a controller, the following are required:
//    a. HomeRowTech object must be initiated at assigned to scope: $scope.homeRowTech = new Home....
//    b. A keydown event to handle the process (see listener below)
//    c. When the homeRowTech modal completes successfully, it triggers a callback. A callback needs to be bound to advance
//          the modal sequence: modalSequence.next()

app.controller('TimedWritingModalController', [
  '$scope',
  '$rootScope',
  'ModalSequence',
  'ModalSequenceArray',
  'HomeRowTechModal',
  '$state',
  '$timeout',
  '$stateParams',
  'SkipHomeRowTechModal',
  function (
    $scope,
    $rootScope,
    ModalSequence,
    ModalSequenceArray,
    HomeRowTechModal,
    $state,
    $timeout,
    $stateParams,
    SkipHomeRowTechModal
  ) {
    // Instantiate our sequences. First ModalSequences will be auto-started below
    var modals = [];
    modals.push(new ModalSequence('#welcome-modal-sequence', { modalNames: ['welcomeModal', 'homeRowTechModal'] }));
    modals.push(new ModalSequence('#results-modal-sequence', { modalNames: ['resultsModal'] }));
    modals.push(new ModalSequence('#no-permission', { modalNames: ['noPermissionModal'] }));
    modals.push(new ModalSequence('#error-modal-sequence', { modalNames: ['errorModal'] }));

    // start the first modalSequence
    var modalSequences = new ModalSequenceArray(modals);

    // // attach the event listener and activate modal
    // var listenerDestroyer = activateListener();
    // modalSequences.getByIndex(0).start(listenerDestroyer);
    //
    // Called from PracticeTimedWritingController.
    //   -> data must contain a modalSelector attribute with a string as to which modal to watch.
    //         the modal names are associated with objects below in the switch statement.
    $scope.$on('TimedWritingModals:start', function (a, data) {
      // error
      if (data.modalSelector === '#error-modal-sequence') {
        modalSequences.getBySelector(data.modalSelector).start();
        return;
      }

      const listenerDestroyer = activateListener(data.delayListener);
      modalSequences.getBySelector(data.modalSelector).start(listenerDestroyer);
    });

    // keydown event handler
    //  -> this is the engine of this controller.
    //  -> reacts to keypresses, and defines criteria to advance
    //   -> returns the $destroy event
    var modalSequenceSelector;
    $scope.allowListener = true; // also animates button
    function activateListener(delayListener) {
      // used to make sure user doesn't accidently advance the modal
      $scope.allowListener = false;
      delayListener = delayListener || 0;
      $timeout(function () {
        $scope.allowListener = true;
      }, delayListener);

      var activeModal = '';
      var activeModalName = '';
      return $scope.$on('keydown', function (a, e) {
        if (!modalSequences.getActiveModalSequence()) {
          throw 'Modal listener is active, but no modal active.';
        }
        if (!$scope.allowListener) return; // enables a delay, so user won't accidently advance modal

        // get the activeModalName
        activeModal = modalSequences.getActiveModalSequence();
        activeModalName = activeModal.activeModal();
        modalSequenceSelector = activeModal.selector;

        // homeRowTech handles its own keydown events
        if (activeModalName === 'homeRowTechModal') {
          if (e.key_pressed === 'Backspace') {
            $state.reload();
          }
          return;
        }

        // advance the slide on keydown
        if (e.key_pressed === ' ') {
          // Advance if: it's a welcome modal then advance
          // debugger;
          if (['welcomeModal'].indexOf(activeModalName) > -1 && !$scope.timedWriting.complete) {
            activeModal.next();
            // no active modal sequence, then we're done!
            if (!modalSequences.getActiveModalSequence()) {
              $rootScope.$broadcast('TimedWritingModals:complete', { modalSelector: modalSequenceSelector });
              return;
            }
            // if we're now on a homeRowTech modal, activate it
            if (modalSequences.getActiveModalSequence().activeModal() === 'homeRowTechModal') activateHomeRowTech(); // activate the homeRowTechModal if it's active
          } else if (['resultsModal'].indexOf(activeModalName) > -1 && !$scope.timedWriting.complete) {
            // If it's the results modal and the user has another chance, refresh them back to original page
            activeModal.next();
            $timeout(function () {
              $state.go(
                'app.keyboarding.supervised_timed_writing',
                { notification_id: $stateParams.notification_id },
                { reload: true }
              );
            }, 500);
          }
        } else if (e.key_pressed === 'Backspace') {
          $state.go('app.home');
        }
      });
    }

    // homeRowTechModal ------------------------------------------------------------------------------------
    // HRT modal requires $scope.homeRowTechModal and $scope.homeRowTech to be set, as these are accessed from the view.
    //
    // creates a new homeRowTech object
    //  -> should be called to enable homeRowTech
    function activateHomeRowTech() {
      // check whether we need to do HRT. If not, broadcast it as complete.
      if (SkipHomeRowTechModal.shouldSkip('TimedWriting', $scope.$parent.currentUser)) {
        $rootScope.$broadcast('homeRowTech:modalComplete');
        return;
      }

      $scope.homeRowTechModal = new HomeRowTechModal({
        scope: $scope, // keydown listener is attached to this scope (so it will be auto-destroyed with scope)
        listenerTimeout: 200, // necessary to prevent transition issue where HRT jumped straight to instructions instead of hands
        sequence: null, // || "leftToRight", ..., defined in factory.
        numberOfFingersToVerify: 3, // || >2
        delayAfterSuccess: 800, // a little shorter than usual
        activateNow: true, // specific to the modal
        skinTone: $scope.currentUser.user_preference.skin_tone,
      });
      $scope.homeRowTech = $scope.homeRowTechModal.homeRowTech;
    }

    // listens for the modalComplete broadcast.
    //  -> should be attached to the same scope that was passed to HomeRowTechModal constructor
    $scope.$on('homeRowTech:modalComplete', function () {
      // what modal sequence was active?
      var modalSelector = modalSequences.getActiveModalSequence().selector;

      // advance the modal
      modalSequences.getActiveModalSequence().next();
      // if the modal sequence is no longer active, broadcast notice to LessonModal
      if (!modalSequences.isActive()) $rootScope.$broadcast('TimedWritingModals:complete', { modalSelector: modalSelector });
    });

    // modal control functions (for 'x' in top right corner, for example)

    // takes us back to supervised timed writing
    $scope.backToTimedWriting = function () {
      modalSequences.getActiveModalSequence().exit();

      $timeout(function () {
        $state.go(
          'app.keyboarding.supervised_timed_writing',
          { notification_id: $stateParams.notification_id },
          { reload: true }
        );
      }, 500);
    };

    // Attached to the 'X' in the top right of the modal
    //  -> Goes home
    $scope.goHome = function () {
      $state.go('app.home');
    };

    // Attached to the 'X' in the top right of homerowtech
    //  -> Goes back
    $scope.goBack = function () {
      $state.reload();
    };

    // used in error modal
    $scope.reload = function () {
      $state.reload();
    };
  },
]);
