// Purpose is to provide a general set of functionality that can be
//   mixed into other factories to provide support for uploading
//   files securely to S3.
//
// In effect: This class does the pre-work that's required to upload a file
//   so that other classes can simply send the metadata to the backend.
app.service('S3Upload', [
  '$http',
  '$q',
  function ($http, $q) {
    // params is an object with the following properties
    //   formSelectorID is string. Ex: '#my-upload-form'.
    //   validFileFormats is an array of lower case strings. Ex: ['docx']
    //   maximumByteSize is the maximum upload size. Ex: 2097152 (2 MB)
    //   presignedPostURL is the URL where we send a POST request
    //      and retrieve the needed credentials to upload to S3.
    //   presignedPostParams any extra information to be sent to presignedPostURL
    //      in order to retrieve credentials. Ex: {}. Note: We always add the filename.
    //   view is an object with { disableForm:, errorMessage:, successMessage: }
    var S3Upload = function (params) {
      var self = this;

      this.formSelectorID = params.formSelectorID;
      this.validFileFormats = params.validFileFormats;
      this.maximumByteSize = params.maximumByteSize || 2097152;
      this.presignedPostURL = params.presignedPostURL;
      this.presignedPostParams = params.presignedPostParams;
      this.s3DirectPost = {};

      // find the upload form and attach the appropriate listeners
      $(function () {
        var fileElement = fileinput()[0];
        if (typeof fileElement === 'undefined' || fileElement.length === 0) {
          throw new Error('Error: Unable to initialize S3Upload. Cannot locate file input field with ID ' + fileElement);
        }
        // attach the event listener to the file
        // upload($(fileElement));
      });

      // checks that the file size and format are correct
      this.validateFile = function () {
        var file = fileinput().prop('files')[0];

        if (typeof file === 'undefined') {
          return $q.reject({ data: { errors: 'Error: Please select a file to be uploaded.' } });
        }

        var filename = file.name;
        var fileExtension = filename.split('.').pop();

        if (self.validFileFormats.indexOf(fileExtension.toLowerCase()) < 0) {
          return $q.reject({
            data: {
              errors:
                'Error: File upload must be have a valid file extension: ' +
                self.validFileFormats.join(', ') +
                '. Please upload the correct file or ask your instructor for help.',
            },
          });
        }

        if (file.size > self.maximumByteSize) {
          return $q.reject({
            data: {
              errors:
                'Error: File exceeds the maximum size limit. Please review the instructions or ask your instructor for help.',
            },
          });
        }

        return $q.resolve({});
      };

      // retrieves a presigned post object from the server at the
      // specified endpoint
      // return a promise
      this.getPresignedPost = function () {
        return self.validateFile().then(function () {
          var filename = fileinput().prop('files')[0].name;
          self.presignedPostParams.filename = filename;
          return $http
            .post(self.presignedPostURL, self.presignedPostParams)
            .then(function (res) {
              self.s3DirectPost = res.data;
              console.log('Retrieved permission to upload.');
              return res.data;
            })
            .catch(function (err) {
              // propogate the error
              return $q.reject(err);
            });
        });
      };

      // initiates the file upload
      // returns a promise
      this.sendToS3 = function () {
        var deferred = $q.defer();

        var fileList = fileinput().prop('files');
        var fileElement = fileinput()[0];

        // initialize the upload.
        // NOTE: add function must be here other wise the file will auto-upload! Boooo!
        $(fileElement).fileupload({ add: function (e, data) {} });

        $(fileElement)
          .fileupload('send', {
            files: fileList,
            url: self.s3DirectPost.url,
            type: 'POST',
            // autoUpload:       true, // we want to check user still has permission.
            formData: self.s3DirectPost.fields,
            paramName: 'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
            dataType: 'XML', // S3 returns XML if success_action_status is set to 201
          })
          .then(function (result, textStatus, jqXHR) {
            deferred.resolve(result);
            console.log('success');
          })
          .catch(function (jqXHR, textStatus, errorThrown) {
            deferred.reject(errorThrown);
            console.log('error');
          });
        // .complete(function (result, textStatus, jqXHR) { console.log('complete'); });

        return deferred.promise;
      };

      // this.createDocumentSubmission = function () {
      //     var document_submission = self.s3DirectPost;

      //     delete document_submission["fields"]
      //     delete document_submission["url"]

      //     return $http.post('document_submissions', {
      //         document_submission: document_submission,
      //     });
      // }

      function fileinput() {
        return $(self.formSelectorID).find("input[type='file']");
      }

      this.destroy = function () {
        return fileinput().fileupload('destroy');
      };

      // function upload(elem) {
      //     var fileInput = elem;

      //     // fileupload() initializes the upload
      //     fileInput.fileupload({
      //         fileInput: fileInput,
      //         //   url:             s3DirectPost.url,
      //         type: 'POST',
      //         //   autoUpload:       true, // we want to check user still has permission.
      //         //   formData:         s3DirectPost.fields,
      //         paramName: 'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      //         dataType: 'XML',  // S3 returns XML if success_action_status is set to 201
      //         replaceFileInput: false,  // must be false. removing causes things to break

      //         // NOTE: add function must be here other wise the file will auto-upload! Boooo!
      //         add: function (e, data) { },
      //     });

      //     return fileInput;
      // }
    };

    return S3Upload;
  },
]);
