app.controller('UserPreferenceController', [
  '$scope',
  '$http',
  '$timeout',
  'UserPreference',
  function ($scope, $http, $timeout, UserPreference) {
    /*--------------- 
    Scope Variable
    -----------------*/

    const userPreference = $scope.currentUser.user_preference || {};

    // Initialize UserPreference factories
    $scope.skinToneSettings = new UserPreference(userPreference, 'skin_tone', 'Skin Tone');
    $scope.typistInitialsSettings = new UserPreference(userPreference, 'typist_initials', 'Typist Initials');

    $scope.view = {
      // Skin tone options the user can choose from
      // Note: make sure this is the same as what's being used in SkinToneSettings.jsx
      skinToneOptions: [
        { alt: 'No Skin Tone', value: 'noSkinTone', color: '#FFD767' },
        { alt: 'Light Skin Tone', value: 'lightSkinTone', color: '#FCDDCD' },
        { alt: 'Medium Light Skin Tone', value: 'mediumLightSkinTone', color: '#ECBD96' },
        { alt: 'Medium Dark Skin Tone', value: 'mediumDarkSkinTone', color: '#A06940' },
        { alt: 'Dark Skin Tone', value: 'darkSkinTone', color: '#512D1A' },
      ],
    };

    /*--------------- 
    Init
    -----------------*/

    // activate tooltips
    $timeout(function () {
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
      });
    }, 1000);
  },
]);
