app.controller('CourseTemplateController', [
  '$scope',
  'course_templates',
  'organization',
  '$timeout',
  function ($scope, course_templates, organization, $timeout) {
    // loaded from the server
    $scope.course_templates = course_templates;

    $scope.organization = organization;

    // activate tooltips
    $timeout(function () {
      // Code snippet from docs https://getbootstrap.com/docs/5.1/components/tooltips/#example-enable-tooltips-everywhere
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
      });
    }, 1000);
  },
]);
