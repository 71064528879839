app.controller('CourseBuilderIndexController', [
  '$scope',
  'courseTemplates',
  'user',
  'allAssignments',
  function ($scope, courseTemplates, user, allAssignments) {
    $scope.allAssignments = allAssignments;
    $scope.courseTemplates = courseTemplates;
    $scope.organization = user.organization;
  },
]);
