import { angularStates } from '../../statesUrls';

app.config([
  '$stateProvider',
  function ($stateProvider) {
    var scrollToTop = [
      function () {
        window.scrollTo(0, 0); // scrolls to top of page
        return true;
      },
    ];

    $stateProvider

      // only permit users who have purchased access to the keyboarding product to see
      //  routes prefixed with app.keyboarding
      .state('app.documents', {
        template: '<ui-view/>',
        abstract: true, // this state will not have a url
        resolve: {
          hasPurchasedDocuments: [
            'currentUser',
            '$state',
            '$q',
            function (currentUser, $state, $q) {
              // check if user has access to documents and redirect as necessary
              if (!currentUser.instructor && currentUser.documentsExpired) {
                console.log('You do not have access to Documents. Redirecting to subscription page.');
                // redirect and send a flash message if user does not have subscription
                $state.go('app.subscriptions.document_production', {
                  flashMessage: { message: 'You have not purchased keyboarding.', error: true },
                });
                return $q.reject();
              }
              return true;
            },
          ],
        },
      })

      // these states facilitate a student activity though to completion.
      // Example:
      //  1. Go to http://localhost:3000/#/document_production/assignment/1/pre
      //       -> '1' is the assignment id. This is connected to DocumentAssignment::BusinessLetter (for example)
      //           see the seeds.rb in document_production for an example.
      //       -> Verifies whether user has permission to start the assignment.
      //       -> displays the pre assignment instructions.
      //  2. User clicks 'Start' to begin the document production activity.
      .state('app.documents.document_production_activity', {
        abstract: true,
        url: angularStates['app.documents.document_production_activity'],
        params: {
          document_assignment_id: null,
          notification_id: null,
        },
        resolve: {
          // We get a basic document assignment object (i.e. without instructions), since at this point we only want to display
          // basic information such the name of the assignment, etc. We get the full object when a user clicks 'Start'
          // using the InstructionsController
          documentAssignment: [
            'DocumentAssignment',
            '$stateParams',
            function (DocumentAssignment, $stateParams) {
              return new DocumentAssignment().initWithoutPermission($stateParams.document_assignment_id);
            },
          ],
          // check if the user has permission to do this exercise.
          permission: [
            '$stateParams',
            '$http',
            '$q',
            '$state',
            'currentUser',
            function ($stateParams, $http, $q, $state, currentUser) {
              // http://localhost:3000/#/document_production/assignment/1/0/pre
              if (currentUser.instructor) {
                return $q.resolve({});
              }

              return $http({
                method: 'GET',
                url: '/get_permission_from_notification_id/' + $stateParams.notification_id,
              })
                .then(function (res) {
                  if (res.data === null) {
                    return $q.reject();
                  }
                  // console.log(res.data);
                  return res.data;
                })
                .catch(function () {
                  console.log('Error: No permission available for current assignment');
                  $state.go('app.home');
                  return { data: null };
                });
            },
          ],
        },
        templateUrl: 'document_production/document_assignment/_document_assignment_template.html',
        controller: 'DocProductionActivityController',
      })
      .state('app.documents.document_production_activity.pre', {
        url: angularStates['app.documents.document_production_activity.pre'],
        templateUrl: 'document_production/document_assignment/document_assignment_pre.html',
        controller: 'DocumentProductionPreController',
        onEnter: scrollToTop,
      })

      .state('app.documents.document_production_activity.instructions', {
        url: '/instructions',
        templateUrl: 'document_production/document_assignment/show_student_instructions.html',
        controller: 'InstructionsController',
        resolve: {
          // documentAssignment: ['DocumentAssignment', '$stateParams', 'permission', 'user', function(DocumentAssignment, $stateParams, permission, user) {
          //   if (user.instructor) return new DocumentAssignment().init($stateParams.document_assignment_id);
          //   return new DocumentAssignment().initFromPermission(permission.id);
          // }],
          documentAssignment: [
            'DocumentAssignment',
            'permission',
            'currentUser',
            '$q',
            '$stateParams',
            '$state',
            function (DocumentAssignment, permission, currentUser, $q, $stateParams, $state) {
              // if instructor...
              if (currentUser.instructor) return new DocumentAssignment().init($stateParams.document_assignment_id, currentUser);
              // if student...
              return new DocumentAssignment().initFromPermission(permission.id, permission.section_id).catch(function () {
                $state.go('app.documents.document_production_activity.pre', $stateParams);
              });
            },
          ],
          // documentAssignment: ['DocumentAssignment', 'document_assignment_id', function(DocumentAssignment, document_assignment_id){
          //   return true;
          //   debugger;
          //   DocumentAssignment;
          //   document_assignment_id;
          //   // return new DocumentAssignment
          // }],
        },
        onEnter: scrollToTop,
      })

      // NOTE: if no longer sharing a template with rest of document production, then don't need abstract parent state
      .state('app.documents.document_production_results', {
        abstract: true,
        url: '/document_production/results/:activity_id',
        params: {
          activity_id: null,
        },
        resolve: {
          user: [
            'Auth2',
            '$state',
            function (Auth2, $state) {
              return Auth2.currentUser().then(
                function (user) {
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        templateUrl: 'document_production/document_assignment/_document_assignment_template.html',
      })
      // TODO: Can we collapse these into one state?
      .state('app.documents.document_production_results.main', {
        url: '',
        templateUrl: 'document_production/document_submission/results_view.html',
        controller: 'DocProductionResultsController',
        resolve: {
          activityResults: [
            'DocProductionResultsDataService',
            '$stateParams',
            function (DocProductionResultsDataService, $stateParams) {
              return DocProductionResultsDataService.getSubmission($stateParams.activity_id).then(function (documentSubmission) {
                return documentSubmission;
              });
            },
          ],
          user: [
            'Auth2',
            '$state',
            'activityResults',
            function (Auth2, $state, activityResults) {
              return Auth2.currentUser().then(
                function (user) {
                  // check if
                  if (user.id !== activityResults.user_id && user.id !== activityResults.instructor_id && !user.instructor) {
                    $state.go('app.home');
                  }
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          isGradeReleased: [
            'user',
            '$state',
            'activityResults',
            '$stateParams',
            function (user, $state, activityResults, $stateParams) {
              if (activityResults.grade_released) return true;
              if (user.instructor) return true;
              console.log('User arrived at results page on error. Results are not yet released.');
              return $state.go('app.documents.document_production_activity.pre', $stateParams);
            },
          ],
        },
        onEnter: scrollToTop,
      })

      // These states are to generate a list of all possible document assignments and
      //  layer them into the view
      .state('app.instructor.document_assignment_index', {
        url: angularStates['app.instructor.document_assignment_index'],
        controller: 'DocumentAssignmentIndexController',
        templateUrl: 'document_production/document_assignment/document_assignment_index.html',
        resolve: {
          user: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  // User instructor check happens at the parent state (app.instructor)
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          documentAssignments: [
            '$http',
            'DocumentAssignment',
            function ($http, DocumentAssignment) {
              return $http.get('document_assignments').then(function (response) {
                const documentAssignments = response.data;
                return documentAssignments.map(function (da) {
                  return new DocumentAssignment(da);
                });
              });
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      });

    // Instructor/admin views
    $stateProvider
      // Admin view for unrestricted view of the contacts directory
      .state('app.admin.contacts_directory', {
        url: angularStates['app.admin.contacts_directory'],
        controller: 'ContactsDirectoryAdminController',
        templateUrl: 'document_production/admin/contacts_directory.html',
      });
  },
]);
