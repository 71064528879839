// KeyboardUtilities
app.service('keyboardUtilities', [
  function () {
    // Utility functions
    var keycode_map = {
      8: 'Backspace',
      9: 'Tab',
      13: 'Enter',
      16: 'Shift',
      17: 'Control',
      18: 'Alt',
      20: 'CapsLock',
      32: ' ',
      48: '0',
      49: '1',
      50: '2',
      51: '3',
      52: '4',
      53: '5',
      54: '6',
      55: '7',
      56: '8',
      57: '9',
      59: ';', //this is a hack. have to stop using keycodes
      96: '0', //numpad
      97: '1', //numpad
      98: '2', //numpad
      99: '3', //numpad
      100: '4', //numpad
      101: '5', //numpad
      102: '6', //numpad
      103: '7', //numpad
      104: '8', //numpad
      105: '9', //numpad
      91: 'Meta',
      173: '-',
      186: ';',
      187: '=',
      188: ',',
      189: '-',
      190: '.',
      191: '/',
      192: '`',
      219: '[',
      220: '\\',
      221: ']',
      222: "'",
    };

    // Utility functions
    var keycode_map_with_shift = {
      8: 'Backspace',
      9: 'Tab',
      13: 'Enter',
      16: 'Shift',
      17: 'Control',
      18: 'Alt',
      20: 'CapsLock',
      32: ' ',
      34: '"', //this is a hack. have to stop using keycodes
      48: ')',
      49: '!',
      50: '@',
      51: '#',
      52: '$',
      53: '%',
      54: '^',
      55: '&',
      56: '*',
      57: '(',
      59: ':', //this is a hack. have to stop using keycodes
      96: '0', //numpad
      97: '1', //numpad
      98: '2', //numpad
      99: '3', //numpad
      100: '4', //numpad
      101: '5', //numpad
      102: '6', //numpad
      103: '7', //numpad
      104: '8', //numpad
      105: '9', //numpad
      91: 'Meta',
      173: '_',
      186: ':',
      187: '+',
      188: '<',
      189: '_',
      190: '>',
      191: '?',
      192: '~',
      219: '{',
      220: '|',
      221: '}',
      222: '"',
    };

    this.getKeyPressed = function (e, case_sensitive) {
      var key;
      // Transform the character if it's non-alpha
      if (e.key) {
        key = e.key;
        if (key === 'Spacebar') {
          key = ' ';
        } // Fix for internet explorer
        // Just like Spacebar, the idea here is to convert the 'Enter' key code
        // to what the Enter key represents as a single ASCII
        // This is a custom char to represent an enter key, and has to be consistent
        // with what is found in the 'text to type' passed to TypingInput service
        if (key === 'Enter') {
          key = '↵';
        }
      } else if (e.keyCode) {
        // use keyCode | LEGACY BROWSERS ONLY
        key = keyFromKeyCode(e);
      }

      // debug
      // console.log("Key: " + key);
      // console.log("e.key: " + e.key);
      // console.log("e.keyCode: " + e.keyCode);
      // console.log("e.keyIdentifier: " + e.keyIdentifier);
      // console.log(e);
      // console.log("");

      return key;
    };

    this.blockDefaultBehaviourOfTheseKeys = function (e) {
      // Prevent default actions of backspace (8), enter(13), space(32), ...
      //      single-quote(222) since it opens a finder window in firefox
      //      "/" since it jumps to debugger finder in Firefox
      // Don't block the keyboard if an input is in focus
      if (e.target.localName === 'input') return;
      if (['Tab', 'Backspace', 'Enter', ' ', "'", '/'].indexOf(e.key_pressed) >= 0) {
        e.preventDefault();
        return true;
      } else {
        return false;
      }
    };

    // Used through our modals to prevent scrolling using the space key
    //   - we allow advancing through modals with the space key
    this.blockScrollingWithSpaceKey = function (e) {
      // Prevent default actions space(32), ...
      if (e.target.localName === 'input') return;
      if ([' '].indexOf(e.key_pressed) >= 0) {
        e.preventDefault();
        return true;
      } else {
        return false;
      }
    };

    // This is for backwards compatibility, where e.key is not defined in the keypress event.
    var hasBeenWarned = false; // outputs a console log that we're using legacy keyCodes
    function keyFromKeyCode(e) {
      // warn that we're using keyCodes
      if (!hasBeenWarned) {
        console.log('Warning: Using legacy keycodes to determine keypresses.');
        hasBeenWarned = true;
      }

      var case_sensitive = typeof case_sensitive !== 'undefined' ? case_sensitive : true;
      var key = String.fromCharCode(e.keyCode);

      if (e.keyCode < 60 || e.keyCode > 90) {
        if (e.shiftKey) {
          key = keycode_map_with_shift[e.keyCode];
        } else {
          key = keycode_map[e.keyCode];
        }
      }

      // Set equal to an empty string if a key was pressed that we don't care about
      if (key == undefined) {
        key = '';
      }

      // Return the lower case if shift and cap lock is not held down
      if (!e.shiftKey || !case_sensitive) {
        key = key.toLowerCase();
      }
      return key;
    }
  },
]);
