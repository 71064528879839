// Animates the keyboard
app.controller('FingerAnimationsModalController', [
  'ModalSequence',
  'ModalSequenceArray',
  '$scope',
  function (ModalSequence, ModalSequenceArray, $scope) {
    // Instantiate our sequences. First ModalSequences will be auto-started below
    var modals = [];
    modals.push(new ModalSequence('#welcome-modal-sequence', { modalNames: ['finger-animated-keyboard'] }));

    // start the first modalSequence
    var modalSequences = new ModalSequenceArray(modals);

    // attach the event listener and activate modal
    // var listenerDestroyer = activateListener();
    modalSequences.getByIndex(0).start();

    $scope.repeatAnimation = function () {
      var letter = $('#chosen-letter')[0].value || 'w';
      $scope.$broadcast('animate-keyboard', { digit: letter, isShift: false });
      return;
    };
    // $scope.keysdown_lowcase = [];
    //
    // $scope.$on("keyup", function(a, e) {
    //     $scope.remove_from_keysdown_lowcase(e.key_released_lowcase);
    //     // also checks if command was released. If yes, clears keysdown
    // });
    //
    // $scope.$on("keydown", function(a, e) {
    //     $scope.add_to_keysdown_lowcase(e.key_pressed_lowcase);
    //     keyboardUtilities.blockDefaultBehaviourOfTheseKeys(e);
    // });
    //
    // $scope.$on("blur", function(a, e) {
    //   $scope.keysdown_lowcase = [];
    // })
    //
    // $scope.isKeyDown = function(key_to_check) {
    //   return ($.inArray(key_to_check, $scope.keysdown_lowcase) > -1)
    // }
    //
    // // keysdown_lowcase
    // $scope.add_to_keysdown_lowcase = function(key) {
    //     if (!$scope.isKeyDown(key)) {
    //         $scope.keysdown_lowcase.push(key);
    //     }
    // };
    // $scope.remove_from_keysdown_lowcase = function(key) {
    //     var index_of_key = $.inArray(key, $scope.keysdown_lowcase);
    //     if(index_of_key > -1) {
    //       $scope.keysdown_lowcase.splice(index_of_key, 1);
    //     }
    //     if (key === "command") {
    //       $scope.keysdown_lowcase = [];
    //     }
    // };
    //
    //
    // // Hands ------------------------------------------------------------------------------------------
    // // showHands();
    // // var typingTimer = $timeout(function() { showHands(); }, 2000);
    // var typingTimer;
    // var activateHands = false;
    // $scope.$on("keyup", function(a, e) {
    //   if (activateHands) {
    //     $timeout.cancel(typingTimer);
    //     hideHands();
    //     typingTimer = $timeout(function() { showHands(); }, 2000);
    //   }
    // });
    //
    // $scope.$on("keyboard:show-hands", function() {
    //   activateHands = true;
    //   $timeout.cancel(typingTimer);
    //   $timeout(showHands, 500);
    //   typingTimer = $timeout(function() { showHands(); }, 2000);
    // });
    //
    // $scope.$on("keyboard:hide-hands", function() {
    //   activateHands = false;
    //   $timeout.cancel(typingTimer);
    // })
    //
    // var handsTopCss = parseInt($('#hands-over-keyboard').css('top'), 10);
    // var slideAmount = 30;
    // function showHands() {
    //   if(!$('#hands-over-keyboard').is(':visible')) {
    //     $("#hands-over-keyboard").css("top", handsTopCss+slideAmount);
    //     $("#hands-over-keyboard").show().animate({top: "-=" + String(slideAmount)});
    //   }
    // }
    //
    // function hideHands() {
    //   if($('#hands-over-keyboard').is(':visible')) {
    //     $("#hands-over-keyboard").fadeOut(120);
    //   }
    // }
  },
]);
