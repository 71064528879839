// Helper methods for the Student Factory
app.service('StudentHelpers', [
  '$http',
  '$filter',
  function ($http, $filter) {
    // default activity values
    this.setDefaults = function (userDataArray, key) {
      if (userDataArray.length > 0) {
        return userDataArray;
      }
      // var obj = {height: 1, minutes: 0 };

      var defaultValues = {
        // practice_time: [{user_id: this.id, practice_time: [obj, obj, obj, obj, obj, obj, obj, obj, obj, obj] }],
        timed_writings: [],
        top_scores: [{ netWPM: 0, grossWPM: 0, accuracy: 0, hidden: true }],
        avg_top_3: [{ speed: 0, tooltip: '(0+0+0)/3', hidden: true }],
        avg_top_4: [{ speed: 0, tooltip: '(0+0+0+0)/4', hidden: true }],
        avg_top_5: [{ speed: 0, tooltip: '(0+0+0+0+0)/5', hidden: true }],
      };
      return defaultValues[key];
    };

    this.augmentData = function (userDataArray, key, student) {
      var augmentData = {
        timed_writings: addTooltipsToTimedWritings,
        timed_writing_permissions: countTimedWritingPermissions,
      };

      // if we have a mapping, apply it to each element in array
      if (typeof augmentData[key] === 'function') {
        userDataArray = augmentData[key](userDataArray, student);
      }

      return userDataArray;
    };

    // LOCAL FUNCTIONS TO AUGMENT SERVER RESPONSES -------------------

    // counts the number of timedWritingPermissions that are active
    function countTimedWritingPermissions(timedWritingPermissions, student) {
      if (!student.timed_writing_permissions || !student.timed_writing_permissions.length == 0) {
        student.view.activeTimedWritingPermissions = [];
        student.view.expiredTimedWritingPermissions = [];
        return;
      }
      student.view.activeTimedWritingPermissions = student.timed_writing_permissions.filter((p) => p.ms_to_expiry > 0);
      student.view.expiredTimedWritingPermissions = student.timed_writing_permissions.filter((p) => p.ms_to_expiry <= 0);
    }

    // Augments timed_writings received from server, adding tooltips etc.
    function addTooltipsToTimedWritings(t_ws, student) {
      var requirements = student.requirements;

      t_ws = t_ws.map(function (t_w) {
        if (requirements.accuracy_metric === 'accuracy') {
          t_w.tooltip =
            String(t_w[requirements.type]) +
            ' ' +
            requirements.type +
            ' | ' +
            String(t_w.accuracy) +
            '% accuracy for ' +
            String(t_w.duration_in_ms / 60000.0) +
            ' mins on ' +
            $filter('date')(new Date(t_w.created_at), 'MMM d, y');
        } else if (requirements.accuracy_metric === 'max_errors') {
          t_w.tooltip =
            String(t_w[requirements.type]) +
            ' ' +
            requirements.type +
            ' | ' +
            String(t_w.errors_nonbackspaced) +
            ' total errors for ' +
            String(t_w.duration_in_ms / 60000.0) +
            ' mins on ' +
            $filter('date')(new Date(t_w.created_at), 'MMM d, y');
        } else {
          console.log('WARNING: ERROR IN SECTION FACTORY!');
        }
        t_w.created_at_timestamp = t_w.created_at;
        t_w.data = JSON.parse(t_w.data);
        t_w.passed = t_w.achieved_min_accuracy && t_w[requirements.type] >= Number(requirements.speed);

        return t_w;
      });

      student.numPasses = t_ws.filter(function (t_w) {
        return t_w.passed;
      }).length; // used to sort view
      student.numPassesTooltip =
        student.first_name +
        ' has passed this test ' +
        (student.numPasses >= 5 ? 'at least ' : '') +
        String(student.numPasses) +
        ' times this semester!';

      return t_ws;
    }
  },
]);
