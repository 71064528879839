app.factory('ContactsDirectory', [
  '$timeout',
  '$http',
  '$q',
  function ($timeout, $http, $q) {
    var contactsDirectory = function (props) {
      var self = this;

      /***********
       * Non-view variable declaration
       **********/
      // define keys to search inside the contacts in directoryListing and their point value when calculating search score
      this.keysToSearch = {
        firstName: 1,
        lastName: 2,
        company: 2,
      };

      this.directoryListing = null;

      /***********
       * View variable declaration
       **********/

      // Scope variables accessible in view
      this.view = {
        disableSearch: true,
        errorLoadingContactsList: false,
        // Status variable that determines when to stop displaying spinning circle animation
        directoryListingLoaded: false,
        // Status variable for search results being loaded
        searchResultsLoading: false,
        // Making sure this is null instead of empty array, displays an intro message instead of "no results"
        filteredDirectoryListing: null,
        // Variable that determines which contact card to expand
        selectedContactCardId: null,
        // Used for pagination
        itemsPerPage: 10, // if max we ever display is 100 results, 10 per page will yield only 10 pagination tabs
        currentPageNumber: 1,
      };

      // Attach any properties passed into the constructor
      assignAllPropertiesToSelf(props);

      /***********
       * Methods
       **********/

      this.init = function (contactsListUrl, document_assignment_id) {
        // Start with a promise so that we have a single catch statement (due to $http.get throwing some errors not via the promise chain)
        return $http
          .get(contactsListUrl)
          .then(function (res) {
            var contactsList = res.data.people;
            // Quick check that the contactsList retrieved is a valid contact list
            if (!isValidContactDirectory(contactsList)) return $q.reject(new Error('Invalid contactsList'));

            self.directoryListing = contactsList;
            self.view.directoryListingLoaded = true;
            self.view.disableSearch = false;
            // returning self to enable function chaining
            return contactsList;
          })
          .catch(function (e) {
            console.error(`Error (da id:${document_assignment_id}):`, e);
            self.view.errorLoadingContactsList = true;
          });
      };

      // generate an array of page numbers given total number of items and number of items per page
      // eg. total of 10 items with 3 items per page will generate [1,2,3,4]
      this.createPaginationArray = function (totalCount, itemsPerPage) {
        var numPages = Math.ceil(totalCount / itemsPerPage);
        var accArray = [];
        for (var i = 0; i < numPages; i++) {
          accArray.push(i + 1);
        }
        return accArray;
      };

      // A function to set a new page number, taking into account min and max pages given the number of pages and
      this.setPageNumber = function (pageNumber, totalCount, itemsPerPage) {
        self.view.currentPageNumber = Math.max(
          Math.min(pageNumber, Math.ceil(self.view.filteredDirectoryListing.length / self.view.itemsPerPage)),
          1
        );
      };

      // Function for filtering the directory listing based on searched keyword
      this.searchDirectory = function (searchString) {
        // Returns to contact cards list view, in case a card is currently expanded
        self.collapseContactCard();
        // Reset the current page number to default first page
        self.view.currentPageNumber = 1;

        // Check to make sure keyword is a string and directoryListing has been loaded, because further operations require it to be
        if (Array.isArray(self.directoryListing) && typeof searchString === 'string' && searchString) {
          //  showing loading spinner
          self.view.searchResultsLoading = true;
          self.view.disableSearch = true;

          $timeout(function () {
            self.view.filteredDirectoryListing = self.directoryListing
              // go through every contact in directory listing, and calculate a match score for each
              .map(function (contact) {
                // Split search keyword at whitspace boundary, generating an array
                var searchScore = searchString
                  .toLowerCase()
                  .split(/\s+/) // split search keyword at whitespace boundary, to get an array of individual words
                  // given array of individual keywords, we want to calculate the cumulative search score for the entire search string
                  .reduce(function (acc, keyword) {
                    var keywordScore = 0; // Initially, the given keyword has 0 score across all searchable details of the current contact

                    // Go through every "searchable detail" in the current contact
                    for (var key in self.keysToSearch) {
                      // Make sure we do not loop through inherited properties (much cleaner syntax with ES6: Object.keys() or Object.entries())
                      if (self.keysToSearch.hasOwnProperty(key)) {
                        if (typeof contact[key] === 'string' && keyword === contact[key].slice(0, keyword.length).toLowerCase()) {
                          // If matched, add the number of points to the accumulator, relative to number of characters matched (this prevents short words like initials dominating the results)
                          keywordScore += (self.keysToSearch[key] * keyword.length) / contact[key].length;
                        }
                      }
                    }
                    return acc + keywordScore;
                  }, 0);
                // The returned object is the same contact card (now part of filteredDirectoryListing scope variable) with a search score
                return Object.assign({}, contact, { _searchScore: searchScore });
              })
              .sort(function (contactA, contactB) {
                // Optionally after this, can remove the _searchScore key
                return contactB._searchScore - contactA._searchScore;
              })
              .filter(function (contact) {
                // Only show contacts with a relevant search score
                return contact._searchScore > 0;
              });

            // turn of loading message
            self.view.searchResultsLoading = false;
            self.view.disableSearch = false;
          }, 1000); // end of timeout function
        } else {
          // turn of loading message
          self.view.searchResultsLoading = false;
          // Default optionis to return an empty array
          self.view.filteredDirectoryListing = [];
        }
      };

      this.expandContactCard = function (id) {
        self.view.selectedContactCardId = id;
      };

      this.collapseContactCard = function (id) {
        self.view.selectedContactCardId = null;
      };

      // assigns all properties retrieved from the server to self
      function assignAllPropertiesToSelf(props) {
        for (var prop in props) {
          if (props.hasOwnProperty(prop)) {
            // User Instruction Factory for prop
            if (prop === 'instructions') {
              props[prop] = props[prop].map(function (inst) {
                return new Instruction(inst);
              });
            }
            self[prop] = props[prop];
          }
        }
      }

      // quick check if we retrieved a valid contact list
      // Should be an array, and should have a few common properties
      const isValidContactDirectory = (directory) => {
        return (
          Array.isArray(directory) &&
          directory.length > 0 &&
          (directory[0].hasOwnProperty('firstName') ||
            directory[0].hasOwnProperty('lastName') ||
            directory[0].hasOwnProperty('company'))
        );
      };
    }; // end of constructor
    return contactsDirectory;
  },
]);
