// Factory to create or instantiate document assignment instructions
app.factory('Instruction', [
  '$http',
  'S3Upload',
  '$q',
  'markdownit',
  'ContactsDirectory',
  'AudioFile',
  'StarterFileBrowser',
  function ($http, S3Upload, $q, markdownit, ContactsDirectory, AudioFile, StarterFileBrowser) {
    // country param is the country current Document Assignment belongs to
    // generatedVariables is an object where each key is a random variable name used in instructions text
    // and the values are strings of text the variables should be substituted with (eg. { 'contact1.firstName': 'John'})
    var Instruction = function (props, country, generatedVariables = {}, documentAssignment) {
      var self = this;
      // generated document variables that are used for substituting into the instruction text
      // (if text has variable placeholders). In the form of {'contact.first_name': 'John'}
      this.generatedVariables = generatedVariables; // undefined if not available
      // the document assignment object that the instructions
      // are associated with. Contains the url for contacts directory
      this.documentAssignment = documentAssignment;

      assignAllPropertiesToSelf(props, country, documentAssignment);

      this.update = function () {
        return $http
          .post('api/instructions', {
            instruction: {
              id: self.id,
              name: self.name,
              display_type: self.display_type,
              number: self.number,
              document_assignment_id: self.document_assignment_id,
              content: self.content,
              options: self.options,
            },
          })
          .then(function (res) {
            self.view = {
              errorMessage: null,
              disableForm: false,
              successMessage: 'Successfully updated this instruction!',
            };
            assignAllPropertiesToSelf(res.data);
          })
          .catch(function (res) {
            self.view = {
              errorMessage: res.data.errors || 'Oh no -- something went wrong! Better have Matt take a look at this.',
              disableForm: false,
              successMessage: null,
            };
            return $q.reject();
          });
      };

      this.destroy = function () {
        var r = confirm(
          "Warning: This will permanently delete this instruction and will affect any student currently accessing this assignment. Is this what you'd like to do?"
        );
        if (r == true) {
          return $http
            .delete('api/instructions/' + self.id, {
              instruction: {
                id: self.id,
                name: self.name,
                display_type: self.display_type,
                number: self.number,
                document_assignment_id: self.document_assignment_id,
                content: self.content,
                options: self.options,
              },
            })
            .then(function (res) {
              self.view = {
                errorMessage: null,
                disableForm: false,
                successMessage: 'This instruction has been deleted!',
              };
              assignAllPropertiesToSelf(res.data);
            })
            .catch(function (res) {
              self.view = {
                errorMessage: res.data.errors || 'Oh no -- something went wrong! Better have Matt take a look at this.',
                disableForm: false,
                successMessage: null,
              };
              return $q.reject();
            });
        }
      };

      this.uploadImage = function (formSelector) {
        self.view = {
          errorMessage: null,
          successMessage: 'Checking Permissions...',
          disableForm: true,
        };

        var s3Upload = new S3Upload({
          formSelectorID: formSelector,
          validFileFormats: ['png'],
          presignedPostURL: 'api/instructions/set_presigned_post',
          presignedPostParams: {
            id: self.id,
            document_assignment_id: self.document_assignment_id,
            display_type: self.display_type,
            number: self.number,
            upload_type: 'docx_starter_key',
          },
        });

        return s3Upload
          .getPresignedPost()
          .then(function (data) {
            self.content = data.content;
            self.options.filename = data.options.filename;
            self.view.successMessage = 'Permission received! Attempting upload...';
          })
          .catch(function (err) {
            self.view = {
              errorMessage: err.data.errors,
            };
            return $q.reject(err.data.errors);
          })
          .then(s3Upload.sendToS3)
          .then(function (res) {
            self.view = {
              errorMessage: null,
              disableForm: true,
              successMessage: 'File uploaded. Updating the reference in our database...',
            };
            return res;
          })
          .then(self.update)
          .catch(function (err) {
            if (!self.view.errorMessage) {
              self.view = {
                errorMessage: 'Oh no -- something went wrong! Better have Matt take a look at this.',
                disableForm: false,
                successMessage: null,
              };
            }
            return $q.reject();
          });
      };

      // private methods ------------------------------------------------------
      // assigns all properties retrieved from the server to self
      // country prop is the country current Document Assignment belongs to,
      // should be either 'CA' or 'US'. It gets passed on to
      // StarterFileBrowser class so that the correct version of the widget is rendered
      function assignAllPropertiesToSelf(props, country, documentAssignment) {
        for (var prop in props) {
          if (props.hasOwnProperty(prop)) {
            // If the Instruction is of markdown type, add the contentHTML
            if (prop === 'display_type' && props[prop] === 'markdown') {
              self.contentHTML = insertGeneratedVariables(markdownit.render(props['content'] || ''), self.generatedVariables);
            } else if (prop === 'display_type' && props[prop] === 'draftLetter') {
              // const content = obfuscateContentHTML(props['content'] || '');
              const contentHTML = insertGeneratedVariables(markdownit.render(props['content'] || ''), self.generatedVariables);
              self.contentHTML = obfuscateContentHTML(contentHTML);
            } else if (prop === 'display_type' && props[prop] === 'contactsDirectory') {
              // Note: props.content should contain the url linking to the contactsList json
              self.contactsDirectory = new ContactsDirectory();
              if (documentAssignment.contacts_directory_url) {
                self.contactsDirectory.init(documentAssignment.contacts_directory_url, documentAssignment.id);
              }
            } else if (prop === 'display_type' && props[prop] === 'audio') {
              // Note: props.content should contain the url linking to the contactsList json
              self.audioFile = new AudioFile();
            } else if (prop === 'display_type' && props[prop] === 'starterFileBrowser') {
              // Note: props.content should contain the url linking to the contactsList json
              self.starterFileBrowser = new StarterFileBrowser(country, documentAssignment.style_guide_version);
            }
            // assign all properties from the Instruction model
            self[prop] = props[prop];
          }
        }
      }

      // This function takes a `text` string that contains syntax in the form of `{{contact1.first_name}}`
      // These parts of the text are substituted with the matching string in the generatedVariables object
      // generatedVariables object is in the form {'contact1.first_name': 'John'}
      // Updated text string is returned
      function insertGeneratedVariables(text, generatedVariables) {
        const regex = new RegExp(`\{\{([a-zA-Z0-9_\.]*)\}\}`, `g`);
        return text.replaceAll(regex, (match, p1, offset, str) => {
          const replacementStr = generatedVariables[p1];
          // TODO: decide what to do, throw error which stops page from rendering?
          // Or add a flash message option to each instruction ?
          // if(replacementStr == undefined) throw new Error('Error: could not generated instructon text.')
          if (replacementStr == undefined)
            return '<mark>**Missing Instruction. Please contact Typist support (support@typistapp.ca).**</mark>';
          return replacementStr;
        });
      }

      // function takes in contentHTML, a stringified html (returned by markdownit.render)
      // and returns the same stringified html, but with zero width white space (unicode U+200B ZERO WIDTH SPACE) characters interspersed throughout
      function obfuscateContentHTML(contentHTML) {
        const dom = document.createElement('div');
        dom.innerHTML = contentHTML;

        // The strategy is to get every Text node only (icluding nested) and only those that are not just continuously white space characters
        // because inserting the zero-width space character in between continuous ' ', prevents the DOM from properly remvoving whitespace via its render algorithm
        // eg. the html that we see in VSCode and the html returned by markdownit contains lot's of newlines and spaces for easier readability, however when the browser renders it,
        // it collapses continuous spaces, removes newline characters, etc.
        // This article has good explanations - https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model/Whitespace
        let treeWalker = document.createTreeWalker(
          dom, // Root element where TreeWalker starts
          NodeFilter.SHOW_TEXT, // Which types of features TreeWalker should extract (see docs for other types)
          // Optional function that can be used to filter elements that are returned bt TreeWalker
          (node) => {
            // select all text nodes that are not just white space
            return !is_ignorable(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
          }
        );

        // Go through every element that the TreeWalker finds (this automatically includes nested elements)
        // and add non breaking space characters throughout
        while (treeWalker.nextNode()) {
          const text = treeWalker.currentNode.textContent;
          // Only adding zero width space character to spaces, and not after every letter, because the latter
          // messes up the browser's word wrapping algorithm (due the zero width character, words can then be split at any position)
          treeWalker.currentNode.textContent = text
            .split(' ') // Split at every space character
            .map((char) => `${char}\u200B`) // Add the zero width space
            .join(' ') // re-add the original space character that was used to split the string
            .replace(/o/g, `\u03bf`); // additional layer of cheating prevention - swap all 'o' characters with an identical looking Greek omicron character
        }

        return dom.innerHTML;
      }

      // The following helper functions are taken from https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model/Whitespace
      // They are what browser DOM uses for rendering stringified html content on the page (by properly collapsing consecutive space characters, trimming nodes, etc)

      /**
       * Determine whether a node's text content is entirely whitespace.
       *
       * @param nod  A node implementing the |CharacterData| interface (i.e.,
       *             a |Text|, |Comment|, or |CDATASection| node
       * @return     True if all of the text content of |nod| is whitespace,
       *             otherwise false.
       */
      function is_all_ws(nod) {
        // Use ECMA-262 Edition 3 String and RegExp features
        return !/[^\t\n\r ]/.test(nod.textContent);
      }

      /**
       * Determine if a node should be ignored by the iterator functions.
       *
       * @param nod  An object implementing the DOM1 |Node| interface.
       * @return     true if the node is:
       *                1) A |Text| node that is all whitespace
       *                2) A |Comment| node
       *             and otherwise false.
       */

      function is_ignorable(nod) {
        return (
          nod.nodeType == 8 || // A comment node
          (nod.nodeType == 3 && is_all_ws(nod))
        ); // a text node, all ws
      }
    };

    return Instruction;
  },
]);
