app.controller('GamesController', [
  '$scope',
  '$http',
  '$timeout',
  '$state',
  function ($scope, $http, $timeout, $state) {
    $scope.successErrorMessage = null;
    $scope.disableButtons = false;
    $scope.contest = {};

    // gets the notification_id from the contest code, and redirects
    $scope.joinContest = function (contest) {
      $scope.contest.errors = null;
      disableButtonsFor3Secs();
      if (!contest.code) {
        flashErrorMessage('Please enter the code for this contest. Your instructor will have it on their screen.');
        return;
      }

      $http
        .post('contest/get_firebase_id_from_code.json', { contest: { code: contest.code } })
        .then(function (response) {
          flashSuccessMessage('Taking you to your contest! Good luck!');
          $timeout(function () {
            $state.go('app.keyboarding.competition', { contest_id: response.data.firebase_id });
          }, 3000);
        })
        .catch(function (response) {
          flashErrorMessage(response.data.errors);
        });
    };

    // helpers for the error/success messages that can appear
    // method to close the div when the 'x' is clicked on
    $scope.hideSuccessErrorMessages = function () {
      $scope.successErrorMessage = null;
    };

    function flashErrorMessage(message) {
      $scope.successErrorMessage = { message: message, success: false };
      $timeout(function () {
        $scope.successErrorMessage = null;
      }, 5000);
    }

    function flashSuccessMessage(message) {
      $scope.successErrorMessage = { message: message, success: true };
      // $timeout(function() { $scope.successErrorMessage = null; }, 5000);
    }

    function disableButtonsFor3Secs() {
      $scope.disableButtons = true;
      $timeout(function () {
        $scope.disableButtons = false;
      }, 3000);
    }
  },
]);
