import InteractiveKeyboard from '../../../../components/InteractiveKeyboard/InteractiveKeyboard';
// PracticeTimedWritingController
//
// Handles all activities during a timed writing.
//
// Listens for the following events:
//  a. keydown : creates the typing log
//  b. TimedWritingModals:complete : performs an action based on which modal finished
//
// When timed writing complete, broadcasts:
//  a. TimedWritingModals:start : launches the appropriate modal.
//
// Controls the functionality during lessons
//  -> controls launching modals on this page
//  -> controls how the stats are displayed on the screen
//  -> calls methods to save or update data for user.
app.controller('PracticeTimedWritingController', [
  '$scope',
  'TypingInput',
  '$timeout',
  '$q',
  '$state',
  'userSettings',
  'UserSettings',
  'accountSettings',
  'currentUser',
  'ReactComponent',
  'Auth2',
  'keyboardUtilities',
  function (
    $scope,
    TypingInput,
    $timeout,
    $q,
    $state,
    userSettings,
    UserSettings,
    accountSettings,
    currentUser,
    ReactComponent,
    Auth2,
    keyboardUtilities
  ) {
    // adding currentUser so that the modal controllers can access it through
    // $scope.$parent.currentUser
    $scope.currentUser = currentUser;
    // Track the digitIndex variable from TypingInput service. Used for displaying the next-to-type letter as a hint
    // for the student if no keypress is detected
    $scope.digitIndex = 0;

    $scope.$broadcast('keyboard:hide-hands');
    // Global variables ---------------------------------------------------------------
    $scope.timedWritingModal = userSettings.orDefault({
      practice: { duration: '5', type: 'netWPM', accuracy_metric: 'accuracy' },
    });
    $scope.accountSettings = accountSettings;

    // Get/create instance of a ReactComponent with id 'react-keyboard'.
    // the updateReactKeyboard and removeReactKeyboard functions act specifically on the react component with id 'react-keyboard'
    const { update: updateReactKeyboard, remove: removeReactKeyboard } = ReactComponent.init(
      'react-keyboard',
      InteractiveKeyboard,
      {
        enableHover: false,
        animateOnKeyPress: false,
        version: 2,
        highlightKeys: [],
        hideHands: true,
        skinTone: currentUser.user_preference.skin_tone,
        skinToneUpdateCallback: (skinTone) => {
          // first, we update the currentUser in this controller
          currentUser.user_preference.skin_tone = skinTone;
          // next, we have to update the currentUser stored in Auth2 so that all other
          // angularJS controllers have access to the updated user object
          Auth2.updateUserPreference({ skin_tone: skinTone });
        },
      }
    );
    // The init function only sets up the ReactComponent factory, but does not render the react component on screen,
    // so an update function must be called (it will render the initial props passed into the init function)
    updateReactKeyboard();

    // Local variables ---------------------------------------------------------------
    var userMayStart = false;
    var sessionReady = newSession(); // newSession is a promise that resolves once ready

    // Creates a new TypingInput object, assigns it to $scope
    //  -> returs: A Promise that resolves once we're ready.
    function newSession() {
      $scope.TypingInput = TypingInput.newSession({
        activity_type: 'PracticeTimedWriting', // Used with save, autosave
        // primarySpeedMetric: "netWPM",  // Affects whether net/gross speed is displayed in view
        enableBackspace: true, // enable the backspace button?
        blockOnError: false, // stop cursor on an error?
        useCountdownTimer: true, // must call: TypingInput.timer.initialize( seconds ) before start
        updateStatsOnTimer: true, // update view stats every second?
        updateStatsOnKeystroke: false, // update view stats each keystroke?
        startIf: function () {
          return userMayStart;
        }, // start conditions
        onStart: function () {
          $scope.message = null;
        },
        eachSecond: function () {},
        onFinish: sessionFinished, // callback once contest is complete (line of text finished, or time expired)
      });

      // This line of code below destroys the timer if the user switches pages before finishing
      $scope.$on('$destroy', function () {
        $scope.TypingInput.timer.stop();
        removeReactKeyboard();
      });
      return $scope.TypingInput.updateLineOfTextFromUrl('exercise/lineoftext.json');
    }

    // Callback to TypingInput
    //   -> Fires when the session is complete
    function sessionFinished() {
      $scope.$broadcast('keyboard:hide-hands');
      userMayStart = false;
      $scope.TypingInput.save()
        .then(function () {
          $scope.$broadcast('TimedWritingModals:start', { modalSelector: '#results-modal-sequence', delayListener: 1200 }); // delayListener prevents user from accidently hitting space to move through modals
        })
        .catch(function (response) {
          // Server did not save the session
          const errorMessage =
            typeof response.data === 'object' && response.data.errors
              ? response.data.errors
              : 'Oh no, Typist could not save this activity! Please check your internet connection and try again.';

          $scope.TypingInput.errorMessage = errorMessage;
          $scope.$broadcast('TimedWritingModals:start', { modalSelector: '#error-modal-sequence' });
        });
      return true;
    }

    // keydown event handler. Required by TypingInput
    $scope.$on('keydown', function (a, e) {
      // prevent default behaviour of space key while modals are active
      keyboardUtilities.blockScrollingWithSpaceKey(e);

      // Create the event log
      if (userMayStart) {
        $scope.digitIndex = $scope.TypingInput.addEvent(e);
        // Add a timeout so that the css animation responsible for the hands fading in/out starts first, and then the key highlight happens,
        // otherwise on the fade out, the next highlight key is immediately set, before the fade out animation for previous hand position is finished
        // (make sure to sync this to the transition delay in InteractiveKeyboardKey.module.scss .fingerImageContainer class, currently both are set to 300ms)
        $timeout(() => {
          // check again whether use is done
          if (userMayStart)
            updateReactKeyboard({
              highlightKeys: [$scope.TypingInput.view.lineOfTextObjects[$scope.digitIndex].letter],
            });
        }, 300);
      } // Store the event
    });

    // enables user to start
    function allowStart() {
      userMayStart = true;
    }

    // Modal methods ------------------------------------------------------------------

    $scope.$on('TimedWritingModals:complete', modalSequenceComplete);

    // Reacts to modals being complete by either:
    //  -> Redirecting to a new timed writing (if results modal sequence finished)
    //  -> Initializing the lesson screen if user is allowed to start
    function modalSequenceComplete(a, data) {
      // if we were on the result modal, redirect and reinitialize
      if (data && data.modalSelector === '#results-modal-sequence') {
        $timeout(function () {
          $state.go('app.keyboarding.practice_timed_writing', {}, { reload: true });
        }, 500);
        return;
      }

      var duration = Number($scope.timedWritingModal.practice.duration);
      UserSettings.set('practice_timed_writing', $scope.timedWritingModal);
      $scope.TypingInput.timer.initialize(duration * 60);
      $scope.TypingInput.primarySpeedMetric = $scope.timedWritingModal.practice.type || 'netWPM';
      // line below is to line up with what's expected in the _score_board.html
      $scope.timedWriting = { permission: { options: { accuracy_metric: $scope.timedWritingModal.practice.accuracy_metric } } };
      // Make sure we're ready to go
      $q.all([sessionReady]).then(function () {
        $timeout(() => document.getElementById('line-of-text').focus(), 0);
        allowStart();
        $scope.$broadcast('keyboard:show-hands');

        $timeout(() => {
          // Check if no keys have been pressed yet, show hands on the homerow keys
          if ($scope.digitIndex === 0) {
            updateReactKeyboard({
              hideHands: false,
            });
          }
        }, 500).then(() => {
          // This loads in the very first letter of the text prompt that the user should type next
          $timeout(() => {
            // Check if no keys have been pressed yet, only then display the hint for the first letter to press
            if ($scope.digitIndex === 0) {
              updateReactKeyboard({
                highlightKeys: [$scope.TypingInput.view.lineOfTextObjects[0].letter],
              });
            }
          }, 6000);
        });
      });
    }
  },
]);
