/* global process */
import React from 'react';
import { createRoot } from 'react-dom/client';

/*
This factory is used for handling mounting/unmounting of React Components via Angular. 
ReactDOM.render() is used to render the react components. As long as the same html container is passed in, React will use its diffing mechanism on
the props passed in, which means that we need to pass in all the props (old and new ones) everytime we call render. This factory
acts as a saving state for props used by each react component mounted at a specific HTML DOM element.

A few notes on how to use this factory:
  - To use the factory, destructure the results of the init() method call to get the update() and remove() functions, which will act on the react component that was just initialized:
    const {update, remove} = ReactComponent.init('react-keyboard', <ReactComponent/>, {prop1: 1, prop2: 2})
  - If ReactComponent is being utilized by multiple controllers at same time and initial props are passed into init() function,
    make sure only one of them passes initial props. Alternatively, any further props passed in via update() method will be 
    persisted in subsequent update() calls
  - IMPORTANT: make sure to run the remove() function on every React component so that the unmount sequence runs, otherwise if Angular removes the root html that the React component is mounted to,
    the React component will no longer be accessible and its unmounting lifecycle will not be properly executed. Best done in a controllers `on('$destroy')` listener
  - the remove() function can be called on anlready removed react component, as no errors will be raised
*/

app.factory('ReactComponent', [
  function () {
    // Class Constructor
    function ReactComponent() {
      // all react components initialized are stored in this object, where the key is the html id of the react element
      // eg. {'react-keyboard': {component: React.element, props: {}}}, where
      // component is a react component
      // props is an object passed in to the react component
      this.components = {};
    }

    // Factory design pattern that creates a new instance of a react component given an `htmlId`
    // or retrieves an existing one, if a react component with same htmlId has already been initialized.
    // The goal is to basically have a common saved state of the props being used for a shared react component
    ReactComponent.prototype.init = function (htmlId, component, initialProps = {}) {
      if (!this.components[htmlId]) {
        this.components[htmlId] = {
          component,
          props: initialProps,
          // This will store the container that has been initialized with createRoot
          // in React 18, the container root should only be created once, hence why storing it in this class variable
          root: null,
        };
      }

      // NOTE: both update() and remove() have access to specific htmlId values passed into init() due to closure
      return {
        // props object passed in will be merged with the previously saved props. This way, just a subset of props
        // that are actually changing can be passed in
        update: (props = {}) => {
          // 1. Make sure to fetch the container every time, to make sure it still exists in the DOM
          const container = document.getElementById(htmlId);

          // 2. If no container, but the component is still cached in this factory, remove it
          if (!container && this.components[htmlId]) {
            // NOTE: be wary of the order of things from Angular perspective - if there is an ng-if on an element
            // around the div used for mounting react, this block might execute because the html container might not yet exist,
            // yet the components object already has an entry for this htmlId.
            delete this.components[htmlId];
            if (process.env.NODE_ENV === 'development') {
              console.warn(
                'Component was not unmounted properly or update method was called before html container was rendered in the DOM.'
              );
            }
          }
          // 3. Don't update anything if actual html container or an entry in this factory for this component is no longer present
          if (!container || !this.components[htmlId]) return;

          // 4. Save the state of the new props on the class instance
          this.components[htmlId].props = { ...this.components[htmlId].props, ...props };

          // 5. Render the actual react component into the DOM (subsequent calls to an already created React component will update it)
          if (!this.components[htmlId].root) {
            // In React 18, root can only be created once, afterwards re-used
            this.components[htmlId].root = createRoot(container); // createRoot(container!) if you use TypeScript
          }
          // React 18 uses root.render instead of ReactDOM.render
          this.components[htmlId].root.render(
            React.createElement(this.components[htmlId].component, this.components[htmlId].props)
          );
        },
        // The remove function will delete the stored react component in this.components and will unmount the actual react component in the DOM
        remove: () => {
          if (this.components[htmlId] && this.components[htmlId].root) {
            this.components[htmlId].root.unmount();
          }
          delete this.components[htmlId];
        },
      };
    };

    return new ReactComponent();
  },
]);
