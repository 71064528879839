app.controller('NewPasswordController', [
  '$scope',
  '$http',
  '$state',
  'Auth',
  '$timeout',
  '$q',
  'AnimatedLoginKeyboardFactory',
  function ($scope, $http, $state, Auth, $timeout, $q, AnimatedLoginKeyboardFactory) {
    $scope.stateIsNewPassword = true; // required to manage the modals in _welcome.html
    $scope.view = {
      recoveryEmailSent: false,
      validations: { password: null, password_confirmation: null },
      passwordResetInProgress: false,
      passwordResetIsSuccessful: false,
      passwordResetIsFailure: false,
      passwordResetProgressMessage: null,
      resetValidations: function () {
        this.validations.password = null;
        this.validations.password_confirmation = null;
      },
    };
    $scope.keyboard = new AnimatedLoginKeyboardFactory('#loading-keyboard-auth');

    // This method is called when user submits -----------------------
    $scope.resetPassword = function (user) {
      if (!validatePasswordAndPasswordRecovery(user)) return;
      $scope.view.passwordResetInProgress = true;

      var params = {
        password_reset: {
          password: user.password,
          password_confirmation: user.password_confirmation,
          reset_password_token: $state.params['reset_password_token'],
        },
      };

      // update the password
      return runLoadingAnimationForMinTime($http.post('/users/recovery/update_password', params), 3000)
        .then(showSuccessMessageRedirectToAfterLogin)
        .catch(showErrorMessageAndResetForm);
    };

    // private methods ------------------------------------------------------------------------------------
    // animates the loading keyboard, and returns when either the http promise is resolve or 3 seconds have passed
    //  returns the result of the http promise
    function runLoadingAnimationForMinTime(httpPromise, minTime) {
      // start the keyboard
      $scope.keyboard.showKeyboard = true;
      $scope.keyboard.stopKeyboard = false;
      $scope.keyboard.animate();

      var wait = function () {
        return $timeout(function () {}, minTime);
      };

      return wait()
        .then(function () {
          return httpPromise;
        })
        .then(function (response) {
          $scope.keyboard.stopKeyboard = true;
          $timeout(function () {
            $scope.keyboard.showKeyboard = false;
          }, 500);
          return response;
        });
    }

    function showSuccessMessageRedirectToAfterLogin() {
      // http password change was successful. show message, redirect to after-login
      // 1. "You have successfully changed your password"
      $scope.view.passwordResetIsSuccessful = true;
      return showSuccessMessage().then(showRedirectMessage).then(redirectUserToWelcomeState);
    }

    function showSuccessMessage() {
      $scope.view.passwordResetProgressMessage = 'You have successfully changed your password.';
      return $timeout(function () {}, 4000);
    }

    function showRedirectMessage() {
      $scope.view.passwordResetProgressMessage = 'You will now be redirected to login.';
      return $timeout(function () {}, 4000);
    }

    function redirectUserToWelcomeState() {
      $state.go('authentication.sign_in');
      return;
    }

    function showErrorMessageAndResetForm(error) {
      $scope.keyboard.stopKeyboard = true;
      $scope.view.passwordResetIsFailure = true;

      // If the token is the problem, redirect
      if (error.data.errors.indexOf('token is invalid') > -1) {
        $scope.keyboard.showKeyboard = false;
        $scope.view.validations.password =
          'This link is no longer valid. Enter your email on the next page and a new link will be emailed to you. Redirecting.';
        $timeout(function () {
          $state.go('authentication.password_recovery');
        }, 7000);
        return;
      }

      $scope.view.passwordResetInProgress = false;

      return $timeout(function () {
        $scope.keyboard.showKeyboard = false;
        $scope.view.passwordResetInProgress = false;
        $scope.view.passwordResetIsFailure = false;
      }, 2000);
    }

    // Updates $scope to include a validation message.
    //  Returns true if valid
    function validatePasswordAndPasswordRecovery(user) {
      $scope.view.resetValidations();

      if (user.password === undefined) {
        $scope.view.validations.password = 'Please enter your password.';
        return false;
      }
      if (user.password.length < 6) {
        $scope.view.validations.password = 'Please enter a password that is at least 6 characters.';
        return false;
      }
      if (user.password_confirmation === undefined) {
        $scope.view.validations.password_confirmation = 'Please confirm your password.';
        return false;
      }
      if (user.password !== user.password_confirmation) {
        $scope.view.validations.password_confirmation = 'Your password confirmation must match your password.';
        return false;
      }
      return true;
    }
  },
]);
