app.controller('AccountExpiredController', [
  '$scope',
  'AccessCodeFactory',
  'StripePaymentFactory',
  '$state',
  '$stateParams',
  'Auth2',
  'org',
  function ($scope, AccessCodeFactory, StripePaymentFactory, $state, $stateParams, Auth2, org) {
    $scope.user = $state.params.userCredentials;
    $scope.view = $scope.view || {};
    // allow any user to renew their account with stripe
    $scope.view.orgAllowsStripe = true;

    $scope.parentView.showSubheading = false; // this is inheirited from parent scope. displays: "The typing trainer designed..."

    validatePresenceOfUserCredentials($scope.user);

    $scope.accessCode = new AccessCodeFactory($scope.user);

    $scope.org = org;

    // Stripe Payment is bound to HTML. When a user submits the Stripe form,
    //  stipePayment intercepts the successful payment, adds the stripeTokens to the
    //  user data, and sends to our server to charge the card and update the account.
    // called either from the UI if we have multiple program_products at an organization,
    //  or if we just have 1 it's called from the controller.
    $scope.setProduct = (product) => {
      $scope.product = product;
      //validate that we have the proper pricing, country and state information for the organization
      if (
        !(typeof $scope.product === 'object') ||
        !(typeof $scope.product.price === 'number') ||
        !(typeof $scope.product.tax === 'object')
      ) {
        console.log('Missing pricing and tax information');
        return $state.go('app.home');
      }

      $scope.stripePayment = new StripePaymentFactory('#stripe-form', 'update', $scope.user, $scope.product);
      $scope.stripePayment.paymentSuccess.promise.then(redirectToHomepage); // binds the callback
      $scope.keyboard = $scope.stripePayment.keyboard;
    };

    // Set products
    $scope.products = org.products;
    if ($scope.products.length === 1) $scope.setProduct($scope.products[0].product);

    $scope.updateSubscription = function () {
      $scope.keyboard = $scope.accessCode.keyboard;

      $scope.accessCode
        .updateSubscription($scope.user)
        .then(redirectToHomepage)
        .catch(function (obj) {
          $scope.keyboard = $scope.stripePayment.keyboard;
          return $q.reject(obj);
        });
    };

    // needed if there's an error charging the card (i.e. flagged as high risk)
    $scope.reloadState = function () {
      // reload the state and let them try with a different card
      return $state.go($state.current.name, { userCredentials: $scope.user }, { reload: true });
    };

    function redirectToHomepage(user) {
      var credentials = { login: user.username, password: user.password, remember_me: 1 };
      return Auth2.login(credentials).then(function () {
        return $state.go('afterLogin');
      });
    }

    function validatePresenceOfUserCredentials(userCredentials) {
      if (!userCredentials || !userCredentials.username || !userCredentials.password) {
        console.log('Error. UserCredentials not supplied.');
        return $state.go('app.home');
      }
    }
  },
]);
