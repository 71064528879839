// Provides helper method to refresh the tooltips on a page
app.service('TooltipService', [
  '$timeout',
  function ($timeout) {
    // updates the tooltips... which caused me to lose 4 hours on a Sunday...
    //  here's the issue:
    //    when using title="{{ myTitle }}" in html, Bootstrap's tooltips uses a data-original-title
    //    attribute to actually store/display the tooltip. Unfortunately, this doesn't always get updated
    //    when the angular variable changes. So, we use timeouts to let the digest cycle run, and manually copy the
    //    title attributes to the data-bs-original-title attributes. Argh.
    this.updateTooltips = function () {
      $timeout(function () {
        $('[data-bs-toggle="tooltip"]').each(function () {
          // sometimes the title is blank for no apparent reason. don't override in these cases.
          if ($(this).attr('title')) {
            $(this).attr('data-bs-original-title', $(this).attr('title'));
          }
        });
        $timeout(function () {
          // finally, activate the tooltips
          // Code snippet from docs https://getbootstrap.com/docs/5.1/components/tooltips/#example-enable-tooltips-everywhere
          [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
            bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
          });
        }, 500);
      }, 1500);
    };

    return this;
  },
]);
