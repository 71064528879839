app.controller('MyModalsController', [
  '$scope',
  '$timeout',
  'ModalSequence',
  '$state',
  function ($scope, $timeout, ModalSequence, $state) {
    // var modalSequence = new ModalSequence("#startup");
    // modalSequence.start();

    $scope.start = function () {
      modalSequence.start();
    };
    $scope.next = function () {
      modalSequence.next();
    };
    $scope.exit = function (self) {
      // debugger;
      // ModalSequence.exit();
      modalSequence.exit();
    };
    $scope.previous = function () {
      modalSequence.previous();
    };
    $scope.reload = function () {
      $state.reload();
    };
    // On initialize
    // angular.element(document).ready(function () {
    //   // $timeout ensures that all items on a page have rendered before running. This eliminates a problem where
    //   //    the my-modal elements have not yet rendered when document is ready.
    //   $timeout(function() {
    //     $("#startup").find(".sequence").first().modal('show');
    //   });
    // });
  },
]);
