app.controller('ClassController', [
  '$scope',
  'Auth2',
  '$state',
  '$stateParams',
  '$http',
  '$timeout',
  'Classes',
  'templates',
  'DefaultSettings',
  'UserSettings',
  function ($scope, Auth2, $stateParams, $state, $http, $timeout, Classes, templates, DefaultSettings, UserSettings) {
    $scope.disableJoinClass = false;
    $scope.disableCreateClass = false;
    // Hide if there's a code (ie if we should be seeing a classlist)
    $scope.showClass = $stateParams.params.code === '';
    $scope.view = {};
    $scope.templates = templates;

    // Determines which example LMS registration message/video is shown to instructors
    //  SAIT: 12 (removed)
    //  Morrisville: 25
    //  Allegany: 14
    //  Durham: 22
    //  Capilano: 123
    //  Eastern Florida: 80
    $scope.org_purchase_in_bookstore_only = [22, 123, 80];
    $scope.org_purchase_online_or_in_bookstore = [22, 23, 13, 25, 14]; // Durham, Yukon, Spartanburg, Morrisville

    // Load user and user's class sections
    Auth2.currentUser().then(function (user) {
      $scope.user = user;
      if ($scope.user.sections.length === 0) {
        if ($scope.user.instructor) {
          // Show tips for a new semester if the user doesn't have any classes
          if (user.sections.length == 0) {
            $scope.view.showTipsForNewSemester = true;
          }

          $scope.successErrorMessage = {
            success: null,
            message:
              'Welcome to Typist! To get started, create a class on the right side of the page. This will create a code that you can share with your students!',
          };
        } else {
          $scope.successErrorMessage = {
            success: null,
            message:
              "We see that you have not yet joined a class. You may join a class once you receive a class code from your instructor (Hint: It will look like 'CC42353'). In the mean time, click 'Home' to get started learning!",
          };
        }
      }
    });

    // student methods ------------------------------------------------------------------
    $scope.joinClass = function (section) {
      // disable join button
      $scope.disableJoinClass = true;

      // Validate the section code
      Classes.join(section)
        .then(function (newSection) {
          section.code = ''; // reset the view
          $scope.disableJoinClass = false;
          Auth2.refreshSections().then(function (user) {
            $scope.user = user;
          }); // update the user
          $scope.successErrorMessage = {
            success: true,
            message: 'You have joined ' + newSection.name + "! Click 'Home' to start your training!",
          };
        })
        .catch(function (error) {
          $scope.successErrorMessage = { success: false, message: error.errors };
          $timeout(function () {
            $scope.successErrorMessage = null;
          }, 5000);
          $scope.disableJoinClass = false;
        });
    };

    $scope.unjoinClass = function (section_code) {
      Classes.unjoin({ section: { code: section_code } })
        .then(function (section) {
          // Update the user
          Auth2.refreshSections().then(function (user) {
            $scope.user = user;
          });
          // Set the success message
          $scope.successErrorMessage = {
            success: true,
            message: 'You have left ' + section.name + '. You may re-join again later using the class code from your instructor.',
          };
        })
        .catch(function (error) {
          $scope.successErrorMessage = { success: false, message: error.errors };
          $timeout(function () {
            $scope.successErrorMessage = null;
          }, 5000);
        });
    };

    // instructor methods ---------------------------------------------------------------
    $scope.createClass = function (section) {
      if ($scope.templates.length > 0) {
        section.templateRequired = true;
      } // this property is used in the Classes service
      if (section.course_template_id) {
        const selectedTemplate = $scope.templates.find((t) => section.course_template_id == String(t.id));
        section.ehr = selectedTemplate.ehr;
      }
      $scope.disableCreateClass = true;
      Classes.create(section)
        .then(function (newSection) {
          section.name = '';
          section.course_template_id = null; // clear the form
          $scope.disableCreateClass = false;
          $scope.view.showTipsForNewSemester = false; // automatically hide our tips
          setClasslistViewSettingsFromTestRequirements(newSection, $scope.templates);
          $scope.successErrorMessage = {
            success: true,
            message: 'You have created a new section. Below you will see your new section and section code to give to students!',
          };
          Auth2.refreshSections().then(function (user) {
            $scope.user = user;
          });
        })
        .catch(function (error) {
          $scope.disableCreateClass = false;
          $scope.successErrorMessage = { success: false, message: error.errors };
          $timeout(function () {
            $scope.successErrorMessage = null;
          }, 5000);
        });
    };

    var destroyRequestPending = false;
    $scope.destroySection = function (section, toBeDestroyed) {
      if (destroyRequestPending) {
        return;
      }
      // only show hidden sections if we're unhiding
      var includeDeletedSections = !toBeDestroyed || $scope.view.showHiddenSections;
      destroyRequestPending = true;

      Classes.toggleDestroy(section, includeDeletedSections)
        .then(function (user) {
          $scope.user = user;
          destroyRequestPending = false;
          var message = toBeDestroyed
            ? 'You have hidden your class, ' +
              section.name +
              ". You can view this class again by clicking the 'Hide Hidden Classes' button below and selecting 'Unhide'."
            : 'You have unhidden your class, ' +
              section.name +
              '. You may need to scroll to the bottom of this page to see it, as your classes are sorted by the date they were created.';
          $scope.successErrorMessage = { success: true, message: message };
          // Auth2.refreshSections(includeDeletedSections).then(function(user) {  });
        })
        .catch(function () {
          destroyRequestPending = false;
          $scope.successErrorMessage = { success: false, message: 'Something went wrong. Please contact Typist Support.' };
          $timeout(function () {
            $scope.successErrorMessage = null;
          }, 5000);
        });
    };

    // toggles the view of hidden sections
    var refreshingSections = false;
    $scope.showHiddenSections = function () {
      if (refreshingSections) {
        return;
      }
      if ($scope.view.showHiddenSections) {
        $scope.view.showHiddenSections = !$scope.view.showHiddenSections;
        return;
      }
      $scope.view.showTipsForNewSemester = false;
      refreshingSections = true;

      Auth2.refreshSections(true)
        .then(function (user) {
          $scope.user = user;
        })
        .then(function () {
          refreshingSections = false;
          $scope.view.showHiddenSections = !$scope.view.showHiddenSections;
        });
    };

    // successErrorMessage popup methods --------------------------------------------------
    $scope.hideSuccessErrorMessages = function () {
      $scope.successErrorMessage = null;
    };

    // If there is a course_template and there are test_requirements then
    //   set the test_requirements as the default view in the class.
    function setClasslistViewSettingsFromTestRequirements(section, templates) {
      if (templates.length === 0 && section.course_template_id !== null) return;
      var template = templates.filter(function (t) {
        return t.id === section.course_template_id;
      })[0];
      if (typeof template === 'undefined') return; // If they select 'My Template is not listed'
      var testRequirements = template.test_requirements;
      if ($.isEmptyObject(testRequirements)) return;

      var defaults = DefaultSettings.testSettings();
      var defaultSettings = {
        duration: String(testRequirements.duration_in_seconds / 60),
        speed: Number(testRequirements.speed),
        type: testRequirements.type,
        accuracy_metric: testRequirements.accuracy_metric,
        accuracy: Number(testRequirements.accuracy) || defaults.accuracy,
        max_errors: String(testRequirements.max_errors) || defaults.max_errors,
        max_attempts: String(testRequirements.max_attempts),
        expiresAfterMinutes: defaults.expiresAfterMinutes,
      };

      return UserSettings.set('class/' + section.code + '/test', defaultSettings);
      // DefaultSettings;
      // UserSettings;
      // debugger;
    }
  },
]);
