/* global SERVER_TIME_SYSTEM_TIME_DIFF */
import { getDatabase, ref, onValue, off, child, get } from 'firebase/database';

// Manages contestModals
//  -> broadcasts "contestModals:complete" on $rootScope when ready to start
//  -> prevents the user from starting if they arrive too late
app.controller('CompetitionModalController', [
  '$scope',
  '$rootScope',
  'ModalSequence',
  'ModalSequenceArray',
  '$state',
  '$stateParams',
  '$timeout',
  '$http',
  function ($scope, $rootScope, ModalSequence, ModalSequenceArray, $state, $stateParams, $timeout, $http) {
    // debugMode();

    // Instantiate our sequences. First ModalSequences will be auto-started below
    var welcomeModalSequence = new ModalSequence('#competition-welcome-modal-sequence', { modalNames: ['welcomeModal'] });
    var resultsModalSequence = new ModalSequence('#results-modal-sequence', { modalNames: ['resultsModal'] });
    var tooLateModalSequence = new ModalSequence('#too-late-modal-sequence', { modalNames: ['tooLateModal'] });
    const db = getDatabase();
    var contestRef = ref(db, `games/contests/${$stateParams.contest_id}`);

    // start the first modalSequence
    // eslint-disable-next-line no-unused-vars
    var modalSequences = new ModalSequenceArray([welcomeModalSequence, resultsModalSequence, tooLateModalSequence]);

    // NOTE: make sure to de-register the rootScope listener when this controller is destroyed,
    // otherwise the event listeners will accumulate and multiple resultsModalSequence instances will start
    const deregisterShowResultsListener = $rootScope.$on('contestModals:showResults', function () {
      resultsModalSequence.start();
    });

    // debug mode
    // function debugMode() {
    //   $timeout(function () {
    //     modalSequences.exit();
    //     $rootScope.$broadcast('contestModals:complete');
    //   }, 2000);
    // }

    // wait for contest to start
    onValue(child(contestRef, 'started'), (snapshot) => {
      // if the contest hasn't started, show the welcome modal sequence
      if (!snapshot.val()) {
        welcomeModalSequence.start();
        return;
      }

      var currentTimeMS = Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF;
      // Note: May have issues if computer clocks are out of sync
      // Note: Only students who joined before the instructor clicked the button can start
      if (snapshot.val() && currentTimeMS - snapshot.val() < 8000 && welcomeModalSequence.isActive) {
        // If it just started, start the launch sequence
        $timeout(function () {
          startCountdown();
        }, 500);
      } else if (snapshot.val()) {
        // contest started awhile ago. Either this person is late, or they have completed:
        lateOrComplete();
      }
      // other wise keep waiting for instructor to start
    });

    $scope.goBack = function (state) {
      if (state) {
        $state.go(state);
      } else {
        $state.go('app.home');
      }
    };

    function lateOrComplete() {
      // check if the user has actually finished the contest
      get(child(contestRef, `participants/${$rootScope.user.id}`)).then(function (snapshot) {
        if (snapshot.val() && snapshot.val().finished) {
          // show results
          assignPreviousResultsToScope(snapshot.val());
        } else {
          // too late
          $timeout(function () {
            tooLateModalSequence.start();
          }, 200);
        }
      });
    }

    $scope.$on('$destroy', () => {
      // Make sure the results modal is closed
      modalSequences.getActiveModalSequence().exit();
      // De-registering the $rootScope listener for contestModals:showResults
      deregisterShowResultsListener();
      off(child(contestRef, 'started'));
    });

    // retrieves the results from postgres, using the firebase_id
    function assignPreviousResultsToScope() {
      $http.get('contest/' + $stateParams.contest_id + '.json').then(function (response) {
        $scope.TypingInput = { stats: response.data.data };
        resultsModalSequence.start();
      });
    }

    // Start countdown, broadcasts "contestModals:complete" when finished
    function startCountdown() {
      $timeout(function () {
        $scope.smallMessage = 'The contest is about to begin!';
      }, 0);
      $timeout(function () {
        $scope.message = null;
        $scope.smallMessage = 'The timer will start once you begin typing.';
      }, 1500);
      $timeout(function () {
        $scope.smallMessage = null;
        $scope.message = 'Contest opens in 10!';
      }, 6000);
      var interval = 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('10!', '9!');
      }, 6000 + interval);
      interval += 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('9!', '8!');
      }, 6000 + interval);
      interval += 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('8!', '7!');
      }, 6000 + interval);
      interval += 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('7!', '6!');
      }, 6000 + interval);
      interval += 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('6!', '5!');
      }, 6000 + interval);
      interval += 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('5!', '4!');
      }, 6000 + interval);
      interval += 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('4!', '3!');
      }, 6000 + interval);
      interval += 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('3!', '2!');
      }, 6000 + interval);
      interval += 1000;
      $timeout(function () {
        $scope.message = $scope.message.replace('2!', '1!');
      }, 6000 + interval);
      interval += 1000;
      // interval is now = 10000
      $timeout(function () {
        $scope.message = "Begin typing when you're ready!";
        $timeout(function () {
          welcomeModalSequence.exit();
          $rootScope.$broadcast('contestModals:complete');
        }, 500);
      }, 6000 + interval);
      return;
    }
  },
]);
