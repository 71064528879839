// Creates the animations for signin and register in the Welcome Controller
//  -> Returns a variable HomeRowTech that will resolve the state attribute
//      when the process completes. Access this through onComplete attribute.
//
//  Ex.
//  $scope.homeRowTech = new HomeRowTech();
//  $scope.homeRowTech.onComplete.then(function() { } );
//
//  Also, requires a keydown event listener:
//  var deregister = $scope.$on("keydown", function(a, e) {
//    var correctKey = $scope.homeRowTech.keydown(e);
//    if (!correctKey) reset();
//  });
//
// Predefined sequences within local variable 'sequences' within keysToVerify!

import homeRowTechHandsImage from '~Images/finger_animations_svg/HOMEROW-TECH.svg';

app.factory('HomeRowTech', [
  '$q',
  function ($q) {
    var HomeRowTech = function (options) {
      // Use a predefined sequence (see below)?
      //  leave null for randomized sequence
      this.sequence = options.sequence || null;

      // Store the file path to the Homerow tech hands svg so that they can be dynamically rendered in the view
      this.homeRowTechHandsImage = homeRowTechHandsImage;
      this.skinTone = options.skinTone || 'mediumLightSkinTone';

      // How many fingers should we verify before process completes?
      this.numberOfFingersToVerify = options.numberOfFingersToVerify || 8;

      // keysArray is an array containing each of the 8 home row keys
      //  an array is used to simplify all the functional programming used in here (filters, maps, etc.)
      //  status codes keep track (below) keep track of state

      // status codes
      //  0: Not yet prompted (should be blank)
      //  1: Press this finger
      //  2: Finger completed successfully (gray)
      this.keysArray = keysToVerify(this.sequence);

      // the currently indicated finger. used internally.
      this.currentKey = this.keysArray.nextKey();

      // is resolved once the process completes. attach promises to onComplete (below)
      this.state = $q.defer();

      // becomes resolved when process is complete. bind an event listener as follows:
      //  $scope.homeRowTech = new HomeRowTech();
      //  $scope.homeRowTech.onComplete.then(function() { } );
      this.onComplete = this.state.promise;

      // added into a div with attribute aria-live to read the instructions to the
      // user who may be using VoiceOver
      this.voiceoverInstruction = `To begin, press the key under your ${this.currentKey.prompt}.`;
    };

    // public methods ---------------------------------------------------------------------------------------

    // verifies keypress and takes appropriate action. should be attached to a keydown event in a controller.
    HomeRowTech.prototype.keydown = function (e) {
      // Correct key pressed
      if (e.key_pressed === this.currentKey.key) {
        this.currentKey.status = 2; // update the status of the current key (2 is complete)
        if (checkIfProcessComplete(this)) {
          this.voiceoverInstruction = `Success.`;
          return true; // is the procedure complete?
        }
        this.currentKey = this.keysArray.nextKey(); // updates indicated key
        this.voiceoverInstruction = `Correct. Press the key under your ${this.currentKey.prompt}`;
        return true;
      } else {
        //incorrect key
        return false;
      }
    };

    // reset the procedure to initial settings
    HomeRowTech.prototype.reset = function () {
      this.voiceoverInstruction = '';
      this.keysArray.forEach(function (el) {
        el.status = 0;
      });
      this.currentKey = this.keysArray.nextKey();
      this.voiceoverInstruction = `Error. Starting over. Press the key under your ${this.currentKey.prompt}.`;
    };

    // private methods -------------------------------------------------------------------------------------

    // Checks if the process is complete. If it is, it resolves the state promise.
    function checkIfProcessComplete(self) {
      var filteredArray = self.keysArray.filter(function (el) {
        return el.status === 0;
      });
      var numCorrect = self.keysArray.filter(function (el) {
        return el.status === 2;
      }).length;

      // If all the fingers are complete, or the number of fingers to verify criteria has been met
      if (filteredArray.length === 0 || numCorrect >= self.numberOfFingersToVerify) {
        // resolve the state!
        self.state.resolve();
        return true;
      } else {
        return false;
      }
    }

    // this array keeps track of state with status codes below. it's used to update the view.

    // status codes
    //  0: Not yet prompted (should be blank)
    //  1: Press this finger
    //  2: Finger completed successfully (gray)
    function keysToVerify(sequenceName) {
      // create overrides if we're going to use a pre-defined sequence
      if (typeof sequenceName === 'string') {
        var useSequence = true;
      }

      var keys = [
        { key: 'a', prompt: 'Left pinky finger', tag_id: '#left-pinky-highlight', status: 0 },
        { key: 's', prompt: 'Left ring finger', tag_id: '#left-ring-highlight', status: 0 },
        { key: 'd', prompt: 'Left middle finger', tag_id: '#left-middle-highlight', status: 0 },
        { key: 'f', prompt: 'Left pointer finger', tag_id: '#left-pointer-highlight', status: 0 },
        { key: 'j', prompt: 'Right pointer finger', tag_id: '#right-pointer-highlight', status: 0 },
        { key: 'k', prompt: 'Right middle finger', tag_id: '#right-middle-highlight', status: 0 },
        { key: 'l', prompt: 'Right ring finger', tag_id: '#right-ring-highlight', status: 0 },
        { key: ';', prompt: 'Right pinky finger', tag_id: '#right-pinky-highlight', status: 0 },
      ];

      // are the defined sequences that can be used
      //  examples use the array indices of the above array
      var sequences = {
        leftToRight: [0, 1, 2, 3, 4, 5, 6, 7],
        rightToLeft: [7, 6, 5, 4, 3, 2, 1, 0],
        skipOneLeftToRight: [0, 2, 4, 6, 7, 5, 3, 1],
        skipOneRightToLeft: [7, 5, 3, 1, 0, 2, 4, 6],
      };

      // returns the next to be indicated, and marks its status to '1'
      keys.nextKey = function () {
        var incompleteKeys = keys.filter(function (el) {
          return el.status === 0;
        });
        var completedKeys = keys.filter(function (el) {
          return el.status === 2;
        });

        // short-circuit if we're using a predefined sequence
        var key;
        if (useSequence) {
          key = keys[sequences[sequenceName][completedKeys.length]];
          key.status = 1; // set the status
          return key;
        }

        // pick a random finger, unless criteria below overrides
        var randIndex = Math.floor(Math.random() * incompleteKeys.length);
        key = incompleteKeys[randIndex];

        // if there's exactly one finger completed, pick the next one from the other hand
        if (completedKeys.length === 1) {
          // if left hand
          if (['a', 's', 'd', 'f'].indexOf(completedKeys[0].key) > -1) {
            randIndex = Math.floor(Math.random() * 4 + 4); // pick from the right hand
          } else {
            randIndex = Math.floor(Math.random() * 4); // pick from the left hand
          }
          key = keys[randIndex];
        }

        key.status = 1; // set the status
        return key;
      };

      return keys;
    }

    return HomeRowTech;
  },
]);
