// Animates the keyboard
app.controller('KeyboardTestController', [
  '$scope',
  '$stateParams',
  'TypingInput',
  'accountSettings',
  function ($scope, $stateParams, TypingInput, accountSettings) {
    $scope.accountSettings = accountSettings;

    // sets of text to try
    $scope.text = [
      { name: 'Bad Ascii Symbols', text: '\'‘’ "“” —–-' },
      { name: 'Symbols - Right', text: '-=[]\\;\',./ _+{}|:"<>?' },
      { name: 'Symbols - Num Row', text: '`~!@#$%^&*()' },
      { name: 'Letters', text: 'qwertyuiopasdfghjklzxcvbnm QWERTYUIOPASDFGHJKLZXCVBNM' },
      { name: 'OpenDyslexic Problems 1', text: '""" ;"; !"! ?"?' },
      { name: 'OpenDyslexic Problems 2', text: "' \" ''' \"\"" },
      { name: 'Width Test 1', text: 'm '.repeat(100) }, // currently, the line will split at 52 chars. Creating a longer line of chars to see word wrap and line split on screen
    ];

    $scope.TypingInput = TypingInput.newSession({});
    $scope.TypingInput.updateLineOfTextFromString($scope.text[$stateParams.text].text);

    // keydown event handler
    $scope.$on('keydown', function (a, e) {
      // Create the event log
      $scope.TypingInput.addEvent(e); // Store the event
    });
  },
]);
