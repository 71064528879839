/* global app, angular */
app.factory('RandomText', [
  function () {
    // Class Constructor - this class is an instance of a 'text' type Document Variable
    // all the properties passed in 'props' get copied over to the instance.
    function RandomText(props) {
      this.view = {
        flashMessage: null, // eg. null or {message: '', error: false}
      };

      this.name = null;
      this.type = 'text';

      // ng-model for the form. Used to specify the randomization parameters. Parameters are initialized to empty strings
      // because that is the format the forms will use to define them anyways (though null/undefined also works as all are falsy)
      this.params = {
        textOptionsMultiline: '', // multiline textOptions, ng-model for the textarea in the view. User input only, not stored in this form in the backend.
        // clone of textOptionsMultiline converted to an array of strings, where every the original string is split at every line with whitespace trimmed at either end
        // This is the variable that is stored in the backend
        textOptions: [],
      };

      // Used to keep track of previous param values
      // Used in the onInputChange() function
      this._previousParams = this.params;

      // instantiate variable with existing properties if props are passed in
      this.assignAllPropertiesToSelf(props);

      this.params['textOptions'] = multilineTextToStringsArray(this.params['textOptionsMultiline']);
    }

    // Validate function that can be run before generate or on save.
    // Will check for specific combinations of params and throw errors if there are inconsistencies
    // returns true if validations pass
    RandomText.prototype.validate = function () {
      // Note: angularjs by default trims the output, so whitespace at beginning or end of textarea string will be gone by this point
      // However, we still have to check for empty lines and lines filled with whitespace characters
      let re = new RegExp('^\\s*$', 'gm'); // check for 0 or more consecutive whitespace characters between beginning and end of a line
      if (re.test(this.params.textOptionsMultiline)) {
        throw new Error(`"${this.name}" variable: can't have blank lines of text or lines filled with tabs and/or spaces.`);
      }
      return true;
    };

    // This function will generate a randomized instance of this text, using the randomization parameters.
    // return value is a string
    // With all values stringified (including null -> '', false -> 'false', 1 -> '1', etc)
    RandomText.prototype.generate = function () {
      this.view.flashMessage = null;
      // 1. Extract variables
      let textArray = multilineTextToStringsArray(this.params['textOptionsMultiline']);

      // 2. Generate a random text
      const randomText = textArray[Math.floor(textArray.length * Math.random())];

      // 4. Return the random line of text
      return randomText;
    };

    // Generate a flattened js object representing this variable
    RandomText.prototype.toObj = function () {
      const paramsCopy = angular.merge({}, this.params);
      // Split the textarea string at every line to form an array of strings and trim each string to get rid of accidental extra spaces in beginning or end
      paramsCopy['textOptions'] = multilineTextToStringsArray(this.params['textOptionsMultiline']);
      return {
        name: this.name,
        type: this.type,
        params: paramsCopy,
      };
    };

    // This function will check validty of the form and make changes to the form
    // on every input field change
    RandomText.prototype.onInputChange = function () {
      // Re-generate the json representation of textOptionsMultiline when the contents of the textbox changes
      if (this._previousParams.textOptionsMultiline !== this.params.textOptionsMultiline) {
        this.params['textOptions'] = multilineTextToStringsArray(this.params['textOptionsMultiline']);
      }

      // Need a deep merge of this.params because there are nested object structures
      this._previousParams = angular.merge({}, this.params);
    };

    // this function will copy over every key-value pair in props and
    // assign it to self
    RandomText.prototype.assignAllPropertiesToSelf = function (props) {
      var self = this;
      for (var prop in props) {
        if (Object.prototype.hasOwnProperty.call(props, prop)) {
          self[prop] = props[prop];
        }
      }
      // Convert an array of strings to a single multiline string for the textarea input
      if (self['params']['textOptions']) {
        // generate a single string of all the lines of text, so it can be displayed in the textarea input
        self['params']['textOptionsMultiline'] = self['params']['textOptions'].join('\n');
      }
    };

    // Takes a multiline string (eg. from the textarea input form), splits it at every line, and returns
    // an array of strings. Each line is also whitespace trimmed at each end
    function multilineTextToStringsArray(multilineText) {
      if (!multilineText) return [];
      return multilineText.split('\n').map((line) => line.trim());
    }

    return RandomText;
  },
]);
