app.controller('CommissionDashboardController', [
  '$scope',
  '$http',
  function ($scope, $http) {
    // gets all the data
    $http.get('admin/commission').then(function (response) {
      $scope.data = response.data;
      console.log($scope.data);
    });
  },
]);
