app.directive('docProductionActivityTimer', [
  '$interval',
  '$filter',
  function ($interval, $filter) {
    function link(scope, element, attrs) {
      var timer;
      scope.overtime = false;
      scope.timerActive = false;
      if (scope.expiryTime) {
        timer = startTimer(scope.expiryTime, scope);
      }

      scope.$watch('expiryTime', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          destroyTimer(timer);
          timer = startTimer(newValue, scope);
        } else {
        }
      });

      element.on('$destroy', function () {
        destroyTimer(timer);
      });
    }

    function startTimer(expiryTime, scope) {
      var currentTime = new Date() - SERVER_TIME_SYSTEM_TIME_DIFF;
      scope.overtime = false;
      scope.timerActive = true;
      var timer = $interval(function () {
        currentTime = new Date() - SERVER_TIME_SYSTEM_TIME_DIFF;
        if (expiryTime >= currentTime) {
          scope.timeLeft = $filter('date')(expiryTime, 'MMM d, y h:mm a');
        } else {
          if (expiryTime < currentTime) {
            scope.overtime = true;
            scope.onTimerExpire();
          }
          $interval.cancel(timer);
        }
      }, 500);
      return timer;
    }

    function destroyTimer(timer) {
      $interval.cancel(timer);
    }

    return {
      restrict: 'A',
      link: link,
      // scope: true,
      scope: {
        expiryTime: '=expiryTime',
        onTimerExpire: '&onTimerExpire',
        requestToReload: '=requestToReload',
      },
      templateUrl: 'document_production/components/_timer_partial.html',
    };
  },
]);
