app.factory('InstrOrgRegFactory', [
  '$http',
  '$q',
  function ($http, $q) {
    // contains helper methods for the registration process
    var InstrOrgRegFactory = function () {
      var self = this;

      this.localValidations = function (user) {
        var valid = true;
        var validations = {};

        if (user === undefined) {
          validations.instructor = 'Please make a selection.';
          return $q.reject(validations);
        }
        if (user.instructor === undefined) {
          validations.instructor = 'Please make a selection.';
          return $q.reject(validations);
        }
        if (user.organization === undefined) {
          validations.organization = 'Please make a selection.';
          return $q.reject(validations);
        }
        if (!user.instructor) {
          // commas in student number will mess up CSV creation
          if (user.studentNumber && user.studentNumber.includes(',')) {
            validations.student_number = 'Your student number cannot contain a comma.';
            return $q.reject(validations);
          }
        }
        // If the user is an instructor, ensure they have sort of code
        if (user.instructor) {
          if (user.instructor_key === undefined) {
            validations.instructor_key = 'Please enter the instructor key.';
            return $q.reject(validations);
          }
          if (user.instructor_key !== 'Keyboard19!') {
            validations.instructor_key =
              'Instructor key is not valid. Please ask the lead instructor at your college to reach out to Typist for the new key. We update these each semester.';
            return $q.reject(validations);
          }
        }
        return $q.resolve();
      };

      // retrieves a list of organizations
      this.getOrganizations = function () {
        return $http.get('users/list_organizations').then(function (response) {
          return response.data;
        });
      };
    }; // end of class

    return InstrOrgRegFactory;
  },
]);
