/* global SERVER_TIME_SYSTEM_TIME_DIFF */
import { getDatabase, ref, update, serverTimestamp } from 'firebase/database';
import InteractiveKeyboard from '../../../../components/InteractiveKeyboard/InteractiveKeyboard';

app.controller('TimedWritingController', [
  '$scope',
  'TypingInput',
  '$http',
  '$stateParams',
  'accountSettings',
  '$q',
  'currentUser',
  'ReactComponent',
  '$timeout',
  'Auth2',
  'keyboardUtilities',
  function (
    $scope,
    TypingInput,
    $http,
    $stateParams,
    accountSettings,
    $q,
    currentUser,
    ReactComponent,
    $timeout,
    Auth2,
    keyboardUtilities
  ) {
    // adding currentUser so that the modal controllers can access it through
    // $scope.$parent.currentUser
    $scope.currentUser = currentUser;
    // Track the digitIndex variable from TypingInput service. Used for displaying the next-to-type letter as a hint
    // for the student if no keypress is detected
    $scope.digitIndex = 0;

    $scope.accountSettings = accountSettings;

    $scope.$broadcast('keyboard:hide-hands');

    // Get/create instance of a ReactComponent with id 'react-keyboard'.
    // the updateReactKeyboard and removeReactKeyboard functions act specifically on the react component with id 'react-keyboard'
    const { update: updateReactKeyboard, remove: removeReactKeyboard } = ReactComponent.init(
      'react-keyboard',
      InteractiveKeyboard,
      {
        enableHover: false,
        animateOnKeyPress: false,
        version: 2,
        highlightKeys: [],
        hideHands: true,
        skinTone: currentUser.user_preference.skin_tone,
        skinToneUpdateCallback: (skinTone) => {
          // first, we update the currentUser in this controller
          currentUser.user_preference.skin_tone = skinTone;
          // next, we have to update the currentUser stored in Auth2 so that all other
          // angularJS controllers have access to the updated user object
          Auth2.updateUserPreference({ skin_tone: skinTone });
        },
      }
    );

    // The init function only sets up the ReactComponent factory, but does not render the react component on screen,
    // so an update function must be called (it will render the initial props passed into the init function)
    updateReactKeyboard();

    // Local variables ---------------------------------------------------------------
    var userMayStart = false;
    const db = getDatabase();
    var sessionReady;
    // var resultsModalSequence = new ModalSequence("#results-modal-sequence");
    // var welcomeModalSequence = new ModalSequence("#welcome-modal-sequence");
    // var noPermissionModalSequence = new ModalSequence("#no-permission");
    // var errorModalSequence = new ModalSequence("#error-modal-sequence");
    $scope.timedWriting = {};

    // Get the permission object associated with this notification. This permission object contains
    //  metadata from all attempts (handled by next function)
    //  -> when startTimedWriting is called, it will verify that this promise is complete
    getAndCheckPermission($stateParams.notification_id)
      .then(assignPreviousResultsToView)
      .then(checkIfUserMayStartANewAttempt)
      .then(initializeNewAttempt);

    // listens for callback from TimedWritingModalController and activates the timed writing
    //  -> only starts a timed writing after the welcome-modal-sequence, which may include homeRowTech
    $scope.$on('TimedWritingModals:complete', function (a, data) {
      if (data.modalSelector === '#welcome-modal-sequence') {
        // check if the student might be cheating. see PermissionController for more details on how this works.
        //  also shuts down the notifications so that they stop flashing since student has now accessed their test
        if (isStudentCheating()) {
          // isStudentCheating will launch the error modal with the message received from the server
          console.log('Test invalidated.');
          return;
        } else if (isPermissionExpired()) {
          console.log('Permission Expired.');
          return;
        } else {
          // let them start!
          startTimedWriting();
        }
      }
    });

    // Retrieves the Permission associated with the notification_id.
    //  -> No Permission? launch noPermissionModalSequence
    //  -> Permission?    set $scope.timedWriting.permission.
    //                    launch welcomeModalSequence.
    function getAndCheckPermission(notification_id) {
      return $http.get(`get_permission_from_notification_id/${notification_id}.json`).then(function (response) {
        if (!response.data) {
          $scope.$broadcast('TimedWritingModals:start', {
            modalSelector: '#no-permission',
            delayListener: 0,
          }); // delayListener prevents user from accidently hitting space to move through modals
          return $q.reject('You do not have permission to access this test.');
        } else {
          $scope.timedWriting.permission = response.data;
          $scope.$broadcast('TimedWritingModals:start', {
            modalSelector: '#welcome-modal-sequence',
            delayListener: 0,
          }); // delayListener prevents user from accidently hitting space to move through modals
          return response;
        }
      });
    }

    function assignPreviousResultsToView() {
      // count the number of attempts that were started but not completed
      // these are added by the PermissionController as stubbed activites
      countIncompleteAttempts();

      // create an array of ints for ng-repeat to loop over (required by view)
      $scope.timedWriting.arrayOfInts = [];
      for (var i = 1; i <= Number($scope.timedWriting.permission.options.max_attempts); i++)
        $scope.timedWriting.arrayOfInts.push(i);
      // If there are no activities defined, create the property so the view doesn't crash
      $scope.timedWriting.permission.activities = $scope.timedWriting.permission.activities || [];
      return;
    }

    function checkIfUserMayStartANewAttempt() {
      // Is this permission expired?
      const now = Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF;
      var expiresOn = $scope.timedWriting.permission.expires_on;
      var isExpired = new Date(expiresOn).getTime() < now;

      // Is the user out of attempts?
      var previousAttempts = $scope.timedWriting.permission.activities;
      var isOutOfAttempts = previousAttempts.length >= $scope.timedWriting.permission.options.max_attempts;

      // Has the user tried to do multiple attempts from multiple sessions?
      //  -> i.e. the user may be trying to cheat?
      var test_started_from_multiple_sessions = $scope.timedWriting.permission.attempts_from_different_sessions;

      if (isExpired || isOutOfAttempts || test_started_from_multiple_sessions) {
        // 1. Show the results of the test
        $scope.timedWriting.complete = true;
        return $q.reject(); // stop promise chain
      }
      // TODO: Set timer to expire
      return $q.resolve();
    }

    function initializeNewAttempt() {
      var duration = Number($scope.timedWriting.permission.options.duration_in_seconds);
      sessionReady = newSession(duration); // assigns $scope.TypingInput
      $scope.TypingInput.timer.initialize(duration);
    }

    // Global variables ---------------------------------------------------------------
    // refactor the fuck out of this. Extract it. Fuck.
    function newSession(duration_in_s) {
      $scope.TypingInput = TypingInput.newSession({
        activity_type: 'TimedWriting', // Used with save, autosave
        primarySpeedMetric: $scope.timedWriting.permission.options.type, // grossWPM || netWPM, affects view
        enableBackspace: true, // enable the backspace button?
        blockOnError: false, // stop cursor on an error?
        useCountdownTimer: true, // must call: TypingInput.timer.initialize( seconds ) before start
        updateStatsOnTimer: true, // update view stats every second?
        updateStatsOnKeystroke: false, // update view stats each keystroke?
        startIf: function () {
          return userMayStart;
        }, // start conditions
        onStart: function () {
          // run on start
        },
        eachSecond: function () {
          // if timer is enabled, this is run each second until expiry
          // if (TypingInput.timer.countdown%3 === 0) {
          // firebase.userStats().update({netWPM: TypingInput.stats.netWPM || 0, accuracy: TypingInput.stats.accuracy || 0});
          // }
        },
        onFinish: sessionFinished, // callback once contest is complete (line of text finished, or time expired)
      });
      // This line of code below destroys the timer if the user switches pages before finishing
      $scope.$on('$destroy', function () {
        $scope.TypingInput.timer.stop();
        removeReactKeyboard();
      });
      return $scope.TypingInput.updateLineOfTextFromUrl('exercise/lineoftext.json', { duration_in_s });
    }

    // Enables the user to start.
    //   -> userMayStart must be bound to TypingInput object
    function allowStart() {
      userMayStart = true;
    }

    // Callback to TypingInput
    //   -> Fires when the session is complete
    function sessionFinished() {
      userMayStart = false;
      $scope.$broadcast('keyboard:hide-hands');
      $scope.TypingInput.save({
        permission_id: $scope.timedWriting.permission.id,
      })
        .then(function () {
          $scope.$broadcast('TimedWritingModals:start', {
            modalSelector: '#results-modal-sequence',
            delayListener: 1500,
          });
        })
        .catch(function (response) {
          // Server did not save the session
          const errorMessage =
            typeof response.data === 'object' && response.data.errors
              ? response.data.errors
              : 'Oh no, Typist could not save this activity! Please check your internet connection and try again.';

          $scope.TypingInput.errorMessage = errorMessage;
          $scope.$broadcast('TimedWritingModals:start', {
            modalSelector: '#error-modal-sequence',
            delayListener: 1000,
          });
        });
      return true;
    }

    // Fires when the modal sequence completes
    //  -> Initializes TypingInput.timer & allows user to start
    function startTimedWriting() {
      const debug = {
        userSystemTime: new Date(),
        SERVER_TIME_SYSTEM_TIME_DIFF,
        now: Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF,
        expires_on: new Date($scope.timedWriting.permission.expires_on).getTime(),
      };
      // let the server know that an attempt has officially started
      $http.put(`permission/register_timed_writing_start/${$scope.timedWriting.permission.id}.json`, { debug: debug });

      // TODO: Catch if this doesn't work

      window.scrollTo(0, 0); // scroll to top for consistent view
      // Make sure TypingInput is ready, has text, etc.
      $q.all([sessionReady]).then(function () {
        $timeout(() => document.getElementById('line-of-text').focus(), 0);
        allowStart();
        // keyboard_controller picks up this event and shows the hands on the keyboard
        $scope.$broadcast('keyboard:show-hands');

        $timeout(() => {
          // Check if no keys have been pressed yet, show hands on the homerow keys
          if ($scope.digitIndex === 0) {
            updateReactKeyboard({
              hideHands: false,
            });
          }
        }, 500).then(() => {
          // This loads in the very first letter of the text prompt that the user should type next
          $timeout(() => {
            // Check if no keys have been pressed yet, only then display the hint for the first letter to press
            if ($scope.digitIndex === 0) {
              updateReactKeyboard({
                highlightKeys: [$scope.TypingInput.view.lineOfTextObjects[0].letter],
              });
            }
          }, 6000);
        });
      });
    }

    // This method uses the same_session_accessing_test property on the permission object to
    //  check whether two sessions from the same user are accessing a test.
    // If this is the case, there are two scenarios:
    //  1. The student has accessed their test, logged out, logged back in and tried to start their test again.
    //  2. The student has someone in a different room simultaneously accessing their test (i.e. cheating).
    // Either case, if a student tries to start their test in this situation, we will tell the server, invalidate the tests,
    //   and show the user an error modal explaining what happened.
    function isStudentCheating() {
      var permission = $scope.timedWriting.permission;
      var isCheating = !permission.same_session_accessing_test;
      if (isCheating) {
        // tell the server the student is potentially trying to cheating
        //  -> this will update the attempts_from_different_sessions property
        $http
          .get('permission/two_sessions_accessing_same_test/' + permission.id)
          .then(function (response) {
            $scope.TypingInput.errorMessage = response.data.message;
            $scope.$broadcast('TimedWritingModals:start', {
              modalSelector: '#error-modal-sequence',
              delayListener: 1500,
            });
          })
          // send notification to the professor to update the violated permissions object.
          // this is being watched by the gradesheet and will automatically pull down information from the server
          .then(function () {
            update(ref(db, `actions/sections/${$scope.timedWriting.permission.section_id}/violatedPermissionAdded`), {
              timestamp: serverTimestamp(),
            });
            return true;
          });
      }
      return isCheating;
    }

    // Checks whether a permission is expired. If yes, it sends the user to the error modal.
    function isPermissionExpired() {
      var now = Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF;
      var expires_on = new Date($scope.timedWriting.permission.expires_on).getTime();
      // we add ten seconds here to estimate how long it would take a student to get through HomeRowTech
      if (expires_on < now + 10000) {
        $scope.TypingInput.errorMessage = 'The test window has expired for this test.';
        $scope.$broadcast('TimedWritingModals:start', {
          modalSelector: '#error-modal-sequence',
          delayListener: 1500,
        });
        return true;
      } else {
        return false;
      }
    }

    function countIncompleteAttempts() {
      if (!$scope.timedWriting || !$scope.timedWriting.permission || $scope.timedWriting.permission.activities.length == 0)
        return true;

      $scope.timedWriting.permission.numIncompleteAttempts = $scope.timedWriting.permission.activities.filter(
        function (activity) {
          return activity.data.stubbed_activity_due_to_refresh;
        }
      ).length;
    }

    // keydown event handler
    $scope.$on('keydown', function (a, e) {
      // prevent default behaviour of space key while modals are active
      keyboardUtilities.blockScrollingWithSpaceKey(e);

      // Create the event log
      // Make sure to start logging key events after the modal sequence is complete
      if (userMayStart) {
        $scope.digitIndex = $scope.TypingInput.addEvent(e); // Store the event
        // Add a timeout so that the css animation responsible for the hands fading in/out starts first, and then the key highlight happens,
        // otherwise on the fade out, the next highlight key is immediately set, before the fade out animation for previous hand position is finished
        // (make sure to sync this to the transition delay in InteractiveKeyboardKey.module.scss .fingerImageContainer class, currently both are set to 300ms)
        $timeout(() => {
          updateReactKeyboard({
            highlightKeys: [$scope.TypingInput.view.lineOfTextObjects[$scope.digitIndex].letter],
          });
        }, 300);
      }
    });
  },
]);
