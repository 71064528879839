app.factory('StripePaymentFactory', [
  '$q',
  'AnimatedLoginKeyboardFactory',
  '$timeout',
  '$http',
  function ($q, AnimatedLoginKeyboardFactory, $timeout, $http) {
    // Processes payments with the Stripe form
    //  requires the element id of the Stripe form in order to prevent the default action
    //    and capture a payment.
    //  requires a method, either "create" or "update" to determine which method to invoke on
    //    the Strip form submit
    var StripePaymentFactory = function (stripeFormId, method, user, product) {
      var self = this;
      if (!stripeFormId) {
        console.log('WARNING: Need a Stripe form element id to be passed to StripePaymentFactory.');
      }
      if (method !== 'create' && method !== 'update') {
        console.log("WARNING: method argument to StripePaymentFactory must be either 'create' or 'update'.");
      }
      if (!user) {
        console.log('WARNING: A valid user must be supplied to StripePaymentFactory.');
      }
      self.method = method;
      self.user = user;
      self.keyboard = new AnimatedLoginKeyboardFactory('#loading-keyboard-auth');
      self.progressMessage = null;

      // Get pricing information from product
      self.product = product;
      self.stripe_price = product.stripe_price;
      self.currency = product.currency;
      if (typeof self.currency !== 'string' || self.stripe_price < 5000 || self.stripe_price > 20000)
        throw 'ERROR: Currency and Amount are not defined!';

      // A promise that is resolved by the update and create methods. Used to bind to
      //  a controller callback
      self.paymentSuccess = $q.defer();

      // Prevent the default action of Stripe's modal.
      //  this enables us to launch with a custom price
      // launchModalWithCustomPrice(stripeFormId);

      // Overrides Stripe's request to the server so that we can capture the payment information
      // this method is bound to _strip_payment_form.html
      //
      // Stripe works as follows (from https://stripe.com/docs/checkout/tutorial):
      //  1. The customer arrives at your payment page that includes the Checkout code, loaded over HTTPS.
      //  2. The customer clicks the payment button (e.g., Pay with Card), completes the payment form, and clicks Pay $9.99 within the Checkout window (or whatever your Checkout pay button is).
      //  3. Checkout sends the payment details directly to Stripe from the customer's browser, assuming the details pass basic validation.
      //  4. Stripe returns a token to Checkout, or an error message if the card-network validation fails.
      //  5. Checkout takes the returned token and stores it in the page's primary form—the one surrounding the <script> tag above, in a hidden element named stripeToken.
      //  5b. THE CODE BELOW INTERCEPTS, ADDS USER DATA.
      //  6. Checkout submits the form to your server.
      //  7. Your server uses the posted token to charge the card.
      this.changeStripeSubmitBindings = function () {
        console.log('Preventing default Stripe behaviour. Adding callback.');

        if ($(stripeFormId).get(0)) {
          $(stripeFormId).get(0).submit = function () {
            // Bind data to $scope
            var stripeData = $(this).serializeArray();
            var stripeDataFormatted = {};
            if (stripeData) {
              for (var i = 0; i < stripeData.length; i++) stripeDataFormatted[stripeData[i].name] = stripeData[i].value;
            }
            // Call submit method, that passes data to
            $timeout(function () {
              if (self.method === 'update') {
                updateSubscription(stripeDataFormatted);
              } else if (self.method === 'create') {
                createAccount(stripeDataFormatted);
              }
            }, 200);
            // Prevent stripe request to server.
            return false;
          };
        } else {
          console.log('WARNING: CANNOT LOCATE STRIPE FORM IN StripePaymentFactory.');
        }
      };

      // called from Stripe form natively (see notes above)
      //  resolves a promise self.paymentSuccess that can be bound to a controller variable
      //  see AccountExpiredController for an example
      function updateSubscription(stripeData) {
        self.validations = {};
        self.payment_validation_started = true;
        self.payment_validation_success = false;

        // user must be logged in to update their account
        const user = self.user;
        const userCreds = { username: user.username, password: user.password };

        self.keyboard.showKeyboard = true;
        self.keyboard.animate(220);
        self.progressMessage = 'Attempting payment with credit card...';

        const paymentCreds = {
          method: 'stripe',
          stripeToken: stripeData.stripeToken,
          stripeEmail: user.email,
          product_id: self.product.id,
        };

        return $http
          .put('users/update_subscription', { payment: paymentCreds, user: userCreds })
          .then(function (response) {
            self.payment_validation_success = true;
            // self.successMessage = "You have updated your subscription! Welcome back!";
            // self.keyboard.stopKeyboard = true;
            return $timeout(function () {
              // paymentSuccess is a callback that is resolved by update and create methods
              // self.keyboard.showKeyboard = false;
              self.paymentSuccess.resolve(user);
              return user;
            }, 3000);
          })
          .catch(serverRegistrationError);
      }

      function createAccount(stripeData, test) {
        self.validations = {};
        self.payment_validation_started = true;
        self.payment_validation_success = false;

        self.keyboard.showKeyboard = true;
        self.keyboard.animate(220);
        self.progressMessage = 'Attempting payment with credit card...';

        var user = self.user;

        // Should call the server now...
        var userCredentials = {
          username: user.username,
          password: user.password,
          password_confirmation: user.passwordConfirmation,
          instructor: user.instructor,
          first_name: user.firstName,
          last_name: user.lastName,
          student_number: user.studentNumber,
          email: user.email,
          organization_id: user.organization,
        };
        const paymentCreds = {
          method: 'stripe',
          stripeToken: stripeData.stripeToken,
          stripeEmail: user.email,
          product_id: self.product.id,
        };

        return $http
          .post('/users.json', { user: userCredentials, payment: paymentCreds })
          .then(function (user) {
            return $timeout(function () {
              // paymentSuccess is a callback that is resolved by update and create methods
              return self.paymentSuccess.resolve(user);
            }, 3000);
          })
          .catch(serverRegistrationError);
      }

      function serverRegistrationError(response) {
        var responseData = response.data.errors;
        self.payment_validation_started = false;
        self.payment_validation_success = false;
        self.keyboard.stopKeyboard = true;

        return $timeout(
          function () {
            self.keyboard.showKeyboard = false;
            self.progressMessage = null;
            // handle fraud/out of funds issues
            if (responseData === 'Your card was declined.') {
              self.validations.error =
                'This can sometimes happen if the ZIP or Postal Code is not the same as that on the card, if the card is not activated, or most often, if the card has been frozen. To proceed with payment, you should contact your bank by calling the phone number located on the back of your card and ask why the payment did not go through. Afterwards, please feel free to email us at support@typistapp.ca for support.';
            } else {
              self.validations.error = responseData;
            }

            return $q.reject(responseData);
          },
          self.method == 'create' ? 2500 : 10
        );
      }

      this.launchPaymentModal = function () {
        // We add the following attributes later: amount, currency ('cad' || 'usd')
        // StripeCheckout is added in application.html.erb in <head>
        var key = $('#stripePublishableKey').val();
        var imageURL = $('#stripeImageURL').val();

        var handler = StripeCheckout.configure({
          key: key,
          name: self.product.name,
          description: '3 Year Subscription',
          zipCode: true,
          image: imageURL,
          locale: 'auto',
          token: function (token) {
            $('input#stripeToken').val(token.id);
            $(stripeFormId).submit();
          },
        });
        handler.open({
          amount: self.stripe_price,
          currency: self.currency,
          email: self.user.email, // added this line so that the email is automatically entered
        });

        // Close Checkout on page navigation (i.e. the back button)
        $(window).on('popstate', function () {
          handler.close();
        });
      };
    };

    return StripePaymentFactory;
  },
]);
