app.controller('HomeController', [
  '$scope',
  'Level',
  function ($scope, Level) {
    $scope.level = Level;

    $scope.successErrorMessage = {
      success: true,
      message:
        "Welcome to Typist's home screen! Ensuring proper hand position is the most important thing to improve your typing speed! Be sure to regularly review the diagrams below!",
    };

    $scope.hideSuccessErrorMessages = function () {
      $scope.successErrorMessage = null;
    };
  },
]);
