export default class CourseTemplate {
  constructor(courseData = { name: 'My New Course', structure: [] }, allAssignments = []) {
    // Assign all properties from the JSON object to the class
    this.addIdStrToCourseWork(courseData.structure);
    Object.assign(this, courseData);
    if (courseData.structure.length < 1) this.addWeekToCourse(undefined, { name: 'Week 1' });
    if (this.creator) this._creatorName = `${this.creator.first_name} ${this.creator.last_name}`;
    this._testRequirementsStr = JSON.stringify(this.test_requirements);
    // all assignments is added as a function so that we can more easily
    // inspect the shape of the courseTemplate
    this.allAssignments = () => allAssignments;
    if (allAssignments.length < 1) alert('Error: Could not initialize assignments in Course Template!');

    this.updateDocumentAssignmentProperties(courseData.structure);
    this._addMultipleWarnings = [];
    this._warnings = [];
  }

  addWeekToCourse(index, attrs = {}) {
    const defaultWeek = { name: null, description: null, courseWork: [] };

    if (index === undefined) {
      this.structure.push({ ...defaultWeek, ...attrs });
    } else {
      this.structure.splice(index, 0, { ...defaultWeek, ...attrs });
    }
  }

  addCourseWorkToWeek(courseWork, id, weekIndex, courseWorkIndex) {
    courseWork._errorMessage = null;
    // Note: Since the `_idStr` argument is the only one tracked by our model in the UI, the courseWork
    //  is generally the courseWork that is being replaced. However, we need its type and we reuse its
    //  options.
    const allAssignments = this.allAssignments();
    const newCourseWork = allAssignments.find((a) => a.type == courseWork.type && a._idStr == String(id));
    const options = courseWork.options;
    if (!newCourseWork) {
      const errorMessage = `Could not find courseWork "${courseWork.name}".\nNo assignment with this id/type pair: ${id} / ${courseWork.type}`;
      // reset the _idStr property that the model is tracking in order to tell the user this assignment didn't actually change
      courseWork._errorMessage = errorMessage;
      courseWork._idStr = String(courseWork.id);
      throw Error(errorMessage);
    } else if (newCourseWork.name !== courseWork.name) {
      const weekName = this.structure[weekIndex].name;
      this._addMultipleWarnings.push(
        `Warning: Might have added incorrect assignment to week "${weekName}" (names do not match). Tried to add courseWork "${courseWork.name}" (id: ${courseWork.id}) and instead added "${newCourseWork.name}" (id: ${newCourseWork.id})`
      );
    }

    let formattedCourseWork;
    if (courseWork.type === 'Document Production') {
      formattedCourseWork = this.documentAssignmentToCourseWork(newCourseWork, options);
    } else if (courseWork.type === 'Keyboarding') {
      formattedCourseWork = this.keyboardingAssignmentToCourseWork(newCourseWork, options);
    } else throw new Error(`Unknown type in courseWork. type: ${courseWork.type}`, courseWork);
    // if (courseWork.name && courseWork.name !== formattedCourseWork.name)
    //   throw new Error(
    //     `CourseWork's name ${courseWork.name} does not match the loaded courseWork's name ${formattedCourseWork.name}. Possibly an issue with an incorrect 'id' property`,
    //     courseWork,
    //     formattedCourseWork
    //   );
    if (formattedCourseWork) this.structure[weekIndex].courseWork[courseWorkIndex] = formattedCourseWork;
  }

  // Example:
  // addCategoryToWeek({category: "Business Tutorials Part 1", country: 'CA', style_guide_version: 2.2}, 0, 0)
  // OR (using alternative_category)
  // addCategoryToWeek({alternative_category: "Business Tutorials Part 1", country: 'CA', style_guide_version: 2.2}, 0, 0)
  addCategoryToWeek({ category, alternative_category, country, style_guide_version }, weekIndex, courseWorkIndex) {
    const allAssignments = this.allAssignments();
    const matchingAssignments = allAssignments.filter((assignment) => {
      const matchesCategory = category
        ? assignment.category === category
        : assignment.alternative_category === alternative_category;

      return matchesCategory && assignment.country === country && style_guide_version == assignment.style_guide_version;
    });

    if (matchingAssignments.length === 0) {
      throw new Error(
        `No matching assignments found for ${category ? 'category' : 'alternative_category'}: ${category || alternative_category}, country: ${country}, style_guide_version: ${style_guide_version}`
      );
    }

    let courseWorkIdx = courseWorkIndex;
    matchingAssignments.forEach((assignment) => {
      this.addCourseWorkToWeek(
        { type: assignment.type, id: assignment.id, name: assignment.name, options: assignment.options },
        assignment.id,
        weekIndex,
        courseWorkIdx
      );
      courseWorkIdx++; // Increment the courseWorkIndex for each added assignment
    });
    return matchingAssignments.length;
  }

  // Expects content to be a JSON string containing an array like:
  // [
  //  {
  //    "name": "Week 7",
  //    "courseWork": [
  //      {"id": 6, "type": "Keyboarding", "level": "bronze" },
  //      {"id": 196, "type": "Document Production", "name": "Simulation - Email 8" },
  //    ]
  //  }
  // ]
  addMultipleWeeks(startingWeekIdx, content) {
    this._addMultipleWarnings = [];
    const parsedArr = JSON.parse(content);

    parsedArr.forEach((week, weekIdx) => {
      const currentWeekIdx = startingWeekIdx === undefined ? this.structure.length : startingWeekIdx + weekIdx;
      let courseWorkCategoryAssignmentsInWeek = 0; // if we add multiple assignments via a category, we need to adjust the index

      // if weekIndex is undefined we're adding to the bottom of the course, so we don't need to increment anything
      if (startingWeekIdx === undefined) this.addWeekToCourse(startingWeekIdx, { name: week.name, _active: true });
      else this.addWeekToCourse(currentWeekIdx, { name: week.name });
      (week.courseWork || []).forEach((courseWork, courseWorkIdx) => {
        try {
          if (courseWork.category || courseWork.alternative_category) {
            courseWorkCategoryAssignmentsInWeek +=
              this.addCategoryToWeek(courseWork, currentWeekIdx, courseWorkIdx + courseWorkCategoryAssignmentsInWeek) - 1;
          } else {
            this.addCourseWorkToWeek(
              { type: courseWork.type, id: courseWork.id, options: courseWork.options, name: courseWork.name },
              courseWork.id,
              currentWeekIdx,
              courseWorkIdx + courseWorkCategoryAssignmentsInWeek
            );
          }
        } catch (error) {
          this._addMultipleWarnings.push(`Error adding courseWork to week "${week.name}". ${error.message}`);
          console.error(
            `❌ Error adding courseWork to course, likely caused from an incorrect courseWork (id: ${courseWork.id}, name: ${courseWork.name}, type: ${courseWork.type}):`,
            `\n  - week:`,
            week,
            `\n  - courseWork:`,
            courseWork,
            `\n  - Error:`,
            error.message
          );
        }
      });
    });
    console.log(this.structure[startingWeekIdx]);
    return this._addMultipleWarnings < 1 ? true : false;
  }

  moveWeek(weekIndex, direction) {
    if (weekIndex < 0 || weekIndex >= this.structure.length) {
      console.error(`Invalid weekIndex: ${weekIndex}. Unable to move week.`);
      return;
    }

    const newIndex = direction === 'up' ? weekIndex - 1 : direction === 'down' ? weekIndex + 1 : weekIndex;

    if (newIndex < 0 || newIndex >= this.structure.length) {
      console.error(`Invalid move. Week cannot be moved ${direction} from index ${weekIndex}.`);
      return;
    }

    // Swap the weeks
    const [movedWeek] = this.structure.splice(weekIndex, 1);
    this.setAttributeOnAllWeeks({ _active: false });
    movedWeek._active = true;
    this.structure.splice(newIndex, 0, movedWeek);

    console.log(`Week moved from index ${weekIndex} to ${newIndex}.`);
  }

  moveCourseWork(weekIndex, courseWorkIndex, direction) {
    const week = this.structure[weekIndex];
    const isMovingUp = direction === 'up';
    const isMovingDown = direction === 'down';
    const newIndex = isMovingUp ? courseWorkIndex - 1 : isMovingDown ? courseWorkIndex + 1 : courseWorkIndex;

    if (newIndex < 0 && isMovingUp && weekIndex > 0) {
      // Move to the end of the previous week
      const [movedCourseWork] = week.courseWork.splice(courseWorkIndex, 1);
      this.setAttributeOnAllCourseWork({ _active: false });
      movedCourseWork._active = true;
      this.structure[weekIndex - 1].courseWork.push(movedCourseWork);
      console.log(`Course work moved from start of week ${weekIndex} to end of week ${weekIndex - 1}.`);
      return;
    }

    if (newIndex >= week.courseWork.length && isMovingDown && weekIndex < this.structure.length - 1) {
      // Move to the start of the next week
      const [movedCourseWork] = week.courseWork.splice(courseWorkIndex, 1);
      this.setAttributeOnAllCourseWork({ _active: false });
      movedCourseWork._active = true;
      this.structure[weekIndex + 1].courseWork.unshift(movedCourseWork);
      console.log(`Course work moved from end of week ${weekIndex} to start of week ${weekIndex + 1}.`);
      return;
    }

    if (newIndex < 0 || newIndex >= week.courseWork.length) {
      console.error(`Invalid move. Course work cannot be moved ${direction} from index ${courseWorkIndex}.`);
      return;
    }

    // Swap the course works within the same week
    const [movedCourseWork] = week.courseWork.splice(courseWorkIndex, 1);
    this.setAttributeOnAllCourseWork({ _active: false });
    movedCourseWork._active = true;
    week.courseWork.splice(newIndex, 0, movedCourseWork);
    console.log(`Course work moved from index ${courseWorkIndex} to ${newIndex} in week ${weekIndex}.`);
  }

  deleteWeek(weekIndex) {
    // Method to delete a week from the structure
    if (weekIndex >= 0 && weekIndex < this.structure.length) {
      this.structure.splice(weekIndex, 1);
      console.log(`Week at index ${weekIndex} has been deleted.`);
    } else {
      console.error(`Invalid weekIndex: ${weekIndex}. Unable to delete week.`);
    }
  }

  deleteCourseWorkFromWeek(week, weekIndex, courseWorkIndex) {
    const originalCourseWork = week.courseWork;
    // Remove the course work at the specified index
    this.structure[weekIndex].courseWork = [
      ...originalCourseWork.slice(0, courseWorkIndex),
      ...originalCourseWork.slice(courseWorkIndex + 1),
    ];
  }

  // Remove any keys from any week or courseWork that begin with an underscore
  // returns a copy of a course's structure
  removeUnderscoreKeysStructure() {
    // Create a deep copy of the structure array
    const structureCopy = JSON.parse(JSON.stringify(this.structure));

    // Iterate over each object in the structure array
    return structureCopy.map((week) => {
      // Delete any keys in the week object that start with an underscore
      Object.keys(week).forEach((key) => {
        if (key.startsWith('_')) {
          delete week[key];
        }
      });

      if (Array.isArray(week.courseWork)) {
        week.courseWork = week.courseWork.map((courseWorkItem) => {
          return Object.keys(courseWorkItem).reduce((acc, key) => {
            if (!key.startsWith('_')) {
              acc[key] = courseWorkItem[key];
            }
            return acc;
          }, {});
        });
      }
      return week;
    });
  }

  // Add _idStr to all courseWork
  // We need to add _idStr to each courseWork since ng-repeats don't work well with number keys
  addIdStrToCourseWork(structure) {
    structure.forEach((week) =>
      week.courseWork.forEach((cw) => {
        cw._idStr = String(cw.id);
        cw._expanded = false;
      })
    );
  }

  // check if the document assignment has had any changes and if so apply these to the
  updateDocumentAssignmentProperties(structure) {
    const allDocumentAssignments = this.allAssignments().filter((da) => da.type == 'Document Production');

    structure.forEach((week) =>
      week.courseWork.forEach((cw) => {
        if (cw.type === 'Document Production') {
          const da = allDocumentAssignments.find((da) => String(da.id) == String(cw.id));
          if (da) {
            cw.name = da.name;
            cw.country = da.country;
            cw.style_guide_version = da.style_guide_version;
            cw.difficulty = da.difficulty;
            cw.randomized = da.randomized;
            cw.accessible = da.accessible;
          } else {
            console.warn(`Warning: Could not load document assignment with id: ${cw.id}}, name: ${cw.name}`);
          }
        }
      })
    );
  }

  // parse the test requirements when the string changes in the UI
  parseTestRequirements = function () {
    this._test_requirement_error = null;
    this._test_requirement_success = null;
    try {
      const newTestRequirements = JSON.parse(this._testRequirementsStr);
      this.test_requirements = newTestRequirements;
      this._testRequirementsStr = JSON.stringify(this.test_requirements);
      this._test_requirement_success = "Test Requirements Successfully Parsed! Click 'Update' to save!";
      console.log('Test Requirements Updated!');
    } catch (e) {
      this._test_requirement_error = 'Error: Could not parse JSON!';
      this._testRequirementsStr = JSON.stringify(this.test_requirements);
      console.error('Test Requirements contains invalid JSON', e);
    }
  };

  // takes a keyboaring assignment and transforms it into something we will
  // embed in our courseTemplate
  keyboardingAssignmentToCourseWork(keyboardingAssignment, options = {}) {
    if (!keyboardingAssignment.name || !keyboardingAssignment.id) return null;

    const defaultOptions = {
      goal: 'silver',
      bump_to: null,
      revert_to: null,
    };

    return {
      copiedName: keyboardingAssignment.name,
      id: keyboardingAssignment.id,
      type: 'Keyboarding',
      options: { ...defaultOptions, ...options },
      // newly added by Matt in June 2024 ----
      name: keyboardingAssignment.name,
      _expanded: true, // removed on save
      _idStr: keyboardingAssignment._idStr, // removed on save
      // -------------------------------------
    };
  }

  // takes a document assignment and transforms it into something we will
  // embed in our courseTemplate
  documentAssignmentToCourseWork(documentAssignment, options = {}) {
    if (!documentAssignment.name || !documentAssignment.id) return null;

    const defaultOptions = {
      auto_release_grades: true,
      attempts: 1,
      max_duration_mins: 110,
      base_points: documentAssignment.base_points,
    };

    return {
      copiedName: documentAssignment.name,
      id: documentAssignment.id,
      type: 'Document Production',
      // newly added by Matt in June 2024 ----
      name: documentAssignment.name,
      country: documentAssignment.country,
      style_guide_version: documentAssignment.style_guide_version,
      difficulty: documentAssignment.difficulty,
      randomized: documentAssignment.randomized,
      accessible: documentAssignment.accessible,
      _expanded: true, // removed on save
      _idStr: documentAssignment._idStr, // removed on save
      // -------------------------------------
      options: { ...defaultOptions, ...options },
    };
  }

  setAttributeOnAllCourseWork(attrs = {}) {
    this.structure.forEach((week) => {
      week.courseWork.forEach((courseWork) => {
        Object.assign(courseWork, attrs);
      });
    });
  }

  setAttributeOnAllWeeks(attrs = {}) {
    this.structure.forEach((week) => {
      Object.assign(week, attrs);
    });
  }
}
