import { angularStates } from '../../statesUrls';

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'AuthInterceptProvider',
  function ($stateProvider) {
    var scrollToTop = [
      function () {
        window.scrollTo(0, 0); // scrolls to top of page
        return true;
      },
    ];

    $stateProvider

      .state('app.keyboarding.invigilation_centre', {
        url: angularStates['app.keyboarding.invigilation_centre'],
        controller: 'TestCentreControllerMain',
        templateUrl: 'test_centre/_test_centre_main.html',
        resolve: {
          proctoredTests: [
            '$http',
            'Permissions',
            function ($http, Permissions) {
              return Permissions.getProctoredTests();
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
        // we do need this here, since if we're enterring from the course_select state it may move us to the bottom
        onEnter: scrollToTop,
      })

      .state('app.keyboarding.test_centre_course_select', {
        url: '/test_centre/course_select',
        controller: 'TestCentreControllerCourseSelect',
        templateUrl: 'test_centre/_test_centre_course_select.html',
        resolve: {
          user: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state) {
              return Auth2.currentUser().then(
                function (user) {
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          proctoredTests: [
            '$http',
            'Permissions',
            function ($http, Permissions) {
              return Permissions.getProctoredTests();
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      });
  },
]);
