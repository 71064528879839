app.controller('DocProductionResultsController', [
  '$scope',
  '$stateParams',
  'DocumentSubmission',
  'Auth2',
  'DocumentAssignment',
  'currentUser',
  'Instruction',
  '$http',
  '$q',
  '$timeout',
  '$state',
  function (
    $scope,
    $stateParams,
    DocumentSubmission,
    Auth2,
    DocumentAssignment,
    currentUser,
    Instruction,
    $http,
    $q,
    $timeout,
    $state
  ) {
    /* *****
     * Variables
     * *****/

    $scope.documentAssignment = {};
    $scope.docProductionResults = [];
    $scope.totalScore = 0;
    $scope.view = {
      // keeping the status variables for instructions here instead of document assignment factory,
      // because document assignmeny typically loads all these things at once, and doesnt need more
      // complexity to load every element individually.
      instructions: {
        loading: false, // used to display a spinner in the view, while the instructions are being fetched
        flashMessage: null, // null if empty or {message: '', error: true}
      },
      errorMessage: null,
      showAdminTools: false,
      // show/hide the explanation prompt when user clicks the link to delete a submission
      showDeleteSubmissionPrompt: false,
      generateTest: {
        deductions: 0,
        description: '',
      },
    };

    // We need the current user to determine whether to show the admin
    //  panel that autogenerates test files
    $scope.user = currentUser;

    /* *****
     * Initialize
     * *****/

    // Process:
    //   1. Check if grading is complete.
    //   2. Check if grade is released.
    //   3. Download JSON analysis from S3.
    var documentSubmission = new DocumentSubmission();
    documentSubmission
      .init($stateParams.activity_id, true)
      .then((result) => {
        // Short timeout so that the view is not too jumpy
        return new Promise((resolve) => {
          setTimeout(() => resolve(result), 500);
        });
      })
      .then(function (result) {
        $scope.documentSubmission = documentSubmission;
        $scope.documentAssignment = new DocumentAssignment();
        $scope.documentAssignment.initWithoutPermission(documentSubmission.document_assignment_id);
      })
      .catch(function (err) {
        $scope.view.errorMessage = err;
      });

    // ng-click callback for soft deleting a document submission.
    $scope.deleteSubmission = () => {
      $scope.documentSubmission
        .delete()
        .then(() => {
          // Running a full page reload (instead of $state.reload) so that if user presses Back in their browser,
          // the updated document submissions will be updated in gradesheet
          window.location.reload();
        })
        .catch(() => {
          // Scroll to the top and collapse the delete submission confirmation box
          window.scrollTo(0, 0);
          $scope.view.showDeleteSubmissionPrompt = false;

          $scope.view.errorMessage = 'Could not delete submission. Please contact Typist support at support@typistapp.ca.';
        });
    };

    // On-click function that fetches generated varaibles for the current submission (if any) and instructions associated with the assignment
    $scope.getInstructionsAndGeneratedVariables = function () {
      $scope.view.instructions.loading = true;

      // Short timeout so that the view is not too jumpy
      return $timeout(() => {}, 500)
        .then(() => {
          // 1. If this assignment has associated generated variables then fetch them
          if ($scope.documentSubmission.generated_variable_id) {
            return $http
              .get(`api/generated_variables/${$scope.documentSubmission.generated_variable_id}`)
              .then((res) => res.data);
          }
          return $q.resolve();
        })
        .then((generated_variables_for_user_section) => {
          // 2. Get the instructions. Since instructions need to be attched to the DocumentAssignment factory
          // instance (for the instructions partial to be reused), the method for getting them is located on the DocumentAssignment instance

          // If there are no generated variables, Instructions class will just not use them.
          // If there are no generated variables but they are needed by assignment, then instructions will render
          // placeholder meassages asking to contact Typist support due to missing variables
          $scope.documentAssignment.getInstructions(generated_variables_for_user_section);
        })
        .then(() => {
          $scope.view.instructions.loading = false;
        })
        .catch(() => {
          $scope.view.instructions.flashMessage = {
            message: 'Could not get instructions for this assignment. Please contact Typist support at support@typistapp.ca.',
            error: true,
          };
          $scope.view.instructions.loading = false;
        });
    };

    // Fetches a course description from CourseBuilderDataService and populates the currentCourseTemplate with it

    // $scope.docProductionResults = activityResults.grading_details;
    // $scope.totalScore = activityResults.points;
    // console.log("THESE ARE THE RAW RESULTS", $scope.docProductionResults)
  },
]);
