app.factory('NameEmailRegFactory', [
  '$http',
  '$q',
  'AnimatedLoginKeyboardFactory',
  '$timeout',
  'Auth',
  'Auth2',
  function ($http, $q, AnimatedLoginKeyboardFactory, $timeout, Auth, Auth2) {
    // contains helper methods for the registration process
    var NameEmailRegFactory = function () {
      var self = this;
      this.validations = {};
      this.keyboard = new AnimatedLoginKeyboardFactory('#loading-keyboard-auth');
      this.view = { inProgress: false, progressMessage: null, isFailure: false, isSuccessful: false };

      this.validateNameAndEmail = function (user) {
        self.validations = {};

        return localValidations(user).then(serverValidations).then(emailAvailableStudent);
      };

      // Local validations
      //  returns a rejected promise on failure
      //  returns a resolved promise with the user object on success
      function localValidations(user) {
        // Validations
        if (user === undefined) {
          self.validations.firstName = 'Please enter your first name, last name and email.';
          return $q.reject();
        }
        if (user.firstName === undefined) {
          self.validations.firstName = 'Please enter your first name.';
          return $q.reject();
        }
        if (user.firstName.length < 2) {
          self.validations.firstName = 'Please enter a valid first name (more than 1 character).';
          return $q.reject();
        }
        if (user.firstName.includes('@')) {
          self.validations.firstName = 'Your first name must only contain letters.';
          return $q.reject();
        }
        if (user.firstName.includes(',')) {
          self.validations.firstName = 'Your first name must only contain letters.';
          return $q.reject();
        }
        if (user.firstName[0] === user.firstName[0].toLowerCase()) {
          self.validations.firstName =
            'Your name may be used on class grade sheets and professional certificates. Your first name must start with a capital letter.';
          return $q.reject();
        }
        if (user.firstName[1] === user.firstName[1].toUpperCase()) {
          self.validations.firstName =
            'Your name may be used on class grade sheets and professional certificates. Please only capitalize the first letter of your name, and leave the rest of the letters in lower case.';
          return $q.reject();
        }
        if (user.lastName === undefined) {
          self.validations.lastName = 'Please enter your last name.';
          return $q.reject();
        }
        if (user.lastName.length < 2) {
          self.validations.lastName = 'Please enter a valid last name (more than 1 character).';
          return $q.reject();
        }
        if (user.lastName.includes('@')) {
          self.validations.lastName = 'Your last name must only contain letters.';
          return $q.reject();
        }
        if (user.lastName.includes(',')) {
          self.validations.lastName = 'Your last name must only contain letters.';
          return $q.reject();
        }
        // skip the capital letter requirement if the last name should not be capitalized
        // ex: van Gogh, del Toro, etc.
        // we check this by looking for a space in the last name.
        var dutchName = user.lastName.trim().includes(' ');
        if (user.lastName[0] === user.lastName[0].toLowerCase() && !dutchName) {
          self.validations.lastName =
            'Your name may be used on class grade sheets and professional certificates. Your last name must start with a capital letter.';
          return $q.reject();
        }
        if (user.lastName[1] === user.lastName[1].toUpperCase()) {
          self.validations.lastName =
            'Your name may be used on class grade sheets and professional certificates. Please only capitalize the first letter of your name, and leave the rest of the letters in lower case.';
          return $q.reject();
        }
        if (user.email === undefined) {
          self.validations.email = 'Please enter your email address.';
          return $q.reject();
        }
        if (user.email.indexOf('@') < 0) {
          self.validations.email = 'Please enter a valid email address.';
          return $q.reject();
        }
        if (user.confirmEmail === undefined || user.email !== user.confirmEmail) {
          self.validations.email = 'The email addresses you provided are not the same.';
          return $q.reject();
        }

        return $q.resolve(user);
      }

      // Validate availability of username, and that passwords match
      function serverValidations(user) {
        self.view.inProgress = true;
        self.keyboard.animate(220);
        self.view.progressMessage = 'Validating email address.';

        // 3500 delay on success. Failure delay is in serverValidationsError
        var waitOnSuccess = $timeout(function () {
          return $q.resolve();
        }, 3500);

        // Should call the server now...
        var credentials = {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          confirm_email: user.confirmEmail,
        };

        var validateNameAndEmailWithServer = $http.post('users/validate_email.json', { user: credentials });

        return $q.all([validateNameAndEmailWithServer, waitOnSuccess]).catch(serverValidationsError);
      }

      function emailAvailableStudent() {
        self.view.progressMessage = 'Email available! Continuing registration...';
        self.keyboard.stopKeyboard = true;
        self.keyboard.showKeyboard = false;
        self.view.isSuccessful = true;
        return $timeout(function () {
          return $q.resolve();
        }, 3000);
      }

      // Handle a failed server request
      //  return a rejected promise
      function serverValidationsError(response) {
        var responseData = response.data;

        return $timeout(function () {
          self.view.progressMessage = null;
          self.view.isFailure = true;
          if (responseData.first_name) {
            self.validations.firstName = 'First name ' + responseData.first_name[0];
          }
          if (responseData.last_name) {
            self.validations.lastName = 'Last name ' + responseData.last_name[0];
          }
          if (responseData.email || typeof responseData === 'string') {
            if (typeof responseData === 'string' && responseData.indexOf('Email has already been taken') != -1) {
              // this message has been overwritten by the view in signup5
              var message = 'Email has already been taken';
              self.validations.email = message;
            } else if (responseData.email) {
              self.validations.email = 'Email ' + responseData.email[0];
            }
          }

          self.keyboard.stopKeyboard = true;
          self.keyboard.showKeyboard = false;

          return $timeout(function () {
            self.view.isFailure = false;
            self.view.progressMessage = null;
            self.view.inProgress = false;
            return $q.reject();
          }, 2000);
        }, 2500);
      }
    }; // end of class

    return NameEmailRegFactory;
  },
]);
