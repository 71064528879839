/* global app */

app.service('LastNameCodeForm', [
  '$http',
  function ($http) {
    this.waitingForServer = false;
    this.errorMessage = null;
    this.isEditable = null;
    this.confirmed1 = false;
    this.confirmed2 = false;

    // Submit the last name code form. Takes in the object of input fields. eg. {user_id: 1242, last_name_code: '12344'}
    this.submit = function ({ user_id, last_name_code } = {}) {
      if (this.waitingForServer) return;
      this.waitingForServer = true;

      return $http.put('users/update_last_name_code.json', { user: { id: user_id, last_name_code } }).then((res) => {
        this.waitingForServer = false;
        return res.data;
      });
    };

    return this;
  },
]);
