/* global app, angular */
app.factory('RandomContact', [
  function () {
    // Class Constructor - this class is an instance of a 'contact' type Document Variable
    // all the properties passed in 'props' get copied over to the instance.
    // documentVariablesList is an instance of the parent DocumentVariablesList class. It gives access to the contactsList and to all the other document variables
    function RandomContact(props, documentVariablesList) {
      this.contactsList = documentVariablesList.contactsList;
      this.view = {
        flashMessage: null, // eg. null or {message: '', error: false}
        jobTitleOptions: this.contactsList.generateJobTitleOptions(),
        addressFeaturesOptions: this.contactsList.generateAddressFeaturesOptions(true),
      };
      this.name = null;
      this.type = 'contact';
      this.documentVariablesList = documentVariablesList;

      // ng-model for the form. Used to specify the randomization parameters. Parameters are initialized to empty strings
      // because that is the format the forms will use to define them anyways (though null/undefined also works as all are falsy)
      this.params = {
        company: {
          type: '', // can be either '' ('any'), 'reference', 'none', or 'specific'
          ref: '', // If type is 'reference', than this property will be used - a string refering to the name of another contact variable
          specificCompanies: [], // if type is 'specific', then this array of values will be used
        },
        industry: {
          type: '', // can be either '' ('any'), 'specific'
          specificIndustry: '', // if type is 'specific', then this is either '' or a name of the industry
        },
        jobTitle: {
          type: '', // can be either '' ('any'),  'specific'
          specificJobTitles: [], // if type is 'specific', then this array of values will be used
        },
        internal: false, // whether a company is internal/external
        addressFeatures: '', // can be either '' ('any') or ['Suite', 'Unit'], where an empty array means no suite, unit, etc
      };

      // Used to keep track of previous param values
      // Useful in the onInputChange() function
      this._previousParams = this.params;

      // instantiate variable with existing properties if props are passed in
      this.assignAllPropertiesToSelf(props);
    }

    // Updates the jobTitle/positions dropdown options
    // This function is called by the parent DocumentVariablesList instance on all contact variables at once
    // (this way if any contact is referencing another contact, the dropdown list will be cross-referenced and updated accordingly)
    RandomContact.prototype.updateDropdownOptions = function () {
      // This is the parent class that stores all the variable instances, including this one
      const documentVariables = this.documentVariablesList.documentVariables;

      // 1. Get values needed for generating a list of job titles/positions
      let companies = this.params.company.specificCompanies;
      let industry = this.params.industry.specificIndustry;
      let internal = this.params.internal;

      // 2. Determine if this variable is referencing another variable in its company field
      const referencedContactName = this.params.company.ref;
      // 2a. if yes, then find this variable in the documentVariables list and set it as
      // the current company (overwrite the above statement)
      if (referencedContactName) {
        const referencedContactVariable = documentVariables.find((d) => {
          return d.name === referencedContactName;
        });
        if (referencedContactVariable) {
          companies = referencedContactVariable.params.company.specificCompanies;
        }
      }

      // 3. Update job title/position dropdown options
      this.view.jobTitleOptions = this.contactsList.generateJobTitleOptions({
        companies,
        industry,
        internal,
      });

      // 4. Update the address features options, because like title/position, this dropdown is also dynamic
      this.view.addressFeaturesOptions = this.contactsList.generateAddressFeaturesOptions(isCorporate(this.params));
    };

    // Validate function that can be run before generate or on save.
    // Will check for specific combinations of params and throw errors if there are inconsistencies
    // returns true if validations pass
    RandomContact.prototype.validate = function () {
      if (this.params.company.type === 'reference' && !this.params.company.ref) {
        throw new Error(`"${this.name}" variable: missing a reference to another contact.`);
      }
      if (
        this.params.company.type === 'specific' &&
        !(Array.isArray(this.params.company.specificCompanies) && this.params.company.specificCompanies.length)
      ) {
        throw new Error(`"${this.name}" variable: at least one company must be chosen.`);
      }
      if (this.params.industry.type === 'specific' && !this.params.industry.specificIndustry) {
        throw new Error(`"${this.name}" variable: an industry has not been chosen.`);
      }
      if (
        this.params.jobTitle.type === 'specific' &&
        !(Array.isArray(this.params.jobTitle.specificJobTitles) && this.params.jobTitle.specificJobTitles.length)
      ) {
        throw new Error(`"${this.name}" variable: at least one position must be chosen.`);
      }
      return true;
    };

    // This function will generate a randomized instance of this contact, using the randomization parameters.
    // referenceContact is an object of a generated RandomContact that was already generated due to being higher up
    // the document variables list
    // generatedContacts is an object of already generated contacts (the ones that are higher in the list in documentVariablesList), where key is the name of the variable and the value is the generated contact object
    // Note: the order in which the generated variables are generated is not the same as the order in the view (the list gets resorted such that contacts being referenced are higher up)
    // return object is a contact from the contacts directory eg. {first_name: 'John', last_name: 'Smith'}
    // With all values stringified (including null -> '', false -> 'false', etc)
    RandomContact.prototype.generate = function (referenceContact, generatedContacts = {}) {
      this.view.flashMessage = null;
      // The list of these ids is used to remove these already generated contacts from filteredContactsList below, so that we do not have duplicates
      const previouslyGeneratedContactIds = Object.values(generatedContacts).map((contact) => parseInt(contact.id, 10));

      // 1. Define variables to be used for filtering contactsDir.
      let companies = this.params.company.specificCompanies;
      let industry = this.params.industry.specificIndustry;
      let internal = this.params.internal;
      let jobTitles = this.params.jobTitle.specificJobTitles;
      let nonCompany = this.params.company.type === 'none';
      let addressFeatures = this.params.addressFeatures ? this.params.addressFeatures.value : '';
      if (this.params.company.type === 'reference' && !referenceContact) {
        throw new Error(`"${this.name}" variable: This variable is referencing a contact that cannot be generated.`);
      }

      // 1b. If company is referencing another contact, use it to override values for company filter and ignore industry
      if (referenceContact) {
        companies = [referenceContact.company];
      }

      // 2. Narrow down list of contacts to sample from by filtering over industry, jobTitle, and company
      let filteredContactsList = this.contactsList.filterContacts({
        companies,
        industry,
        internal,
        jobTitles,
        nonCompany,
        addressFeatures,
      });

      // 2b. Remove any contacts that have already been generated in document variables list so far
      filteredContactsList = filteredContactsList.filter((contact) => {
        return !previouslyGeneratedContactIds.includes(contact.id);
      });

      // 3. Return a random contact from the filtered list,
      // with all properties converted to strings (including false -> "false", null -> '')
      let randomContact = filteredContactsList[Math.floor(filteredContactsList.length * Math.random())];

      // 3a. If filteredContactsList is empty (due to params being too specific), randomContact will be undefined, so throw an error
      if (!randomContact) {
        throw new Error(
          `"${this.name}" variable: No contacts can be generated using options specified. Please modify the options in the form to be less restrictive.`
        );
      }

      // 4. Add custom properties
      randomContact = {
        ...randomContact,
        streetAddress: randomContact.streetAddress + (randomContact.apartmentSuite ? `, ${randomContact.apartmentSuite}` : ''),
      };

      // 5. Convert to an object with all values stringified
      const stringifiedRandomContact = {};
      Object.keys(randomContact).forEach(function (key) {
        // Note: make sure the stringified properties are added to a new object,
        // and are not directly modifying the object in te contacts dir
        stringifiedRandomContact[key] = String(randomContact[key]);
      });
      return stringifiedRandomContact;
    };

    // Generate a flattened js object representing this variable
    RandomContact.prototype.toObj = function () {
      return {
        name: this.name,
        type: this.type,
        params: this.params,
      };
    };

    // Removes contact reference (i.e. on this.params.company) if it matches the passed in contactName string
    // Used during clean-up when referenced contact is removed from the documentVariables list
    RandomContact.prototype.removeContactReference = function (contactName) {
      // So far, ability to reference other contacts is only on the this.params.company property
      if (this.params.company.ref === contactName) {
        this.params.company.ref = null;
      }
    };

    // This function will check validty of the form and make changes to the form
    // on every input field change
    // eg. re-populate jobTitleOptions list based on company and industry choices
    RandomContact.prototype.onInputChange = function () {
      // Impose constraints on input fields
      if (this.params.company.type === 'none') {
        this.params.industry.type = '';
        this.params.industry.specificIndustry = '';
        this.params.jobTitle.type = '';
        this.params.jobTitle.specificJobTitles = [];
        this.params.internal = false;
        this.params.company.specificCompanies = [];
        this.params.company.ref = '';
      }

      // This is the only time addressFeatures gets reset so far
      // An argument can be made if specific companies are chosen, then addresses can also be reset,
      // However there is a use case where a range of companies are chosen, address features are chosen,
      // but the underlying contacts directory gets swapped (which changes the address features associated
      // with each company)
      if (this.params.company.type === 'reference') {
        this.params.addressFeatures == '';
        this.params.industry.type = '';
        this.params.industry.specificIndustry = '';
        this.params.internal = true; // both specific and reference allow to only choose internal companies
      }

      if (this.params.company.type === 'specific') {
        this.params.industry.type = '';
        this.params.industry.specificIndustry = '';
        this.params.internal = true; // both specific and reference allow to only choose internal companies
        this.params.company.ref = '';
      }

      if (this.params.industry.type === 'specific') {
        this.params.company.type = '';
        this.params.company.ref = '';
        this.params.company.specificCompanies = [];
      }
      if (this.params.industry.type === '') {
        this.params.industry.specificIndustry = '';
      }
      if (this.params.company.type === '') {
        this.params.company.ref = '';
        this.params.company.specificCompanies = [];
      }
      if (this.params.jobTitle.type === '') {
        this.params.jobTitle.specificJobTitles = [];
      }

      // Should only regenerated address features if:
      // a. company.type changed from '' or 'specific' to 'none' or vice versa
      if (isCorporate(this._previousParams) !== isCorporate(this.params)) {
        this.view.addressFeaturesOptions = this.contactsList.generateAddressFeaturesOptions(isCorporate(this.params));
      }

      // Update jobTitle dropdown options for ALL document variables (eg. .updateDropdownOptions() method is called on every 'contact' variable)
      // (eg. if another contact variable is referencing this one, both variables will need to adjust the jobTitles dropdown options accordingly)
      // Also, regenerating the positions list on every other param change is ok because the list is sensitive
      // to all the other conditions
      this.documentVariablesList.updateAllDocumentVariableForms();

      // Need a deep merge of this.params because there are nested object structures
      this._previousParams = angular.merge({}, this.params);
    };

    // Small helper function that takes in the params object (same structure as this.params )
    // and returns whether the params specify a corporate or non-corporate random contact
    function isCorporate(params) {
      return params.company.type !== 'none';
    }

    // this function will copy over every key-value pair in props and
    // assign it to self
    RandomContact.prototype.assignAllPropertiesToSelf = function (props) {
      var self = this;
      for (var prop in props) {
        if (Object.prototype.hasOwnProperty.call(props, prop)) {
          self[prop] = props[prop];
        }
      }
    };

    return RandomContact;
  },
]);
