app.controller('AuthenticationParentController', [
  '$scope',
  '$state',
  '$timeout',
  function ($scope, $state, $timeout) {
    if ($state.current.name.indexOf('sign_in') > -1) {
      // displays: The Typing Trainer Designed Exclusively....
      $scope.parentView = { showSubheading: true };
    } else {
      $scope.parentView = { showSubheading: false };
    }

    // Function to focus on the first focusable element inside #login-box
    function focusFirstFocusableElement() {
      $timeout(() => {
        const loginBox = document.getElementById('login-box');
        if (loginBox) {
          const focusableSelectors = `
              a[href], button, input:not([type="hidden"]), textarea, select, details, 
              [tabindex]:not([tabindex="-1"]), [contenteditable="true"]
            `;
          const focusableElements = loginBox.querySelectorAll(focusableSelectors);
          if (focusableElements.length > 0) {
            focusableElements[0].focus();
          }
        }
      }, 0); // Timeout ensures it runs after the state change completes
    }

    // Listen for state changes
    $scope.$on('$stateChangeSuccess', function () {
      focusFirstFocusableElement();
    });
  },
]);
