app.controller('SigninController', [
  '$scope',
  'SigninFactory',
  '$timeout',
  '$q',
  '$state',
  '$location',
  function ($scope, SigninFactory, $timeout, $q, $state, $location) {
    $scope.parentView.showSubheading = true; // this is inheirited from parent scope. displays: "The typing trainer designed..."

    $scope.signinFactory = new SigninFactory();
    $scope.submitButton = $scope.signinFactory; // attaches events: inProgress, isSuccessful, isFailure, etc.
    $scope.keyboard = $scope.signinFactory.keyboard;

    $scope.submit = function (user) {
      $scope.signinFactory.sign_in(user).then(redirectToHomepage).catch(handleRejection);
      // if it's an error? is there a redirect message?
      // If successful, redirect to home!
    };

    function handleRejection(response) {
      if (response && response.reason === 'AccountExpired') {
        return $state.go('authentication.account_expired', { userCredentials: response.userCredentials });
      } else {
        return $q.reject(response);
      }
    }

    function redirectToHomepage() {
      // include any URL params through $location.$$search
      return $state.go('afterLogin', $location.$$search);
    }
  },
]);
