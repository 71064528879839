app.controller('LearnDocumentProductionController', [
  '$scope',
  '$http',
  'DocumentAssignment',
  function ($scope, $http, DocumentAssignment) {
    // Simple controller to simplify my view.

    $scope.view = {
      // Allows admin to override the switch between canadian and american versions of the page
      pageLanguage: 'CA', // 'CA' or 'US'
    };

    $http
      .get('document_assignments')
      .then(function (response) {
        return response.data.map(function (da) {
          return new DocumentAssignment(da);
        });
      })
      .then((documentAssignments) => {
        const filteredAssignments = documentAssignments.filter((da) => {
          return da.id <= 45;
        });
        $scope.documentAssignments = filteredAssignments;
      });
  },
]);
