// manages all requests to the backend StatisticController.
//  used by StudentFactory to build Student objects
//  used by the Section factory to instantiate multiple Student objects

app.service('StatisticService', [
  '$http',
  'DocumentSubmission',
  '$filter',
  function ($http, DocumentSubmission, $filter) {
    // params is an object that can contain:
    // scope: {section_code: "CC83492" || section_id: 123 &&|| user_id:[]}
    // requirements: {}
    // statistics: ["timed_writings", ...]
    this.get = function (params) {
      return $http
        .post('statistics.json', params)
        .then(formatLessonResults)
        .then(formatPracticeTime)
        .then(formatDocumentProduction);
    };

    // ------------------------------------------------------------------------------------
    // private methods --------------------------------------------------------------------
    // ------------------------------------------------------------------------------------
    // These methods transform server results into something more usable on the front end
    //

    // formats the results to be cumulative, computes percentage completion
    function formatLessonResults(response) {
      if (!response.data['lesson_progress']) {
        return response;
      }

      var results = response.data['lesson_progress'];
      // if there are no students in the response, move on.
      var numStudents = Object.keys(results).length;
      if (numStudents === 0) {
        return response;
      }

      // get the category names, remove "user_id" from the key
      var categories = Object.keys(results[0]);
      var index = $.inArray('user_id', categories);
      if (index != -1) {
        categories.splice(index, 1);
      }

      // Get the category sum (we will use this to get percentages later)
      var lessonTotals = {};
      categories.forEach(function (category) {
        // just use the first student, since server makes sure all the category names are present
        lessonTotals[category] =
          results[0][category]['none'] +
          results[0][category]['bronze'] +
          results[0][category]['silver'] +
          results[0][category]['gold'];
      });

      // format the results from server
      var bronze = 0;
      var silver = 0;
      var gold = 0;
      results.forEach(function (result) {
        // make cumulative & percentages
        categories.forEach(function (category) {
          // if someone earned a gold, they also have a bronze and silver on that lesson...
          bronze = result[category]['bronze'] + result[category]['silver'] + result[category]['gold'];
          silver = result[category]['silver'] + result[category]['gold'];
          gold = result[category]['gold'];
          result[category]['bronze'] = {
            completed: bronze,
            percentage: Math.floor(((bronze * 1.0) / lessonTotals[category]) * 1000) / 10,
          };
          result[category]['silver'] = {
            completed: silver,
            percentage: Math.floor(((silver * 1.0) / lessonTotals[category]) * 1000) / 10,
          };
          result[category]['gold'] = {
            completed: gold,
            percentage: Math.floor(((gold * 1.0) / lessonTotals[category]) * 1000) / 10,
          };
        });
      });

      return response;
    }

    // adds height and 'minutes' to response
    function formatPracticeTime(response) {
      if (!response.data['practice_time']) {
        return response;
      }
      var results = response.data['practice_time'];
      const { practice_start_day, practice_start_month, practice_start_year } = response.data['requirements'];

      // add 'height' property to each day for visualization
      results.forEach(function (student) {
        // Add a start date and number of days, to be checked by the front end
        student.practice_time.forEach(function (day) {
          const minHeight = 2; // 2 px minimum
          // 26 is the pixel height of the bar.
          // numMinutesForFullHeight is the number of minutes needed for full height
          const numMinutesForFullHeight = 30.0;
          day.height = Math.min(26, Math.floor((day.minutes / numMinutesForFullHeight) * 26.0));
          if (day.height < minHeight) day.height = minHeight;
          day.heightStyle = { height: `${day.height}px` };
          // The date coming stored in the .date key is in the form of 'YYYY-MM-DD' ISO 8601 format (See Ruby's Date.to_s() method)
          // eg. '2020-01-02' is January 2, 2020. Javascript date initialization using date-only (as is case here)
          // uses Date.parse internally, but generates it using UTC time instead of local (see MDN)
          // A fix for that is to provide an expanded representation. eg. `2020-01-02T00:00:00`
          // The following will add a new key instead of override existing .date key
          day.dateObj = new Date(`${day.date}T12:00:00`);
          day.dateStr = $filter('date')(day.dateObj, 'fullDate');
        });

        // Add the requested practice start date if defined
        // Note: if any of start day, month, or year are defined, server will use a start date as a param
        // and will throw an error before this function is called if there is anything wrong with the date
        if (practice_start_day && practice_start_month && practice_start_year) {
          // Must make sure first day in the returned week is a monday, otherwise throw error
          const firstDayOfweek = student.practice_time[student.practice_time.length - 1].dateStr;
          if (firstDayOfweek.slice(0, 3) !== 'Mon') {
            throw new Error('Error: First day is not a Monday');
          }

          // This is used as an indicator by the view whether the practice days returned had a start date in requirements or not
          // Note: month and day are not padded, so not a valid ISO 8601 format. If needs to be used as a Date object, generate a js date using integer
          // values directly
          student.start_date = `${practice_start_year}-${practice_start_month}-${practice_start_day}`;
        }
      });

      return response;
    }

    // adds height and 'minutes' to response
    function formatDocumentProduction(response) {
      if (!response.data['document_production']) {
        return response;
      }
      var document_production = response.data['document_production'];

      var now = new Date();
      var permissions = document_production.permissions;
      permissions.forEach(function (permission) {
        permission.expires_on = new Date(permission.expires_on);
        // if more than 24 hours in the future, display the date instead
        // 86400000 = one day in ms
        permission.expiresOnDisplay = permission.expires_on > Date.now() + 86400000 ? 'MMM d' : 'shortTime';
        permission.inFuture = permission.expires_on.getTime() >= now.getTime();
      });
      // instantiate document submission objects to get access to methods
      var document_submissions = document_production.document_submissions.map(function (ds) {
        return new DocumentSubmission(ds);
      });

      // Assign document_submissions and permissions as first level objects in the response
      response.data['permissions'] = permissions;
      response.data['document_submissions'] = document_submissions;

      // assign permission and document_submissions to each student
      return response;
    }

    return this;
  },
]);
