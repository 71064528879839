app.factory('SectionHelpers', [
  '$q',
  '$timeout',
  function ($q, $timeout) {
    // This function generates an array of dates (Mondays, representing a given week) that are rendered in the instructor's gradesheet for instructor to choose from
    // Takes in a string representing the rails generated  creation date/time (ISO standard) of the current section, eg. "2020-10-21T16:26:38.953Z"
    // Second parameter is another stopping point, in case createdAtDate is too far back
    // Returns an array of Date objects that represent a Monday of a full week. Range of dates is from createdAt date OR 6 months ago, to previous Monday (of a full week) from today
    // eg. generatePracticeTimeWeekRanges('October 1, 2020', 24) => [new Date('19 Oct ,2020'), new Date('12 Oct, 2020'), new Date('5 Oct, 2020'), new Date('28 Sep, 2020'), new Date('21 Sep, 2020')]
    function generatePracticeTimeWeekRanges(createdAtDateString, maxWeeksBack = 6 * 4) {
      // 1. Variable declaration assignment and Error handling if date cannot be generated
      let result = []; // accumulator array of dates that this function returns
      // Convert the passed in date string to Js object and set the time to midnight, for easier comparison
      const createdAtDate = new Date(createdAtDateString);
      // Want to exit early if date is invalid
      if (isNaN(createdAtDate)) return result;

      createdAtDate.setHours(0, 0, 0, 0);

      // Get current date, adjusting for difference between server system time, and set the time to midnight, for easier comparison
      const todayDate = new Date(Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF);
      todayDate.setHours(0, 0, 0, 0);

      // Helper date (another stopping point, if createdAtDate is too far back)
      const maxPastDate = new Date(todayDate.getTime());
      maxPastDate.setMonth(todayDate.getMonth() - Math.round(maxWeeksBack / 4));
      maxPastDate.setHours(0, 0, 0, 0);

      // 2. Initiate the placeholder variable for the next calculated date, starting at today
      let currentDay = todayDate;

      // 3. Special case - if createdAtDate is less than a week ago from today's date,
      // then exit early, calculating the previous Monday based on createdAtDate (instead of today's date)
      // This is to cover the special case of today's date being a Monday for instance, while createdAtDate is a Sunday - a full Week will then be 2 mondays ago
      // (since the week that the Sunday belongs to is not technically complete)

      // Rules applying to all scenarios:
      // - 1 whole week prior to createdAtDate is included (eg. if it's a sunday, then the monday of the previous week is included)
      // - a full week is Monday to Sunday (end of day)

      // Examples of special scenarios (^ = created at, * = today):
      // --- Created at and current days are the same ---
      // M1   T   W   T   F   Sa   S   M2   T   W   T   F   Sa   S   M3*^ T   W   T   F   Sa   S
      //    => we should only go back to M2 as that is a complete week relative to both created at and today's date

      // --- Today's date is a monday and created_at is sunday (day before) ---
      // M1   T   W   T   F   Sa   S   M2   T   W   T   F   Sa   S^  M3*  T   W   T   F   Sa   S
      //    => we should include both M1 (a whole week relative to S^) and M2 (a whole week relative to M3)

      // --- Today's date is a tuesday and created_at is sunday (2 days before) ---
      // M1   T   W   T   F   Sa   S   M2   T   W   T   F   Sa   S^  M3   T*  W   T   F   Sa   S
      //    => we should include both M1 (a whole week relative to S^) and M2 (whole week relative to T*)

      // --- Today's date is a tuesday and created_at is a monday (1 day before) ---
      // M1   T   W   T   F   Sa   S   M2   T   W   T   F   Sa   S   M3^  T*  W   T   F   Sa   S
      //    => we should include M2 only

      // --- Today's date is a friday and created_at is previous friday (1 week before) ---
      // M1   T   W   T   F   Sa   S   M2   T   W   T   F^  Sa   S   M3   T   W   T   F*  Sa   S
      //    => we should include M1 and M2 only since M3 is not complete yet

      // --- Today's date is a friday and created_at is previous friday (1 week before) ---
      // M1   T   W   T   F   Sa   S   M2*  T   W   T   F   Sa   S   M3*  T   W   T   F   Sa   S
      //    => we should include M1 and M2

      // --- MORE THAN A WEEK (else statement)  ---
      // M1   T   W   T   F   Sa   S   M2^  T   W   T   F   Sa   S   M3   T   W   T   F*  Sa   S
      //    => we should include M1 and M2

      // --- MORE THAN A WEEK (else statement)  ---
      // M1   T   W   T   F   Sa   S^  M2   T   W   T   F   Sa   S   M3   T   W   T   F*  Sa   S
      //    => we should include M1 and M2 and a monday before M1 as well (because full monday before created at is not M1)

      if (getPreviousMonday(currentDay).getTime() <= createdAtDate.getTime()) {
        const prevMondayBasedOnCurrentDay = getPreviousMonday(currentDay);
        const prevMondayBasedOnCreatedAtDate = getPreviousMonday(createdAtDate);
        if (prevMondayBasedOnCreatedAtDate.toString() === prevMondayBasedOnCurrentDay.toString()) {
          result.push(getPreviousMonday(createdAtDate));
        } else {
          result.push(getPreviousMonday(currentDay));
          result.push(getPreviousMonday(createdAtDate));
        }
      } else {
        // 4. If createdAtDate is more than or equal to a week ago, then keep iterating backwards, getting the previous Monday until we hit either of the 2 stopping points:
        //  - createdAtDate
        //  - sixMonthsAgo

        // Safety net for while loop
        let _crashCounter = maxWeeksBack + 5;
        while (currentDay.getTime() > createdAtDate.getTime() && currentDay.getTime() > maxPastDate.getTime()) {
          // Early stop if loop doesn't properly stop
          if (_crashCounter < 0) break;

          currentDay = getPreviousMonday(currentDay);
          result.push(currentDay);
          // Decrement the _crashCounter
          _crashCounter--;
        }

        // 5. Add one more week before createdAtDate (so instructors have options to see practice time before section was created)
        result.push(getPreviousMonday(currentDay));
      }

      // Extract the day, month and year into its own keys (used for sending request to backend using Statistics service)
      // the dateObj key is a js Date object, which is used by angular in the view to show formatted dates
      return result.map((dateObj) => {
        return {
          dateObj: dateObj,
          day: dateObj.getDate(),
          month: dateObj.getMonth() + 1,
          year: dateObj.getFullYear(),
        };
      });
    }

    // Helper function to get the previous Monday before a provided date. If current date is a Monday, return Monday from last week
    // If current date is a Sunday, return a full week back, as the week the Sunday is part of is not yet complete
    // This function takes in a Date object, relative to which the previous Monday will be calculated
    // Returns a Date object
    function getPreviousMonday(dateObj) {
      // Note: using setDate instead of just subtracting milliseconds, as this relies on internal js Date functionality,
      // and takes care of daylight savings for us

      // Determine current day of the week. Sunday = 0, Saturday = 6
      const dayOfWeek = dateObj.getDay();
      let dateObjCopy = new Date(dateObj.getTime()); // Make a copy of the passed in date object
      // Modify the date object copy
      dateObjCopy
        // Subtract current day of week + a week back, to get the last Monday (adding 6 because day of week for Monday is 1)
        // If current day is Sunday, this week is incomplete and have to go back an entire week back
        .setDate(dateObjCopy.getDate() - (dayOfWeek === 0 ? dayOfWeek + 6 + 7 : dayOfWeek + 6));
      return dateObjCopy;
    }

    // returns a promise that resolves after ms
    // Used by section factory in a $q.all call to create a minimum load time
    function minDelay(ms) {
      var deferred = $q.defer();
      $timeout(() => deferred.resolve(), ms);
      return deferred.promise;
    }

    return {
      generatePracticeTimeWeekRanges,
      minDelay,
    };
  },
]);
