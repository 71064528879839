/* global SERVER_TIME_SYSTEM_TIME_DIFF */
import { getDatabase, ref, query, limitToLast, onValue, off, update } from 'firebase/database';
import { firebaseNotificationsObjToArray } from '../../../components/Navigation/Notifications/FirebaseNotificationHelpers';
// Right now:
// notifications/users/2/...
//  => Land in the users 'notification' dropdown
//
// notifications/sections/12/...
//  => No longer in firebase, we send these notifications via notifications/users/2/...
//
// notifications/global/....
//  => Not used at the moment
//
// ... other stuff

app.controller('NavController', [
  '$scope',
  '$rootScope',
  'Auth',
  'Auth2',
  '$state',
  '$timeout',
  function ($scope, $rootScope, Auth, Auth2, $state, $timeout) {
    const db = getDatabase();

    //
    $scope.notifications = [];
    $scope.view = {
      // has a user clicked to view the active notifications?
      // each notification has a 'viewed: true' property added in the turnOffAlert function
      // that is activated when they click to see their notifications
      viewedNotifications: true,
    };
    let notificationPromises = [];

    // -------------------------------------------------------------------
    // Notifications ----------------------------------------------------
    // -------------------------------------------------------------------
    // On-click event for notifications in the notification dropdown
    $scope.redirectToActivity = function (notification) {
      // note: this method was a cluster fuck to make when I was building this for contests (took me 2 days)
      //         the challenge was that the notification id (in firebase's "notifications" folder) and the contest id
      //         (in firebase's "contests" folder) were not the same, and I need the controller to get the contest by id.
      //         HOWEVER, this proved a problem since if it was the notifcation id in the URL, refreshes would try to find the contest
      //         by that id.
      //         In short, if you change the line below, TEST EVERYTHING (test, contests, refreshing, etc). and consider just adding
      //         a new parameter like I did below with contest_id.
      return $state
        .go(notification.redirectState, {
          notification: notification,
          notification_id: notification.firebaseKey,
          contest_id: notification.contestFirebaseId,
          document_assignment_id: notification.document_assignment_id,
        })
        .catch(() => {
          // If permission referenced in notification does not exists then display an error alert message
          alert('Your instructor has removed this activity.');
        });
    };

    // New code to handle notifications
    let firebaseUser;
    let notificationsQuery;
    Auth2.currentUser().then((user) => {
      const userId = user.id;
      firebaseUser = ref(db, `notifications/users/${userId}`);

      notificationsQuery = query(firebaseUser, limitToLast(7));
      onValue(notificationsQuery, (snapshot) => {
        // need to applyAsync since angularjs doesn't monitor non-angularjs async code. We use
        // applyAsync instead of just apply to avoid clashes with digest cycle (and errors we
        // were experiencing)
        $scope.$applyAsync(() => {
          cancelNotificationPromises();

          $scope.notifications = (firebaseNotificationsObjToArray(snapshot.val(), SERVER_TIME_SYSTEM_TIME_DIFF) || []).sort(
            function (a, b) {
              return b.createdAt - a.createdAt;
            }
          );

          setActiveNotificationsToExpire($scope.notifications);

          // When a user clicks on notifications:
          $scope.view.viewedNotifications = !$scope.notifications.some(
            (notification) => !notification.viewed && !notification._expired
          );
        });
      });
    });

    // Turn off "You have a new notification!" popup after a user views the notifications
    $scope.turnOffAlert = () => {
      if (!firebaseUser) return;
      const unviewedNotifications = $scope.notifications.filter((notification) => !notification.viewed && !notification._expired);
      const updates = unviewedNotifications.reduce((acc, notification) => {
        return { ...acc, [`${notification.firebaseKey}/viewed`]: true };
      }, {});

      return update(firebaseUser, updates);
    };

    // -------------------------------------------------------------------
    // -------------------------------------------------------------------
    function cancelNotificationPromises() {
      if (notificationPromises.length < 1) return true;
      // cancel any existing promises
      notificationPromises.forEach((p) => $timeout.cancel(p));
    }

    // determine how long each notification has until it expires and then set its
    // _expired property to true at that time.
    function setActiveNotificationsToExpire(notifications) {
      cancelNotificationPromises();

      const now = new Date().getTime() - SERVER_TIME_SYSTEM_TIME_DIFF;
      // assign all the notification promises to the variable so we can destroy them
      notificationPromises = notifications
        .filter((notification) => !notification._expired)
        .map((notification) => {
          const msToExpiry = notification.expiresOnDate - now;
          return $timeout(() => {
            const index = $scope.notifications.findIndex((n) => n.firebaseKey === notification.firebaseKey);
            $scope.notifications[index]._expired = true;
          }, Math.max(msToExpiry, 0));
        });
    }

    // remove any promise notifications that are around
    $scope.$on('$destroy', () => {
      if (firebaseUser) off(firebaseUser);
      if (notificationsQuery) off(notificationsQuery);
      cancelNotificationPromises();
    });

    // -------------------------------------------------------------------
    // User Authentication/Devise ----------------------------------------
    // -------------------------------------------------------------------
    $scope.signedIn = Auth.isAuthenticated;
    $scope.logout = Auth.logout;

    Auth2.currentUser().then(
      function (user) {
        $rootScope.user = user;
      },
      function () {}
    );

    // Add event listeners to handle when the user logs in or out
    $scope.$on('devise:new-registration', function (e, user) {
      $rootScope.user = user;
    });

    $scope.$on('devise:login', function (e, user) {
      $rootScope.user = user;
    });

    $scope.isExpired = function (date) {
      return date < new Date();
    };
    // -------------------------------------------------------------------
    // -------------------------------------------------------------------
  },
]);
