// Provides help functions to the Permission service.
//   should store no data
app.service('PermissionsHelperFunctions', [
  '$http',
  function ($http) {
    /************************
     *   Define constants (user `const` if using Babel)
     ***********************/
    // Constants to better keep constitency across the controller and views and to faster spot errors
    var constants = {
      // TEST_NEW : 'TEST_NEW',
      TEST_IN_PROGRESS: 'TEST_IN_PROGRESS',
      TEST_COMPLETE: 'TEST_COMPLETE',
      ACCURACY_METRIC_ACCURACY: 'ACCURACY_METRIC_ACCURACY',
      ACCURACY_METRIC_MAX_ERRORS: 'ACCURACY_METRIC_MAX_ERRORS',
      TEST_TYPE_NET_WPM: 'TEST_TYPE_NET_WPM',
      TEST_TYPE_GROSS_WPM: 'TEST_TYPE_GROSS_WPM',
    };

    this.constants = constants;

    this.filterPermissionsByStatus = function (proctoredTests, status) {
      return proctoredTests.filter(function (test) {
        return test.status === status;
      });
    };

    this.reshapePermissions = function (proctoredTests) {
      if (proctoredTests.length == 0) return [];

      return proctoredTests.map(function (permission) {
        var accuracy;
        var test_type;

        switch (permission.options.type) {
          case 'netWPM':
            test_type = {
              type: constants.TEST_TYPE_NET_WPM,
              value: 'Goal to achieve ' + permission.options.speed + ' netWPM',
            };
            break;
          case 'grossWPM':
            test_type = {
              type: constants.TEST_TYPE_GROSS_WPM,
              value: 'Goal to achieve ' + permission.options.speed + ' grossWPM',
            };
            break;
          default:
        }

        switch (permission.options.accuracy_metric) {
          case 'accuracy':
            accuracy = {
              type: constants.ACCURACY_METRIC_ACCURACY,
              value: permission.options.accuracy + '%',
            };
            break;
          case 'max_errors':
            accuracy = {
              type: constants.ACCURACY_METRIC_MAX_ERRORS,
              value: 'max. ' + permission.options.max_errors + ' errors',
            };
            break;
          default:
        }

        // Return a new object so it is easy to see how it is mapped to html side and only necessary info is passed through
        return {
          title: permission.action,
          speed: permission.options.speed,
          bestAttempt: calculateBestAttemptOnTest(permission),
          duration_in_seconds: permission.options.duration_in_seconds,
          max_attempts: permission.options.max_attempts,
          expires_on: permission.expires_on,
          accuracy: accuracy,
          test_type: test_type,
          status: calculateStatus(permission),
          notification_id: permission.options.notification_id, // used in ng-click to refer user to proper test
          created_at: permission.created_at,
        };
      });
    };

    function calculateBestAttemptOnTest(permission) {
      if (permission.activities.length === 0) return;

      var type = permission.options.type; // "netWPM" or "grossWPM"
      var accuracyMetric = permission.options.accuracy_metric == 'accuracy' ? 'accuracy' : 'errors_nonbackspaced'; // "accuracy" or "errors"
      var accReqs = permission.options[accuracyMetric];
      var speedReqs = permission.options.speed;

      var bestAttempt = permission.activities[0];
      var activity;
      for (var i = 1; i < permission.activities.length; i++) {
        bestAttempt = betterAttempt(permission, bestAttempt, permission.activities[i]);
      }
      // Just an example of how html is currently wired.
      return {
        netWPM: bestAttempt.netWPM,
        grossWPM: bestAttempt.grossWPM,
        accuracy: bestAttempt.accuracy,
        errors: bestAttempt.errors_nonbackspaced,
      };
    }

    // determines which attempt (activity) was better
    function betterAttempt(permission, activity1, activity2) {
      var type = permission.options.type; // "netWPM" or "grossWPM"
      var accuracyMetric = permission.options.accuracy_metric; // "accuracy" or "max_errors"
      var accReqs = permission.options[accuracyMetric];
      var speedReqs = permission.options.speed;

      // Check if they passed with the current activity.
      if (!activity1.data.pass && activity2.data.pass) {
        return activity2;
      } else if (activity1.data.pass && !activity2.data.pass) {
        return activity1;
      } else if (activity1.data.pass && activity2.data.pass) {
        // If they both passed, take the one with the higher speed
        return activity1[type] >= activity2[type] ? activity1 : activity2;
      } else if (!activity1.data.pass && !activity2.data.pass) {
        activity1.passedAccuracy = activityPassedAccuracy(permission, activity1);
        activity2.passedAccuracy = activityPassedAccuracy(permission, activity2);
        // if the both passed accuracy, take the highest speed (since they both failed speed)
        if (activity1.passedAccuracy && activity2.passedAccuracy) {
          if (Number(activity1[type]) >= Number(activity2[type])) {
            return activity1;
          } else {
            return activity2;
          }
        } else {
          // Other wise, take the one with the highest accuracy
          // TODO: Make this work with errors
          var activityAttr = accuracyMetric === 'max_errors' ? 'errors_nonbackspaced' : accuracyMetric;
          if (Number(activity1[activityAttr]) >= Number(activity2[activityAttr])) {
            // activity1 has a bigger accuracy or bigger number of errors. So, if it's accuracy return activity1, but if it's errors return activity2
            return accuracyMetric === 'accuracy' ? activity1 : activity2;
          } else {
            return accuracyMetric === 'accuracy' ? activity2 : activity1;
          }
        }
      }
    }

    // determines if an activity achieved the minimum accuracy
    function activityPassedAccuracy(permission, activity) {
      var accuracyMetric = permission.options.accuracy_metric; // "accuracy" or "max_errors"
      var minAccuracy = permission.options[accuracyMetric]; // either "90" or "3" for errors
      if (accuracyMetric === 'accuracy') {
        return activity.accuracy >= Number(permission.options.accuracy);
      }
      if (accuracyMetric === 'max_errors') {
        return activity.errors_nonbackspaced <= Number(permission.options.max_errors);
      }
    }

    function calculateStatus(permission) {
      // Return either one of the constants. Used switch statements as just an example:
      // TODO: If the test has not expired:
      //          a. Set a timeout to change the status of this test for when it does expire.
      //          b. When the controller goes out of scope, destroy this timeout so that we don't add multiple when going in/out of this state.
      var expired = new Date(permission.expires_on) < new Date();
      var allAttemptsComplete = permission.options.max_attempts == permission.activities.length;

      // we'll figure this out later
      switch (expired || allAttemptsComplete) {
        case true:
          // test has expired
          // Calculation for COMPLETE
          return constants.TEST_COMPLETE;
        default:
        case false:
          // Calculation for IN_PROGRESS
          return constants.TEST_IN_PROGRESS;
      }
    }

    function deCamelizeTitle(title) {
      if (title) {
        return title
          .replace(/([A-Z])/g, ' $1')
          .split(/\s+/g)
          .filter(function (val) {
            return val;
          })
          .map(function (word) {
            return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
          })
          .join(' ');
      }
    }

    // TODO: define function in a single declaration. Currently separated for quick testing
    this.deCamelizeTitle = deCamelizeTitle;

    // TODO: Move testing to a separate file or into a dedicated frontent testing framework
    // function testDeCamelizeTitle(input, expected) {
    //   var output = deCamelizeTitle(input);
    //   console.log("Testing input:", "'" + input + "'");
    //   console.assert(output === expected, {input, expected, output});
    // }

    // console.log("If you are seeing this, the tests should be moved to a testing framework or deleted from permissions/permission_helper_functions.js")
    // testDeCamelizeTitle("CamelCaseTitle", "Camel Case Title");
    // testDeCamelizeTitle("camelCaseTitle", "Camel Case Title");
    // testDeCamelizeTitle("notcamelcasetitle", "Notcamelcasetitle");
    // testDeCamelizeTitle("title with spaces already", "Title With Spaces Already");
    // testDeCamelizeTitle("title with CamelCase title", "Title With Camel Case Title");
    // testDeCamelizeTitle("title with moreCamelCase title", "Title With More Camel Case Title");
    // testDeCamelizeTitle("title \t with moreCamelCase           title", "Title With More Camel Case Title");
    // testDeCamelizeTitle("", undefined);
    // testDeCamelizeTitle(undefined, undefined);
  },
]);
