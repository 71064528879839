import { angularStates } from '../../statesUrls';

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'AuthInterceptProvider',
  function ($stateProvider, $urlRouterProvider, AuthInterceptProvider) {
    $stateProvider

      .state('app.keyboarding.certificates/view', {
        url: angularStates['app.keyboarding.certificates/view'],
        templateUrl: 'certificates/_view_certificate.html',
        controller: 'StudentCertificatesController',
        resolve: {
          current_user: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.instructor.certificates/create', {
        url: '/certificates/create',
        templateUrl: 'certificates/_instructor_certificates.html',
        controller: 'InstructorCertificatesController',
        params: {
          students: [],
        },
        resolve: {
          current_user: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      });
  },
]);
