app.factory('SigninFactory', [
  'Auth2',
  '$q',
  'AnimatedLoginKeyboardFactory',
  '$timeout',
  function (Auth2, $q, AnimatedLoginKeyboardFactory, $timeout) {
    // Exposes the following variables to initiate sign in process:
    //  this.validations {username: "message", password: ""}
    var SigninFactory = function () {
      var self = this;
      self.keyboard = new AnimatedLoginKeyboardFactory('#loading-keyboard-auth');
      this.failedSignInAttempts = 0;

      // sign_in method is the main point of interaction with this class.
      //  returns a resolved promise if successful
      //  rejects a promise if not
      this.sign_in = function (user) {
        self.validations = {};
        self.progressMessage = '';
        self.inProgress = true;
        self.isSuccessful = null;

        return localValidations(user)
          .catch(function (validations) {
            self.inProgress = false;
            return $q.reject(validations);
          })
          .then(serverValidations)
          .then(credentialVerified);
      };

      // Performs local validations on a user object.
      //  returns a rejected promise if the validations fail
      function localValidations(user) {
        var response = $q.defer();

        // Validations
        if (user === undefined) {
          self.validations.username = 'Please enter a username and password.';
          response.reject(self.validations);
        } else if (user.username === undefined) {
          self.validations.username = 'Please enter your username.';
          response.reject(self.validations);
        } else if (user.username.length < 6) {
          self.validations.username = 'Please enter a username that is at least 6 characters.';
          response.reject(self.validations);
        } else if (user.password === undefined) {
          self.validations.password = 'Please enter your password.';
          response.reject(self.validations);
        } else if (user.password.length < 6) {
          self.validations.password = 'Please enter a password that is at least 6 characters.';
          response.reject(self.validations);
        } else {
          response.resolve(user);
        }

        return response.promise;
      }

      // Authenticate the user
      function serverValidations(user) {
        // animate the keyboard
        self.keyboard.animate(220);

        // returns a promise that will be resolved in 2000 ms
        var displayMessage = progressMessage('Verifying login credentials..', 2000);

        // Should call the server now...
        var credentials = { login: user.username, password: user.password, remember_me: 1 };
        var loginPromise = Auth2.login(credentials);
        // .catch(function() { debugger; });

        return $q
          .all([loginPromise, displayMessage])
          .catch(function (response) {
            // will be called as soon as loginPromise is rejected.
            //  wait for the displayMessage to finish and then continue
            return displayMessage.then(function () {
              return serverValidationError(response, { username: credentials.login, password: credentials.password });
            });
          })
          .then(function (response) {
            return response[0];
          });
      }

      // Catches server errors, returns a rejected promise
      //  Two scenarios:
      //    1. Invalid credentials. Reset.
      //    2. Account has expired. Redirect to payments.
      function serverValidationError(res, credentials) {
        var response = res.data;
        self.failedSignInAttempts += 1;

        if (
          response.error.indexOf('subscription expired') > -1 ||
          response.error.indexOf('SUMMERPASS') > -1 ||
          response.error.indexOf('pilot program') > -1
        ) {
          return $timeout(function () {}, 2500)
            .then(function () {
              self.keyboard.stopKeyboard = true;
              self.isFailure = true;
              self.keyboard.showKeyboard = false;
              return progressMessage(response.error, 3500);
            })
            .then(function () {
              return progressMessage('Redirecting to payments.', 3000);
            })
            .then(function () {
              // REDIRECT TO PAYMENTS HERE
              reset();
              return $q.reject({ reason: 'AccountExpired', userCredentials: credentials });
            });
        } else {
          const isUsingUsername = !(credentials.username.indexOf('@') > -1);
          // failed to validate email/password
          return $timeout(function () {}, 2500).then(function () {
            self.keyboard.stopKeyboard = true;
            self.isFailure = true;
            self.keyboard.showKeyboard = false;
            if (isUsingUsername) {
              self.progressMessage = 'Invalid username and password.';
              self.progressMessage += ' Sign in using your email address.';
            } else {
              self.progressMessage = 'Invalid email and password.';
            }
            // If the user has 3 or more failed login attempts
            let errorMessageDuration = isUsingUsername ? 5000 : 3000;
            if (self.failedSignInAttempts > 1) {
              self.progressMessage += " Click 'Forgot Password' to reset your password.";
              errorMessageDuration += 4000;
            }
            return $timeout(function () {
              // self.validations.password.push("Username and/or password is incorrect.");
              reset();
              return $q.reject('Invalid username and password.');
              // sequence.serverValidationsError();
            }, errorMessageDuration);
          });
        }
      }

      function reset() {
        self.progressMessage = '';
        self.inProgress = false;
        self.isFailure = false;
      }

      // does some animations, returns the user
      function credentialVerified(user) {
        return progressMessage('Credentials verified.', 900)
          .then(function () {
            return progressMessage('Creating learning environment..', 2000);
          })
          .then(function () {
            self.keyboard.stopKeyboard = true;
            self.isSuccessful = true;
            return progressMessage('Login complete.', 1400);
          })
          .then(function () {
            var message = 'Welcome back, ' + user.first_name + '.';
            self.keyboard.showKeyboard = false;
            return progressMessage(message, 2200).then(function () {
              return user;
            });
          });
      }

      // sets progress message, waits until delay is finished
      // returns a promise
      function progressMessage(message, delayAfter) {
        delayAfter = delayAfter || 0;
        self.progressMessage = message;
        return $timeout(function () {}, delayAfter);
      }
    }; // end of class

    return SigninFactory;
  },
]);
