app.factory('AdminContactsDirectoryContact', [
  function () {
    // Class wrapper for a contact object
    // contact param example: { firstName: 'Bob', lastName: 'Ross', }
    function Contact(contact) {
      // Copy over all the contact properties to this instance
      this.assignAllPropertiesToSelf(contact);
    }

    // Copy over the properties of the passed in object directly onto 'this'
    Contact.prototype.assignAllPropertiesToSelf = function (props) {
      var self = this;
      for (var prop in props) {
        if (props.hasOwnProperty(prop)) {
          self[prop] = props[prop];
        }
      }
    };

    // Copy contact representation to clipboard (with soft returns to be used in microsoft word)
    Contact.prototype.copyToClipboard = function () {
      // Special characters
      const hardReturnChar = String.fromCharCode(10);
      const softReturnChar = String.fromCharCode(11);

      // Contact properties
      const titleAndName = `${this.title ? this.title + ' ' : ''}${this.firstName} ${this.lastName}${softReturnChar}`;
      // Not all contacts have a company name
      const companyName = this.company ? `${this.company}${softReturnChar}` : '';
      const address = `${
        this.apartmentSuite ? this.streetAddress + ', ' + this.apartmentSuite : this.streetAddress
      }${softReturnChar}`;
      // Make sure there are 2 spaces before postal code
      const cityAndPostalCode = `${this.city}, ${this.provinceAbbr}  ${this.postalCode}`;

      // Generate the text to be copied
      // Make sure it is similar to what is displayed in view
      const text = `${titleAndName}${companyName}${address}${cityAndPostalCode}`;
      const blob = new Blob([text], { type: 'text/plain' });
      const data = [new ClipboardItem({ 'text/plain': blob })];

      // Write the text to clipboard
      navigator.clipboard.write(data).then(
        () => {
          // Copied successfully
          alert('Copied successfully');
        },
        (err) => {
          // Did not copy successfully
          alert('Could not copy to clipboard');
        }
      );
    };

    return Contact;
  },
]);
