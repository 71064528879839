app.factory('UsernamePasswordRegFactory', [
  '$http',
  '$q',
  'AnimatedLoginKeyboardFactory',
  '$timeout',
  'Auth',
  'Auth2',
  function ($http, $q, AnimatedLoginKeyboardFactory, $timeout, Auth, Auth2) {
    // contains helper methods for the registration process
    var UsernamePasswordRegFactory = function () {
      var self = this;
      this.validations = {};
      this.keyboard = new AnimatedLoginKeyboardFactory('#loading-keyboard-auth');
      this.view = { inProgress: false, progressMessage: null, isFailure: false, isSuccessful: false };

      this.validateAvailabilityOfUsername = function (user) {
        self.validations = {};
        return localValidations(user).then(serverValidations);
      };

      this.registerInstructor = function (user) {
        self.validations = {};
        self.user = user;

        return localValidations(user).then(serverValidations).then(registerNewInstructor).then(loginUser);
      };

      // Local validations
      //  returns a rejected promise on failure
      //  returns a resolved promise with the user object on success
      function localValidations(user) {
        // Validations
        if (user === undefined) {
          self.validations.username = 'Please enter a username and password.';
          return $q.reject(self.validations);
        }
        if (user.username === undefined) {
          self.validations.username = 'Please enter your username.';
          return $q.reject(self.validations);
        }
        if (user.username.length < 6) {
          self.validations.username = 'Please enter a username that is at least 6 characters.';
          return $q.reject(self.validations);
        }
        if (user.username.indexOf(' ') != -1) {
          self.validations.username = 'Your username cannot contain spaces (only letters and numbers).';
          return $q.reject(self.validations);
        }
        if (user.password === undefined) {
          self.validations.password = 'Please enter your password.';
          return $q.reject(self.validations);
        }
        if (user.password.length < 6) {
          self.validations.password = 'Please enter a password that is at least 6 characters.';
          return $q.reject(self.validations);
        }
        if (user.passwordConfirmation === undefined) {
          self.validations.passwordConfirmation = 'Please confirm your password.';
          return $q.reject(self.validations);
        }
        if (user.password !== user.passwordConfirmation) {
          self.validations.passwordConfirmation = 'Your password confirmation must match your password.';
          return $q.reject(self.validations);
        }

        return $q.resolve(user);
      }

      // Validate availability of username, and that passwords match
      function serverValidations(user) {
        self.view.inProgress = true;
        self.keyboard.animate(220);
        self.view.progressMessage = 'Checking availability of username.';

        // 3500 delay on success. Failure delay is in serverValidationsError
        var waitOnSuccess = $timeout(function () {
          return $q.resolve();
        }, 3500);

        // Should call the server now...
        var credentials = { username: user.username, password: user.password, password_confirmation: user.passwordConfirmation };
        var validateUsernameWithServer = $http.post('users/validate_username.json', { user: credentials });

        return $q
          .all([user, validateUsernameWithServer, waitOnSuccess])
          .then(usernameAvailableMessage)
          .catch(serverValidationsError);
      }

      function usernameAvailableMessage(resolves) {
        const user = resolves[0];
        if (user.instructor) {
          self.view.progressMessage = 'Username Available!';
        } else {
          self.view.progressMessage = 'Username Available. Continuing registration...';
        }
        self.keyboard.stopKeyboard = true;
        self.keyboard.showKeyboard = false;
        self.view.isSuccessful = true;
        return $timeout(function () {
          return $q.resolve();
        }, 3000);
      }

      // Handle a failed server request
      //  return a rejected promise
      function serverValidationsError(response) {
        var responseData = response.data;

        return $timeout(function () {
          self.view.progressMessage = null;
          self.view.isFailure = true;

          if (responseData.username) {
            self.validations.username = 'Username ' + responseData.username;
          }
          if (responseData.password) {
            self.validations.password = 'Password ' + responseData.password;
          }
          if (responseData.password_confirmation) {
            self.validations.passwordConfirmation = 'Password confirmation ' + responseData.password_confirmation;
          }
          self.keyboard.stopKeyboard = true;
          // scope.progressMessage.splice(0, scope.progressMessage.length);
          // scope.progressMessage.push("Invalid username and password.")
          return $timeout(function () {
            self.keyboard.showKeyboard = false;
            self.view.inProgress = false;
            self.view.isFailure = false;
            return $q.reject();
          }, 2000);
        }, 2500);
      }

      // registers a new instructor
      function registerNewInstructor() {
        var user = self.user;
        // Should call the server now...
        var credentials = {
          username: user.username,
          password: user.password,
          password_confirmation: user.passwordConfirmation,
          instructor: user.instructor,
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          organization_id: user.organization,
          confirm_email: user.confirmEmail,
          access_code: user.access_code,
        };
        if (user.studentNumber) {
          credentials.student_number = user.studentNumber;
        }
        self.user = credentials;

        return Auth.register(credentials).catch(function (response) {
          self.progressMessage = 'Error creating account. Please contact Typist for support @ support@typistapp.ca.';
          console.log(response);
          return $q.reject();
        });
      }

      // sign_in the instructor
      function loginUser() {
        var credentials = { login: self.user.username, password: self.user.password, remember_me: 1 };
        return Auth2.login(credentials);
      }
    }; // end of class

    return UsernamePasswordRegFactory;
  },
]);
