// Essentially a subclass of HomeRowTech. Adds some view helpers.
//  -> manages keydown events
//  -> adds view helpers for the modal
//  -> tracks completions through UserSettings and CacheService

// This function shows how to activate the modal:
//  function activateHomeRowTech() {
//    $scope.homeRowTechModal = new HomeRowTechModal({
//      scope: $scope,              // keydown listener is attached to this scope (so it will be auto-destroyed with scope)
//      listenerTimeout: 200,       // necessary to prevent transition issue where HRT jumped straight to instructions instead of hands
//      sequence: null,             // || "leftToRight", ..., defined in factory.
//      numberOfFingersToVerify: 5, // || >2
//      activateNow: true           // specific to the modal
//    });
//    $scope.homeRowTech = $scope.homeRowTechModal.homeRowTech;
//  }

app.factory('HomeRowTechModal', [
  'HomeRowTech',
  '$timeout',
  '$rootScope',
  'CacheService',
  'UserSettings',
  function (HomeRowTech, $timeout, $rootScope, CacheService, UserSettings) {
    // uses the same options as HomeRowTech
    var HomeRowTechModal = function (options) {
      // default options
      options = options || {};
      // scope is required to attach the event listener to, so that it gets cleaned up with the scope
      if (!options.scope) throw 'HomeRowTechModal requires a scope to attach to. Should not be $rootScope.';
      options.activateNow = options.activateNow || false;
      options.sequence = options.sequence || null;
      options.numberOfFingersToVerify = options.numberOfFingersToVerify || 8;
      // options.skinTone = options.skinTone;

      // how long to show the green check before advancing
      this.delayAfterSuccess = options.delayAfterSuccess || 2000;

      // attach a homerowtech object
      this.homeRowTech = new HomeRowTech(options);
      // the event listener will be attached to this scope:
      this.scope = options.scope;
      addViewHelpersTo(this.homeRowTech);
      this.callback = addCallbackTo(this.homeRowTech, this);
      this.keydownListener = null;
      this.homeRowTech.showButton = true;

      if (options.activateNow) this.activate();
    };

    // public methods ---------------------------------------------------------------------------------------
    // a promise that resolves when the HomeRowTech completes
    HomeRowTechModal.prototype.onComplete = function () {
      return this.callback;
    };

    // toggles the view from the hands to the instructions
    HomeRowTechModal.prototype.toggleInstructions = function () {
      this.showInstructions = !this.showInstructions;
    };

    // registers the keydown listener.
    HomeRowTechModal.prototype.activate = function () {
      var self = this;
      this.keydownListener = this.scope.$on('keydown', function (a, e) {
        if (e.key_pressed === ' ') {
          window.scrollTo(0, 0); // prevents a small auto-scroll that's annoying when switching between instructions
          self.homeRowTech.reset(); // just reset the homerowtech (ie don't count it as an error)
          self.toggleInstructions();
        } else if (!self.homeRowTech.keydown(e)) {
          self.reset(); // if the keypress is incorrect

          // if the keypress was a capital letter, warn the user that their cap-lock may be on
          if ((e.key_pressed !== e.key_pressed_lowcase && e.key_pressed.length === 1) || e.key_pressed === 'CapsLock') {
            self.showCaplockWarning = true;
          } else {
            self.showCaplockWarning = false;
          }
        } else {
          // it's correct...
          self.showCaplockWarning = false;
        }
      });
    };

    // deregisters the event listener, stored in keydownListener (as a function)
    HomeRowTechModal.prototype.deactivate = function () {
      if (this.keydownListener !== null) {
        this.keydownListener();
        this.keydownListener = null;
      }
    };

    // resets the process
    HomeRowTechModal.prototype.reset = function () {
      var self = this;
      this.homeRowTech.reset();
      $timeout(function () {
        self.homeRowTech.status = 'progress-warning';
      }, 0).then(
        $timeout(function () {
          self.homeRowTech.status = 'progress-default';
        }, 300)
      );
    };

    // private methods -------------------------------------------------------------------------------------
    // adds variables that are used in the view
    function addViewHelpersTo(self) {
      // default options for modal CSS
      self.status = 'progress-default'; // this is the flashbar underneath the hands
      self.showInstructions = false;
    }

    // animates the complete sequence, broadcasts the completion
    function addCallbackTo(homeRowTech, self) {
      // When HomeRowTech completes
      //  do these things:
      return homeRowTech.onComplete
        .then(function () {
          self.deactivate(); // unloads the event listener
          self.homeRowTech.status = 'progress-success';
          updateStatusButton('success', self); // shows the big green checkmark, fade opacity
          return $timeout(function () {
            return true;
          }, self.delayAfterSuccess);
        })
        .then(function () {
          // update the CacheService & UserSettings for SkipHomeRowTechModal
          var obj = { lastCompletedAt: Date.now() };
          CacheService.set(obj, 'homeRowTech');
          UserSettings.set('homeRowTech', obj);

          self.reset(); // reset the view and HomeRowTech object

          updateStatusButton('reset', self);
          $rootScope.$broadcast('homeRowTech:modalComplete');
        });
    }

    // shows the big green checkmark at the end
    function updateStatusButton(status, self) {
      if (status === 'success') {
        $('#homerowtech-hands-image').fadeTo(400, 0.7); // fade the hands to 70% opacity
        $('.status-button').show();
        self.displaySuccessButton = true;
        self.displayFailureButton = false;
      } else if (status === 'failure') {
        $('.status-button').show();
        self.displaySuccessButton = false;
        self.displayFailureButton = true;
      } else if (status === 'reset') {
        $('.status-button').hide();
        $('#homerowtech-hands-image').css({ opacity: 1 });
      }
      return;
    }

    return HomeRowTechModal;
  },
]);
