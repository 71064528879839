// LessonModalController
//
// Launches a modal automatically when loaded.
// Listens for the following events:
//  -> lessonModals:start        : launches a new modal sequence (such as when a lesson completes)
//  -> homeRowTech:modalComplete : indicates that the homeRowTech modal completes (this is needed for HomeRowTech to work)
//
// On complete, broadcasts:
//  -> lessonModals:complete : modals are all finished
//
// -------
// Implementation details:
//  -> handles the keydown events if they're required to advance lessons
//  -> HomeRowTech requires a few extra methods to be included
//  -> keydown listener is activated on lessonModals:start broadcast, and is
//        deregistered when the modal completes, or controller goes out of scope
//
// HomeRowTech Modal Integration
//  To install HomeRowTech on a controller, the following are required:
//    a. HomeRowTech object must be initiated at assigned to scope: $scope.homeRowTech = new Home....
//    b. A keydown event to handle the process (see listener below)
//    c. When the homeRowTech modal completes successfully, it triggers a callback. A callback needs to be bound to advance
//          the modal sequence: modalSequence.next()

app.controller('LessonModalController', [
  '$scope',
  '$rootScope',
  'ModalSequence',
  'ModalSequenceArray',
  'HomeRowTechModal',
  '$state',
  'SkipHomeRowTechModal',
  function ($scope, $rootScope, ModalSequence, ModalSequenceArray, HomeRowTechModal, $state, SkipHomeRowTechModal) {
    const currentUser = $scope.$parent.currentUser;

    // Instantiate our sequences. First ModalSequences will be auto-started below
    var modals = [];
    modals.push(new ModalSequence('#lessons-welcome-modal-sequence', { modalNames: ['welcomeModal', 'homeRowTechModal'] }));
    modals.push(new ModalSequence('#lesson-complete-but-no-star', { modalNames: ['noStarModal', 'homeRowTechModal'] }));
    modals.push(new ModalSequence('#lesson-complete-earned-a-star', { modalNames: ['earnedStarModal', 'homeRowTechModal'] }));

    // start the first modalSequence
    var modalSequences = new ModalSequenceArray(modals);

    // attach the event listener and activate modal
    var listenerDestroyer = activateListener();
    modalSequences.getByIndex(0).start(listenerDestroyer);

    // Called from LessonController.
    //   -> data must contain a modalSelector attribute with a string as to which modal to watch.
    //         the modal names are associated with objects below in the switch statement.
    $scope.$on('lessonModals:start', function (a, data) {
      listenerDestroyer = activateListener();
      modalSequences.getBySelector(data.modalSelector).start(listenerDestroyer);
    });

    // keydown event handler
    //  -> this is the engine of this controller.
    //  -> reacts to keypresses, and defines criteria to advance
    //   -> returns the $destroy event
    var activeModal, activeModalName;
    function activateListener() {
      return $scope.$on('keydown', function (a, e) {
        // exit unless there's an active modal sequence
        if (!modalSequences.isActive()) return;

        // get the activeModalName
        activeModal = modalSequences.getActiveModalSequence();
        activeModalName = activeModal.activeModal();

        if (e.key_pressed === ' ') {
          // if it's one of these three modals...
          if (['welcomeModal', 'noStarModal', 'earnedStarModal'].indexOf(activeModalName) > -1) {
            activeModal.next();
            // no active modal sequence, then we're done!
            if (!modalSequences.getActiveModalSequence()) {
              $rootScope.$broadcast('lessonModals:complete');
              return;
            }
            // if we're now on a homeRowTech modal, activate it
            if (modalSequences.getActiveModalSequence().activeModal() === 'homeRowTechModal') activateHomeRowTech(); // activate the homeRowTechModal if it's active
          }
        } else if (e.key_pressed === 'Backspace') {
          $state.go('app.keyboarding.level', {
            level: $state.params.level,
          });
        }
      });
    }

    // homeRowTechModal ------------------------------------------------------------------------------------
    // HRT modal requires $scope.homeRowTechModal and $scope.homeRowTech to be set, as these are accessed from the view.
    //
    // creates a new homeRowTech object
    //  -> should be called to enable homeRowTech
    function activateHomeRowTech() {
      // check whether we need to do HRT. If not, broadcast it as complete.
      if (SkipHomeRowTechModal.shouldSkip('Lesson', currentUser)) {
        $rootScope.$broadcast('homeRowTech:modalComplete');
        return;
      }

      $scope.homeRowTechModal = new HomeRowTechModal({
        scope: $scope, // keydown listener is attached to this scope (so it will be auto-destroyed with scope)
        listenerTimeout: 200, // necessary to prevent transition issue where HRT jumped straight to instructions instead of hands
        sequence: null, // || "leftToRight", ..., defined in factory.
        numberOfFingersToVerify: 5, // || >2
        activateNow: true, // specific to the modal
        skinTone: $scope.currentUser.user_preference.skin_tone,
      });
      $scope.homeRowTech = $scope.homeRowTechModal.homeRowTech;
    }

    // listens for the modalComplete broadcast.
    //  -> should be attached to the same scope that was passed to HomeRowTechModal constructor
    $scope.$on('homeRowTech:modalComplete', function () {
      // advance the modal
      modalSequences.getActiveModalSequence().next();
      // if the modal sequence is no longer active, broadcast notice to LessonModal
      if (!modalSequences.isActive()) $rootScope.$broadcast('lessonModals:complete');
    });

    // a function to enable the 'back' button in the modals
    // enables the back button in the modals
    // for the 'x' in the modal
    $scope.goBack = function () {
      $state.go('app.keyboarding.level', {
        level: $state.params.level,
      });
    };
  },
]);
