app.factory('AdminContactsDirectoryIndividuals', [
  '$timeout',
  '$http',
  '$q',
  function ($timeout, $http, $q) {
    // Class Constructor for individuals contacts directory (admin view)
    // Provides ability to sort contacts by province/city
    // Expect an array of contacts to be passed in
    function ContactsDirectory({ contacts = [] } = {}) {
      // assign all properties to self so that we can instantiate
      this.assignAllPropertiesToSelf({
        contacts,
      });

      // Variables affecting the view
      this.view = {
        // Variables used for visually tracking selections
        selectedCityName: null,
        // Variables used for holding unique list of names
        provinceNamesList: getUniqueValues(contacts, 'company'),
        cityNamesList: [],
        // Subset of contacts filtered by a parameter
        contactsFilteredByCity: [],
        // Custom view object for hierarchaly showing cities sorted by
        // Province -> city. Used by individuals contacts
        // e.g. {'Ontario': ['Toronto', 'Barrie']}
        nestedProvinceCityNamesList: [],
      };

      // This will create the extendedCOmpanyNamesList
      this.init();
    }

    // Class Methods

    // On click event when a city is selected.
    // Main purpose of function is to filter the original contacts list to those belonging to the city
    // Since the list of cities could be much longer than list of positions, if scrollToAnchor param is passed in, upon clicking
    // the browser should scroll to the element with that id
    ContactsDirectory.prototype.selectCity = function (cityName, scrollToAnchor) {
      // save the current company selection name
      this.view.selectedCityName = cityName;
      // Filter out the companies
      this.view.contactsFilteredByCity = this.contacts
        .filter((contact) => contact.city === cityName)
        // Sort alphabetically by last name
        .sort((a, b) => {
          if (a.lastName > b.lastName) {
            return 1;
          } else if (a.lastName < b.lastName) {
            return -1;
          } else {
            return 0;
          }
        });

      // Scroll list of positions into view
      if (scrollToAnchor) {
        const scrollToElement = document.getElementById(scrollToAnchor);
        if (scrollToElement) scrollToElement.scrollIntoView();
        // Add an additional scroll due to header covering the top
        window.scroll(0, window.scrollY - 100);
      }
    };

    // Perform some initial sorting of company names by province and city
    ContactsDirectory.prototype.init = function () {
      // Make sure to attach it to the "scope" as a separate step, directly adding to the
      // keys directly does not appear to trigger Angular's digest cycle

      // Sorty by province -> city
      this.view.nestedProvinceCityNamesList = this.createNestedProvinceCityNamesList();
    };

    // Function for creating a 2 level nested object structure, reorganizing contacts by province -> cities
    // Returns a structure like this {'Ontario':['Toronto', 'Barrie']}
    ContactsDirectory.prototype.createNestedProvinceCityNamesList = function () {
      // Original contacts list
      const contacts = this.contacts;
      let self = this;
      // Accumulator for the nested re-organizing of contacts based on industry, company name, and city
      let nestedProvinceCityNamesList = {};

      getUniqueValues(contacts, 'province')
        .sort()
        .forEach((provinceName) => {
          // Get subset of contacts belonging to the province
          const provinceContacts = contacts.filter((contact) => contact.province === provinceName);
          nestedProvinceCityNamesList[provinceName] = getUniqueValues(provinceContacts, 'city');
        });
      return nestedProvinceCityNamesList;
    };

    // // assigns all properties retrieved from the server to self
    ContactsDirectory.prototype.assignAllPropertiesToSelf = function (props) {
      var self = this;
      for (var prop in props) {
        if (props.hasOwnProperty(prop)) {
          self[prop] = props[prop];
        }
      }
    };

    // Private class functions

    /*
     * Given an array of objects, return an array of all
     * possible values at obj[key] within the objects array
     * eg. getUniqueValues([{num: 1}, {num: 2}, {num: 1}], 'num') => [1,2]
     *     -> All the possible values of the 'num' property of the objects
     *        inside the array
     */
    function getUniqueValues(objectsArray = [], key) {
      let acc = {};
      objectsArray.forEach((obj) => {
        // only accept keys that exist on the object and have non-empty string values
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].length > 0) {
          acc[obj[key]] = obj[key];
        }
      });
      return Object.keys(acc);
    }

    return ContactsDirectory;
  },
]);
