app.controller('ProfileController', [
  '$scope',
  '$http',
  '$timeout',
  'ProfileChart',
  '$state',
  '$stateParams',
  '$q',
  function ($scope, $http, $timeout, ProfileChart, $state, $stateParams, $q) {
    $timeout(function () {
      // Code snippet from docs https://getbootstrap.com/docs/5.1/components/tooltips/#example-enable-tooltips-everywhere
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
      });
    }, 1500);

    // tableData is a table representation of the data loaded in the radar chart. It is used to render a hidden table representing the
    // chart for screen readers. eg. [{letter: 'a', letter_speed: 20, error_rate: 2 }]
    $scope.view = { noData: false, showExplanation: false, tableData: {} };

    // when user clicks on strongest/weakest letter, redirects to a lesson
    $scope.referLesson = function (letter) {
      if (!letter || !letter.letter) return;

      $http.get('profile/refer_lesson/' + letter.letter).then(function (response) {
        $state.go('app.keyboarding.lesson', {
          level: response.data.challenge.level_id,
          row: response.data.challenge.row,
          column: response.data.challenge.column,
          lesson: response.data.order,
        });
      });
    };

    // enables keyboard-only users to tab through the content on the radar diagram
    let previousActiveElement = null;
    $scope.$on('keydown', function () {
      // wait for digest cycle to complete
      $timeout(() => {
        const activeElement = document.activeElement;
        // don't update if the activeElement didn't change
        if (previousActiveElement && previousActiveElement === activeElement) return;
        previousActiveElement = activeElement;
        console.log(activeElement);
        const hiddenProfileLetterElement = activeElement.querySelector('.visually-hidden-profile-letter');

        if (hiddenProfileLetterElement) {
          // Get the inner text of the child element
          const innerText = hiddenProfileLetterElement.innerText;
          // mimicing the tooltip that is passed the labelCallback from the canvas
          const tooltip = { title: innerText };
          $timeout(() => labelCallback(tooltip), 10);
          console.log(innerText);
        }
      }, 0);
    });

    // Fires when the chart experiences a mouse-over
    var labelCallback = function (tooltip) {
      if (!tooltip) {
        // $scope.profile.current_letter = {};
        return;
      }
      // need to convert again. Below equation was copied from profile_chart_service
      var letterIndex = $scope.profile.labels.indexOf(tooltip.title);
      var error_scale_factor = 2.0;
      var error_rate = parseFloat($scope.profile.data[1][letterIndex] / error_scale_factor).toFixed(2);
      var speed = Math.round($scope.profile.data[0][letterIndex] * 100.0) / 100.0;
      let current_letter = {
        title: tooltip.title,
        speed: speed,
        error_rate: error_rate,
      };

      // bind to scope
      $scope.$apply(function () {
        $scope.profile.current_letter = current_letter;
      });
    };

    // Here we determine if we're a student viewing our own profile, or an
    //  instructor iew a student's profile in student_view_of_instructor
    var sectionPromise = $q.defer();
    // timeScale allows us to scale up/down the animation times
    var timeScale = 1;
    if ($state.current.name === 'app.instructor.section.studentView') {
      $scope.$parent.$parent.section.onInit().then(function () {
        timeScale = 0.8;
        return sectionPromise.resolve({
          section_id: $scope.$parent.$parent.section.id,
          user_id: $stateParams.student_id,
        });
      });
    } else {
      sectionPromise.resolve({
        section_id: null,
        user_id: $scope.user.id,
      });
    }

    sectionPromise.promise
      .then(function (params) {
        var user_id = params.user_id;
        var section_id = params.section_id;

        return ProfileChart.radarChart(labelCallback, { user_id: user_id, section_id: section_id });
      })
      .then(function (chart) {
        $timeout(function () {
          $scope.profile = $scope.profile || {};
          $scope.profile.overall_statistics = {};
          $scope.profile.overall_statistics.sample_size = chart.overall_statistics.sample_size;

          // tableData is a table representation of the data loaded in the radar chart. It is used to render a hidden table representing the
          // chart for screen readers. eg. [{letter: 'a', letter_speed: 20, error_rate: 2 }]
          $scope.view.tableData = chart.labels.map((letter, idx) => {
            // NOTE: these calculations are copied over from labelCallback above
            // TODO: for consistency, refactor the code so that labelCallback and this calculation use the same function call
            const error_scale_factor = 2.0;
            const speed = Math.round(chart.data[0][idx] * 100.0) / 100.0;
            const error_rate = parseFloat(chart.data[1][idx] / error_scale_factor).toFixed(2);
            return { letter, speed, error_rate };
          });
        }, 0);

        // Set estimated_wpm
        $timeout(function () {
          $scope.profile.overall_statistics.estimated_wpm = chart.overall_statistics.estimated_wpm;
        }, 2000 * timeScale);

        // Set wpm
        $timeout(function () {
          $scope.profile.overall_statistics.wpm = chart.overall_statistics.wpm;
        }, 2500 * timeScale);

        // Set accuracy
        $timeout(function () {
          $scope.profile.overall_statistics.accuracy = chart.overall_statistics.accuracy;
        }, 3000 * timeScale);

        // Set strongest_letter
        $timeout(function () {
          $scope.profile.strongest_letter = chart.strongest_letter;
        }, 3300 * timeScale);

        // Set weakest_letter
        $timeout(function () {
          $scope.profile.weakest_letter = chart.weakest_letter;
        }, 4300 * timeScale);

        // Set chart
        $timeout(function () {
          $scope.profile = chart;
        }, 5300 * timeScale);

        // After the chart is displayed, check if there's no data
        $timeout(function () {
          $scope.view.noData = chart.overall_statistics.sample_size === 0;
          if ($scope.view.noData) {
            $timeout(function () {
              $('#profile-chart').slideUp('slow');
            }, 300 * timeScale);
          }
        }, 5800 * timeScale);

        // $scope.profile = chart;
      });
  },
]);
