// Retrives user object, with class sections
//  -> ANY CHANGES TO THE USER OBJECT SHOULD BE DONE HERE!
app.service('Auth2', [
  'Auth',
  '$http',
  '$q',
  'Student',
  function (Auth, $http, $q, Student) {
    var self = this;
    var _currentUser;

    // help prevent multiple successive requests
    var loginPromise;
    var isLoginComplete = false;

    // retrives a promise that resolves to current user (including sections)
    this.currentUser = function () {
      if (_currentUser) return $q.resolve(_currentUser); // return the internal variable if we have it
      // isLoginComplete will ensure we make only one request, and return the results to all callers
      if (loginPromise && !isLoginComplete) return loginPromise;
      isLoginComplete = false;

      loginPromise = Auth.currentUser().then(function (user) {
        var params; // we want students to be able to see their old sections their instructors may have hidden
        if (user.instructor) {
          params = { include_deleted: false };
        }
        if (!user.instructor) {
          params = { include_deleted: true };
        }
        // if the user has no associated user_preference object (i.e. user_preference is null)
        // then set it to an empty object here
        user.user_preference = user.user_preference || {};

        return $http.get('class.json', { params: params }).then(function (response) {
          user.sections = response.data;
          if (user.instructor) {
            _currentUser = user;
          } else {
            _currentUser = new Student(user);
          }
          isLoginComplete = true;
          return _currentUser;
        });
      });
      return loginPromise;
    };

    // if changing a user_preference from angularjs, we may need to call this function with the new
    // user_preference object so that these changes are seen across the app
    this.updateUserPreference = (userPreference = {}) => {
      _currentUser.user_preference = { ..._currentUser.user_preference, ...userPreference };
    };

    // retrieves sections from the server and updates the user object
    this.refreshSections = function (includeDeletedSections) {
      // if we don't have the current user, get the current user with sections
      if (!_currentUser) return self.currentUser();

      var params; // we want students to be able to see their old sections their instructors may have hidden
      if (_currentUser.instructor) {
        params = { include_deleted: includeDeletedSections || false };
      }
      if (!_currentUser.instructor) {
        params = { include_deleted: includeDeletedSections || true };
      }

      return $http.get('class.json', { params: params }).then(function (response) {
        _currentUser.sections = response.data;

        return _currentUser;
      });
    };

    // proxy to Auth's login, but will retrieve class sections as well
    this.login = function (credentials, config) {
      return Auth.login(credentials, config)
        .then(function (user) {
          // if the user has no associated user_preference object (i.e. user_preference is null)
          // then set it to an empty object here
          user.user_preference = user.user_preference || {};

          if (user.instructor) {
            _currentUser = user;
          } else {
            _currentUser = new Student(user);
          }
          return self.refreshSections();
        })
        .catch(function (response) {
          return $q.reject(response);
        });
    };

    this.logout = function (config) {
      Auth.logout(config);
    };

    // pushes a section to the internal _currentUser object
    //  -> returns user
    this.pushSection = function (section) {
      _currentUser.sections.push(section);
      return _currentUser;
    };

    // updates a section by id
    //  useful for delete or update requests, kicked off by Classes service
    this.updateSection = function (updatedSection) {
      var section = _currentUser.sections.filter(function (el) {
        return el.id === updatedSection.id;
      });
      section = updatedSection;
      return section;
    };

    return this;
  },
]);
