/* globals SERVER_TIME_SYSTEM_TIME_DIFF */

// Factory for de-cluttering the classlist controller. Mainly it hosts the scope view object
app.factory('ClasslistViewDefaults', [
  function () {
    // function takes 2 params that are scoped to classlist controller. Composition
    // happens here rather than in the controller to save space
    // Object returned is an object that gets merged into the $scope.view in the classlist controller
    function generateViewDefaults(stateName, organizationId) {
      return {
        stateName: stateName,
        docProdGradeDisplaySettings: {
          showExplanation: false,
          // Nested because expecting more options here in the future
        },
        showTestColumn: false,
        allowTestAssignment: false,
        disableTestAllButton: false,
        testAllButtonMessage: 'Test All',
        lessonNames: [
          {
            key: 'basics1',
            display: 'Basics 1',
          },
          {
            key: 'basics2',
            display: 'Basics 2',
          },
          {
            key: 'basics3',
            display: 'Basics 3',
          },
          {
            key: 'basics4',
            display: 'Basics 4',
          },
          {
            key: 'basics5',
            display: 'Basics 5',
          },
          {
            key: 'punctuation',
            display: 'Punct.',
          },
          {
            key: 'number_row',
            display: 'Numbers',
          },
          {
            key: 'numpad',
            display: 'NumPad',
          },
          {
            key: 'special_chars',
            display: 'Special Chars.',
          },
          {
            key: 'capital_letters_1',
            display: 'Caps 1',
          },
          {
            key: 'capital_letters_2',
            display: 'Caps 2',
          },
          {
            key: 'capital_letters_3',
            display: 'Caps 3',
          },
          {
            key: 'pangrams_1',
            display: 'Pangrams 1',
          },
          {
            key: 'pangrams_2',
            display: 'Pangrams 2',
          },
          {
            key: 'pangrams_3',
            display: 'Pangrams 3',
          },
          {
            key: 'extra_practice_1',
            display: 'Extra 1',
          },
          {
            key: 'extra_practice_2',
            display: 'Extra 2',
          },
          {
            key: 'extra_practice_3',
            display: 'Extra 3',
          },
          {
            key: 'single_hand',
            display: '1-Hand',
          },
          {
            key: 'speed_builders',
            display: 'Speed +',
          },
          {
            key: 'backwards_text',
            display: 'Backwards',
          },
          {
            key: 'foreign_language',
            display: 'Foreign',
          },
          {
            key: 'foreign_language_2',
            display: 'Foreign 2',
          },
          {
            key: 'foreign_language_3',
            display: 'Foreign 3',
          },
          {
            key: 'foreign_language_4',
            display: 'Foreign 4',
          },
          {
            key: 'legal_term',
            display: 'Legal',
          },
          {
            key: 'executive_term',
            display: 'Executive',
          },
          {
            key: 'medical_term',
            display: 'Medical',
          },
          {
            key: 'medical_term_2',
            display: 'Medical 2',
          },
          {
            key: 'medical_term_3',
            display: 'Medical 3',
          },
          {
            key: 'medical_term_4',
            display: 'Medical 4',
          },
        ],
        // organization_id == 16 is Conestoga. Not enabling this low of minutes for anyone else..
        minPracticeTimeOptions:
          organizationId === 16
            ? {
                '15 minutes': 15,
                '20 minutes': 20,
                '25 minutes': 25,
                '30 minutes': 30,
                '45 minutes': 45,
              }
            : {
                '20 minutes': 20,
                '25 minutes': 25,
                '30 minutes': 30,
                '45 minutes': 45,
              },
        // used in action_row_set_expiry to populate the select box with 14 days
        documentAssignmentExpiresOnDaysOptions: next14Days(),
        documentAssignmentExpiresOnHoursOptions: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        // ES6 shorthand to create an array with integers 1-59
        documentAssignmentExpiresOnMinutesOptions: Array.from(
          {
            length: 60,
          },
          (_, i) => (i < 10 ? `0${i}` : String(i))
        ),
        // documentExpiresOnDay is defined on the view since we don't want to save this value
        //  in firebase
        documentExpiresOnDay: null,
        testLinks: {
          showTestLinkRequirements: false,
        },
      };
    }

    // Returns an array of Date objects spanning from current date, to next 14 days in the future
    function next14Days() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((days) => {
        const now = new Date().getTime() - SERVER_TIME_SYSTEM_TIME_DIFF;
        return addDays(new Date(now), days);
      });
    }

    // returns a date object with number of 'days' (an integer) added
    // to the 'date' (a js Date object) passed in
    function addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    return {
      generateViewDefaults,
      next14Days,
    };
  },
]);
