// Data service for getting course builder data
app.service('StudentCourseScheduleDataService', [
  '$q',
  '$timeout',
  'CourseBuilderDataService',
  'DocProductionResultsDataService',
  function ($q, $timeout, CourseBuilderDataService, DocProductionResultsDataService) {
    // TEMPORARY - mock structure until it can be fetched from database

    this.getStudentCourseSchedule = function () {
      // TODO: this function should fetch from database and retrieve the correct student course schedule
      return DocProductionResultsDataService.getPastAssignmentSubmissions(_temp_documentAssignmentId)
        .then(function (resp) {
          var scoresObject = {};
          scoresObject[_temp_documentAssignmentId] = resp[0];
          return scoresObject;
        })
        .catch(function () {
          return {};
        })
        .then(function (scoresObject) {
          return generateStudentCourseSchedule(scoresObject);
        });
    };
  },
]);
