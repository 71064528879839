// Manges Levels, Challenges and Lessons data
app.service('Level', [
  '$http',
  '$q',
  'UserLessonData',
  function ($http, $q, UserLessonData) {
    var self = this;
    this.level = {}; // an object containing keys [0, 1, 2, 3...] corresponding to level numbers
    this.displayLevel = 1;
    this.currentLevelDisplay = [];
    // Used by the view, currentLevelArrays transforms challenges into:
    // [
    //    [Challenge],
    //    [Challenge, Challenge, Challenge],
    //    [Challenge, Challenege]
    // ]

    this.view = {
      // Only counting the completed stars here, total stars can be calculated based on total number of lessons count
      // Used in new lessons view to show overall progress for current level
      totalStarsSummary: {
        lessonsCount: undefined,
        bronze: undefined,
        silver: undefined,
        gold: undefined,
      },
    };

    // Retrieves info, updates currentLevelDisplay to this number
    this.update = function (number) {
      if (self.level[number]) {
        displayLevel(number);
        return $q.resolve();
      } else {
        return $http
          .get(`level/${number}.json`)
          .then(function (structure) {
            self.level[number] = structure.data;
            displayLevel(number);
          })
          .then(function () {
            return UserLessonData.update(self.level[number]);
          });
      }
    };

    // This function recalculates the total amount of stars earned
    // If level object is populated with challenges, then it goes through each challenge
    // and each lesson, keeping track of all the stars collected
    // If Challenges are not present at the current level object, then this function does not update the total stars summary
    this.calculateTotalStarsSummary = function () {
      // Return early if level at displayLevel is not defined
      // This check works properly on the first load of lesson page, because in the Level Controller, the
      // Level.update() includes both Level data loading, and the lesson progress for the current level page
      // Upon switching levels, since the Levels are already loaded (cached), the front end fetches new lesson progress data for the
      // new level page, so this if statement is not activated (because it doesn't check for the nested level lesson data, just the level key)
      // TODO: make this check for lesson progress
      if (!self.level[self.displayLevel]) {
        // This resets the totalStarsSummary, which causes the view to display loading circles
        self.view.totalStarsSummary = {};
        return;
      }

      const currentLevelChallenges = self.level[self.displayLevel].challenges;
      // 1. gather all the lessons across all challenges
      const allLessons = currentLevelChallenges.reduce((acc, challenge) => {
        return [...acc, ...challenge.lessons];
      }, []);

      // 2. Count up all the bronze,silver, and gold stars
      const results = allLessons.reduce(
        (acc, lesson) => {
          if (lesson.currentLevel === 'bronze') {
            return {
              ...acc,
              bronze: acc.bronze + 1,
            };
          } else if (lesson.currentLevel === 'silver') {
            // Having a silver star implies user completed a bronze star, so increment that as well
            return {
              ...acc,
              bronze: acc.bronze + 1,
              silver: acc.silver + 1,
            };
          } else if (lesson.currentLevel === 'gold') {
            // Having a gold star implies user completed silver and bronze stars, so increment those as well
            return {
              ...acc,
              bronze: acc.bronze + 1,
              silver: acc.silver + 1,
              gold: acc.gold + 1,
            };
          }
          // By default do not update the stars count
          return acc;
        },
        {
          bronze: 0,
          silver: 0,
          gold: 0,
          // Number of total stars in each category equals the number of lessons
          lessonsCount: allLessons.length,
        }
      );

      // Update the totalStarsSumamry
      self.view.totalStarsSummary = results;
    };

    // Erases any locally stored data.
    //  Used by classes_service to make sure that if a students lesson progress is changed after joining a
    //      new section, the Level service will refresh.
    //
    this.eraseLocallyStoredData = function () {
      self.level = {};
      return true;
    };

    // Returns challenge
    // params: {level: 2, row: 3, column: 0}
    // DOES NOT YET retrieves user results if necessary
    this.getChallenge = function (params) {
      let challenge = self.level[params.level].challenges.filter(function (chall) {
        return Number(chall.row) === Number(params.row) && Number(chall.column) === Number(params.column);
      })[0];
      challenge.levelName = self.level[params.level].name;
      return challenge;
    };

    // Returns challenge
    // params: {level: 1, row: 0, column: 2, order: 1}
    // retrieves user results if necessary
    this.getLesson = function (params) {
      let challenge = self.getChallenge(params);
      let lesson = challenge.lessons.filter(function (lesson) {
        return Number(lesson.order) === Number(params.lesson);
      })[0];
      lesson.levelName = challenge.levelName;
      UserLessonData.update(self.level[params.level]); //.then(updateLessonsWithUserData);
      return lesson;
    };

    // updates timesCompleted for all lessons in the current challenge
    this.updateLessonResultsForChallenge = function (levelNumber) {
      return UserLessonData.update(self.level[levelNumber]); //.then(updateLessonsWithUserData);
    };

    // removes progress, refreshes application
    this.deleteProgress = function (whatToDelete) {
      var currentLevel = this.displayLevel;
      var confirmMessage = 'This action will delete ' + whatToDelete + ' for all lessons in ' + this.currentLevelDisplay.name;
      confirmMessage += '. This action is permanent and cannot be undone within Typist, or by the Typist staff. ';
      confirmMessage += "Click 'OK' if you would like to proceed. Once complete, your page will refresh.";
      if (typeof currentLevel !== 'number') return;

      // destroy_level:
      //   1: destory gold & silver & bronze stars
      //   2: destroy gold & silver stars
      //   3: destory gold stars
      var deleteLevel;
      if (whatToDelete === 'all bronze, silver and gold stars') {
        deleteLevel = 1;
      } else if (whatToDelete === 'all gold and silver stars') {
        deleteLevel = 2;
      } else if (whatToDelete === 'all gold stars') {
        deleteLevel = 3;
      }

      if (confirm(confirmMessage)) {
        $http.delete('level/progress/' + currentLevel + '/' + deleteLevel).then(function () {
          // refresh browser
          location.reload();
        });
      }
    };

    // Changes the level currently displayed
    //  & creates the display object
    function displayLevel(number) {
      self.displayLevel = Number(number);
      // sets self.currentLevelDisplay
      transformLevelIntoArrays(number);
    }

    // updates this.currentLevelDisplay
    function transformLevelIntoArrays(number) {
      let level = self.level[number];
      self.currentLevelDisplay = [];
      var numChallenges = level.challenges.length;
      var maxRow = 0;

      // Get maximum row # & add level to challenges
      for (let j = 0; j < numChallenges; j++) {
        level.challenges[j].levelNumber = number;
        maxRow = level.challenges[j].row > maxRow ? level.challenges[j].row : maxRow;
      }
      // transform into rows
      for (let i = 0; i <= maxRow; i++) {
        self.currentLevelDisplay[i] = [];
        for (let j = 0; j < numChallenges; j++) {
          if (level.challenges[j].row === i) self.currentLevelDisplay[i].push(level.challenges[j]);
        }
      }
      // set the view
      // Set the level name
      self.currentLevelDisplay.name = level.name;
      // Set the row class
      var length = 0;
      for (let i = 0; i <= maxRow; i++) {
        length = self.currentLevelDisplay[i].length;
        if (length === 1) self.currentLevelDisplay[i].displayClass = 'one-icon';
        if (length === 2) self.currentLevelDisplay[i].displayClass = 'two-icons';
        if (length === 3) self.currentLevelDisplay[i].displayClass = 'three-icons';
        if (length === 4) self.currentLevelDisplay[i].displayClass = 'four-icons';
      }
    }
  },
]);
