app.controller('PasswordRecoveryController', [
  '$scope',
  '$http',
  '$state',
  'Auth',
  '$timeout',
  'AnimatedLoginKeyboardFactory',
  function ($scope, $http, $state, Auth, $timeout, AnimatedLoginKeyboardFactory) {
    $scope.stateIsPasswordRecovery = true; // required to manage the modals in _welcome.html
    $scope.view = {
      recoveryEmailSent: false,
      inProgress: false,
      isSuccessful: null,
      isFailure: null,
      validations: { email: null },
    };
    $scope.keyboard = new AnimatedLoginKeyboardFactory('#loading-keyboard-auth');

    $scope.submit = function (user) {
      if (!localValidations(user)) return;
      $scope.view.validations.email = null;
      $scope.view.inProgress = true;

      return runLoadingAnimationForMinTime($http.post('/users/recovery/reset_password', { email: user.email }), 5000)
        .then(showSuccessMessage)
        .catch(showErrorMessage);
    };

    // private methods ------------------------------------------------------------------------------------
    // animates the loading keyboard, and returns when either the http promise is resolve or 3 seconds have passed
    //  returns the result of the http promise
    function runLoadingAnimationForMinTime(httpPromise, minTime) {
      // start the keyboard
      $scope.keyboard.showKeyboard = true;
      $scope.keyboard.stopKeyboard = false;
      $scope.keyboard.animate();

      var wait = function () {
        return $timeout(function () {}, minTime);
      };

      return wait()
        .then(function () {
          return httpPromise;
        })
        .then(function (response) {
          $scope.keyboard.stopKeyboard = true;
          $timeout(function () {
            $scope.keyboard.showKeyboard = false;
          }, 500);
          return response;
        });
    }

    function showSuccessMessage() {
      $scope.view.isSuccessful = true;
      $timeout(function () {
        $scope.view.recoveryEmailSent = true;
      }, 3000);
      return;
    }

    function showErrorMessage(error) {
      $scope.keyboard.stopKeyboard = true;
      $scope.view.isFailure = true;
      $scope.view.validations.email = error.data['errors'];
      // If the token is the problem, redirect
      // if (error.data.errors.indexOf("token is invalid") > -1) {
      //   $scope.keyboard.showKeyboard = false;
      //   $scope.view.validations.password = "This link is no longer valid. Enter your email on the next page and a new link will be emailed to you. Redirecting.";
      //   $timeout(function() { $state.go("password_recovery") }, 7000);
      //   return;
      // }

      $scope.view.inProgress = false;

      return $timeout(function () {
        $scope.keyboard.showKeyboard = false;
        $scope.view.inProgress = false;
        $scope.view.isFailure = false;
      }, 2000);
    }

    function localValidations(user) {
      // Validations
      if (user === undefined) {
        $scope.view.validations.email = 'Please enter your email.';
        return false;
      }
      if (user.email === undefined) {
        $scope.view.validations.email = 'Please enter your email.';
        return false;
      }
      if (user.email.indexOf('@') < 0) {
        $scope.view.validations.email = 'Please enter a valid email address.';
        return false;
      }
      return true;
    }
  },
]);
