app.controller('TutorialsPageController', [
  '$scope',
  '$sce',
  function ($scope, $sce) {
    // Simple controller to simplify my view.

    $scope.videos = [
      {
        title: 'An Overview of Typist',
        description: "If you're new to Typist, this video is where you should start!",
        category: 'keyboarding',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/QsW2_wDNAh4'),
      },
      {
        title: 'Instructor - Create a Class, Add Students',
        description: 'How to create a new class section and add students to your new section.',
        category: 'getting started',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/dQ9RdY5rSms'),
      },
      {
        title: 'Testing - The Definitive Guide',
        description: 'Learn how to properly give a test within Typist, and see the results on your gradesheet.',
        category: 'keyboarding',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/uJqUVH8-pVU'),
      },
      {
        title: 'Testing - Remote Test Links',
        description: 'Remote Test Links allow you to schedule tests for your students.',
        category: 'keyboarding',
        link: $sce.trustAsResourceUrl('https://www.useloom.com/embed/0316a4fe95cf403bb8ba3394426589eb'),
      },
      {
        title: 'Contests - a.k.a. Championship Mode',
        description: 'Learn how to use contests; a fun way to engage your students through an interactive typing competition.',
        category: 'keyboarding',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/8VXgslYVbCw'),
      },
      {
        title: 'Instructor - How to Read Your Gradesheet',
        description:
          'Learn how to see student test results, average of best 3 (and 5) tests, which students are practicing along with other tips and tricks!',
        category: 'keyboarding',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/onIe1VI9ibQ'),
      },
      {
        title: 'Instructor - Accommodation with Dictation',
        description: 'How to assess a student with dictation, using Dragon Dictation or other software, within Typist.',
        category: 'keyboarding',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/8l7oXUv09pY'),
      },
      {
        title: 'Student - How to Register and Join Class',
        description:
          'You may wish to share this videos with your students to show them how to create an account and join a class section.',
        category: 'getting started',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/aL4q9u2Wf8I'),
      },
      {
        title: 'Student - How to Register with Access Card',
        description:
          "You may wish to share this videos with your students to show them how to create an account with an access card that they've purchased.",
        category: 'getting started',
        link: $sce.trustAsResourceUrl('https://www.useloom.com/embed/23ea5a2ee6ec4436927da141a21cf39a'),
      },
      {
        title: 'How Typist Prevents Cheating',
        description:
          'Be sure to watch our video on Testing first (see above). In this video, I simulate two students trying to access the same test.',
        category: 'keyboarding',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/9gEh3vzNyyo'),
      },
      {
        title: 'Student - How to Join a Class',
        description:
          'Share this video with your students to show them how they can join your class section. Copy this link: https://youtu.be/9LYsvS5euqw',
        category: 'getting started',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/9LYsvS5euqw'),
      },
      {
        title: 'Instructor - How to Register',
        description:
          "How to create an instructor account and your first class section. If you're reading this, you've already created your account.",
        category: 'getting started',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/OLDKpw4LUWc'),
      },
      {
        title: 'Class Reports (How Students See Their Grades)',
        description: 'Learn how you, a student, can track all of your data within your course.',
        category: 'getting started',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/cRXQVL1EuLw'),
      },
      {
        title: 'Typist Documents: How to Complete Documents in Typist',
        description:
          'From start to finish, this video illustrates how to complete document assignments in Typist. This is an excellent video to share with students.',
        category: 'documents',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/bcOSIzp90Wk'),
      },
      {
        title: 'Typist Documents: How Due Dates Work',
        description:
          'Informative for both instructors and students, this video explores how due dates, extensions, and what students/instructors see after uploading.',
        category: 'documents',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/lt17sV_vNwA'),
      },
      {
        title: 'Tutorial: Multi-Page Headers on a Mac',
        description:
          'Although it is HIGHLY recommended that students use Word on Windows, this video shows how to properly insert multi-page headers on Word for Mac.',
        category: 'documents',
        link: $sce.trustAsResourceUrl('https://www.useloom.com/embed/99c02004a7df47a1a71af058a1b85ddf'),
      },
      {
        title: 'Instructor - Managing and Assigning Documents',
        description:
          'How to give your students document assignments, set due times, and release grades (and what these all mean)!',
        category: 'documents',
        link: $sce.trustAsResourceUrl('https://www.youtube.com/embed/BTjjPgxb31c'),
      },
      {
        title: 'Keyboarding Certificates - Overview and Tutorial',
        description: "Create beautiful, printable certificates certifying your students' keyboarding speeds.",
        category: 'keyboarding',
        link: $sce.trustAsResourceUrl('https://www.useloom.com/embed/6245f17192364d679f932b42ad5d64a2'),
      },
      {
        title: 'Last Name Codes',
        description:
          'If you have students with the same name in your class, consider using last name codes to uniqely identify each student.',
        category: 'getting started',
        link: $sce.trustAsResourceUrl('https://www.useloom.com/embed/0bd1450dac67401a957c23542f31f696'),
      },
    ];
  },
]);
