app.controller('StudentDashboardController', [
  '$scope',
  'Auth2',
  'Student',
  'StatisticService',
  '$q',
  'TooltipService',
  '$http',
  'SectionHelpers',
  function ($scope, Auth2, Student, StatisticService, $q, TooltipService, $http, SectionHelpers) {
    $scope.view = {
      sectionID: '',
      isLoading: false,
      timed_writing_limit: 8,
      // swapableColumnMap is used for adding tooltips
      swapableColumnMap: {
        avg_top_3: 'Avg. Top 3 Tests',
        avg_top_4: 'Avg. Top 4 Tests',
        avg_top_5: 'Avg. Top 5 Tests',
        top_scores: 'Best Score',
      },
      hasDocumentAssignments: false,
    };

    // set information
    Auth2.currentUser().then(function (user) {
      $scope.student = user;
    });

    $scope.changeCourse = function () {
      if ($scope.view.sectionID === '') return; // this corresponds to 'Please select a course' option
      $scope.view.isLoading = true;
      $scope.section = $scope.student.sections.filter(function (s) {
        return s.id == $scope.view.sectionID;
      })[0];

      $scope.section.practice = {
        // ng-model for currently selected practice time week to display.
        // It's either null (default for Last 10 Days option), or { day: 1, month: 2, year: 2020, dateObj: Date},
        // where dateObj is a js Date object used in view to display a properly formatted date
        startDate: null,
        // Determines whether to show a loading bar or not
        dataLoaded: false,
        // List of practice time weeks to choose from. This list is generated after classlist has been loaded
        selectFormWeekOptions: SectionHelpers.generatePracticeTimeWeekRanges($scope.section.created_at),
      };

      $scope.student
        .getTestRequirementsForSection($scope.section)
        .then(
          $scope.student.getStatsForSection.bind(
            null,
            $scope.section,
            ['timed_writings', 'tw_metadata', 'practice_time', 'document_production', 'lesson_progress'],
            {}
          )
        )
        .then(layerStudentResultsOnCourseTemplate)
        .then(TooltipService.updateTooltips)
        .then(function () {
          $scope.view.isLoading = false;
          // Turn off loading status for practice time data
          $scope.section.practice.dataLoaded = true;
        });
    };

    // ng-change function for the dropdown menu of practice time lists for a given section
    // This loads stats only for 'practice_time' for a given section
    // startDate param is an object { day: 1, month: 2, year: 2020} or null (defaults to 'Last 10 Days' practice time)
    // Note: the startDate is actually $scope.section.practice.startDate being passed in (in section_factory, the function with)
    // same name calls a different update function, which in turn augments the requirements object. Goal of both functions is to
    // In the end get statistics service to get data from backend
    $scope.getCurrentPracticeTimeWeek = function (startDate) {
      const startDateRequirements = startDate
        ? {
            practice_days: 7,
            practice_start_day: startDate.day,
            practice_start_month: startDate.month,
            practice_start_year: startDate.year,
          }
        : {
            practice_days: 10,
          };

      // Turn on the loading animation for the practice time chart
      $scope.section.practice.dataLoaded = false;

      return $q
        .all([
          $scope.student.getStatsForSection($scope.section, ['practice_time'], startDateRequirements),
          // Add a minimum delay before showing the results on screen (note this applies to all stats being fetched)
          // Not just practice_time
          SectionHelpers.minDelay(800),
        ])
        .then(([results, __timer]) => results)
        .then(TooltipService.updateTooltips)
        .then(() => {
          // Turn off loading status for practice time data
          $scope.section.practice.dataLoaded = true;
          $scope.student.tabulatePracticeTimeStats($scope.section.view.minPracticeTimeMinutes);
        });
    };

    // When we load the controller:
    //  1. Grab the user
    //  2. Pull the course_template from the db
    //  3. Pull user data from the db
    //  4. Create student.document_assignments by combining student data with coruseTemplate
    //      object in student.mapDocumentAssignmentsToCourseTemplate()
    function layerStudentResultsOnCourseTemplate() {
      if (!$scope.section.course_template_id) {
        return $q.resolve({});
      }
      return $scope.student.mapDocumentAssignmentsToCourseTemplate($scope.section.course_template);
    }
  },
]);
