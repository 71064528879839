// Retrieves document submissions for a particular user, scoped appropriately
app.service('DocProductionResultsDataService', [
  '$http',
  'DocumentSubmission',
  function ($http, DocumentSubmission) {
    this.getSubmission = function (id) {
      return $http({
        method: 'GET',
        url: `/document_submissions/${id}.json`,
      })
        .then(function (response) {
          return new DocumentSubmission(response.data);
        })
        .catch(function (error) {
          // TODO: add an error catch if submission doesn't exist
          console.error('Activity not found: ', error);
        });
    };

    // Temporary implementation
    this.getPastAssignmentSubmissions = function (assignment_id, section_id) {
      const sectionUrl = section_id ? `/${section_id}` : ``;
      var url = `api/document_submissions/${assignment_id}${sectionUrl}.json`;
      return $http.get(url).then(function (response) {
        return response.data.map((submission) => new DocumentSubmission(submission));
      });
    };
  },
]);
