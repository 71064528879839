// Will ensure that an object has keys
app.service('ValidateObjectService', [
  function () {
    // should be bonded to element id
    return {
      // Validates that an object has an array of keys defined.
      //  -> if not, it calls the callback
      //
      // obj is an object
      // neededAttributes is an array of strings (required keys for the object)
      // callback is called on failure
      validate: function (obj, neededAttributes, callback) {
        var reject = false;

        var neededAttribute;
        for (var i = 0; i < neededAttributes.length; i++) {
          neededAttribute = neededAttributes[i];
          if (typeof obj === 'undefined' || obj === null || typeof obj[neededAttribute] === 'undefined') {
            return callback();
          }
        }
      },
    };
  },
]);
