// Shows classlist (ie student names), allows instructors to give tests.
app.controller('AssignmentViewAsInstructorController', [
  '$scope',
  '$stateParams',
  function ($scope, $stateParams) {
    // debugger;
    $scope.view = {
      disableTestCriteriaForm: true,
    };

    // Structure:
    // A section has a code, students, and an instructor
    // Create the section object
    // $scope.section = CacheService.get($stateParams.code);
    $scope.$parent.section
      .onInit()
      .then(function () {
        $scope.section = $scope.$parent.section;
        $scope.documentAssignment = $scope.section.getDocAssFromCourseTemplate().filter(function (da) {
          return da.id == $stateParams.id;
        })[0];
        return true;
      })
      // get the Document Production Information. These allow us to iterate through
      //  this student's course work
      .then(pullDocumentProductionInformation);

    function pullDocumentProductionInformation() {
      // If we don't have documentProduction, no need to do anything
      if (!$scope.section.hasDocumentProduction) return true;

      $scope.courseTemplate = $scope.section.course_template;
      console.log($scope.courseTemplate);
      console.log($scope.student);
    }

    $scope.changeGrade = function () {};
    // debugger;
  },
]);
