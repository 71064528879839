import { getDatabase, ref, onValue, off } from 'firebase/database';

// watches the actions/

app.factory('FirebaseActions', [
  function () {
    // expects an object:
    // actions = {"action1": callback1, "action2": callback2}
    //
    // Responder watches the folder: actions/path
    //  any time theres an update it will trigger the event
    //    associated with the action name.
    //
    // For example, in a controller:
    // var firebaseActions = new FirebaseActions();
    // // destroy listener when controller exits
    // $scope.$on("$destroy", function() { firebaseActions.destroy(); });
    //
    // firebaseActions.register(  // will only run once. sets up firebase listener.
    //   "sections/studentAdded",
    //   callbackFunction
    // );

    //
    // FirebaseActions destroys itself on state changes
    var Responder = function () {
      var _listeners = [];
      var listenerIsActive = false;
      var listenerFirstRequest = true;
      const db = getDatabase();

      this.register = function (path, callback) {
        if (listenerIsActive) return;
        listenerIsActive = true;
        // console.log("listening to " + path);
        var actionsRef = ref(db, `actions/${path}`);
        onValue(actionsRef, () => {
          if (listenerFirstRequest) {
            listenerFirstRequest = false;
            return;
          } // we dont want to update when we get initial values
          console.log('updated');
          callback();
        });
        // add to array so we can destroy them all later
        _listeners.push(actionsRef);
      };

      this.destroy = function () {
        for (var i = 0; i < _listeners.length; i++) {
          off(_listeners[i]);
        }
      };
    };

    return Responder;
  },
]);
