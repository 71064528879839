/* global SERVER_TIME_SYSTEM_TIME_DIFF */
import { createFirebaseUpdateObjectForUsers } from '../../../../components/Navigation/Notifications/FirebaseNotificationHelpers.js';
import { getDatabase, ref, update, serverTimestamp } from 'firebase/database';

// Helper functions for the creation and management of document assigments through
//  the sections object
app.service('DocumentAssignmentCreatorService', [
  '$http',
  '$q',
  'Permissions',
  function ($http, $q, Permissions) {
    const db = getDatabase();
    // options will overwrite the options within document_assignment.options
    // currently, the only option supported is {accommodation: true | false}
    this.startDocProdAssignment = function (section, expiresOnDay, options) {
      var students = section.students;
      var documentAssignments;
      let duration = section.view.documentProduction.documentTimeLimit;

      // we can either useTimeLimit (2 hours) or set the expires on
      //  directly (Saturday at 11:59 p.m.)
      // note, we use promises here since the `setExpiresOnWithDate` method
      // since a request to our server to ensure we're setting dates correctly
      let expiresOnPromise;
      if (section.view.documentProduction.useTimeLimit) {
        expiresOnPromise = setExpiresOnWithDuration(duration, options);
      } else {
        expiresOnPromise = setExpiresOnWithDate(
          expiresOnDay,
          section.view.documentProduction.documentExpiresOn,
          section.instructor.organization.timezone
        );
      }

      return expiresOnPromise.then((expiresOn) => {
        // Validations
        //  a. expiresOn must be at least 10 minutes in the future
        const now = Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF;
        if (now + 1000 * 60 * 10 >= expiresOn.getTime()) {
          const message =
            'Error: The expiry day/time must be at least 20 minutes in the future. Please select a new day/time and try again.';
          return $q.reject(message);
        }

        // 1. Get an array of document_assignments that have been selected on the gradesheet
        //      these are students.document_assignments[:id].selected
        var assignmentsArr = getSelectedAssignments(students);

        // 2. Get the relevant assignments from section and add them to the
        //      assignmentsArr so that we have all the user defined info
        documentAssignments = section.getDocAssFromCourseTemplate();
        assignmentsArr.forEach(function (assignment) {
          assignment.documentAssignment = documentAssignments.filter(function (da) {
            return da.id == assignment.document_assignment_id;
          })[0];
          assignment.instructor = section.instructor;
          assignment.section_id = section.id;
          assignment.expiresOn = expiresOn;
        });

        // Now: Group the assignmentsArr by assignment_id and user_id
        //  For each assignment_id, we want to know which users should receive it
        // Step a. Get all the unique document_assignment_ids
        let assignmentIds = assignmentsArr
          .map(function (a) {
            return a.document_assignment_id;
          })
          .filter(function (x, i, a) {
            return a.indexOf(x) == i;
          });
        // Step b. Get all the user_ids that have this document_assignment_id
        var user_ids = [];
        var documentAssignmentsTemp;
        var documentAssignmentTemp;
        var permissionPromises = [];
        assignmentIds.forEach(function (documentAssignmentId) {
          documentAssignmentsTemp = assignmentsArr.filter(function (a) {
            return a.document_assignment_id === documentAssignmentId;
          });
          user_ids = documentAssignmentsTemp.map(function (a) {
            return a.user_id;
          });
          // get the first obj to use as a prototype

          // override options will override within the permission creation process
          documentAssignmentTemp = documentAssignmentsTemp[0];
          documentAssignmentTemp.assignmentOverrideOptions = options;
          permissionPromises.push(createNotifications(user_ids, documentAssignmentTemp).then(createPermissions));
        });

        return $q.all(permissionPromises);
      });
    };

    // accepts $scope which contains a section property
    this.releaseDocProdAssignments = function (section) {
      var students = section.students;

      // 1. Get an array of document_assignments that have been selected on the gradesheet
      //      these are students.document_assignments[:id].selected
      var assignmentsArr = getSelectedAssignments(students);

      // 2. Compile the document_submission_ids into an array
      var document_submission_ids = assignmentsArr.map(function (da) {
        return da.document_submissions[0].id;
      });

      // 3. Send the document_submission_ids along with the section.id to the server
      //      to update these with the property grade_released = true
      return $http.put('api/document_submissions/release_grades.json', {
        section_id: section.id,
        document_submission_ids: document_submission_ids,
      });
    };

    // Cancel one or more due dates for one or more student
    this.cancelDocProdDueDates = function (section) {
      const assignments = getSelectedAssignments(section.students);

      // Group permissions by user ids, because that's how they are stored in firebase
      const permissionsPerStudentId = assignments.reduce((acc, da) => {
        const permissionIdsInFuture = da.permissions.filter((p) => p.inFuture);
        // Even though we are not expecting multiple active permissions, if they are detected for whatever reason,
        // all of them will be deleted

        const existingPermissionIds = acc[da.user_id] || [];
        return {
          ...acc,
          [da.user_id]: [...existingPermissionIds, ...permissionIdsInFuture],
        };
      }, {});

      // Flattened permission IDs for Typist server to delete these from our database
      const allPermissionIds = Object.values(permissionsPerStudentId)
        .reduce((acc, permissionsArr) => {
          return [...acc, ...permissionsArr];
        }, [])
        .map((p) => p.id);

      return (
        $http
          .post('permissions/destroy_multiple.json', {
            ids: allPermissionIds,
          })
          // Destroy the corresponding notifications in Firebase
          .then(() => destroyNotifications(permissionsPerStudentId))
      );
    };

    // marks selected=false for all student->document_assignments
    this.deselectAllAssignments = function (section) {
      // 1. creates an array of arrays containing assignments that have
      //      been selected on the gradesheet
      var assignmentsArrOfArr = section.students.map(function (student) {
        return Object.values(student.document_assignments).filter(function (da) {
          return da.selected;
        });
      });
      // we flatten the array down here
      var assignmentsArr = [].concat.apply([], assignmentsArrOfArr);

      // 2. sets each value to false.
      assignmentsArr.map(function (da) {
        da.selected = false;
      });
      return true;
    };

    // helpers -------------------------------------------------------------------------------

    // Destroys speficified notifications for a list of users
    // notificationIdsPerStudent is in the form of { 12324: [Permission, Permission, Permission]}, where
    // a ctitical key in Permission is `notification_id`
    // NOTE: if invalid or empty userId or permissionIds are passed in, these firebase requests are simply ignored in order
    // to prevent overriding some parent data structure in firebase.
    function destroyNotifications(notificationIdsPerStudent) {
      // For each student, delete all the specified permissions
      const firebaseRequests = Object.entries(notificationIdsPerStudent).map(([userId, permissions]) => {
        // If user ID is invalid, don't send a firebase request.
        // Note, userId can be passed in as a string (eg. from nav_controller.js)
        const userIdInt = parseInt(userId, 10);
        if (!Number.isInteger(userIdInt)) return Promise.resolve();

        // convert permissions to an update object
        const updates = permissions.reduce((acc, p) => {
          // Make sure permission is a valid string, otherwise also return early, and the firebase notification
          // deletion attempt will be made next time student tries to access it
          if (
            typeof p.notification_id !== 'string' ||
            (typeof p.notification_id === 'string' && p.notification_id.length === 0)
          ) {
            return acc;
          }

          // hard deleting a notification in Firebase by setting the key value to null
          return {
            ...acc,
            [`notifications/users/${userIdInt}/${p.notification_id}`]: null,
          };
        }, {});

        // NOTE: if the path is invalid (eg. user id or notificaiton id are incorrect),
        // there will be no error thrown, and instead a new entry will be created in Firebase at that location
        return (
          // .update() is recommmended by Firebase docs for updating multiple keys at once (functions as a transaction
          // so if any one update fails, all fail)
          // .set() (this will override the children as well), .transaction() can also be used, but they work one key at a time
          update(ref(db), updates)
        );
      });
      return Promise.all(firebaseRequests);
    }

    function getSelectedAssignments(students) {
      var assignmentsArrOfArr = students.map(function (student) {
        return Object.values(student.document_assignments).filter(function (da) {
          return da.selected;
        });
      });
      // we flatten the array down here
      return [].concat.apply([], assignmentsArrOfArr);
    }

    // how many assignments are selected
    this.countSelectedAssignments = function (section) {
      return getSelectedAssignments(section.students).length;
    };

    // takes view input such as {hour: 12, minute: 0, period: 'am', day: new Date() } and transforms into
    //  input that our server can accept in order to generate a correct Date object
    function setExpiresOnWithDate(expiresOnDay, expiresOn, timezone) {
      if (typeof expiresOnDay === 'undefined' || expiresOnDay === null)
        return $q.reject("Please choose a due date for these assignments to expire by clicking 'Select Expiry Date' above.");

      // converts the hour (1-12) to 24 hour time (1-24)
      let hour = convertHourTo24Hour(expiresOn);
      const params = {
        date: {
          // returns the day of the month
          day: expiresOnDay.getDate(),
          // returns the month number. add 1 since month numbers are 0 (Jan) to 11 (Dec)
          month: expiresOnDay.getMonth() + 1,
          year: expiresOnDay.getFullYear(),
          hour: parseInt(hour),
          min: parseInt(expiresOn.minute, 10),
        },
        timezone: timezone,
      };
      // Since we can't trust that the user's timezone is set correctly, and I can't find a reliable way
      //  to get a datetime object on a particular day/time in a particular timezone, we'll get it from
      //  the server here. I'm relying on the StackOverflow answer below plus a substantial amount of reading:
      // https://stackoverflow.com/questions/20834411/how-do-i-specify-the-time-zone-when-creating-a-javascript-date
      // TL;DR: "You can't set the timezone, however you can use UTC values to create a date object, adjust the hours
      // and minutes for the offset, then use the UTC methods to get the date and time components for the countdown."
      return $http.post('api/time_zone/date.json', params).then((res) => {
        return new Date(res.data);
      });
    }

    // returns a promise that resolves to the expiresOn date
    function setExpiresOnWithDuration(duration, options) {
      // duration = duration || { minutes: 110, hours: 0, days: 0 };
      // we make a copy here because duration is tied to the view, and if
      //  we modify it as below, it will malfunction
      duration = $.extend(true, { minutes: 110, hours: 0, days: 0 }, duration);
      options = options || {};
      // Prework: If options has the accommodation options...
      if (options.accommodation) {
        duration.minutes = 0;
        duration.hours = 0;
        duration.days = 7;
      }
      return $q.resolve(dateInFuture(duration.days, duration.hours, duration.minutes));
    }

    function dateInFuture(plusDays, plusHours, plusMinutes) {
      const now = Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF;
      const addDays = (plusDays || 0) * 24 * 60 * 60 * 1000;
      const addHours = (plusHours || 0) * 60 * 60 * 1000;
      const addMinutes = (plusMinutes || 0) * 60 * 1000;
      const dateInFuture = new Date(now + addDays + addHours + addMinutes);
      return dateInFuture;
    }

    // options should be: {studentIds: [123, 234]}   (an array of at least 1)
    // creates a notification for the student and returns: {notificationIds: [], studentIds: []}
    function createNotifications(studentIds = [], obj) {
      if (studentIds.length < 1) return Promise.reject();
      if (!obj) return Promise.reject();

      // Notification object data to be created in firebase
      const message = {
        sender: obj.instructor.first_name + ' ' + obj.instructor.last_name,
        expiresOn: obj.expiresOn.toString(), // changed from 20!!
        createdAt: serverTimestamp(),
        message: 'Assignment available: ' + obj.documentAssignment.copiedName,
        studentOnly: true,
        redirectState: 'app.documents.document_production_activity.pre',
        document_assignment_id: obj.document_assignment_id,
        active: true,
        showAlert: true,
      };

      const { notificationIds, updateObj } = createFirebaseUpdateObjectForUsers(studentIds, message);

      return update(ref(db), updateObj).then(() => {
        return {
          notificationIds,
          studentIds,
          obj,
        };
      });
    }

    function createPermissions(args) {
      // var notificationIds = [];
      var notificationIds = args.notificationIds;
      var studentIds = args.studentIds; // an array of student ids
      var obj = args.obj;

      var p = {
        userId: studentIds,
        sectionId: obj.section_id,
        expiresOn: obj.expiresOn,
        action: 'Document Assignment',
        document_assignment_id: obj.document_assignment_id,
        notification_id: notificationIds,
        options: Object.assign(obj.documentAssignment.options, obj.assignmentOverrideOptions),
      };

      return Permissions.create_timed_writings(p).then(function () {
        return args;
      });
    }

    // convert a 12 hour time with period (ie am or pm) to a 24 hour time
    // 12:30 am should return 00:30
    // 12:30 p.m. should return 12:30
    function convertHourTo24Hour(expiresOn) {
      let hour = parseInt(expiresOn.hour, 10);
      hour = hour === 12 ? 0 : hour;
      const isPM = expiresOn.period === 'pm' || expiresOn.period === 'p.m.';

      return isPM ? hour + 12 : hour;
    }

    return this;
  },
]);
