app.controller('ContactsDirectoryAdminController', [
  '$scope',
  '$http',
  'ContactsDirectory',
  '$q',
  'AdminContactsDirectoryCompanies',
  'AdminContactsDirectoryIndividuals',
  'AdminContactsDirectoryContact',
  function (
    $scope,
    $http,
    ContactsDirectory,
    $q,
    AdminContactsDirectoryCompanies,
    AdminContactsDirectoryIndividuals,
    AdminContactsDirectoryContact
  ) {
    /* *****
     * Variables
     * *****/

    $scope.view = {
      // Variable for tracking whether a contacts directory list is loading,
      contactsDirectoryLoading: false,
      // Variable for tracking whether a contacts directory list is loaded,
      contactsDirectoryLoaded: false,
      // Flash messages
      flashMessage: null, // in the form of { message: '', error: true}
    };

    // Ng-model for loading contacts directory from the server
    $scope.contactsDirectorySrc = {};

    /* *****
     * Initialize
     * *****/

    /* *****
     * Functions - scope mapping
     * *****/

    // Load contacts directory json object from a provided url
    $scope.loadContactsDirectory = (url = '') => {
      // Reset status variables
      $scope.view.contactsDirectoryLoaded = false;
      $scope.view.contactsDirectoryLoading = true;
      $scope.view.flashMessage = null;

      // Instantiate the contacts directory widget
      const contactsDirectory = new ContactsDirectory();
      contactsDirectory
        .init(url)
        // As soon as fetched, also render the structured contacts directory
        .then(() => {
          if (contactsDirectory.view.errorLoadingContactsList) {
            // contactsDirectory widget has its own error handling and catch statement,
            // So to see if a request failed, instead query its instance variable view.directoryListingLoaded
            return $q.reject();
          }

          // Allows the view to display the widgets
          $scope.view.contactsDirectoryLoaded = true;
          $scope.view.contactsDirectoryLoading = false;

          // This is for the searchable instructions widget
          $scope.instruction = {
            contactsDirectory,
          };

          // filter out internal company contacts
          const internalContactsDirectory = contactsDirectory.directoryListing
            .filter((contact) => contact.internal)
            // Instantiate a new Contact object, which has copyToClipboard functionality
            // This object is only used by the admin contacts directory
            .map((contact) => {
              return new AdminContactsDirectoryContact(contact);
            });

          // Repeat for external companies
          const externalContactsDirectory = contactsDirectory.directoryListing
            .filter((contact) => !contact.internal)
            // Instantiate a new Contact object, which has copyToClipboard functionality
            // This object is only used by the admin contacts directory
            .map((contact) => {
              return new AdminContactsDirectoryContact(contact);
            });

          // Repeat for individuals
          const individualsDirectory = contactsDirectory.directoryListing
            .filter((contact) => !contact.company)
            // Instantiate a new Contact object, which has copyToClipboard functionality
            // This object is only used by the admin contacts directory
            .map((contact) => {
              return new AdminContactsDirectoryContact(contact);
            });

          // Create internal companies contacts directory object
          $scope.ContactsDirectoryInternal = new AdminContactsDirectoryCompanies({
            contacts: internalContactsDirectory,
            sortByIndustry: false,
          });

          // Create external companies contacts directory object
          $scope.ContactsDirectoryExternal = new AdminContactsDirectoryCompanies({
            contacts: externalContactsDirectory,
            sortByIndustry: true,
          });

          // Create individuals contacts directory object
          $scope.ContactsDirectoryIndividuals = new AdminContactsDirectoryIndividuals({
            contacts: individualsDirectory,
          });

          // Create unique list of addresses across the entire contacts directory
          $scope.ContactsDirectoryUniqueAddresses = getUniqueAddressValues(contactsDirectory.directoryListing, 'streetAddress');
        })
        .catch((err) => {
          $scope.view.contactsDirectoryLoading = false;
          $scope.view.flashMessage = {
            message: 'Could not load contacts directory',
            error: true,
          };
        });
    };

    /* *****
     * Functions definitions
     * *****/

    // Function that gets a list of unique addresses in the contacts directory and returns a sorted list of string values
    // representing the full address
    function getUniqueAddressValues(objectsArray = []) {
      let acc = {};
      // The key over which to find list of unique values
      const key = 'streetAddress';
      objectsArray.forEach((obj) => {
        // only accept keys that exist on the object and have non-empty string values
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].length > 0) {
          // Store under the key equal to the value of the current address, thus only storing unique values
          // The value of the object is what part of the address should be presented to the viewer
          if (!acc.hasOwnProperty(obj[key])) {
            // Store value in accumulator only if it doesn't already exist
            // extract subset of keys such that _contact_card.html partial can be used, but without names and emails
            const { apartmentSuite, streetAddress, city, provinceAbbr, postalCode } = obj;
            acc[obj[key]] = {
              apartmentSuite,
              streetAddress,
              city,
              provinceAbbr,
              postalCode,
            };
            // acc[obj[key]] = `${obj['apartmentSuite'] ? obj['apartmentSuite'] + ', ' : ''}${obj['streetAddress']}, ${obj['city']} ${obj['provinceAbbr']}  ${obj['postalCode']}`;
          }
        }
      });
      return Object.values(acc).sort();
    }
  },
]);
