app.controller('AccountController', [
  '$scope',
  'currentUser',
  '$timeout',
  '$http',
  'accountSettings',
  'UserSettings',
  'LastNameCodeForm',
  function ($scope, currentUser, $timeout, $http, accountSettings, UserSettings, LastNameCodeForm) {
    /*--------------- 
    Scope Variable
    -----------------*/

    // User information to display
    $scope.currentUser = currentUser;
    // Angular model for holding data from the email edit form
    $scope.emailEdit = {};
    $scope.view = {
      // Toggles visibility of the email edit form
      showEmailEditForm: false,
      // toggles visibility of the initials create form
      flashMessage: null, // Any messages from the server upon form submission. Null if empty
      waitingForServer: false, // boolean variable used to disable form when waiting for server to respond
      // 8471, 8274, 11130, 8691 = Deb, Marilyn Sunni and Laura from RDC, 8753=Angela Wiens (st. RDC)
      orgHasDyslexiaModeEnabled: !([].indexOf(currentUser.id) > -1 || [].indexOf(currentUser.organization_id) > -1),
    };
    $scope.accountSettings = accountSettings;

    /*--------------- 
    Scope Functions 
    -----------------*/

    // handles updating of last_name_code of a user
    $scope.lastNameCodeForm = LastNameCodeForm;
    $scope.updateLastNameCode = (params) => {
      return $scope.lastNameCodeForm
        .submit(params)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          $scope.lastNameCodeForm.errorMessage =
            'Cannot update last name code. Please contact Typist Support at support@typistapp.ca';
          return;
        });
    };

    // Submit the email update form. Takes in the object of input fields. eg. {email: 'abc@gmail.com', emailConfirmation: 'abc@gmail.com'}
    $scope.submitEmailEditForm = function ({ email } = {}) {
      // 1. Send request to server to update user's email address
      //      - Disable submit while waiting for server
      $scope.view.waitingForServer = true;

      $http
        .put('users/update_email', { user: { email: email } })
        .then((res) => res.data)
        .then((updatedUser = {}) => {
          // a. Clear the form and collapse
          $scope.emailEdit = {};
          $scope.view.showEmailEditForm = false;
          // b. Display success message and scroll to the top
          $scope.view.flashMessage = {
            message: 'Email has been successfully updated, the page will refresh in 3s.',
            error: false,
          };
          // scroll to the top
          window.scrollTo(0, 0);

          // c. Temporarily swap the email in display until reload happens
          $scope.currentUser.email = updatedUser.email;

          // d. Reload browser in 3 sec so that the user object gets updated across the app
          // Note: if user navigates away, the reload will still happen
          // Therefore, to ensure the reload happens, do not destroy the timeout
          // Make sure the amount of seconds here reflects the flash message being displayed
          // Not clearing $scope.view.waitingForServer here because expect the page reload to happen anyways
          $timeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          // a. Re-enable form as no longer waiting for response from server
          $scope.view.waitingForServer = false;
          // n. Display success message and scroll to the top
          // Devise errors are strings in an array, while general system errors are objects
          // Display the first deviser error, otherwise a generic error message
          $scope.view.flashMessage = { message: Array.isArray(err.data) ? err.data[0] : 'Could not update email.', error: true };
          // scroll to the top
          window.scrollTo(0, 0);
        });
    };

    // Cancel the emailEdit form (clear the entries and collapse the form container)
    $scope.cancelEmailEditForm = function () {
      // 1. Collapse the container
      $scope.view.showEmailEditForm = false;
      // 2. Clear the form input
      $scope.emailEdit = {};
    };

    $scope.updateAccountSettings = () => {
      return UserSettings.set('accountSettings', $scope.accountSettings);
    };

    /*--------------- 
    Functions definitions 
    -----------------*/

    // activate tooltips
    $timeout(function () {
      // Code snippet from docs https://getbootstrap.com/docs/5.1/components/tooltips/#example-enable-tooltips-everywhere
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
      });
    }, 1000);
  },
]);
