// A Section contains an array of students & a test object

app.factory('Section', [
  'Test',
  'Student',
  '$timeout',
  '$http',
  'CacheService',
  'UserSettings',
  '$rootScope',
  'FirebaseActions',
  'StatisticService',
  'TooltipService',
  'DefaultSettings',
  '$q',
  'SectionHelpers',
  function (
    Test,
    Student,
    $timeout,
    $http,
    CacheService,
    UserSettings,
    $rootScope,
    FirebaseActions,
    StatisticService,
    TooltipService,
    DefaultSettings,
    $q,
    SectionHelpers
  ) {
    /**
     * Initializes a Section object. Section contains a list of students, a Test object, loads student data and watches for changes in Firebase.
     * @param {String} code - classcode used to load data from server. Ex: "CC23435".
     * @param {Object} scope - Controller local scope. Used to unload listeners when scope is destroyed.
     * @return {Object} Section - A Section object.
     */
    var Section = function (code, scope) {
      var self = this;
      this.code = code; // ex. CC3534
      this.instructor = $rootScope.user;
      this.hasDocumentProduction = false;
      this.practice = {
        // ng-model for currently selected practice time week to display.
        // It's either null (default for Last 10 Days option), or { day: 1, month: 2, year: 2020, dateObj: Date},
        // where dateObj is a js Date object used in view to display a properly formatted date
        startDate: null,
        // Determines whether to show a loading bar or not
        dataLoaded: false,
        // List of practice time weeks to choose from. This list is generated after classlist has been loaded
        selectFormWeekOptions: [],
      };

      // gets data from server and firebase using class code, adds to this.students
      var finishLoadingClasslist = getClass() // loads student names/ids, section, course_template, etc. from pg
        .then(loadViewSettings) // loads view settings, overwrites some from course_template, and default view settings
        .then(setTest) // loads test settings (speed, acc. etc.) from firebase
        .then(useFirebaseToWatchForChanges); // watches firebase-classcode if a student is added. registers listeners.

      // used to string promises
      this.init = function () {
        return (
          finishLoadingClasslist // ensures data from server and firebase has loaded (from above)
            .then(self.updateStatistics) // loads student data from server (avg_top_3, timed_writings, etc.)
            // .then(self.updatePracticeStats)                   // loads practice stats from server
            .then(function () {
              initComplete.resolve();
              return self;
            })
        );
      };

      var initComplete = $q.defer();
      this.onInit = function () {
        return initComplete.promise;
      };

      this.updateClasslist = function () {
        return getClass();
      };

      // Needs access to students and view.minPracticeTimeMinutes Make sure view is defined before accessing
      // Adds a stat property to practice time objects attached to student objects, which is a summation of days with practice times
      // that are greater than minPracticeTimeMinutes currently defined
      this.updatePracticeTimeStats = function () {
        if (self.view && self.view.minPracticeTimeMinutes) {
          self.students.forEach(function (student) {
            student.tabulatePracticeTimeStats(self.view.minPracticeTimeMinutes);
          });
        }
      };

      // useful function if we recall the section from a cache, but where the listeners were
      // destroyed with the scope
      this.refreshFirebaseReferences = function () {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useFirebaseToWatchForChanges();
      };

      // Updates the test results of each student
      // options can contain:
      //    options.statistics_params: ["timed_writings", ...]
      this.updateStatistics = function (options) {
        self.lastUpdatedAt = Math.floor(performance.now());

        options = options || {};
        var statistics_params = options.statistics_params;
        // set defaults if no statistics_params were passed in
        if (!statistics_params) {
          statistics_params = statistics_params || [
            'timed_writings',
            'tw_metadata',
            'lesson_progress',
            'practice_time',
            'timed_writing_permissions',
          ];
          // add document production if the course_template has document_production assignments
          if (hasDocumentAssignmentsInCourseTemplate()) statistics_params.push('document_production');
        }
        // If one of the statistics being updated is practice_time, initiate loading animation
        if (statistics_params.indexOf('practice_time') > -1) {
          self.practice.dataLoaded = false;
        }

        if (Number(self.test.criteria.speed) === 0) {
          self.test.criteria.speed = DefaultSettings.testSettings().speed;
        }
        if (Number(self.test.criteria.accuracy) === 0) {
          self.test.criteria.accuracy = DefaultSettings.testSettings().accuracy;
        }
        if (Number(self.test.criteria.max_errors) === 0) {
          self.test.criteria.max_errors = DefaultSettings.testSettings().max_errors;
        }

        return finishLoadingClasslist.then(function () {
          var acc =
            self.test.criteria.accuracy_metric === 'accuracy' ? self.test.criteria.accuracy : self.test.criteria.max_errors;
          var requirements = {
            speed: self.test.criteria.speed,
            type: self.test.criteria.type,
            accuracy: acc,
            duration_in_ms: self.test.criteria.duration * 1000 * 60,
            accuracy_metric: self.test.criteria.accuracy_metric,
            practice_days: 10,
            tw_limit: 5, // determines how many timed_writings to retrieve. 5 is needed so our tooltip can say "at lesat 5 times"
            timezone: self.instructor.organization.timezone,
          };
          // Server relies on start date requirements to be absent if relying on practice days only
          if (self.practice.startDate) {
            requirements = {
              ...requirements,
              practice_start_day: self.practice.startDate.day,
              practice_start_month: self.practice.startDate.month,
              practice_start_year: self.practice.startDate.year,
              // Hard code the number of days since the dropdown menu lists only a week at a time
              practice_days: 7,
            };
          }
          const scope = {
            section_code: self.code,
            // retrieves Permissions for Timed Writings that are active or expired recently (< 3 hours ago)
            // other options: 'all', 'active', 'recent'
            tw_permissions_status: 'recent',
          };
          return $q
            .all([
              StatisticService.get({
                // scope: { section_code: self.code },
                scope: scope,
                requirements: requirements,
                statistics: statistics_params,
              }),
              // Add a minimum delay before showing the results on screen (note this applies to all stats being fetched)
              // Not just practice_time
              SectionHelpers.minDelay(800),
            ])
            .then(([results]) => results)
            .then(updateViewWithTestResults);
        });
      };

      // returns a student with id matching id
      this.getStudent = function (id) {
        return this.students.filter(function (s) {
          return s.id === Number(id);
        })[0];
      };

      // determines if the data should be refreshsed because we're still using
      //   an old cached version.
      this.msSinceUpdate = function () {
        return Math.floor(performance.now()) - self.lastUpdatedAt;
      };

      // Helper functions ----------------------------------------------------------------------------------------
      function updateViewWithTestResults(results) {
        self.test.view.updateResultsMessage = 'Updated!';
        // makes the criteria in the Create Test modal the same as what we just used.
        self.test.updateTestParamsFromCriteria();
        $timeout(function () {
          self.test.view.promptUserToUpdateResults = false;
          self.test.view.updateResultsMessage = 'Press Enter To Update Test Results!';
        }, 500); // hide the button

        // add results to students ------
        self.students.forEach(function (student) {
          student.update(results);
          student.mapDocumentAssignmentsToCourseTemplate(self.course_template);
        });

        // If one of the statistics being updated is practice_time, initiate loading animation
        if (Array.isArray(results.config.data.statistics) && results.config.data.statistics.indexOf('practice_time') > -1) {
          self.practice.dataLoaded = true;
          // Update All students for practice time temporary statistics (eg. number of practice days with more than min required mins per day)
          self.updatePracticeTimeStats();
        }

        CacheService.set(self, self.code); // set cache
        // --------------

        // update user settings so criteria is the same the next time the instructor logs in
        UserSettings.set('class/' + self.code + '/test', {
          duration: self.test.criteria.duration,
          speed: self.test.criteria.speed,
          type: self.test.criteria.type,
          accuracy_metric: self.test.criteria.accuracy_metric,
          accuracy: self.test.criteria.accuracy,
          max_errors: self.test.criteria.max_errors,
          max_attempts: self.test.max_attempts,
          expiresAfterMinutes: self.test.expiresAfterMinutes,
        });

        // update the test criteria in the cache so when we click on a student
        //  the view is consistent
        CacheService.set(self.test, 'currentTest'); // update the currentTest in the cache
        self.updateTooltips();
        return self;
      }

      this.updateTooltips = TooltipService.updateTooltips;

      // called on instantiation. gets class data from postgres.
      function getClass() {
        return $http
          .get(`class/${code}.json`)
          .then(
            function (data) {
              return data;
            },
            function () {
              console.log('error in router');
            }
          )
          .then(addClasslistToSection);
      }

      // Function called when the practiceTime dropdown is updated (ng-change)
      // the model being updated is this.practice.startDate
      // Passed param is the Monday of a given week, for which we should fetch and show practice time data
      this.getCurrentPracticeTimeWeek = function () {
        return self.updateStatistics({
          statistics_params: ['practice_time'],
        });
      };

      // called when class is instantiated
      //  loads the classlist, sets the students
      function addClasslistToSection(response) {
        var section = response.data; // {section_id: __, name:'My Section', students: []}
        self.name = section.name; // ex. OAGN 115 - 101
        self.ehr = section.ehr;
        self.course_template = section.course_template;
        self.created_at = section.created_at;
        self.hasDocumentProduction = self.course_template && hasDocumentAssignmentsInCourseTemplate();
        self.students = section.students.map(function (student_params) {
          // assume same organization. needed to access timezone
          student_params.organization = self.instructor.organization;
          const student = new Student(student_params);
          student.view = { buttonMessage: 'Give Test', buttonDisabled: false, currentActivity: '' };
          return student;
        });
        self.id = section.section_id;
        // Generate the past training sections to choose from, using create_at date
        // 8353 = Sue.
        self.practice.selectFormWeekOptions = SectionHelpers.generatePracticeTimeWeekRanges(self.created_at);
        return self;
      }

      // called when class is instantiated
      //  loads the classlist, sets the self.test object
      function setTest() {
        // set the test object
        return UserSettings.get('class/' + self.code + '/test').then(function (testSettings) {
          testSettings = testSettings.orDefault(DefaultSettings.testSettings());
          self.test = new Test(self, testSettings);
          return self;
        });
      }

      // called when class is instantiated
      //  loads the classlist, sets the self.test object
      function loadViewSettings() {
        return UserSettings.get('class/' + self.code + '/view').then((viewSettings) => {
          // viewSettings are all the existing viewSettings from firebase
          // if a property is not set, then it has not been changed

          // overwrite any values here
          // ANY CHANGES MADE HERE SHOULD ALSO BE MADE IN STUDENT_FACTORY!
          if (!viewSettings.minPracticeTimeMinutes && self.course_template && self.course_template.min_practice_time_minutes) {
            viewSettings.minPracticeTimeMinutes = self.course_template.min_practice_time_minutes;
            console.log('Using practice time minutes from course template');
          } else if (!viewSettings.minPracticeTimeMinutes) {
            console.log('Using default practice time minutes');
          } else {
            console.log('Using practice time mins from firebase');
          }

          // apply defaults, writing any properties that are missing
          self.view = viewSettings.orDefault(DefaultSettings.classlistViewSettings());
          return self;
        });
      }

      // watches for changes to actions/sections/:id/studentAdded
      //  and then refreshes the section if needed
      function useFirebaseToWatchForChanges() {
        var firebaseActions = new FirebaseActions();
        scope.$on('$destroy', function () {
          firebaseActions.destroy();
        });
        firebaseActions.register(
          // will only run once. sets up firebase listener.
          'sections/' + self.id + '/studentAdded',
          function () {
            console.log('updated classlist');
            getClass().then(self.updateStatistics);
          }
        );
      }

      // document production -------------------------------------------------------

      // Pulls all the DocumentAssignments from within the course_template object, flattens and returns them
      this.getDocAssFromCourseTemplate = function () {
        var assignmentsArr = self.course_template.structure.map(function (week) {
          if (!week.courseWork) return [];
          return week.courseWork.filter(function (cw) {
            return cw.type === 'Document Production';
          });
        });

        // need to flatten the array
        const flattenedAssignments = [].concat.apply([], assignmentsArr);

        // flattenedAssignments is an array of all document assignments in the course template
        return flattenedAssignments;
      };

      function hasDocumentAssignmentsInCourseTemplate() {
        // Exit if we don't have any document production assignments in course template
        if (!self.course_template) return false;
        var documentAssignments = self.getDocAssFromCourseTemplate();
        if (!documentAssignments || documentAssignments.length === 0) return false;

        return true;
      }
    }; // end of constructor

    return Section;
  },
]);
