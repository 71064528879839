// designed to test large JSON loads
app.controller('LoadTestTimedWritingController', [
  '$scope',
  'TypingInput',
  '$http',
  '$timeout',
  'ModalSequence',
  '$q',
  '$state',
  function ($scope, TypingInput, $http, $timeout, ModalSequence, $q, $state) {
    console.log('LoadTestTimedWritingController active');

    // Global variables ---------------------------------------------------------------
    $scope.timedWritingModal = { practice: { duration: '0.1', type: 'netWPM' } }; // default duration value

    // Local variables ---------------------------------------------------------------
    // var resultsModalSequence = new ModalSequence("#results-modal-sequence");
    // var welcomeModalSequence = new ModalSequence("#startup");
    // var errorModalSequence = new ModalSequence("#error-modal-sequence");
    // welcomeModalSequence.start();
    var userMayStart = true;
    var sessionReady = newSession(); // newSession is a promise that resolves once ready

    // Creates a new TypingInput object, assigns it to $scope
    //  -> returs: A Promise that resolves once we're ready.
    function newSession() {
      $scope.TypingInput = TypingInput.newSession({
        activity_type: 'PracticeTimedWriting', // Used with save, autosave
        // primarySpeedMetric: "netWPM",  // Affects whether net/gross speed is displayed in view
        enableBackspace: true, // enable the backspace button?
        blockOnError: false, // stop cursor on an error?
        useCountdownTimer: true, // must call: TypingInput.timer.initialize( seconds ) before start
        updateStatsOnTimer: true, // update view stats every second?
        updateStatsOnKeystroke: false, // update view stats each keystroke?
        startIf: function () {
          return true;
        }, // start conditions
        onStart: function () {
          // run on start
        },
        eachSecond: function () {
          // if timer is enabled, this is run each second until expiry
          // if (TypingInput.timer.countdown%3 === 0) {
          // firebase.userStats().update({netWPM: TypingInput.stats.netWPM || 0, accuracy: TypingInput.stats.accuracy || 0});
          // }
        },
        onFinish: sessionFinished, // callback once contest is complete (line of text finished, or time expired)
      });
      return $scope.TypingInput.updateLineOfTextFromUrl('exercise/lineoftext.json');
    }

    // Enables the user to start.
    //   -> userMayStart must be bound to TypingInput object
    // function allowStart() { userMayStart = true; };

    // Callback to TypingInput
    //   -> Fires when the session is complete
    function sessionFinished() {
      userMayStart = false;
      var ks = [
        { target_key: 'R', key_pressed: 'R', previous_key: null, time_between_keys: null },
        { target_key: 'e', key_pressed: 'e', previous_key: 'R', time_between_keys: 90 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 146 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'd', time_between_keys: 136 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 78 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 57 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 112 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 101 },
        { target_key: 's', key_pressed: 's', previous_key: 'l', time_between_keys: 135 },
        { target_key: 'o', key_pressed: 'o', previous_key: 's', time_between_keys: 124 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 67 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 102 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 124 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 157 },
        { target_key: 'w', key_pressed: 'w', previous_key: 'o', time_between_keys: 58 },
        { target_key: 's', key_pressed: 's', previous_key: 'w', time_between_keys: 178 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 79 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 134 },
        { target_key: 'u', key_pressed: 'u', previous_key: 's', time_between_keys: 69 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'u', time_between_keys: 123 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'b', time_between_keys: 90 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'm', time_between_keys: 101 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 146 },
        { target_key: 's', key_pressed: 's', previous_key: 's', time_between_keys: 158 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 56 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'i', time_between_keys: 68 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 56 },
        { target_key: 's', key_pressed: 's', previous_key: 'n', time_between_keys: 60 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 101 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 75 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 68 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 101 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 34 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 72 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 175 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'd', time_between_keys: 45 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 101 },
        { target_key: 'n', key_pressed: 'n', previous_key: ' ', time_between_keys: 327 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'n', time_between_keys: 68 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 157 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 101 },
        { target_key: 'l', key_pressed: 'l', previous_key: ' ', time_between_keys: 79 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'l', time_between_keys: 46 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 88 },
        { target_key: 'k', key_pressed: 'k', previous_key: 'n', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'k', time_between_keys: 67 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 185 },
        { target_key: 'x', key_pressed: 'x', previous_key: 'e', time_between_keys: 187 },
        { target_key: 't', key_pressed: 't', previous_key: 'x', time_between_keys: 127 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 244 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 11 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'r', time_between_keys: 102 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'n', time_between_keys: 194 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 100 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 111 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'l', time_between_keys: 169 },
        { target_key: '.', key_pressed: '.', previous_key: 'y', time_between_keys: 205 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 87 },
        { target_key: 'T', key_pressed: 'T', previous_key: ' ', time_between_keys: 180 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'T', time_between_keys: 67 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 136 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 100 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 372 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 191 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 68 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 101 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 56 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 101 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 181 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'c', time_between_keys: 101 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 92 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 113 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 42 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 169 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 78 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 125 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 112 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'e', time_between_keys: 124 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'l', time_between_keys: 138 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'f', time_between_keys: 134 },
        { target_key: 'p', key_pressed: 'p', previous_key: ' ', time_between_keys: 216 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'p', time_between_keys: 108 },
        { target_key: 's', key_pressed: 's', previous_key: 'o', time_between_keys: 203 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 92 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 121 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 126 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 167 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 79 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 123 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 101 },
        { target_key: 'x', key_pressed: 'x', previous_key: 'e', time_between_keys: 214 },
        { target_key: 't', key_pressed: 't', previous_key: 'x', time_between_keys: 92 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 103 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 143 },
        { target_key: 'u', key_pressed: 'u', previous_key: 's', time_between_keys: 168 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'u', time_between_keys: 124 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'b', time_between_keys: 135 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'm', time_between_keys: 124 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 45 },
        { target_key: 's', key_pressed: 's', previous_key: 's', time_between_keys: 148 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 88 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'i', time_between_keys: 67 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 34 },
        { target_key: 's', key_pressed: 's', previous_key: 'n', time_between_keys: 79 },
        { target_key: '.', key_pressed: ' ', previous_key: 's', time_between_keys: 149 },
        { target_key: ' ', key_pressed: '.', previous_key: '.', time_between_keys: 177 },
        { target_key: 'M', key_pressed: 'M', previous_key: ' ', time_between_keys: 688 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'M', time_between_keys: 145 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 80 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'n', time_between_keys: 212 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 124 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 124 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 67 },
        { target_key: 'c', key_pressed: 'c', previous_key: 's', time_between_keys: 203 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'c', time_between_keys: 56 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 135 },
        { target_key: 's', key_pressed: 's', previous_key: 's', time_between_keys: 146 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 69 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'i', time_between_keys: 44 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 67 },
        { target_key: '-', key_pressed: '-', previous_key: 'n', time_between_keys: 281 },
        { target_key: 'b', key_pressed: 'b', previous_key: '-', time_between_keys: 127 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'b', time_between_keys: 120 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 139 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 98 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 202 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 113 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 305 },
        { target_key: 'u', key_pressed: 'u', previous_key: 's', time_between_keys: 145 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'u', time_between_keys: 126 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'b', time_between_keys: 279 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 102 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 213 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'd', time_between_keys: 146 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 112 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 102 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 124 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 101 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 146 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 79 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 149 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 222 },
        { target_key: 'w', key_pressed: 'w', previous_key: 'o', time_between_keys: 94 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'w', time_between_keys: 109 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 79 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 100 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'n', time_between_keys: 102 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'l', time_between_keys: 126 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 134 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 201 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 82 },
        { target_key: 'x', key_pressed: 'x', previous_key: 'e', time_between_keys: 211 },
        { target_key: 't', key_pressed: 't', previous_key: 'x', time_between_keys: 78 },
        { target_key: '-', key_pressed: '-', previous_key: 't', time_between_keys: 406 },
        { target_key: 'o', key_pressed: 'o', previous_key: '-', time_between_keys: 281 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 112 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'n', time_between_keys: 113 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'l', time_between_keys: 159 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 124 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 133 },
        { target_key: 'u', key_pressed: 'u', previous_key: 's', time_between_keys: 68 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'u', time_between_keys: 123 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'b', time_between_keys: 135 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'm', time_between_keys: 113 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 90 },
        { target_key: 's', key_pressed: 's', previous_key: 's', time_between_keys: 191 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 11 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'i', time_between_keys: 70 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 53 },
        { target_key: 's', key_pressed: 's', previous_key: 'n', time_between_keys: 57 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 158 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 384 },
        { target_key: 'u', key_pressed: 'u', previous_key: 's', time_between_keys: 89 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'u', time_between_keys: 112 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'c', time_between_keys: 114 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'h', time_between_keys: 55 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 90 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 112 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 92 },
        { target_key: 'A', key_pressed: 'a', previous_key: ' ', time_between_keys: 493 },
        { target_key: 's', key_pressed: 's', previous_key: 'A', time_between_keys: 146 },
        { target_key: 'k', key_pressed: 'k', previous_key: 's', time_between_keys: 720 },
        { target_key: 'R', key_pressed: 'R', previous_key: 'k', time_between_keys: 498 },
        { target_key: 'e', key_pressed: 'E', previous_key: 'R', time_between_keys: 64 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 203 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'd', time_between_keys: 112 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 126 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 89 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 112 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 146 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'w', time_between_keys: 81 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 166 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 59 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 156 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 123 },
        { target_key: 'u', key_pressed: 'u', previous_key: ' ', time_between_keys: 248 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 90 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 90 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 68 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 149 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 289 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 191 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 90 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 127 },
        { target_key: 'o', key_pressed: 'a', previous_key: ' ', time_between_keys: 132 },
        { target_key: 'n', key_pressed: 'l', previous_key: 'o', time_between_keys: 79 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'n', time_between_keys: 743 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'l', time_between_keys: 168 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 214 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 135 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 101 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 158 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 124 },
        { target_key: 'w', key_pressed: 'w', previous_key: 'o', time_between_keys: 69 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'w', time_between_keys: 144 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 203 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 79 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 112 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 68 },
        { target_key: 'p', key_pressed: 'p', previous_key: ' ', time_between_keys: 112 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'p', time_between_keys: 113 },
        { target_key: 's', key_pressed: 's', previous_key: 'o', time_between_keys: 123 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 169 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 202 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 417 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'b', time_between_keys: 247 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'r', time_between_keys: 102 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'o', time_between_keys: 69 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'a', time_between_keys: 223 },
        { target_key: ',', key_pressed: ',', previous_key: 'd', time_between_keys: 225 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 90 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 484 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 90 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 101 },
        { target_key: 'c', key_pressed: 'c', previous_key: 's', time_between_keys: 237 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'c', time_between_keys: 103 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 178 },
        { target_key: 's', key_pressed: 's', previous_key: 's', time_between_keys: 145 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 102 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'i', time_between_keys: 90 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 57 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 179 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 181 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'b', time_between_keys: 78 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 136 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 90 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 190 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 124 },
        { target_key: 'q', key_pressed: 'q', previous_key: ' ', time_between_keys: 405 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'q', time_between_keys: 90 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'u', time_between_keys: 123 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 83 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 109 },
        { target_key: 'i', key_pressed: 'i', previous_key: 't', time_between_keys: 45 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'i', time_between_keys: 80 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 32 },
        { target_key: 's', key_pressed: 's', previous_key: 'n', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 169 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 66 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 94 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 86 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 68 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 102 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 213 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 82 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 53 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'm', time_between_keys: 180 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'm', time_between_keys: 215 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'u', time_between_keys: 68 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'n', time_between_keys: 145 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 159 },
        { target_key: 'y', key_pressed: 'y', previous_key: 't', time_between_keys: 79 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 122 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 158 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 136 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 89 },
        { target_key: 'l', key_pressed: 'l', previous_key: ' ', time_between_keys: 146 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'l', time_between_keys: 101 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 90 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'r', time_between_keys: 248 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'g', time_between_keys: 135 },
        { target_key: '.', key_pressed: '.', previous_key: 'e', time_between_keys: 213 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 124 },
        { target_key: 'S', key_pressed: 'S', previous_key: ' ', time_between_keys: 207 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'S', time_between_keys: 187 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'e', time_between_keys: 101 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'l', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'f', time_between_keys: 103 },
        { target_key: 'p', key_pressed: 'p', previous_key: ' ', time_between_keys: 618 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'p', time_between_keys: 112 },
        { target_key: 's', key_pressed: 's', previous_key: 'o', time_between_keys: 168 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 68 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 146 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 146 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 361 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'd', time_between_keys: 57 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 100 },
        { target_key: 'n', key_pressed: 'n', previous_key: ' ', time_between_keys: 117 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'n', time_between_keys: 86 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 56 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 112 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 113 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'a', time_between_keys: 360 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'c', time_between_keys: 191 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'c', time_between_keys: 135 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'u', time_between_keys: 226 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'm', time_between_keys: 215 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'u', time_between_keys: 110 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'l', time_between_keys: 146 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 68 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 92 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 133 },
        { target_key: 'k', key_pressed: 'k', previous_key: ' ', time_between_keys: 293 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'k', time_between_keys: 123 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 60 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'r', time_between_keys: 143 },
        { target_key: 'a', key_pressed: ' ', previous_key: 'm', time_between_keys: 214 },
        { target_key: ' ', key_pressed: 'a', previous_key: 'a', time_between_keys: 158 },
        { target_key: 'p', key_pressed: 'p', previous_key: ' ', time_between_keys: 697 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'p', time_between_keys: 101 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'o', time_between_keys: 113 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 55 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 90 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 282 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 114 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 167 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'f', time_between_keys: 79 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 79 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 112 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 101 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 60 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 100 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 88 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 364 },
        { target_key: 'u', key_pressed: 'u', previous_key: 's', time_between_keys: 85 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'u', time_between_keys: 158 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'b', time_between_keys: 124 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'm', time_between_keys: 83 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 165 },
        { target_key: 't', key_pressed: 't', previous_key: 't', time_between_keys: 146 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 112 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 127 },
        { target_key: ',', key_pressed: ',', previous_key: 'r', time_between_keys: 155 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 81 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 166 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'b', time_between_keys: 124 },
        { target_key: 't', key_pressed: 't', previous_key: 'u', time_between_keys: 78 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 147 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 45 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 136 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 78 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'e', time_between_keys: 169 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 103 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 178 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'c', time_between_keys: 112 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 146 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 57 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 146 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 247 },
        { target_key: 'i', key_pressed: 'i', previous_key: 't', time_between_keys: 34 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'i', time_between_keys: 90 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'l', time_between_keys: 45 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 180 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'b', time_between_keys: 191 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 102 },
        { target_key: 'v', key_pressed: 'v', previous_key: ' ', time_between_keys: 394 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'v', time_between_keys: 134 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 833 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 112 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 214 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 91 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 123 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 90 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 218 },
        { target_key: 'l', key_pressed: 'l', previous_key: ' ', time_between_keys: 266 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'l', time_between_keys: 316 },
        { target_key: 'k', key_pressed: 'k', previous_key: 'i', time_between_keys: 305 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'k', time_between_keys: 77 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 100 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 192 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 112 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 90 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 68 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 56 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 123 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 181 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 67 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 45 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 101 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 116 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 110 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 123 },
        { target_key: '.', key_pressed: '.', previous_key: 't', time_between_keys: 203 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 67 },
        { target_key: 'M', key_pressed: 'M', previous_key: ' ', time_between_keys: 248 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'M', time_between_keys: 157 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 68 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 127 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 87 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 89 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 90 },
        { target_key: 'S', key_pressed: 's', previous_key: ' ', time_between_keys: 202 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'S', time_between_keys: 563 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'p', time_between_keys: 237 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'l', time_between_keys: 664 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 126 },
        { target_key: 'h', key_pressed: 'h', previous_key: 's', time_between_keys: 109 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'h', time_between_keys: 216 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 155 },
        { target_key: 'P', key_pressed: 'P', previous_key: ' ', time_between_keys: 620 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'P', time_between_keys: 213 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 112 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 237 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 90 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 105 },
        { target_key: 'l', key_pressed: 'l', previous_key: ' ', time_between_keys: 165 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 180 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'o', time_between_keys: 68 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'g', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 123 },
        { target_key: 'u', key_pressed: 'u', previous_key: ' ', time_between_keys: 192 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 89 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 56 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 169 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 90 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 181 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 70 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 110 },
        { target_key: 'N', key_pressed: 'N', previous_key: ' ', time_between_keys: 382 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'N', time_between_keys: 191 },
        { target_key: 'v', key_pressed: 'v', previous_key: 'o', time_between_keys: 304 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'v', time_between_keys: 259 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'e', time_between_keys: 126 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'm', time_between_keys: 234 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'b', time_between_keys: 202 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 67 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 102 },
        { target_key: '2', key_pressed: '2', previous_key: ' ', time_between_keys: 788 },
        { target_key: '7', key_pressed: '7', previous_key: '2', time_between_keys: 181 },
        { target_key: ',', key_pressed: ',', previous_key: '7', time_between_keys: 291 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 114 },
        { target_key: '2', key_pressed: '2', previous_key: ' ', time_between_keys: 258 },
        { target_key: '0', key_pressed: '0', previous_key: '2', time_between_keys: 100 },
        { target_key: '0', key_pressed: '0', previous_key: '0', time_between_keys: 113 },
        { target_key: '7', key_pressed: '8', previous_key: '0', time_between_keys: 540 },
        { target_key: ' ', key_pressed: ' ', previous_key: '7', time_between_keys: 214 },
        { target_key: 'R', key_pressed: 'R', previous_key: ' ', time_between_keys: 326 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'R', time_between_keys: 473 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 213 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'd', time_between_keys: 124 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 101 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 78 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 214 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 56 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 91 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'm', time_between_keys: 134 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'm', time_between_keys: 182 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'u', time_between_keys: 88 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'n', time_between_keys: 124 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 596 },
        { target_key: 'i', key_pressed: 'i', previous_key: 't', time_between_keys: 372 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'i', time_between_keys: 67 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 33 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 113 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 214 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'o', time_between_keys: 169 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'c', time_between_keys: 169 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'c', time_between_keys: 179 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 146 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 170 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'i', time_between_keys: 137 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 155 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'n', time_between_keys: 103 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 122 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 135 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'l', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 101 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 248 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 90 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'o', time_between_keys: 123 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 327 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'r', time_between_keys: 237 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 33 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 90 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'n', time_between_keys: 90 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 91 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 89 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 78 },
        { target_key: 'R', key_pressed: 'R', previous_key: ' ', time_between_keys: 248 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'R', time_between_keys: 90 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 203 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'd', time_between_keys: 146 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 56 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 113 },
        { target_key: '-', key_pressed: '-', previous_key: 't', time_between_keys: 259 },
        { target_key: 'e', key_pressed: 'e', previous_key: '-', time_between_keys: 348 },
        { target_key: 'x', key_pressed: 'x', previous_key: 'e', time_between_keys: 225 },
        { target_key: 't', key_pressed: 't', previous_key: 'x', time_between_keys: 127 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 154 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 67 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'r', time_between_keys: 79 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'n', time_between_keys: 125 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'l', time_between_keys: 100 },
        { target_key: 'p', key_pressed: 'p', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'p', time_between_keys: 102 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'r', time_between_keys: 101 },
        { target_key: 'j', key_pressed: 'j', previous_key: 'o', time_between_keys: 123 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'j', time_between_keys: 124 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'e', time_between_keys: 191 },
        { target_key: 't', key_pressed: 't', previous_key: 'c', time_between_keys: 135 },
        { target_key: 's', key_pressed: ' ', previous_key: 't', time_between_keys: 117 },
        { target_key: ' ', key_pressed: 's', previous_key: 's', time_between_keys: 63 },
        { target_key: 's', key_pressed: 'u', previous_key: ' ', time_between_keys: 259 },
        { target_key: 'u', key_pressed: 'c', previous_key: 's', time_between_keys: 22 },
        { target_key: 'c', key_pressed: 'h', previous_key: 'u', time_between_keys: 90 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'c', time_between_keys: 687 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'h', time_between_keys: 474 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 111 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 91 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 145 },
        { target_key: 'k', key_pressed: 'k', previous_key: 's', time_between_keys: 169 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'k', time_between_keys: 90 },
        { target_key: 'w', key_pressed: 'w', previous_key: 'e', time_between_keys: 270 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'w', time_between_keys: 202 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 104 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'g', time_between_keys: 110 },
        { target_key: 'p', key_pressed: 'p', previous_key: ' ', time_between_keys: 136 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'p', time_between_keys: 136 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'o', time_between_keys: 235 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 112 },
        { target_key: 's', key_pressed: 's', previous_key: 'l', time_between_keys: 137 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 77 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 101 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 34 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 225 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 115 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 110 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 80 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 44 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 121 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 216 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'w', time_between_keys: 12 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'e', time_between_keys: 258 },
        { target_key: 's', key_pressed: 's', previous_key: 'b', time_between_keys: 248 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 78 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 214 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 102 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 78 },
        { target_key: ',', key_pressed: ',', previous_key: 's', time_between_keys: 206 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 87 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 168 },
        { target_key: 'u', key_pressed: 'u', previous_key: 's', time_between_keys: 113 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'u', time_between_keys: 135 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'c', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'h', time_between_keys: 94 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 98 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 540 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 123 },
        { target_key: 'i', key_pressed: 'i', previous_key: ' ', time_between_keys: 57 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 112 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 78 },
        { target_key: '2', key_pressed: '2', previous_key: ' ', time_between_keys: 428 },
        { target_key: '0', key_pressed: '0', previous_key: '2', time_between_keys: 56 },
        { target_key: '0', key_pressed: '0', previous_key: '0', time_between_keys: 135 },
        { target_key: '7', key_pressed: '8', previous_key: '0', time_between_keys: 136 },
        { target_key: ' ', key_pressed: ' ', previous_key: '7', time_between_keys: 527 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 350 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'w', time_between_keys: 80 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 111 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 138 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 103 },
        { target_key: 'G', key_pressed: 'G', previous_key: ' ', time_between_keys: 344 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'G', time_between_keys: 203 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 104 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'e', time_between_keys: 132 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 101 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'n', time_between_keys: 348 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'p', time_between_keys: 260 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'e', time_between_keys: 146 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'a', time_between_keys: 337 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'c', time_between_keys: 193 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 70 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 120 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 67 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 146 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 181 },
        { target_key: 'w', key_pressed: 'w', previous_key: 'o', time_between_keys: 46 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'w', time_between_keys: 145 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 214 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 67 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 247 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'w', time_between_keys: 101 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'e', time_between_keys: 237 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'b', time_between_keys: 136 },
        { target_key: 'u', key_pressed: 's', previous_key: ' ', time_between_keys: 156 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 732 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 225 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 112 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 226 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 78 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 90 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 33 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 395 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 180 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'e', time_between_keys: 213 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'c', time_between_keys: 68 },
        { target_key: 'd', key_pressed: 'e', previous_key: 'i', time_between_keys: 180 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 720 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 236 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 214 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 125 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 100 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 81 },
        { target_key: 'n', key_pressed: 'n', previous_key: ' ', time_between_keys: 123 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'n', time_between_keys: 112 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'a', time_between_keys: 100 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'm', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 91 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 55 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'o', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'f', time_between_keys: 101 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 262 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'a', time_between_keys: 99 },
        { target_key: 'k', key_pressed: 'k', previous_key: ' ', time_between_keys: 179 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'k', time_between_keys: 180 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'i', time_between_keys: 56 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 169 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 113 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 79 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 89 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 138 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'w', time_between_keys: 54 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 146 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 92 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 144 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 112 },
        { target_key: 'i', key_pressed: 'i', previous_key: ' ', time_between_keys: 181 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 123 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 125 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 112 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'w', time_between_keys: 123 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 101 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 125 },
        { target_key: 'r', key_pressed: 'r', previous_key: 't', time_between_keys: 177 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'r', time_between_keys: 134 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'a', time_between_keys: 166 },
        { target_key: 'k', key_pressed: 'k', previous_key: 'c', time_between_keys: 141 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'k', time_between_keys: 192 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 90 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 190 },
        { target_key: '.', key_pressed: '.', previous_key: 'g', time_between_keys: 361 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 146 },
        { target_key: 'R', key_pressed: 'R', previous_key: ' ', time_between_keys: 305 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'R', time_between_keys: 111 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 215 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'd', time_between_keys: 145 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 91 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 111 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 125 },
        { target_key: 'u', key_pressed: 'u', previous_key: ' ', time_between_keys: 112 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 126 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 70 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 99 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 144 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 169 },
        { target_key: 'v', key_pressed: 'o', previous_key: ' ', time_between_keys: 360 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'v', time_between_keys: 585 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 157 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 79 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 214 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 101 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 306 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 110 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 58 },
        { target_key: 'm', key_pressed: 'm', previous_key: ' ', time_between_keys: 201 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'm', time_between_keys: 101 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 148 },
        { target_key: 's', key_pressed: 's', previous_key: 's', time_between_keys: 155 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 125 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 145 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 102 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 93 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 53 },
        { target_key: 'n', key_pressed: 'n', previous_key: ' ', time_between_keys: 147 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'n', time_between_keys: 79 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'a', time_between_keys: 112 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'm', time_between_keys: 95 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 74 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 90 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 89 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 93 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 76 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'w', time_between_keys: 90 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 203 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 89 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 124 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 93 },
        { target_key: 'M', key_pressed: 'M', previous_key: ' ', time_between_keys: 268 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'M', time_between_keys: 269 },
        { target_key: '.', key_pressed: '.', previous_key: 'r', time_between_keys: 158 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 124 },
        { target_key: 'S', key_pressed: 'S', previous_key: ' ', time_between_keys: 251 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'S', time_between_keys: 109 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'p', time_between_keys: 135 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'l', time_between_keys: 180 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 168 },
        { target_key: 'h', key_pressed: 'y', previous_key: 's', time_between_keys: 473 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'h', time_between_keys: 416 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 191 },
        { target_key: 'P', key_pressed: 'P', previous_key: ' ', time_between_keys: 372 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'P', time_between_keys: 168 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 147 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 135 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 213 },
        { target_key: ',', key_pressed: ',', previous_key: 's', time_between_keys: 203 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 91 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 235 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 124 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'n', time_between_keys: 124 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 281 },
        { target_key: 'R', key_pressed: 'r', previous_key: ' ', time_between_keys: 203 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'R', time_between_keys: 663 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 191 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'd', time_between_keys: 146 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 103 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 134 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 89 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'a', time_between_keys: 225 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'd', time_between_keys: 136 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'm', time_between_keys: 123 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 129 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'n', time_between_keys: 118 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 158 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 112 },
        { target_key: 'r', key_pressed: 'r', previous_key: 't', time_between_keys: 195 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'r', time_between_keys: 111 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 88 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 282 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 78 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 214 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 169 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 405 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'f', time_between_keys: 103 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'u', time_between_keys: 155 },
        { target_key: 't', key_pressed: 't', previous_key: 'r', time_between_keys: 203 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 112 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 113 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 22 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 161 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 177 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 101 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'n', time_between_keys: 158 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 116 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'o', time_between_keys: 52 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'u', time_between_keys: 101 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'r', time_between_keys: 136 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'a', time_between_keys: 193 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'g', time_between_keys: 101 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 202 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 89 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 202 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 45 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 67 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 125 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 72 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 141 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'b', time_between_keys: 169 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 90 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 282 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'c', time_between_keys: 123 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 135 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 112 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 124 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'g', time_between_keys: 115 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 76 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'g', time_between_keys: 101 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 147 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 56 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 169 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 101 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 237 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 90 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 80 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 100 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 146 },
        { target_key: 'l', key_pressed: 'l', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 191 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'o', time_between_keys: 56 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'g', time_between_keys: 146 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 79 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 180 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 90 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 56 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'a', time_between_keys: 146 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 229 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'w', time_between_keys: 52 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 124 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 135 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 563 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 135 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 225 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'd', time_between_keys: 80 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'u', time_between_keys: 102 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'r', time_between_keys: 110 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 91 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 67 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'g', time_between_keys: 191 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 46 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 190 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 106 },
        { target_key: 'v', key_pressed: 'v', previous_key: ' ', time_between_keys: 671 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'v', time_between_keys: 145 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 259 },
        { target_key: 'i', key_pressed: 'i', previous_key: 't', time_between_keys: 327 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 80 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 67 },
        { target_key: '.', key_pressed: '.', previous_key: 'g', time_between_keys: 179 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 101 },
        { target_key: 'I', key_pressed: 'I', previous_key: ' ', time_between_keys: 315 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'I', time_between_keys: 157 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 113 },
        { target_key: 'D', key_pressed: 'D', previous_key: ' ', time_between_keys: 304 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'D', time_between_keys: 182 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'e', time_between_keys: 392 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'c', time_between_keys: 294 },
        { target_key: 'm', key_pressed: ',', previous_key: 'e', time_between_keys: 99 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'm', time_between_keys: 315 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'b', time_between_keys: 507 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 78 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 182 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 235 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'o', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'f', time_between_keys: 90 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 123 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 68 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 123 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 45 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 47 },
        { target_key: 'y', key_pressed: 'y', previous_key: ' ', time_between_keys: 201 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'y', time_between_keys: 79 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'e', time_between_keys: 67 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 90 },
        { target_key: ',', key_pressed: 'r', previous_key: 'r', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ',', previous_key: ',', time_between_keys: 113 },
        { target_key: 'M', key_pressed: ' ', previous_key: ' ', time_between_keys: 225 },
        { target_key: 'i', key_pressed: 'M', previous_key: 'M', time_between_keys: 213 },
        { target_key: 's', key_pressed: 'i', previous_key: 'i', time_between_keys: 464 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 718 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 203 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 78 },
        { target_key: 'S', key_pressed: 'S', previous_key: ' ', time_between_keys: 339 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'S', time_between_keys: 145 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'p', time_between_keys: 68 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'l', time_between_keys: 124 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 135 },
        { target_key: 'h', key_pressed: 'h', previous_key: 's', time_between_keys: 112 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'h', time_between_keys: 225 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 146 },
        { target_key: 'P', key_pressed: 'P', previous_key: ' ', time_between_keys: 410 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'P', time_between_keys: 152 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 79 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 182 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 123 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 66 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 203 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'w', time_between_keys: 135 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 101 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 214 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 101 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'n', time_between_keys: 158 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'n', time_between_keys: 78 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'o', time_between_keys: 68 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'u', time_between_keys: 78 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'n', time_between_keys: 113 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'c', time_between_keys: 202 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 192 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 57 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 111 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 90 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 67 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 125 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 45 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 157 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 45 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 237 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'w', time_between_keys: 146 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 33 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'n', time_between_keys: 180 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'n', time_between_keys: 125 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 212 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 113 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 102 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'o', time_between_keys: 134 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'f', time_between_keys: 68 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 103 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 77 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 90 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 283 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 77 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 36 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'm', time_between_keys: 147 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'p', time_between_keys: 180 },
        { target_key: 't', key_pressed: 't', previous_key: 'e', time_between_keys: 235 },
        { target_key: 'i', key_pressed: 'n', previous_key: 't', time_between_keys: 145 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 900 },
        { target_key: 'i', key_pressed: 'i', previous_key: 't', time_between_keys: 90 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'i', time_between_keys: 90 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 78 },
        { target_key: '.', key_pressed: ' ', previous_key: 'n', time_between_keys: 125 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 686 },
        { target_key: 'W', key_pressed: 'W', previous_key: ' ', time_between_keys: 439 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'W', time_between_keys: 114 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 190 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 79 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 291 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 157 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 135 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 56 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 67 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 72 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 75 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 90 },
        { target_key: ',', key_pressed: ',', previous_key: 'e', time_between_keys: 182 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 77 },
        { target_key: 'r', key_pressed: 'r', previous_key: ' ', time_between_keys: 213 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 45 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 248 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'd', time_between_keys: 146 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 102 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 224 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 113 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 112 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 140 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 134 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 300 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 67 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 114 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'm', time_between_keys: 122 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'm', time_between_keys: 271 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'e', time_between_keys: 90 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'm', time_between_keys: 259 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 92 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'r', time_between_keys: 234 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 69 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 77 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 90 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 101 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'e', time_between_keys: 67 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'i', time_between_keys: 92 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 100 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 134 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'c', time_between_keys: 225 },
        { target_key: 'k', key_pressed: 'k', previous_key: 'a', time_between_keys: 126 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'k', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 111 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 146 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'd', time_between_keys: 169 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'a', time_between_keys: 137 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 144 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 113 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 90 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'n', time_between_keys: 281 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'c', time_between_keys: 191 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 83 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 187 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'a', time_between_keys: 102 },
        { target_key: 'y', key_pressed: 'y', previous_key: ' ', time_between_keys: 134 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'y', time_between_keys: 127 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'e', time_between_keys: 87 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 80 },
        { target_key: ',', key_pressed: ',', previous_key: 'r', time_between_keys: 156 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 102 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 214 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'w', time_between_keys: 123 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 45 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'i', time_between_keys: 196 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'c', time_between_keys: 74 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'h', time_between_keys: 135 },
        { target_key: 'i', key_pressed: 'i', previous_key: ' ', time_between_keys: 112 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 48 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 121 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 67 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 115 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 201 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 89 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'n', time_between_keys: 159 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'n', time_between_keys: 77 },
        { target_key: 'v', key_pressed: 'v', previous_key: 'i', time_between_keys: 270 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'v', time_between_keys: 214 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 67 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 192 },
        { target_key: 'a', key_pressed: 'a', previous_key: 's', time_between_keys: 134 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 271 },
        { target_key: 'y', key_pressed: 'i', previous_key: 'r', time_between_keys: 157 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 721 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 134 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'o', time_between_keys: 124 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'f', time_between_keys: 67 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 1013 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 112 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 225 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'd', time_between_keys: 158 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'a', time_between_keys: 193 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 135 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 561 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 78 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 126 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 62 },
        { target_key: 'u', key_pressed: 'u', previous_key: ' ', time_between_keys: 184 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 44 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 68 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 68 },
        { target_key: "'", key_pressed: "'", previous_key: 'r', time_between_keys: 157 },
        { target_key: 's', key_pressed: 's', previous_key: "'", time_between_keys: 158 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 78 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 237 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'a', time_between_keys: 247 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'c', time_between_keys: 147 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 56 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'o', time_between_keys: 89 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'u', time_between_keys: 23 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 79 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 157 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'w', time_between_keys: 123 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 114 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 89 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'f', time_between_keys: 112 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'i', time_between_keys: 114 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 134 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 81 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 121 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 263 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'c', time_between_keys: 188 },
        { target_key: 'e', key_pressed: 'a', previous_key: 'r', time_between_keys: 202 },
        { target_key: 'a', key_pressed: 't', previous_key: 'e', time_between_keys: 92 },
        { target_key: 't', key_pressed: 'e', previous_key: 'a', time_between_keys: 76 },
        { target_key: 'e', key_pressed: 'd', previous_key: 't', time_between_keys: 192 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 771 },
        { target_key: '.', key_pressed: '.', previous_key: 'd', time_between_keys: 242 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 123 },
        { target_key: 'T', key_pressed: 'T', previous_key: ' ', time_between_keys: 248 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'T', time_between_keys: 101 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 80 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 336 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'c', time_between_keys: 135 },
        { target_key: 'k', key_pressed: 'k', previous_key: 'a', time_between_keys: 115 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'k', time_between_keys: 189 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 67 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 371 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'd', time_between_keys: 158 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'a', time_between_keys: 218 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 89 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 194 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'o', time_between_keys: 62 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'f', time_between_keys: 156 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'f', time_between_keys: 160 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 87 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 91 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 101 },
        { target_key: 'n', key_pressed: 'n', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'n', time_between_keys: 69 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 122 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 259 },
        { target_key: 'p', key_pressed: 'p', previous_key: 's', time_between_keys: 79 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'p', time_between_keys: 101 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'e', time_between_keys: 225 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'c', time_between_keys: 83 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'i', time_between_keys: 133 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 99 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'l', time_between_keys: 113 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 213 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'b', time_between_keys: 214 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 135 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'n', time_between_keys: 64 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'e', time_between_keys: 229 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'f', time_between_keys: 112 },
        { target_key: 't', key_pressed: 't', previous_key: 'i', time_between_keys: 338 },
        { target_key: ',', key_pressed: ',', previous_key: 't', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 149 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 301 },
        { target_key: 'x', key_pressed: 'x', previous_key: 'e', time_between_keys: 215 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'x', time_between_keys: 325 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'c', time_between_keys: 393 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'e', time_between_keys: 135 },
        { target_key: 't', key_pressed: 't', previous_key: 'p', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 82 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 110 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 90 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 22 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 92 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 68 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 103 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'a', time_between_keys: 65 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 184 },
        { target_key: 'm', key_pressed: 'm', previous_key: 's', time_between_keys: 73 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'm', time_between_keys: 112 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 105 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 109 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'l', time_between_keys: 102 },
        { target_key: 'i', key_pressed: 'i', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'i', time_between_keys: 292 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 103 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 67 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 224 },
        { target_key: 'r', key_pressed: 'r', previous_key: ' ', time_between_keys: 135 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 101 },
        { target_key: 'p', key_pressed: 's', previous_key: 'e', time_between_keys: 236 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'p', time_between_keys: 777 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 56 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 157 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 225 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 91 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 89 },
        { target_key: 'i', key_pressed: 'i', previous_key: 't', time_between_keys: 130 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 50 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 80 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'g', time_between_keys: 100 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'a', time_between_keys: 115 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 156 },
        { target_key: 'l', key_pressed: 'l', previous_key: 's', time_between_keys: 112 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'l', time_between_keys: 90 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'i', time_between_keys: 135 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'c', time_between_keys: 473 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 78 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 135 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'o', time_between_keys: 56 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'f', time_between_keys: 135 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 316 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'c', time_between_keys: 101 },
        { target_key: 'k', key_pressed: 'k', previous_key: 'a', time_between_keys: 114 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'k', time_between_keys: 156 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 78 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'a', time_between_keys: 79 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'p', time_between_keys: 148 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'p', time_between_keys: 43 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'e', time_between_keys: 102 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 101 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 416 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 78 },
        { target_key: 'n', key_pressed: 'n', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'n', time_between_keys: 79 },
        { target_key: 'x', key_pressed: 'x', previous_key: 'e', time_between_keys: 227 },
        { target_key: 't', key_pressed: 't', previous_key: 'x', time_between_keys: 133 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 56 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 68 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 57 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 100 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 83 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 120 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 45 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 56 },
        { target_key: 'u', key_pressed: 'u', previous_key: ' ', time_between_keys: 191 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 69 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 67 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 100 },
        { target_key: "'", key_pressed: "'", previous_key: 'r', time_between_keys: 136 },
        { target_key: 's', key_pressed: 's', previous_key: "'", time_between_keys: 123 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 113 },
        { target_key: 'n', key_pressed: 'n', previous_key: ' ', time_between_keys: 90 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'n', time_between_keys: 215 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'a', time_between_keys: 77 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'm', time_between_keys: 124 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 124 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 101 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'f', time_between_keys: 101 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 182 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 156 },
        { target_key: '2', key_pressed: '2', previous_key: ' ', time_between_keys: 191 },
        { target_key: '4', key_pressed: '4', previous_key: '2', time_between_keys: 506 },
        { target_key: ' ', key_pressed: ' ', previous_key: '4', time_between_keys: 180 },
        { target_key: 'h', key_pressed: 'h', previous_key: ' ', time_between_keys: 146 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'h', time_between_keys: 102 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'o', time_between_keys: 68 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'u', time_between_keys: 67 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 89 },
        { target_key: '.', key_pressed: '.', previous_key: 's', time_between_keys: 147 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 90 },
        { target_key: 'B', key_pressed: 'B', previous_key: ' ', time_between_keys: 765 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'B', time_between_keys: 161 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 109 },
        { target_key: 'b', key_pressed: 'g', previous_key: 'm', time_between_keys: 349 },
        { target_key: 'a', key_pressed: 'b', previous_key: 'b', time_between_keys: 67 },
        { target_key: 'r', key_pressed: 'a', previous_key: 'a', time_between_keys: 282 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'r', time_between_keys: 1023 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 135 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'i', time_between_keys: 101 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 70 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 113 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 178 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'w', time_between_keys: 123 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 90 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 105 },
        { target_key: 'e', key_pressed: 'e', previous_key: 't', time_between_keys: 98 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 192 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 89 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 79 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 149 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 10 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 223 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 236 },
        { target_key: 'v', key_pressed: 'v', previous_key: 'e', time_between_keys: 180 },
        { target_key: 'e', key_pressed: 'l', previous_key: 'v', time_between_keys: 236 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'e', time_between_keys: 676 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 348 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'o', time_between_keys: 124 },
        { target_key: ' ', key_pressed: 'e', previous_key: 'p', time_between_keys: 168 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 686 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'a', time_between_keys: 147 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 214 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'f', time_between_keys: 225 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 113 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 147 },
        { target_key: ',', key_pressed: ',', previous_key: 't', time_between_keys: 168 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 114 },
        { target_key: 'l', key_pressed: 'l', previous_key: ' ', time_between_keys: 167 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'l', time_between_keys: 68 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'i', time_between_keys: 100 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'g', time_between_keys: 124 },
        { target_key: 't', key_pressed: 't', previous_key: 'h', time_between_keys: 67 },
        { target_key: 'w', key_pressed: 'w', previous_key: 't', time_between_keys: 192 },
        { target_key: 'e', key_pressed: 'a', previous_key: 'w', time_between_keys: 113 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'e', time_between_keys: 675 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'i', time_between_keys: 269 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'g', time_between_keys: 96 },
        { target_key: 't', key_pressed: 't', previous_key: 'h', time_between_keys: 118 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 383 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'n', key_pressed: 'n', previous_key: 's', time_between_keys: 78 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'n', time_between_keys: 90 },
        { target_key: 'w', key_pressed: 't', previous_key: 'o', time_between_keys: 137 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'w', time_between_keys: 347 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'm', time_between_keys: 225 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'o', time_between_keys: 338 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'b', time_between_keys: 213 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'i', time_between_keys: 79 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 146 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 126 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 122 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 80 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 77 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 72 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 73 },
        { target_key: 'c', key_pressed: 'o', previous_key: ' ', time_between_keys: 260 },
        { target_key: 'o', key_pressed: 'u', previous_key: 'c', time_between_keys: 124 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'o', time_between_keys: 900 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'u', time_between_keys: 146 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'l', time_between_keys: 124 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 68 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 282 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'c', time_between_keys: 112 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 78 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'r', time_between_keys: 149 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'r', time_between_keys: 652 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 100 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 190 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 68 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'n', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 123 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 136 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 348 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 135 },
        { target_key: 't', key_pressed: 'w', previous_key: ' ', time_between_keys: 203 },
        { target_key: 'w', key_pressed: 'o', previous_key: 't', time_between_keys: 236 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'w', time_between_keys: 462 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 348 },
        { target_key: 'p', key_pressed: 'p', previous_key: ' ', time_between_keys: 147 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'p', time_between_keys: 179 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'e', time_between_keys: 91 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'o', time_between_keys: 102 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'p', time_between_keys: 152 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 117 },
        { target_key: '.', key_pressed: '.', previous_key: 'e', time_between_keys: 147 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 89 },
        { target_key: 'I', key_pressed: 'I', previous_key: ' ', time_between_keys: 179 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'I', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 103 },
        { target_key: 't', key_pressed: 'e', previous_key: ' ', time_between_keys: 337 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 495 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 281 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 135 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 180 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'e', time_between_keys: 90 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 68 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'r', time_between_keys: 92 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'l', time_between_keys: 159 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 142 },
        { target_key: '1', key_pressed: '2', previous_key: ' ', time_between_keys: 250 },
        { target_key: '9', key_pressed: '9', previous_key: '1', time_between_keys: 1134 },
        { target_key: '5', key_pressed: '5', previous_key: '9', time_between_keys: 225 },
        { target_key: '0', key_pressed: '0', previous_key: '5', time_between_keys: 68 },
        { target_key: 's', key_pressed: 's', previous_key: '0', time_between_keys: 146 },
        { target_key: ',', key_pressed: ',', previous_key: 's', time_between_keys: 416 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 135 },
        { target_key: 'A', key_pressed: 'A', previous_key: ' ', time_between_keys: 473 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'A', time_between_keys: 472 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'r', time_between_keys: 169 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'm', time_between_keys: 136 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 156 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'n', time_between_keys: 214 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 92 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 190 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 134 },
        { target_key: 't', key_pressed: 't', previous_key: 'e', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 89 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 124 },
        { target_key: 's', key_pressed: 's', previous_key: 'a', time_between_keys: 146 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 136 },
        { target_key: 'd', key_pressed: 'c', previous_key: 'i', time_between_keys: 157 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 709 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 236 },
        { target_key: 'h', key_pressed: 'h', previous_key: ' ', time_between_keys: 247 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 68 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 100 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 317 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'd', time_between_keys: 247 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 57 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'e', time_between_keys: 157 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'a', time_between_keys: 149 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'm', time_between_keys: 87 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 146 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 79 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 57 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 371 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'f', time_between_keys: 123 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'o', time_between_keys: 171 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'c', time_between_keys: 223 },
        { target_key: 's', key_pressed: 's', previous_key: 'u', time_between_keys: 194 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 78 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 167 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 213 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 822 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 146 },
        { target_key: 'v', key_pressed: 'v', previous_key: 'e', time_between_keys: 203 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'v', time_between_keys: 104 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'e', time_between_keys: 75 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 204 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'o', time_between_keys: 89 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'p', time_between_keys: 192 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 58 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 144 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'g', time_between_keys: 81 },
        { target_key: 'h', key_pressed: 'h', previous_key: ' ', time_between_keys: 200 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 34 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 94 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 99 },
        { target_key: 'c', key_pressed: 'c', previous_key: ' ', time_between_keys: 284 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'c', time_between_keys: 52 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 67 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'm', time_between_keys: 146 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'p', time_between_keys: 124 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 79 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'n', time_between_keys: 259 },
        { target_key: "'", key_pressed: "'", previous_key: 'y', time_between_keys: 416 },
        { target_key: 's', key_pressed: 's', previous_key: "'", time_between_keys: 123 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 135 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 217 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 89 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 111 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 82 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 42 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 90 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 135 },
        { target_key: 'r', key_pressed: 'e', previous_key: 't', time_between_keys: 134 },
        { target_key: 'a', key_pressed: 'r', previous_key: 'r', time_between_keys: 49 },
        { target_key: 'c', key_pressed: 'a', previous_key: 'a', time_between_keys: 177 },
        { target_key: 'k', key_pressed: 'c', previous_key: 'c', time_between_keys: 225 },
        { target_key: 'e', key_pressed: 'k', previous_key: 'k', time_between_keys: 85 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 759 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 484 },
        { target_key: 'v', key_pressed: 'v', previous_key: ' ', time_between_keys: 112 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'v', time_between_keys: 225 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'e', time_between_keys: 236 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 225 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'i', time_between_keys: 191 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'c', time_between_keys: 315 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 192 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 78 },
        { target_key: ',', key_pressed: ',', previous_key: 's', time_between_keys: 270 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 80 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 304 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'b', time_between_keys: 89 },
        { target_key: 't', key_pressed: 't', previous_key: 'u', time_between_keys: 112 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 105 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 345 },
        { target_key: 'y', key_pressed: 'y', previous_key: 'b', time_between_keys: 159 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'y', time_between_keys: 122 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 90 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 147 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 55 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 136 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 78 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'n', time_between_keys: 191 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 145 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 45 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'o', time_between_keys: 58 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'f', time_between_keys: 90 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 113 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 78 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 114 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 100 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 225 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 204 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'e', time_between_keys: 235 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'c', time_between_keys: 135 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'a', time_between_keys: 315 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 191 },
        { target_key: ',', key_pressed: ',', previous_key: 'e', time_between_keys: 45 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 124 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 225 },
        { target_key: 'm', key_pressed: 'm', previous_key: 's', time_between_keys: 81 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'm', time_between_keys: 135 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 89 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 112 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 123 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 56 },
        { target_key: ',', key_pressed: ',', previous_key: 'r', time_between_keys: 180 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 102 },
        { target_key: 'm', key_pressed: 'm', previous_key: ' ', time_between_keys: 326 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'm', time_between_keys: 82 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 78 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 76 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 90 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 139 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'e', time_between_keys: 221 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'f', time_between_keys: 135 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'f', time_between_keys: 93 },
        { target_key: 'c', key_pressed: 'e', previous_key: 'i', time_between_keys: 110 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'c', time_between_keys: 821 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'i', time_between_keys: 326 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 104 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 110 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 101 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 225 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 69 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 145 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'g', time_between_keys: 92 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 69 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'n', time_between_keys: 250 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 38 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 395 },
        { target_key: 'h', key_pressed: 'h', previous_key: ' ', time_between_keys: 191 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 89 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'a', time_between_keys: 136 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 124 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 258 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'b', time_between_keys: 225 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'e', time_between_keys: 146 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 91 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 472 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 124 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 202 },
        { target_key: 'v', key_pressed: 'v', previous_key: 'e', time_between_keys: 225 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'v', time_between_keys: 158 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'e', time_between_keys: 95 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 163 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'o', time_between_keys: 146 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'p', time_between_keys: 113 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 192 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 78 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 113 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'n', time_between_keys: 156 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 215 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 461 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'w', time_between_keys: 68 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 78 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 61 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 142 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 90 },
        { target_key: 'a', key_pressed: 'a', previous_key: 't', time_between_keys: 135 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 55 },
        { target_key: 't', key_pressed: 't', previous_key: 'r', time_between_keys: 162 },
        { target_key: 'i', key_pressed: 'i', previous_key: 't', time_between_keys: 109 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 78 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 125 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'g', time_between_keys: 113 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 100 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 137 },
        { target_key: 'c', key_pressed: 'o', previous_key: ' ', time_between_keys: 425 },
        { target_key: 'o', key_pressed: 'm', previous_key: 'c', time_between_keys: 540 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 776 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'm', time_between_keys: 135 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 126 },
        { target_key: 'o', key_pressed: 'o', previous_key: ' ', time_between_keys: 171 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 67 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 199 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 89 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 131 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 163 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 44 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 170 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 22 },
        { target_key: 'm', key_pressed: 'm', previous_key: ' ', time_between_keys: 157 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'm', time_between_keys: 101 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 79 },
        { target_key: 'k', key_pressed: 'k', previous_key: 'r', time_between_keys: 135 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'k', time_between_keys: 135 },
        { target_key: 't', key_pressed: 't', previous_key: 'e', time_between_keys: 103 },
        { target_key: '.', key_pressed: ',', previous_key: 't', time_between_keys: 202 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 123 },
        { target_key: 'B', key_pressed: 'C', previous_key: ' ', time_between_keys: 382 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'B', time_between_keys: 91 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 67 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'm', time_between_keys: 203 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'b', time_between_keys: 201 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 124 },
        { target_key: 'd', key_pressed: 'i', previous_key: 'r', time_between_keys: 135 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 788 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'i', time_between_keys: 90 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 168 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 271 },
        { target_key: 'r', key_pressed: 'r', previous_key: ' ', time_between_keys: 214 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 100 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 102 },
        { target_key: 'u', key_pressed: 'u', previous_key: 's', time_between_keys: 135 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'u', time_between_keys: 225 },
        { target_key: 'e', key_pressed: 'b', previous_key: 'm', time_between_keys: 101 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 800 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 100 },
        { target_key: 'h', key_pressed: 'h', previous_key: ' ', time_between_keys: 150 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 68 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 87 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 89 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 191 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'e', time_between_keys: 315 },
        { target_key: 'f', key_pressed: 'f', previous_key: 'f', time_between_keys: 135 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'f', time_between_keys: 114 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 156 },
        { target_key: 't', key_pressed: 't', previous_key: 'r', time_between_keys: 462 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 112 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 123 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 271 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 33 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 113 },
        { target_key: 'b', key_pressed: 'b', previous_key: ' ', time_between_keys: 202 },
        { target_key: 'u', key_pressed: 'u', previous_key: 'b', time_between_keys: 45 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'u', time_between_keys: 68 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'i', time_between_keys: 44 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'l', time_between_keys: 141 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 88 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 121 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'a', time_between_keys: 135 },
        { target_key: 'm', key_pressed: 'm', previous_key: ' ', time_between_keys: 461 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'm', time_between_keys: 101 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 101 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'n', time_between_keys: 112 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'i', time_between_keys: 338 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 102 },
        { target_key: 'u', key_pressed: 'u', previous_key: 't', time_between_keys: 191 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'u', time_between_keys: 101 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 90 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 135 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 315 },
        { target_key: 'n', key_pressed: 'n', previous_key: 's', time_between_keys: 91 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'n', time_between_keys: 89 },
        { target_key: 'w', key_pressed: 'w', previous_key: 'o', time_between_keys: 450 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'w', time_between_keys: 181 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'm', time_between_keys: 134 },
        { target_key: 'b', key_pressed: 'i', previous_key: 'o', time_between_keys: 506 },
        { target_key: 'i', key_pressed: 'l', previous_key: 'b', time_between_keys: 124 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'i', time_between_keys: 1058 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 270 },
        { target_key: '.', key_pressed: '.', previous_key: 'e', time_between_keys: 89 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 192 },
        { target_key: 'H', key_pressed: 'H', previous_key: ' ', time_between_keys: 293 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'H', time_between_keys: 169 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 87 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 171 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'w', time_between_keys: 101 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'o', time_between_keys: 137 },
        { target_key: 'k', key_pressed: 'k', previous_key: 'r', time_between_keys: 88 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'k', time_between_keys: 135 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 202 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 103 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 111 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 124 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 203 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 21 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'n', time_between_keys: 57 },
        { target_key: 's', key_pressed: 's', previous_key: 'g', time_between_keys: 214 },
        { target_key: 'i', key_pressed: 'i', previous_key: 's', time_between_keys: 102 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'i', time_between_keys: 145 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 214 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 56 },
        { target_key: 'h', key_pressed: 'h', previous_key: ' ', time_between_keys: 147 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 67 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 67 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 113 },
        { target_key: 'e', key_pressed: 'e', previous_key: ' ', time_between_keys: 709 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'e', time_between_keys: 135 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'l', time_between_keys: 124 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 191 },
        { target_key: 's', key_pressed: 's', previous_key: 'e', time_between_keys: 78 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 228 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 155 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 349 },
        { target_key: 'o', key_pressed: 'o', previous_key: 's', time_between_keys: 101 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'o', time_between_keys: 68 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 90 },
        { target_key: 'G', key_pressed: 'G', previous_key: ' ', time_between_keys: 304 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'G', time_between_keys: 180 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 67 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'r', time_between_keys: 78 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'm', time_between_keys: 147 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'a', time_between_keys: 84 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 73 },
        { target_key: ',', key_pressed: ',', previous_key: 'n', time_between_keys: 226 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 90 },
        { target_key: 'w', key_pressed: 'w', previous_key: ' ', time_between_keys: 259 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'w', time_between_keys: 10 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'h', time_between_keys: 56 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'o', time_between_keys: 91 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'h', key_pressed: 'h', previous_key: 's', time_between_keys: 112 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 90 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 90 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'r', time_between_keys: 81 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 200 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 80 },
        { target_key: 'h', key_pressed: 'h', previous_key: ' ', time_between_keys: 103 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'h', time_between_keys: 76 },
        { target_key: 's', key_pressed: 's', previous_key: 'i', time_between_keys: 34 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 135 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 146 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'f', time_between_keys: 126 },
        { target_key: 't', key_pressed: 't', previous_key: 'a', time_between_keys: 100 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 122 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 83 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 63 },
        { target_key: "'", key_pressed: "'", previous_key: 'r', time_between_keys: 150 },
        { target_key: 's', key_pressed: 's', previous_key: "'", time_between_keys: 133 },
        { target_key: ' ', key_pressed: ' ', previous_key: 's', time_between_keys: 100 },
        { target_key: 'm', key_pressed: 'm', previous_key: ' ', time_between_keys: 158 },
        { target_key: 'e', key_pressed: 'c', previous_key: 'm', time_between_keys: 731 },
        { target_key: 'c', key_pressed: 'c', previous_key: 'e', time_between_keys: 507 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'c', time_between_keys: 101 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'h', time_between_keys: 247 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 90 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'n', time_between_keys: 180 },
        { target_key: 'c', key_pressed: 'a', previous_key: 'i', time_between_keys: 282 },
        { target_key: 'a', key_pressed: 'l', previous_key: 'c', time_between_keys: 861 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 354 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'l', time_between_keys: 618 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 169 },
        { target_key: 'a', key_pressed: 'a', previous_key: 't', time_between_keys: 124 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 114 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 156 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'e', time_between_keys: 94 },
        { target_key: 't', key_pressed: 't', previous_key: 'n', time_between_keys: 108 },
        { target_key: 's', key_pressed: 's', previous_key: 't', time_between_keys: 125 },
        { target_key: '.', key_pressed: '.', previous_key: 's', time_between_keys: 180 },
        { target_key: ' ', key_pressed: ' ', previous_key: '.', time_between_keys: 55 },
        { target_key: 'A', key_pressed: 'A', previous_key: ' ', time_between_keys: 383 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'A', time_between_keys: 325 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'r', time_between_keys: 282 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'm', time_between_keys: 473 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 101 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'n', time_between_keys: 123 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 81 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 280 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 101 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'n', time_between_keys: 101 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 113 },
        { target_key: 'G', key_pressed: 'G', previous_key: ' ', time_between_keys: 214 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'G', time_between_keys: 247 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 67 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'r', time_between_keys: 180 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'm', time_between_keys: 102 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'a', time_between_keys: 101 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 78 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'n', time_between_keys: 115 },
        { target_key: 'd', key_pressed: 'd', previous_key: ' ', time_between_keys: 201 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'd', time_between_keys: 157 },
        { target_key: 'v', key_pressed: 'v', previous_key: 'e', time_between_keys: 168 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'v', time_between_keys: 148 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'e', time_between_keys: 66 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'l', time_between_keys: 282 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'o', time_between_keys: 112 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'p', time_between_keys: 169 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'e', time_between_keys: 642 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 112 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 180 },
        { target_key: 'e', key_pressed: 'e', previous_key: 's', time_between_keys: 123 },
        { target_key: 'v', key_pressed: 'r', previous_key: 'e', time_between_keys: 91 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'v', time_between_keys: 878 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 100 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'r', time_between_keys: 147 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 82 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'l', time_between_keys: 132 },
        { target_key: 'p', key_pressed: 'p', previous_key: ' ', time_between_keys: 326 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'p', time_between_keys: 89 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'r', time_between_keys: 158 },
        { target_key: 't', key_pressed: 'p', previous_key: 'o', time_between_keys: 237 },
        { target_key: 'o', key_pressed: 'o', previous_key: 't', time_between_keys: 935 },
        { target_key: 't', key_pressed: 't', previous_key: 'o', time_between_keys: 291 },
        { target_key: 'y', key_pressed: 'y', previous_key: 't', time_between_keys: 315 },
        { target_key: 'p', key_pressed: 'p', previous_key: 'y', time_between_keys: 134 },
        { target_key: 'e', key_pressed: 'y', previous_key: 'p', time_between_keys: 214 },
        { target_key: 's', key_pressed: 'e', previous_key: 'e', time_between_keys: 192 },
        { target_key: ' ', key_pressed: 's', previous_key: 's', time_between_keys: 112 },
        { target_key: 'o', key_pressed: ' ', previous_key: ' ', time_between_keys: 102 },
        { target_key: 'f', key_pressed: 'o', previous_key: 'o', time_between_keys: 112 },
        { target_key: ' ', key_pressed: 'f', previous_key: 'f', time_between_keys: 146 },
        { target_key: 't', key_pressed: ' ', previous_key: ' ', time_between_keys: 68 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 697 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 203 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 90 },
        { target_key: 'l', key_pressed: 'l', previous_key: ' ', time_between_keys: 247 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'l', time_between_keys: 102 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'i', time_between_keys: 78 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'g', time_between_keys: 157 },
        { target_key: 't', key_pressed: 't', previous_key: 'h', time_between_keys: 79 },
        { target_key: 'w', key_pressed: 'w', previous_key: 't', time_between_keys: 247 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'w', time_between_keys: 338 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'e', time_between_keys: 115 },
        { target_key: 'g', key_pressed: 'g', previous_key: 'i', time_between_keys: 121 },
        { target_key: 'h', key_pressed: 'h', previous_key: 'g', time_between_keys: 113 },
        { target_key: 't', key_pressed: 't', previous_key: 'h', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 113 },
        { target_key: 's', key_pressed: 's', previous_key: ' ', time_between_keys: 269 },
        { target_key: 'n', key_pressed: 'n', previous_key: 's', time_between_keys: 114 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'n', time_between_keys: 77 },
        { target_key: 'w', key_pressed: 'w', previous_key: 'o', time_between_keys: 777 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'w', time_between_keys: 247 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'm', time_between_keys: 101 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'o', time_between_keys: 237 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'b', time_between_keys: 146 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'i', time_between_keys: 89 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 282 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 117 },
        { target_key: 'a', key_pressed: 'a', previous_key: ' ', time_between_keys: 113 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'a', time_between_keys: 107 },
        { target_key: 'd', key_pressed: 'd', previous_key: 'n', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'd', time_between_keys: 135 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 349 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'f', time_between_keys: 44 },
        { target_key: 'n', key_pressed: 'n', previous_key: 'i', time_between_keys: 67 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'n', time_between_keys: 127 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'a', time_between_keys: 77 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'l', time_between_keys: 157 },
        { target_key: 'y', key_pressed: 't', previous_key: 'l', time_between_keys: 788 },
        { target_key: ',', key_pressed: ',', previous_key: 'y', time_between_keys: 113 },
        { target_key: ' ', key_pressed: ' ', previous_key: ',', time_between_keys: 156 },
        { target_key: 't', key_pressed: 't', previous_key: ' ', time_between_keys: 203 },
        { target_key: 'h', key_pressed: 'h', previous_key: 't', time_between_keys: 543 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'h', time_between_keys: 211 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'e', time_between_keys: 72 },
        { target_key: 'f', key_pressed: 'f', previous_key: ' ', time_between_keys: 232 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'f', time_between_keys: 101 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'i', time_between_keys: 55 },
        { target_key: 's', key_pressed: 's', previous_key: 'r', time_between_keys: 113 },
        { target_key: 't', key_pressed: 't', previous_key: 's', time_between_keys: 102 },
        { target_key: ' ', key_pressed: ' ', previous_key: 't', time_between_keys: 113 },
        { target_key: 'B', key_pressed: 'C', previous_key: ' ', time_between_keys: 370 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'B', time_between_keys: 91 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'o', time_between_keys: 67 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'm', time_between_keys: 281 },
        { target_key: 'a', key_pressed: 'a', previous_key: 'b', time_between_keys: 158 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'a', time_between_keys: 247 },
        { target_key: 'd', key_pressed: 'i', previous_key: 'r', time_between_keys: 417 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'd', time_between_keys: 472 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'i', time_between_keys: 117 },
        { target_key: 'r', key_pressed: 'r', previous_key: 'e', time_between_keys: 85 },
        { target_key: ' ', key_pressed: ' ', previous_key: 'r', time_between_keys: 169 },
        { target_key: 's', key_pressed: 'w', previous_key: ' ', time_between_keys: 225 },
        { target_key: 'n', key_pressed: 'n', previous_key: 's', time_between_keys: 123 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'n', time_between_keys: 767 },
        { target_key: 'w', key_pressed: 'w', previous_key: 'o', time_between_keys: 404 },
        { target_key: 'm', key_pressed: 'm', previous_key: 'w', time_between_keys: 337 },
        { target_key: 'o', key_pressed: 'o', previous_key: 'm', time_between_keys: 282 },
        { target_key: 'b', key_pressed: 'b', previous_key: 'o', time_between_keys: 112 },
        { target_key: 'i', key_pressed: 'i', previous_key: 'b', time_between_keys: 507 },
        { target_key: 'l', key_pressed: 'l', previous_key: 'i', time_between_keys: 57 },
        { target_key: 'e', key_pressed: 'e', previous_key: 'l', time_between_keys: 505 },
        { target_key: ' ', key_pressed: 'e', previous_key: 'e', time_between_keys: 608 },
      ];
      ks = ks.concat(ks).concat(ks).concat(ks);
      var ks2 = ks.map(function (el) {
        return [el['target_key'], el['key_pressed'], el['previous_key'], el['timed_between_keys']];
      });
      // must map here, since we're overwriting the default keystrokes

      // 7276 keystrokes... equivalent of 278.2 netWPM for 5 minutes
      $scope.TypingInput.save({ keystrokes: ks2, duration_in_ms: 300000, netWPM: 278.2, grossWPM: 291.0, accuracy: 95.6 })
        .then(function () {
          console.log('800wpm for 5 minutes...');
          // resultsModalSequence.start();
        })
        .catch(function (response) {
          // Server did not save the session
          $scope.TypingInput.errorMessage = response.data.errors;
          // errorModalSequence.start();
        });
      return true;
    }

    // keydown event handler. Required by TypingInput
    $scope.$on('keydown', function (a, e) {
      // Create the event log
      $scope.TypingInput.addEvent(e); // Store the event
    });

    // Modal methods ------------------------------------------------------------------
    // Fires when the user clicks to close the modal
    //  -> Initializes TypingInput.timer & allows user to start
    var duration = Number($scope.timedWritingModal.practice.duration);
    $scope.TypingInput.timer.initialize(duration * 60);
    $scope.TypingInput.primarySpeedMetric = $scope.timedWritingModal.practice.type || 'netWPM';

    // $scope.startTimedWriting = function() {
    //   var duration = Number($scope.timedWritingModal.practice.duration);
    //   $scope.TypingInput.timer.initialize(duration * 60);
    //   $scope.TypingInput.primarySpeedMetric = $scope.timedWritingModal.practice.type || "netWPM";
    //   // Make sure we're ready to go
    //   $q.all([sessionReady]).then(function() {
    //     welcomeModalSequence.exit();
    //     allowStart();
    //   });
    // }();

    // Attached to the 'X' in the top right of the modal
    //  -> Goes home
    $scope.goHome = function () {
      $state.go('app.home');
    };

    // Attached to the 'X' in the top right of the modal
    $scope.closePracticeResultsModal = function () {
      resultsModalSequence.exit();
    };

    // Used by results modal
    //  -> Effectively restarts the session
    $scope.backToTimedWriting = function () {
      resultsModalSequence.exit();
      // debugger;
      $timeout(function () {
        $state.go('app.keyboarding.practice_timed_writing', {}, { reload: true });
      }, 500);
    };
  },
]);
