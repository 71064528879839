import sortDocumentAssignmentCategories from './sortDocumentAssignmentCategories';
import availableDocumentAssignmentFilters from './availableDocumentAssignmentFilters';
import customDocumentAssignmentSort from './customDocumentAssignmentSort';

app.controller('DocumentAssignmentCreatorController', [
  '$scope',
  'documentAssignments',
  'DocumentAssignment',
  '$state',
  '$timeout',
  '$http',
  function ($scope, documentAssignments, DocumentAssignment, $state, $timeout, $http) {
    $scope.documentAssignments = documentAssignments;
    $scope.assignment = new DocumentAssignment();
    // these populate both the filters and the available options in the dropdown
    const availableFilters = availableDocumentAssignmentFilters;

    $scope.view = {
      currentFilter: {
        // This is for displaying the text describing what the current applied filter is
        // Make sure this has the same values as the default params passed to the server in the resolve method in
        // assignment_creator abstract state
        style_guide_version: '3.0',
        country: 'CA',
      },
      // This is for displaying a list of buttons that user can click to filter document assignments by
      availableFilters,
      styleGuideVersions: [...new Set(availableFilters.map((filter) => filter.style_guide_version))],
      categories: sortDocumentAssignmentCategories(documentAssignments),
    };

    // these is the on click handler for the filter buttons in the view
    $scope.updateDocumentAssignments = function (styleGuideVersion, country) {
      $http
        .get('document_assignments.json', {
          params: { style_guide_version: styleGuideVersion, country },
        })
        .then(function (response) {
          const documentAssignments = response.data;
          const newDocumentAssignments = documentAssignments.map(function (da) {
            return new DocumentAssignment(da);
          });
          $scope.view.currentFilter.styleGuideVersion = styleGuideVersion;
          $scope.view.currentFilter.country = country;
          $scope.view.categories = sortDocumentAssignmentCategories(documentAssignments);
          $scope.documentAssignments = newDocumentAssignments;
        });
    };

    // setup s3 Upload Forms for each document production object
    // var s3Helpers = S3UploadService.init(uploadFormSelector);

    $scope.createAssignment = function (assignment) {
      assignment.disabled = true;
      assignment.status = 'Creating...';
      assignment
        .create()
        .then(function () {
          assignment.disabled = false;
          $scope.documentAssignments.push(assignment);
          $scope.assignment = new DocumentAssignment();
          assignment.status = 'Success!';
        })
        .catch(function (err) {
          assignment.disabled = false;
          assignment.status = 'Failed :( ... ' + err.data.errors;
        });
    };

    $scope.updateAssignment = function (assignment) {
      assignment.disabled = true;

      assignment.status = 'Updating...';
      assignment
        .update()
        .then(function () {
          assignment.status = 'Updated!';
          assignment.disabled = false;
          // documentAssignments.push(assignment);
          // $scope.assignment = new DocumentAssignment();
        })
        .catch(function (err) {
          assignment.disabled = false;
          assignment.status = 'Failed :(  ... ' + err.data.errors;
        });
    };

    $scope.reloadPage = function () {
      $state.reload();
    };

    // custom sorting of document assignments
    $scope.customDocumentAssignmentSort = customDocumentAssignmentSort;

    // activate tooltips
    $timeout(function () {
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
      });
    }, 1000);
  },
]);
