app.controller('CourseTemplateIndexController', [
  '$scope',
  'courseTemplates',
  'user',
  function ($scope, courseTemplates, user) {
    // loaded from the server
    $scope.courseTemplates = courseTemplates;

    $scope.organization = user.organization;
  },
]);
