// Used when there are multiple modal sequences to manage
app.factory('ModalSequenceArray', [
  function () {
    // modalSequences is an array of ModalSequence objects
    //  see LessonModalController for an example
    var ModalSequenceArray = function (modalSequences) {
      this.modalSequences = modalSequences;
    };

    // public methods ---------------------------------------------------------------------------------------
    // returns which ever modal sequence is currently active
    //   -> false, if no modals are active
    ModalSequenceArray.prototype.getActiveModalSequence = function () {
      var activeModals = this.modalSequences.filter(function (sequence) {
        return sequence.isActive;
      });

      // checks for consistency in activeModals
      if (activeModals.length === 0) {
        return false;
      } else if (activeModals.length > 1) {
        throw 'Error in LessonModalController: More than one active modal.';
      }

      return activeModals[0];
    };

    // exits an active modal sequence if there is one
    ModalSequenceArray.prototype.exit = function () {
      if (this.getActiveModalSequence()) {
        this.getActiveModalSequence().exit();
      }
    };

    // returns true if there is a modal sequence that is active, false other wise.
    ModalSequenceArray.prototype.isActive = function () {
      var activeModals = this.modalSequences.filter(function (sequence) {
        return sequence.isActive;
      });

      // checks for consistency in activeModals
      if (activeModals.length === 0) {
        return false;
      } else if (activeModals.length === 1) {
        return true;
      } else if (activeModals.length > 1) {
        throw 'Error in LessonModalController: More than one active modal.';
      }
    };

    // gets the modal sequence from its position
    ModalSequenceArray.prototype.getByIndex = function (index) {
      // verify that
      if (!this.modalSequences || index < 0 || index > this.modalSequences.length) {
        throw 'Error in ModalSequence Array. Cannot locate modal at position ' + index;
      }

      return this.modalSequences[index];
    };

    // gets the modal sequence from its selector
    ModalSequenceArray.prototype.getBySelector = function (selector) {
      var modalsFromSelector = this.modalSequences.filter(function (sequence) {
        return sequence.selector === selector;
      });

      // verify that
      if (!modalsFromSelector || modalsFromSelector.length < 1) {
        throw 'Error in ModalSequence Array. Cannot locate modal sequence with selector: ' + selector;
      }
      if (1 > modalsFromSelector.length) {
        throw 'Error in ModalSequence Array. Located multiple modal sequences with selector: ' + selector;
      }

      return modalsFromSelector[0];
    };

    return ModalSequenceArray;
  },
]);
