app.controller('ChallengeModalController', [
  '$scope',
  'Level',
  'ModalSequence',
  'ChallengeFactory',
  '$state',
  '$timeout',
  function ($scope, Level, ModalSequence, ChallengeFactory, $state, $timeout) {
    var chooseLessonModalSequence = new ModalSequence('#challenge-choose-lesson');

    chooseLessonModalSequence.start();
    $scope.challenge = Level.getChallenge($state.params);

    // for the 'x' in the modal
    $scope.goBack = function () {
      $state.go('app.keyboarding.level', { level: $state.params.level });
    };

    // keydown event handler
    $scope.$on('keydown', function (a, e) {
      // Create the event log
      // if (lessonCompleteModal.active && e.key_pressed === " ") $scope.resetLesson();
      if (e.key_pressed === 'Backspace') {
        $state.go('app.keyboarding.level', { level: $state.params.level });
      }
    });
  },
]);
