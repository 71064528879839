app.controller('ViolatedPermissionsController', [
  '$scope',
  '$http',
  '$timeout',
  'FirebaseActions',
  function ($scope, $http, $timeout, FirebaseActions) {
    var debug = false;
    if (debug)
      $scope.violatedPermissions = JSON.parse(
        '[{"id":837,"user_id":394,"instructor_id":560,"permission_id":118603,"activities":[{"id":2051713,"data":{"pass":false,"errors":28,"netWPM":35,"accuracy":94.9,"grossWPM":35,"totalChars":554,"correctChars":526,"noRedOnError":false,"timeElapsed_ms":180000,"timeElapsedString":"3 minutes","errorsNonbackspaced":0,"totalNonbackspacedChars":525,"correctNonbackspacedChars":525},"netWPM":35.0,"user_id":3118,"accuracy":94.9,"grossWPM":35.0,"created_at":"2018-11-29T15:36:12.462-05:00","updated_at":"2018-11-29T15:36:12.462-05:00","activity_id":null,"activity_type":"TimedWriting","permission_id":118603,"duration_in_ms":180000,"line_of_text_id":2046444,"assigned_by_user_id":560,"errors_nonbackspaced":0}],"activity_type":"TimedWriting","reason":"Multiple logins detected.","original_login_cookie":"2018-10-18T19:48:19.000Z","violating_login_cookie":"2018-11-29T20:40:29.000Z","created_at":"2018-11-29T20:40:57.779Z","updated_at":"2018-11-29T20:40:57.779Z","section_id":480,"hidden":false,"activities_restored":false}]'
      );

    // watches for violatedPermissionAdded in actions/sections/:id
    // if this is detected, we updated violatedPermissions
    var firebaseActions = new FirebaseActions();

    $scope.view = {
      showSuspendedTests: null,
      totalViolatedPermissions: null,
      undecidedViolatedPermissions: null, // the number of violated permissions that require a decision
    };

    $scope.$parent.section
      .onInit()
      .then(getViolatedPermissions)
      .then(setAccuracyMetricAndType)
      .then(watchForNewViolatedPermissions);

    $scope.toggleShowSuspendedTests = function () {
      // only toggle if all violated permission have been addressed
      if ($scope.view.undecidedViolatedPermissions > 0 && $scope.view.showSuspendedTests) {
        return true;
      } else {
        $scope.view.showSuspendedTests = !$scope.view.showSuspendedTests;
      }
    };

    // restores the activities of a violated permission
    $scope.restoreVP = function (vp) {
      vp.changing = true;
      return $http.put(`api/violated_permissions/restore/${vp.id}.json`).then(function () {
        vp.activities_restored = true;
        setUndecidedPermissions();
        return $scope.$parent.section.updateStatistics({ statistics_params: ['timed_writings', 'tw_metadata'] });
      });
    };

    // Sets the 'hidden' property of a violated permission to true
    $scope.hideVP = function (vp) {
      vp.changing = true;
      return $http.put(`api/violated_permissions/hide/${vp.id}.json`).then(function () {
        vp.hidden = true;
        setUndecidedPermissions();
      });
    };

    // private functions ----------------------------------------------------------------
    function getViolatedPermissions() {
      return $http.get(`api/violated_permissions/index/${$scope.$parent.section.id}.json`).then(function (response) {
        $scope.violatedPermissions = response.data.violated_permissions;
        setUndecidedPermissions();
        addStudentNamesToVPs();
      });
    }

    function addStudentNamesToVPs() {
      if ($scope.violatedPermissions.length === 0) return;
      var user_id;
      var student;
      for (var i = 0; i < $scope.violatedPermissions.length; i++) {
        user_id = $scope.violatedPermissions[i].user_id;
        student = $scope.$parent.section.getStudent(user_id);
        $scope.violatedPermissions[i].student_name = student.first_name + ' ' + student.last_name;
      }
    }

    function setAccuracyMetricAndType() {
      $scope.view.accuracy_metric =
        $scope.$parent.section.test.accuracy_metric === 'accuracy' ? 'accuracy' : 'errorsNonbackspaced';
      $scope.view.type = $scope.$parent.section.test.type;
    }

    // This function filters and counts the violated permissions that require actions.
    function setUndecidedPermissions() {
      var count = $scope.violatedPermissions.filter(function (vp) {
        return !vp.hidden && !vp.activities_restored;
      }).length;
      $scope.view.undecidedViolatedPermissions = count;
      $scope.view.totalViolatedPermissions = $scope.violatedPermissions.length;
      $scope.view.showSuspendedTests = $scope.view.showSuspendedTests || $scope.view.undecidedViolatedPermissions > 0;
    }

    // watches firebase for a change in actions/sections/:id/violatedPermissionAdded
    function watchForNewViolatedPermissions() {
      firebaseActions.register(
        // will only run once. sets up firebase listener.
        'sections/' + $scope.$parent.section.id + '/violatedPermissionAdded',
        function () {
          console.log('Watching for violated permissions.');
          return getViolatedPermissions();
        }
      );
      return $scope.$on('$destroy', function () {
        firebaseActions.destroy();
        console.log('No longer watching for violated permissions.');
      });
    }
  },
]);
