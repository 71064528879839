app.factory('AccessCodeFactory', [
  '$q',
  'AnimatedLoginKeyboardFactory',
  '$timeout',
  '$http',
  function ($q, AnimatedLoginKeyboardFactory, $timeout, $http) {
    // Exposes the following variables to initiate sign in process:
    //  this.validations {username: "message", password: ""}
    var AccessCodeFactory = function () {
      var self = this;
      self.keyboard = new AnimatedLoginKeyboardFactory('#loading-keyboard-auth');
      self.access_card_validation_started = false;
      self.validations = {};

      // createSubscription method is the main point of interaction with this class.
      //  returns a resolved promise if successful
      //  rejects a promise if not
      this.createSubscription = function (user) {
        self.validations = {};
        self.access_card_validation_started = true;
        self.access_card_validation_success = false;
        self.validations.enteredClassCode = false;

        return localValidations(user).then(validateAccessCode).then(registerUser);
      };

      // updateSubscription method is the main point of interaction with this class.
      //  returns a resolved promise if successful
      //  rejects a promise if not
      this.updateSubscription = function (user) {
        self.validations = {};
        self.access_card_validation_started = true;
        self.access_card_validation_success = false;
        self.validations.enteredClassCode = false;

        return localValidations(user).then(consumeAccessCard);
      };

      // rejects a promise if missing the user object
      function localValidations(user) {
        self.validations = {};
        // we must have username and password from the first form. Other wise, send user back
        if (user === undefined || user.username === undefined || user.password === undefined) {
          console.log('Must provide user object to update subscription with access card.');
          return $q.reject();
        }

        if (user.access_code === undefined) {
          self.validations.access_code = 'Please enter an access code.';
          self.access_card_validation_started = false; // reset this for another attempt
          return $q.reject(self.validations);
        }
        if (typeof user.access_code === 'string' && user.access_code.length < 3) {
          self.validations.access_code = 'Your access code must be at least 3 characters.';
          self.access_card_validation_started = false; // reset this for another attempt
          return $q.reject(self.validations);
        }

        return $q.resolve(user);
      }

      // Validate AccessCode
      function validateAccessCode(user) {
        user.access_code = user.access_code.toUpperCase();
        return $http
          .post('users/validate_access_code.json', { access_code: user.access_code, organization_id: user.organization })
          .then(function () {
            return $timeout(function () {
              self.access_card_validation_success = true;
              self.progressMessage = 'Success! Access card valid!';
              return $q.resolve(user);
            }, 2500);
          })
          .catch(serverError);
      }

      function registerUser(user) {
        // Should call the server now...
        const userCreds = {
          username: user.username,
          password: user.password,
          password_confirmation: user.passwordConfirmation,
          instructor: user.instructor,
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          organization_id: user.organization,
        };
        if (user.studentNumber) {
          userCreds.student_number = user.studentNumber;
        }
        const paymentCreds = {
          method: 'access_code',
          access_code: user.access_code,
        };

        return $http.post('/users.json', { user: userCreds, payment: paymentCreds }).catch(function (response) {
          self.progressMessage = 'Error processing access code. Please contact Typist for support @ support@typistapp.ca.';
          console.log(response);
          return $q.reject();
        });
      }

      // makes the $http request to consume the access card
      function consumeAccessCard(user) {
        if (user.access_code) user.access_code = user.access_code.toUpperCase();

        const userCreds = {
          username: user.username,
          password: user.password,
        };
        const paymentCreds = {
          method: 'access_code',
          access_code: user.access_code,
        };

        return $http
          .put('users/update_subscription.json', { user: userCreds, payment: paymentCreds })
          .then(function () {
            return $timeout(function () {}, 2500);
          })
          .then(function () {
            self.access_card_validation_success = true;
            return progressMessage('Success! Account updated!', 3000);
          })
          .then(function () {
            return progressMessage('Signing in...', 3000);
          })
          .then(function () {
            return user;
          })
          .catch(serverError);
      }

      // used to catch a server error
      function serverError(response) {
        const hasServerErrorMessage =
          typeof response === 'object' &&
          typeof response.data === 'object' &&
          typeof response.data['errors'] === 'object' &&
          response.data['errors'].length > 0;
        return $timeout(function () {
          if (hasServerErrorMessage) {
            self.validations.access_code = response.data.errors[0];
            if (self.validations.access_code === 'You have entered a class code. Please enter a 16-digit access code.')
              // We display a much larger explanation in the view (_access_card_payment_form.html)
              self.validations.enteredClassCode = true;
          } else {
            self.validations.access_code = 'Access code is not valid.';
          }
          self.access_card_validation_started = false; // reset this for another attempt
          return $timeout(function () {
            // do not reset the access code error message
            // self.validations.access_code = '';
          }, 5000).then(function () {
            return $q.reject();
          });
        }, 3000);
      }

      // sets progress message, waits until delay is finished
      // returns a promise
      function progressMessage(message, delayAfter) {
        self.progressMessage = message;
        return $timeout(function () {
          console.log(message);
        }, delayAfter || 0);
      }
    }; // end of class

    return AccessCodeFactory;
  },
]);
