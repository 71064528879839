// note: requires bootstrap.js
// if you get an error that there's no .modal method,
// this is because bootstrap.js hasn't defined it yet
// import '../../../vendor/bootstrap.js'; // needed for .modal below
app.directive('myModal', function () {
  return {
    templateUrl: 'modals/_my_modal_template.html',
    restrict: 'E',
    transclude: {
      'modal-header': 'modalHeader',
      'close-button': 'closeButton',
      'modal-body': 'modalBody',
      'modal-footer': 'modalFooter',
    },
  };
});
