app.factory('TestLinks', [
  'TimedWritingRequirements',
  '$http',
  'TestLinksHelpers',
  '$filter',
  'UserSettings',
  function (TimedWritingRequirements, $http, TestLinksHelpers, $filter, UserSettings) {
    // Scheduled test links
    function TestLinks({ section = {}, user = {}, settings = {} } = {}) {
      const self = this;

      // Attach the passed in section and user objects
      this.section = section;
      this.user = user;
      // ng-model for storing currently selected start day for creating new test links
      this.days;
      // by default, we expire at 11:59 p.m. but this can be changed by the user in the
      // form
      const defaultClosesAt = { hour: '11', min: '59', period: 'p.m.' };
      this.closesAt = { ...defaultClosesAt, ...settings.closesAt };

      // Fetch test links are stored here
      // eg. [{id: 1, url:''},]
      this.tests = [];

      // Variable listing weeks using which a new test link can be created
      // eg. [{dateObj: Date, days: [{dateObj: Date, disabled: false, day: 1, month: 2, year: 2020}]}]
      this.selectFormWeekOptions = TestLinksHelpers.generateWeekRanges(4 * 6);

      // Variables related to the view
      this.view = {
        showTestRequirementsForm: false,
        // The test requirements id being used for creating new test links
        testRequirementId: null,
        // For hiding 'No links' placeholder untill test links are first fetched from server
        testLinksInitialLoad: false,
      };

      /* INIT */

      // 1. Instantiate a TimedWritingRequirements instance
      this.requirements = new TimedWritingRequirements();

      // 1b. Set current week to default
      this.days = this.selectFormWeekOptions[0].days;

      // 1c. Get/fetch initial requirements for based on current section.test params
      this.requirements.create(this.section.test).then((res) => {
        // 1d. Update initial testRequirementId
        self.view.testRequirementId = res.data.id;
      });

      // 3. Get all test links for current section
      this.getTestLinks();
    }

    /*************
     *  Instance Methods
     *************/

    // Updates the test requirements (ng-click function for the form in the view)
    // Params passed in are the requirements object
    TestLinks.prototype.updateRequirements = function (requirementsObj) {
      const self = this;
      // Note: We're deliberately not saving the day here
      UserSettings.set(`class/${this.section.code}/testLinks/closesAt`, {
        hour: this.closesAt.hour,
        min: this.closesAt.min,
        period: this.closesAt.period,
      });
      this.requirements.create(requirementsObj).then((res) => {
        // 1. Update the current testRequirementId to be used for creating new test links
        self.view.testRequirementId = res.data.id;
        // 2. Close the form
        self.view.showTestRequirementsForm = false;
      });
    };

    // Copies test links to clipboard for easy copy/paste
    TestLinks.prototype.copyToClipboard = function (asHtml = true) {
      const testLinks = this.tests;
      const testLinksString = testLinks
        .map((link) => {
          const requirements = this.requirements.library[link.test_requirement_id];

          // 5 Minute Timing. Expires on Wed. August 31, 2022 11:59 PM.
          const durationInMins = Math.round(requirements.duration_in_seconds / 6.0) / 10;
          const closesAt = $filter('date')(link.closes_at, 'EEE. MMM. d, y h:mm a');
          const url = link.url;

          const description = `${durationInMins} Minute Timing. Expires on ${closesAt}`;
          if (asHtml) {
            return `<a href="${url}" target="_blank">${description}</a>`;
          } else {
            return `${url}`;
          }
        })
        .join(asHtml ? '</br>' : '\n');
      return addToClipboard(testLinksString, asHtml);
    };

    // Get all test links for the current section
    TestLinks.prototype.getTestLinks = function () {
      const self = this;
      // TODO: 1. Turn on loading animation while we fetch links from server
      $http
        .get(`api/sections/${this.section.id}/test_links.json`, {
          params: { test_requirement_type: 'TimedWritingRequirement' },
        })
        .then((res) => {
          // 2a. Attach array of test links to this instance
          self.tests = res.data
            // 2b. Optional: sort by closes_at
            .sort((a, b) => {
              const aDate = new Date(a.closes_at);
              const bDate = new Date(b.closes_at);
              if (aDate > bDate) {
                return 1;
              } else if (aDate < bDate) {
                return -1;
              } else {
                return 0;
              }
            });

          // Add urls for testlinks
          self.tests.forEach((testLink) => (testLink.url = setUrl(testLink)));

          // 2c. fetch all the test requirement ids and make sure they are cached
          // by the TimedWritingRequirements service
          return self.requirements
            .getMultipleRequirementsById(res.data.map((testLink) => testLink.test_requirement_id))
            .then(() => {
              // Return the original response containing the testLinks
              return self.tests;
            });
        })
        .then(() => {
          // Update the view variable for initial load
          self.view.testLinksInitialLoad = true;
        })
        .catch(() => {});
    };

    // Create a test link
    // Params passed in are a date representation object eg. {year: 2020, month: 10, day: 1}
    // and the test requirements id
    TestLinks.prototype.create = function ({ year, month, day } = {}, testRequirementId) {
      $http
        .post(`/api/sections/${this.section.id}/test_links.json`, {
          test_link: {
            test_requirement_id: testRequirementId,
            opens_at: 'now',
            'closes_at(1i)': String(year),
            'closes_at(2i)': String(month),
            'closes_at(3i)': String(day),
            'closes_at(4i)': String(convertHourTo24Hour(this.closesAt)),
            'closes_at(5i)': String(parseInt(this.closesAt.min, 10)),
          },
        })
        .then((res) => {
          let testLink = res.data;
          testLink.url = setUrl(testLink);
          testLink.new = true; // will allow us to highlight this link in the view
          // add the link to current list of links
          this.tests.unshift(testLink);
        })
        .catch(() => {});
    };

    // Deletes a test link
    // param is the id of the test link to delete
    TestLinks.prototype.delete = function (id) {
      const self = this;
      const idx = self.tests.findIndex((testLink) => testLink.id === id);

      // This property is used to disable the delete button in the view
      // until we hear back from the server
      if (self.tests[idx]) self.tests[idx]._updating = true;

      // This makes sure the tooltip associated with the close button does not linger
      // Since it is last active right before element is removed
      // delete this if tooltip is removed from the close button
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl).dispose();
      });

      $http
        .delete(`/api/sections/${this.section.id}/test_links/${id}.json`)
        .then(() => {
          if (idx > -1) self.tests.splice(idx, 1);
        })
        .catch(() => {
          if (self.tests[idx]) self.tests[idx]._updating = false;
        });
    };

    /*************
     * Private functions
     *************/
    function setUrl(testLink) {
      return 'https://' + window.location.host + testLink.url_appendix;
    }

    function addToClipboard(text, asHtml = true) {
      const type = asHtml ? 'text/html' : 'text/plain';
      const blob = new Blob([text], { type: type });
      const data = [new ClipboardItem({ [type]: blob })];

      // Write the text to clipboard
      navigator.clipboard.write(data).then(
        () => {
          // Copied successfully
          alert('Copied successfully');
        },
        () => {
          // Did not copy successfully
          alert('Could not copy to clipboard');
        }
      );
    }

    // convert a 12 hour time with period (ie am or pm) to a 24 hour time
    // 12:30 am should return 00:30
    // 12:30 p.m. should return 12:30
    function convertHourTo24Hour(date) {
      let hour = parseInt(date.hour, 10);
      hour = hour === 12 ? 0 : hour;
      const isPM = date.period === 'pm' || date.period === 'p.m.';

      return isPM ? hour + 12 : hour;
    }

    return TestLinks;
  },
]);
