/* globals SERVER_TIME_SYSTEM_TIME_DIFF */
app.controller('DocumentProductionPreController', [
  'documentAssignment',
  '$q',
  '$scope',
  'DocProductionResultsDataService',
  '$http',
  '$stateParams',
  'permission',
  'TooltipService',
  '$timeout',
  'currentUser',
  'UserPreference',
  function (
    documentAssignment,
    $q,
    $scope,
    DocProductionResultsDataService,
    $http,
    $stateParams,
    permission,
    TooltipService,
    $timeout,
    currentUser,
    UserPreference
  ) {
    /* 
        TODO: refactor this to use same functionality as what's being used in classlist. 
        Perhaps a new class DocumentSubmissionsCollection, that can store submissions,
        calculate best score, etc 
    */

    /* VARIABLES */

    /* SCOPE VARIABLES */
    // Store DocumentSubmission instances
    $scope.pastAssignmentSubmissions = [];
    $scope.view.bestGradeSubmission = {}; // holds a copy of the Document Submission with best grade, otherwhise {}
    // stores user preferences fetched from server, eg. {typist_initials: "aa", ...}
    $scope.typistInitialsSettings;
    $scope.documentAssignment = documentAssignment;
    // Flash messages to display in case there is an error communicating with the server (eg. when fetching user preferences)
    $scope.view.flashMessage = null; // Any messages from the server upon form submission, eg. null or {message: '', error: false}
    // Reset the start assignment button to disabled state in the case where user navigates back from the submission page
    // (The parent controller is active for both states and may retain `enableStart = true` from before submission)
    $scope.view.enableStart = false;
    $scope.currentUser = currentUser;

    /* INITIALIZE */

    // 1. Fetch past all submissions for this document assignment from 'api/document_submissions/{assignment_id}/{secion_id}
    DocProductionResultsDataService.getPastAssignmentSubmissions($stateParams.document_assignment_id, permission.section_id)
      .then(function (submissions) {
        // 2. Submissions are an array of DocumentSubmission objects
        $scope.pastAssignmentSubmissions = submissions;

        // 2a. Call parent method to check if user has permission to start
        $scope.enableStartIfUserHasPermission($scope.pastAssignmentSubmissions);

        // 2b. Calculate best submission grade
        $scope.view.bestGradeSubmission = findBestGradeSubmission($scope.pastAssignmentSubmissions);

        // 3. Filter for document submissions where grading_complete is false
        let ungradedSubmissions = $scope.pastAssignmentSubmissions.filter((submission) => !submission.grading_complete);

        // 4. Set timeouts to check for grade data
        // for submissions whose grade we do not yet have, we will check this many milliseconds after the submission was uploaded
        const submissionCheckIntervals = [10000, 14000, 18000, 22000, 30000, 40000];
        ungradedSubmissions.map((us) => setTimeoutsToCheckForGrades(us, submissionCheckIntervals));
      })
      .then(TooltipService.updateTooltips);

    // 1b. Initiate UserPreference factory for typist_initials if document uses document variables
    if ($scope.documentAssignment.uses_document_variables) {
      const userPreference = $scope.currentUser.user_preference || {};
      $scope.typistInitialsSettings = new UserPreference(userPreference, 'typist_initials', 'Typist Initials');
    }

    /* FUNCTIONS */

    // Sets 1 or more timeouts that will check the server for an updated documentSubmission object and if found,
    //  will replace the documentSubmission object in $scope.pastAssignmentSubmissions
    //
    // @param documentSubmission {DocumentSubmission} - The student submission whose grading has not yet completed in Lambda
    // @param submissionCheckIntervals {Array} - An array of integers (milliseconds) that we will compare to when the submission was created
    //  and check the server for updates. For example [4000] would check the server 4 seconds after the object was created.
    function setTimeoutsToCheckForGrades(documentSubmission, submissionCheckIntervals) {
      if (documentSubmission.grading_complete) return documentSubmission;

      const now = Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF;

      // only attempt to retrieve submissions where grading is not complete and the grade HAS been
      //  released by the instructor
      let delaysMs = submissionCheckIntervals
        .map((delay) => {
          const msSinceSubmission = now - documentSubmission.created_at.getTime();
          return delay - msSinceSubmission;
        })
        .filter((delay) => delay >= 1000);

      // if the submission isn't graded, but all of our check intervals are in the passed,
      // re-check in a few seconds anyways
      if (delaysMs.length === 0) delaysMs.push(3000);
      delaysMs.map((delay) => checkForGrade(documentSubmission, delay));
    }

    // sets a timeout to go and check for grades.
    // Note: This function relies on $scope.pastAssignmentSubmissions and each time will pull a fresh
    //  copy of the documentSubmission in question. Further, it will update the $scope.pastAssignmentSubmissions object
    function checkForGrade(documentSubmission, delay) {
      const id = documentSubmission.id;

      let gradeCheckPromise = $timeout(() => {
        // get the latest copy of documentSubmission from the array
        const ds = $scope.pastAssignmentSubmissions.find((submission) => submission.id == id);
        if (ds.grading_complete) return $q.resolve({});

        console.log('Checking if grading is complete for submission with id: ', ds.id);
        return DocProductionResultsDataService.getSubmission(ds.id).then((submission) => {
          if (submission.grading_complete) {
            // replace submission with the same id from $scope.pastAssignmentSubmissions
            $scope.pastAssignmentSubmissions = $scope.pastAssignmentSubmissions.map((ds) =>
              ds.id === submission.id ? submission : ds
            );

            // recalculate best submission $scope.view.bestGradeSubmission = findBestGradeSubmission($scope.pastAssignmentSubmissions)
            $scope.view.bestGradeSubmission = findBestGradeSubmission($scope.pastAssignmentSubmissions);
          }
        });
      }, delay);

      $scope.$on('$destroy', () => $timeout.cancel(gradeCheckPromise));
    }

    // TEMPORARY - COPIED FROM `_student_factory.js`, refactor later
    // Takes an array of document submissions, eg. [ {,points: 10, base_points: 20, },]
    // Returns the document submission object with the best points score, eg. {, points: 10, base_points: 20}
    // Or returns {} if best grade cannot be determined (eg. invalid point values)
    function findBestGradeSubmission(docSubs = []) {
      // let's not apply a 'best attempt' label if there's only a single submission that's graded
      if (docSubs.filter((ds) => ds.grading_complete).length < 2) return true;
      // The reduce function determines the largest score amongst all submitted documents
      return docSubs.reduce(function (acc, ds) {
        const currentPoints = parseInt(ds.points, 10);
        const currentBasePoints = parseInt(ds.base_points, 10);
        // Make sure the value being compared is not NaN, otherwise will get unexpected behaviour when comparing numbers
        if (!isNaN(currentPoints) && !isNaN(currentBasePoints)) {
          // Only return the new points if they are greater than what's already in the acc variable
          // Setting default of -1 on acc.points because initially acc is {}, which means acc.points is undefined, and we still want to allow assignments that have 0 points score to pass through
          // Comparisons in js with undefined, converts it to NaN, which would return false in comparisons
          return currentPoints > (acc.points || -1) ? ds : acc;
        }
        return acc;
      }, {});
    }
  },
]);
