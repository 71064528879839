/* global SERVER_TIME_SYSTEM_TIME_DIFF */

// Provides an interface for notifications
app.service('Permissions', [
  '$http',
  'PermissionsHelperFunctions',
  function ($http, PermissionsHelperFunctions) {
    // special function to efficiently create timed writings.
    // accepts all the attributes of a permission object, with the following differences:
    //   1. user_id must be an array
    //   2. notification_id must be an array
    this.create_timed_writings = function (p) {
      const expiresOn = p.expiresOn ? p.expiresOn : dateInFuture(p.duration.days, p.duration.hours, p.duration.minutes);

      var data = {
        permission: {
          user_id: p.userId,
          section_id: p.sectionId,
          expires_on: expiresOn,
          action: p.action,
          options: p.options,
          notification_id: p.notification_id,
          document_assignment_id: p.document_assignment_id || null,
        },
        debug: {
          userSystemTime: new Date(),
          SERVER_TIME_SYSTEM_TIME_DIFF,
          duration: p.duration,
        },
      };

      return $http.post('permission_timed_writings', data);
    };

    // retrieves tests that have been created by a proctor for the student to complete.
    //   see PermissionHelperFunctions to see how these tests are shaped.
    //   front-end controller also determines the best attempt on each test, and whether the test can be continued.
    this.getProctoredTests = function () {
      return $http.get('permission/proctored_tests.json').then(function (response) {
        return PermissionsHelperFunctions.reshapePermissions(response.data);
      });
    };

    function dateInFuture(plusDays, plusHours, plusMinutes) {
      const now = Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF;
      const addDays = (plusDays || 0) * 24 * 60 * 60 * 1000;
      const addHours = (plusHours || 0) * 60 * 60 * 1000;
      const addMinutes = (plusMinutes || 0) * 60 * 1000;
      const dateInFuture = new Date(now + addDays + addHours + addMinutes);
      return dateInFuture;
    }

    return this;
  },
]);
