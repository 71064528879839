app.factory('DocumentAssignment', [
  '$http',
  'S3Upload',
  '$q',
  'Instruction',
  'DocumentVariablesList',
  '$timeout',
  '$state',
  function ($http, S3Upload, $q, Instruction, DocumentVariablesList, $timeout, $state) {
    var DocumentAssignment = function (props) {
      var self = this;
      this.country = 'CA'; // Default
      this.section_id = null;
      this.view = {
        starterFile: {},
        solutionFile: {},
        pageReloadRequired: false, // this will be set to true if contacts directory url has been update. Document variables list requires the current version of contacts dir to be loaded
        contactsDirUrl: {
          value: props && props.contacts_directory_url,
          successMessage: null, // string or null
          errorMessage: null, // string or null
          loading: false,
        },
      };
      // assign all properties to self so that we can instantiate
      //  objects in bulk (i.e. with a map)
      assignAllPropertiesToSelf(props);
      // since we group by category, we need to duplicate to prevent page jumps in the assignment creator
      this.categoryDup = this.category;

      // retireves a DocumentAssignment object
      this.initFromPermission = function (permission_id, section_id) {
        self.section_id = section_id;
        return $http
          .get(`api/document_assignments/show_with_permission/${permission_id}.json`)
          .then(function (res) {
            const docAssignmentProps = res.data;
            assignAllPropertiesToSelf(res.data);
            // Only initialize document variables list and subsequently fetch/create
            // generated variables if this document assignment has document variables present
            if (self.document_variables_list) {
              return self.document_variables_list
                .init() // must call this before having access to variables list
                .then(function () {
                  return self.getGeneratedVariables(docAssignmentProps, self.section_id);
                });
            }
            return self;
          })
          .catch(function (err) {
            var errorMessage = err.data.errors;
            return $q.reject(errorMessage);
          });
      };

      // retireves a basic DocumentAssignment object with only:
      //  non-sensitive information (i.e. no instructions)
      //  link to download the starter file (disable in the view for now)
      this.initWithoutPermission = function (documentAssignmentId) {
        return $http
          .get(`api/document_assignments/show_without_permission/${documentAssignmentId}.json`)
          .then(function (res) {
            assignAllPropertiesToSelf(res.data);
            return self;
          })
          .catch(function (err) {
            var errorMessage = err.data.errors;
            return $q.reject(errorMessage);
          });
      };

      // retireves a DocumentAssignment object
      this.init = function (id) {
        return $http
          .get(`document_assignments/${id}.json`)
          .then(function (res) {
            const docAssignmentProps = res.data;
            // Create a new instance of variables list in case current document assignment has no variables defined
            // This way users can still have access to the forms for creating new variables
            self.document_variables_list = new DocumentVariablesList(
              {},
              id,
              docAssignmentProps.json_solution_url,
              // If this is a document without generated variables (so setting contacts directory url is not enforced in the front end)
              // then this value will be null
              docAssignmentProps.contacts_directory_url
            );
            assignAllPropertiesToSelf(res.data);

            self.view.contactsDirUrl.value = self.contacts_directory_url;

            return self.document_variables_list
              .init() // must call this before having access to variables list
              .then(function () {
                return self.getGeneratedVariables(docAssignmentProps);
              });
          })
          .catch(function (err) {
            var errorMessage = err && err.data && err.data.errors;
            return $q.reject(errorMessage);
          });
      };

      // A function that fetches generated variables for current user/section/document assignment combination
      // (if does not exist, it generates a new one)
      // docAssignmentProps is in the form of {instructions: {}, id:1,...}, i.e. the response from the server
      // that gets later instantiated by this factory via assignAllPropertiesToSelf
      // section_id is an integer (undefined for instructors)
      // Returns a promise
      this.getGeneratedVariables = function (docAssignmentProps, section_id) {
        // Ignore request to get generated variables if document variables list
        // is not defined for current document assignment
        const documentVariables = self.document_variables_list ? self.document_variables_list.documentVariables : [];
        if (!documentVariables.length) return $q.resolve(self);

        // 1. Existing generated variables fetched (included with document assignment)
        // If already have generated variables, return early
        if (self.generated_variables_for_user_section) return $q.resolve(self);

        // 2. OR Create new generated variables if they don't exist
        // If no existing generated variables, generate them here and send request back to server
        const generatedVariables = self.document_variables_list.generate();
        return (
          $http
            .post('api/generated_variables.json', {
              section_id: section_id, //will be undefined for instructors
              document_assignment_id: self.id,
              variables: generatedVariables,
            })
            .then(function (res) {
              // For instructions to be immediately updated, they need to be re-instantiated again
              // alternatively, can re-instantiate the entire document assignment object with the new
              // generated variables
              assignAllPropertiesToSelf({
                ...docAssignmentProps,
                generated_variables_for_user_section: res.data,
              });
              // Since we are re-initializing the document assignment, need to make sure
              // document_variables_list is also re-initialized, otherwise the view
              // will keep loading indefinitely (documentVariablesList.documentVariables will be empty)
              return self.document_variables_list
                .init() // must call this before having access to variables list
                .then(function () {
                  return self;
                });
            })
            // Resend the error in the format that the catch block down the promise chain can read it
            .catch(function () {
              return $q.reject({
                data: {
                  errors:
                    'Error: Could not generated random variables for the current assignment. Please contact Typist support.',
                },
              });
            })
        );
      };

      // On-click callback for generating a new set of generated variables
      // that matches the current document variables list version
      // The option to regenerate the variables is displayed to the user if
      // user already has generated variables, but none of them match the current version
      // of document variables list
      this.regenerateVariables = function () {
        // 1. Don't need to check if the version is already the same as document variables list because the backend won't allow it

        // 2. Generate a new version of GeneratedVariable object using the currently loaded document variables list
        let generatedVariables;
        try {
          generatedVariables = self.document_variables_list.generate();
        } catch (e) {
          self.document_variables_list.view.flashMessage = {
            message: e.message,
            error: true,
          };
          return $q.reject();
        }

        return $http
          .post('api/generated_variables.json', {
            section_id: self.section_id, //will be undefined for instructors
            document_assignment_id: self.id,
            variables: generatedVariables,
          })
          .then(function () {
            // This will reload the current `app.documents.document_production_activity.instructions` state, which resolves the documentAssignment
            // which in turn will return the update version of the document assignment and included generated variables
            $state.reload();
          })
          .catch(function () {
            self.document_variables_list.view.flashMessage = {
              message:
                'Error: Could not re-generate the values for the new Random Variables. Please refresh the page and try again or contact Typist support if the problem persists.',
              error: true,
            };
            return $q.reject();
          });
      };

      this.create = function () {
        return $http
          .post('document_assignments.json', {
            document_assignment: {
              name: self.name,
              category: self.category,
              alternative_category: self.alternative_category,
              country: self.country,
              style_guide_version: self.style_guide_version || null,
              // If blank option is selected, want to return null to the server
              difficulty: self.difficulty || null,
            },
          })
          .then(function (res) {
            assignAllPropertiesToSelf(res.data);
          });
      };

      // This update method is used in the assignment creator index screen.
      //  we have three update methods to ensure that only the S3 upload callbacks
      //  will update the starter/solution files.
      this.update = function () {
        return $http
          .put(`document_assignments/${self.id}.json`, {
            document_assignment: {
              name: self.name,
              category: self.category,
              alternative_category: self.alternative_category,
              country: self.country,
              style_guide_version: self.style_guide_version || null,
              requires_permission: self.require_permission,
              randomized: self.randomized,
              accessible: self.accessible,
              // If blank option is selected, want to return null to the server
              difficulty: self.difficulty || null,
              hidden: self.hidden,
            },
          })
          .then(function (res) {
            assignAllPropertiesToSelf(res.data);
          });
      };

      // Called after S3 file upload to update only the starter file attributes
      this.updateStarterFiles = function () {
        return $http
          .put(`document_assignments/${self.id}.json`, {
            document_assignment: {
              docx_starter_key: self.docx_starter_key,
              fodt_starter_key: self.fodt_starter_key,
              json_starter_key: self.json_starter_key,
            },
          })
          .then(function (res) {
            assignAllPropertiesToSelf(res.data);
          });
      };

      // Called after S3 file upload to update only the solution file attributes
      this.updateSolutionFiles = function () {
        return $http
          .put(`document_assignments/${self.id}.json`, {
            document_assignment: {
              docx_solution_key: self.docx_solution_key,
              fodt_solution_key: self.fodt_solution_key,
              json_solution_key: self.json_solution_key,
            },
          })
          .then(function (res) {
            assignAllPropertiesToSelf(res.data);
          });
      };

      this.uploadStarterFile = function (formSelector) {
        self.view.starterFile = {
          errorMessage: null,
          successMessage: 'Checking Permissions...',
          disableForm: true,
        };

        var s3Upload = new S3Upload({
          formSelectorID: formSelector,
          validFileFormats: ['docx'],
          presignedPostURL: 'api/document_assignments/set_presigned_post',
          presignedPostParams: { id: self.id, upload_type: 'docx_starter_key' },
        });

        return s3Upload
          .getPresignedPost()
          .then(function (res) {
            self.docx_starter_key = res.docx_starter_key;
            self.fodt_starter_key = res.fodt_starter_key;
            self.json_starter_key = res.json_starter_key;
            self.view.starterFile.successMessage = 'Permission received! Attempting upload...';
          })
          .catch(function (err) {
            self.view.starterFile = { errorMessage: err.data.errors };
            return $q.reject(err.data.errors);
          })
          .then(s3Upload.sendToS3)
          .then(function (res) {
            self.view.starterFile = {
              errorMessage: null,
              disableForm: true,
              successMessage: 'File uploaded. Updating the reference in our database...',
            };
            return res;
          })
          .then(self.updateStarterFiles)
          .then(function () {
            self.view.starterFile = {
              errorMessage: null,
              disableForm: false,
              successMessage: 'Success! Starter file has been updated!',
            };
          })
          .catch(function () {
            if (!self.view.starterFile.errorMessage) {
              self.view.starterFile = {
                errorMessage: 'Oh no -- something went wrong! Better have Matt take a look at this.',
                disableForm: false,
                successMessage: null,
              };
            }
            return $q.reject();
          });
      };

      this.uploadSolutionFile = function (formSelector) {
        self.view.solutionFile = {
          errorMessage: null,
          successMessage: 'Checking Permissions...',
          disableForm: true,
        };

        var s3Upload = new S3Upload({
          formSelectorID: formSelector,
          validFileFormats: ['docx'],
          presignedPostURL: 'api/document_assignments/set_presigned_post',
          presignedPostParams: { id: self.id, upload_type: 'docx_solution_key' },
        });

        return s3Upload
          .getPresignedPost()
          .then(function (res) {
            self.docx_solution_key = res.docx_solution_key;
            self.fodt_solution_key = res.fodt_solution_key;
            self.json_solution_key = res.json_solution_key;
            self.view.solutionFile.successMessage = 'Permission received! Attempting upload...';
          })
          .catch(function (err) {
            self.view.solutionFile = { errorMessage: err.data.errors };
            return $q.reject(err.data.errors);
          })
          .then(s3Upload.sendToS3)
          .then(function (res) {
            self.view.solutionFile = {
              errorMessage: null,
              disableForm: true,
              successMessage: 'File uploaded. Updating the reference in our database...',
            };
            return res;
          })
          .then(self.updateSolutionFiles)
          .then(function () {
            self.view.pageReloadRequired = true;
            self.view.solutionFile = {
              errorMessage: null,
              disableForm: false,
              successMessage: 'Success! Solution file has been updated!',
            };
          })
          .catch(function () {
            if (!self.view.solutionFile.errorMessage) {
              self.view.solutionFile = {
                errorMessage: 'Oh no -- something went wrong! Better have Matt take a look at this.',
                disableForm: false,
                successMessage: null,
              };
            }
            return $q.reject();
          });
      };

      // Sets a contacts directory url for the current assignment
      this.updateContactsDirectoryUrl = function () {
        self.view.contactsDirUrl.successMessage = null;
        self.view.contactsDirUrl.errorMessage = null;
        self.view.contactsDirUrl.loading = true;
        return (
          $http
            .put(`document_assignments/${self.id}.json`, {
              document_assignment: {
                contacts_directory_url: self.view.contactsDirUrl.value,
              },
            })
            // small delay so that the button doesn't get spammed
            .then(function (res) {
              return $timeout(function () {
                return res;
              }, 500);
            })
            .then(function (res) {
              self.view.pageReloadRequired = true;
              self.view.contactsDirUrl.loading = false;
              self.view.contactsDirUrl.successMessage = 'Contacts Directory Url has been updated.';
              assignAllPropertiesToSelf(res.data);

              self.document_variables_list.contactsDirectoryUrl = self.contacts_directory_url;
            })
            .catch(function (err) {
              self.view.contactsDirUrl.loading = false;
              if (err.data.errors) {
                self.view.contactsDirUrl.errorMessage = err.data.errors;
              } else {
                self.view.contactsDirUrl.errorMessage = 'Oh no -- something went wrong! Better have Matt take a look at this.';
              }
            })
        );
      };

      // Fetches instructions for current assignment and instantiates/merges them
      // onto this document assignment instance
      // If generated_variables_for_user_section param is provided (an object of string values, eg. {'contact1.firstName': 'Bob', ...}),
      // Generated variables will be used to replace any random variable placeholders in Markdown instructions
      this.getInstructions = function (generated_variables_for_user_section) {
        $http.get(`/api/document_assignments/${self.id}/instructions.json`).then((res) => {
          // Instructions, generated_variables_for_user_section, and country are required to instantiate Instructions factory
          assignAllPropertiesToSelf({
            instructions: res.data,
            generated_variables_for_user_section: generated_variables_for_user_section,
            country: self.country,
          });
        });
      };

      // assigns all properties retrieved from the server to self
      function assignAllPropertiesToSelf(props) {
        for (var prop in props) {
          if (Object.prototype.hasOwnProperty.call(props, prop)) {
            // User Instruction Factory for prop
            if (prop === 'instructions') {
              const modifiedProp = props[prop].map(function (inst) {
                let generatedVariables;
                if (
                  props['generated_variables_for_user_section'] !== null &&
                  typeof props['generated_variables_for_user_section'] === 'object'
                ) {
                  generatedVariables = props['generated_variables_for_user_section'].variables;
                }
                // Pass the country ('CA' or 'US') so that the correct Starter File Browser is displayed
                return new Instruction(inst, props.country, generatedVariables, self);
              });
              self[prop] = modifiedProp;
            } else if (prop === 'document_variables_list') {
              const generatedVariables = props['generated_variables_for_user_section'] || {};
              const modifiedProp = new DocumentVariablesList(
                {
                  id: props[prop].id,
                  documentVariables: props[prop].variables,
                  version: props[prop].version,
                },
                self.id,
                props['json_solution_url'],
                props['contacts_directory_url'],
                generatedVariables
              );
              self[prop] = modifiedProp;
            } else {
              self[prop] = props[prop];
            }
          }
        }
      }
    };

    return DocumentAssignment;
  },
]);
