import InteractiveKeyboard from '../../../../../components/InteractiveKeyboard/InteractiveKeyboard';
// LessonController
//
// Delegates the handling of all modals to the modal controller. Listens for message to start a
//   lesson for the user.
//
// Listens for the following events:
//  a. lessonModals:complete : Starts the lesson since modals are complete.
//  b. lesson:lineComplete   : Get a new line for the user to continue to type.
//  c. lesson:lessonComplete : Save data. Launch a modal sequence.
//
// When lessons complete, broadcasts:
//  a. lessonModals:start    : Activates the modals in child controller (LessonModalController)
//
// Controls the functionality during lessons
//  -> controls launching modals on this page
//  -> controls how the stats are displayed on the screen
//  -> calls methods to save or update data for user.
app.controller('LessonController', [
  '$scope',
  '$rootScope',
  'LessonFactory',
  'Level',
  'accountSettings',
  '$state',
  '$timeout',
  'currentUser',
  'ReactComponent',
  'Auth2',
  'keyboardUtilities',
  function (
    $scope,
    $rootScope,
    LessonFactory,
    Level,
    accountSettings,
    $state,
    $timeout,
    currentUser,
    ReactComponent,
    Auth2,
    keyboardUtilities
  ) {
    $scope.accountSettings = accountSettings;

    // Adding currentUser to scope so that the lesson modals can access this object
    // through $scope.$parent.currentUser
    $scope.currentUser = currentUser;
    // Track the digitIndex variable from TypingInput service. Used for displaying the next-to-type letter as a hint
    // for the student if no keypress is detected
    $scope.digitIndex = 0;
    $scope.voiceoverAnnouncement = 'Begin lesson.'; // announced when modals are complete

    // console.log("lesson");
    window.scrollTo(0, 0); // scrolls to top of page
    $scope.$broadcast('keyboard:hide-hands');

    // Get/create instance of a ReactComponent with id 'react-keyboard'.
    // the updateReactKeyboard and removeReactKeyboard functions act specifically on the react component with id 'react-keyboard'
    const { update: updateReactKeyboard, remove: removeReactKeyboard } = ReactComponent.init(
      'react-keyboard',
      InteractiveKeyboard,
      {
        enableHover: false,
        animateOnKeyPress: false,
        version: 2,
        highlightKeys: [],
        hideHands: true,
        skinTone: currentUser.user_preference.skin_tone,
        skinToneUpdateCallback: (skinTone) => {
          // first, we update the currentUser in this controller
          currentUser.user_preference.skin_tone = skinTone;
          // next, we have to update the currentUser stored in Auth2 so that all other
          // angularJS controllers have access to the updated user object
          Auth2.updateUserPreference({ skin_tone: skinTone });
        },
      }
    );

    // The init function only sets up the ReactComponent factory, but does not render the react component on screen,
    // so an update function must be called (it will render the initial props passed into the init function)
    updateReactKeyboard();

    // don't let the user start until the modals are complete
    var allowStart = false;

    // load relevant data
    $scope.lesson = Level.getLesson($state.params);
    let lessonFactory = new LessonFactory($scope.lesson, $scope);
    $scope.TypingInput = lessonFactory.TypingInput;

    // Cancel the user inactivity checker in lesson factory
    $scope.$on('$destroy', () => {
      lessonFactory.cancelCheckUserInactivityInterval();
      removeReactKeyboard();
    });

    // start when the lesson modals are finished
    $scope.$on('lessonModals:complete', function () {
      window.scrollTo(0, 0); // scrolls to top of page
      allowStart = true;
      $scope.$broadcast('lesson:started'); // used at least in active_user_timer
      $scope.$broadcast('keyboard:show-hands');

      $timeout(() => document.getElementById('line-of-text').focus(), 0);
      $timeout(() => {
        // Check if no keys have been pressed yet, show hands on the homerow keys
        if ($scope.digitIndex === 0) {
          updateReactKeyboard({
            hideHands: false,
          });
        }
      }, 500).then(() => {
        // This loads in the very first letter of the text prompt that the user should type next
        $timeout(() => {
          // Check if no keys have been pressed yet, only then display the hint for the first letter to press
          if ($scope.digitIndex === 0) {
            updateReactKeyboard({
              highlightKeys: [],
              // highlightKeys: [$scope.TypingInput.view.lineOfTextObjects[0].letter],
            });
          }
        }, 1500);
      });
    });

    // don't show instructions, but show stats on the screen.
    $scope.$on('lesson:lineComplete', function (a, stats) {
      console.log(stats);
      // $scope.digitIndex = 0;
      $scope.showInstructions = false;
      $scope.stats = stats;
    });

    // when the lesson is complete (ie the bar is filled)
    $scope.$on('lesson:lessonComplete', function (a, stats) {
      $rootScope.$broadcast('keyboard:hide-hands');
      $scope.showInstructions = false;
      allowStart = false;
      $scope.stats = stats;

      // 1. saves the results for the user (Level.updateLessonResultsForChallenge)
      // 2. launches the appropriate modal
      // 3. Updates the lesson if the user could like to keep going
      Level.updateLessonResultsForChallenge($state.params.level).then(function (didUserAdvance) {
        var modalSelector = didUserAdvance ? '#lesson-complete-earned-a-star' : '#lesson-complete-but-no-star';
        return $timeout(function () {
          lessonFactory = new LessonFactory($scope.lesson, $scope);
          $scope.TypingInput = lessonFactory.TypingInput;
          $timeout(function () {
            $scope.stats = $scope.TypingInput.stats;
          }, 400); // reset stats to defaults (incl. progress bar)
          $rootScope.$broadcast('lessonModals:start', { modalSelector: modalSelector });
        }, 1200);
      });
    });

    // keydown event handler
    let setCurrentKey = $timeout(() => {}, 0);
    $scope.$on('keydown', function (a, e) {
      // prevent default behaviour of space key while modals are active
      keyboardUtilities.blockScrollingWithSpaceKey(e);

      // Create the event log
      if (allowStart) {
        $scope.digitIndex = $scope.TypingInput.addEvent(e); // Store the event
        // Add a timeout so that the css animation responsible for the hands fading in/out starts first, and then the key highlight happens,
        // otherwise on the fade out, the next highlight key is immediately set, before the fade out animation for previous hand position is finished
        // (make sure to sync this to the transition delay in InteractiveKeyboardKey.module.scss .fingerImageContainer class, currently both are set to 300ms)
        $timeout.cancel(setCurrentKey);
        setCurrentKey = $timeout(() => {
          updateReactKeyboard({
            // highlightKeys: [$scope.TypingInput.view.lineOfTextObjects[$scope.digitIndex].letter],
            highlightKeys: [],
          });
        }, 300);
      }
    });
  },
]);
