// Provides access to an object that acts as a cache for other objects
//   Service will check for objects by their unique key, and return them if available
app.service('CacheService', [
  function () {
    var _objects = {};

    // Returns an object by its unique key if it exists
    this.get = function (key) {
      if (typeof _objects[key] !== 'undefined') {
        return _objects[key];
      }
      return false;
    };

    // Adds an object to the cache by its unique key if it exists
    this.set = function (obj, key) {
      _objects[key] = obj;
      return true;
    };

    // Clears the Cache
    this.clear = function () {
      _objects = {};
      return true;
    };

    return this;
  },
]);
