// TranscriptionModalController
//
// HomeRowTech Modal Integration
//  To install HomeRowTech on a controller, the following are required:
//    a. HomeRowTech object must be initiated at assigned to scope: $scope.homeRowTech = new Home....
//    b. A keydown event to handle the process (see listener below)
//    c. When the homeRowTech modal completes successfully, it triggers a callback. A callback needs to be bound to advance
//          the modal sequence: modalSequence.next()

app.controller('TranscriptionModalController', [
  '$scope',
  '$rootScope',
  'ModalSequence',
  'ModalSequenceArray',
  '$state',
  '$timeout',
  function ($scope, $rootScope, ModalSequence, ModalSequenceArray, $state, $timeout) {
    // Instantiate our sequences. First ModalSequences will be auto-started below
    var modals = [];
    modals.push(new ModalSequence('#startup', { modalNames: ['welcomeModal'] }));

    // start the first modalSequence
    var modalSequences = new ModalSequenceArray(modals);

    // // attach the event listener and activate modal
    var listenerDestroyer;

    // Called from TranscriptionController.
    //   -> data must contain a modalSelector attribute with a string as to which modal to watch.
    //         the modal names are associated with objects below in the switch statement.
    $scope.$on('TranscriptionModals:start', function (a, data) {
      modalSequences.getBySelector(data.modalSelector).start();

      listenerDestroyer = activateListener(data.delayListener);
      modalSequences.getBySelector(data.modalSelector).start(listenerDestroyer);
    });

    // keydown event handler
    //  -> this is the engine of this controller.
    //  -> reacts to keypresses, and defines criteria to advance
    //   -> returns the $destroy event
    var modalSequenceSelector;
    $scope.allowListener = true; // also animates button
    function activateListener(delayListener) {
      // used to make sure user doesn't accidently advance the modal
      $scope.allowListener = false;
      delayListener = delayListener || 0;
      $timeout(function () {
        $scope.allowListener = true;
      }, delayListener);

      var activeModal = '';
      return $scope.$on('keydown', function (a, e) {
        if (!modalSequences.getActiveModalSequence()) {
          throw 'Modal listener is active, but no modal active.';
        }
        if (!$scope.allowListener) return; // enables a delay, so user won't accidently advance modal

        // get the activeModalName
        activeModal = modalSequences.getActiveModalSequence();
        modalSequenceSelector = activeModal.selector;

        // homeRowTech handles its own keydown events
        // if (activeModalName === "homeRowTechModal") {
        //   if (e.key_pressed === "Backspace") $state.reload();
        //   return;
        // }

        // advance the slide on keydown
        if (e.key_pressed === ' ') {
          activeModal.next();
          // no active modal sequence, then we're done!
          if (!modalSequences.getActiveModalSequence()) {
            $rootScope.$broadcast('TranscriptionModals:complete', { modalSelector: modalSequenceSelector });
            return;
          }
        } else if (e.key_pressed === 'Backspace') {
          $state.go('app.home');
        }
      });
    }

    // Attached to the 'X' in the top right of the modal
    //  -> Goes home
    $scope.goHome = function () {
      $state.go('app.home');
    };

    // Attached to the 'X' in the top right of homerowtech
    //  -> Goes back
    $scope.goBack = function () {
      $state.go('app.home');
    };
  },
]);
