app.controller('PrivacyPolicyRegController', [
  '$scope',
  '$state',
  'ValidateObjectService',
  function ($scope, $state, ValidateObjectService) {
    $scope.user = $state.params.user;
    $scope.parentView.showSubheading = false; // this is inheirited from parent scope. displays: "The typing trainer designed..."

    ValidateObjectService.validate($scope.user, ['instructor', 'organization'], function () {
      return $state.go('app.home');
    });

    $scope.showFrench = false;

    $scope.submit = function (user) {
      return $state.go('authentication.3', { user: user });
    };
  },
]);
