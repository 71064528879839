import { getDatabase, ref, onValue, off } from 'firebase/database';

// Loads certificates for students
app.controller('StudentCertificatesController', [
  '$scope',
  '$timeout',
  'current_user',
  function ($scope, $timeout, current_user) {
    $scope.current_user = current_user;
    const db = getDatabase();

    // Certificates.createMultiple([129], [{ date: new Date(), speed: "95.8", accuracy: "98.2", duration: "3 Minutes", instructor: "Matt McInnis", organization: "Centennial College", sectionName: "Keyboarding 101 Fall 2018" }])

    // adds class to the body element so that we can hide nav, footer etc
    //  for print only in this view. Removes this class when leaving state
    $('body').addClass('transcription-print');
    $scope.$on('$destroy', function () {
      $('body').removeClass('transcription-print');
    });

    // listen for new certificates and grab them if we see them!
    let firebaseRef = ref(db, `users/${current_user.id}/certificates`);
    onValue(firebaseRef, (snapshot) => {
      // need to applyAsync since angularjs doesn't monitor non-angularjs async code. We use
      // applyAsync instead of just apply to avoid clashes with digest cycle (and errors we
      // were experiencing)
      $scope.$applyAsync(() => {
        $scope.certificates = Object.values(snapshot.val() || {});

        $scope.certificate = null;
        if ($scope.certificates.length == 0) {
          $scope.certificate = {
            sample: true,
            best_attempt: { created_at: new Date(), netWPM: '__', accuracy: '__', duration: '__ Minutes' },
            instructor: 'James T. Kirk',
            organization: 'Starfleet Academy',
            sectionName: 'Keyboarding 101 Fall 2018',
            first_name: current_user.first_name,
            last_name: current_user.last_name,
          };
        }
      });
    });

    $scope.$on('$destroy', function () {
      off(firebaseRef);
    });

    $scope.previewCertificate = function (cert) {
      var elmnt = document.getElementById('certificate-print-div');
      elmnt.scrollIntoView();
      $scope.certificate = cert;
    };

    $scope.print = function () {
      $timeout(function () {
        window.print();
      }, 200); // open the print dialog
    };
  },
]);
