// Directive attached to iframe that sends it a message with iframeOrigin info and documentSubmissionId for using the right assignment to grade
app.directive('proofreadResults', [
  '$timeout',
  '$http',
  function ($timeout, $http) {
    var initProofreadingResults = proofreadResults.initProofreadingResults;

    // NOTE: If the font is not cached, it will generate a wrong max char width because will be using system font
    // Since fonts are loaded async, might need to render it somewhere on the website before hand

    function link(scope, element) {
      // TODO: delete this section once AWS lambda serves updated code
      /*
    $http.get('http://localhost:3001')
      .then(function(result){ 
        var render = initProofreadingResults(result.data, element[0], {totalPointsWithinDocument: 25}).render;
        // needed so font loads
        // render();
        $timeout(render, 1000);
      })
    */

      var render = initProofreadingResults(
        // Data to be loaded, which is an array of sections
        scope.documentSubmission.grading_details,
        // container to attach to
        element[0],
        // Extra options
        { totalPointsWithinDocument: 25 }
      ).render;
      // Timeout is needed for now to ensure the font loads
      $timeout(render, 1000);
    }
    return {
      link: link,
    };
  },
]);
