// Provides helper functions primarily to the finger animations controller
//
// Three main ways to interact with this service:
//  1. FingerAnimationsService.highlightKey - animates a single movement
//  2. FingerAnimationsService.simulateKeypresses - animates a series of keypress objects
//  3. FingerAnimationsService.typeString - accepts a string 'hello world' and animates the keypresses
//
// Also included are the following:
//  1. FingerAnimationsService.reset - Cancels active animations and resets the hands to home row position
//
// All methods above will broadcast an event that can be captured by controllers. See FingerAnimationsService.highlightKey
//  for details and implementation.
app.service('FingerAnimationsService', [
  '$timeout',
  'FingerAnimationsServiceHelpers',
  '$q',
  '$rootScope',
  function ($timeout, FingerAnimationsServiceHelpers, $q, $rootScope) {
    var self = this;

    // internal variable used to manage state
    var _currentAnimation = {
      left: { img: null, timers: [], removePreviousHighlightedKeys: function () {}, finger: null },
      right: { img: null, timers: [], removePreviousHighlightedKeys: function () {}, finger: null },
      // showImg simply swaps the currently displayed image with the desired
      showImg: function (hand, key) {
        // Add safeguards for undefined hand or key
        if (!hand || !key) {
          return;
        }
        var keySelector = FingerAnimationsServiceHelpers.transformToSelector(hand, key);
        // Exit if we couldn't get a valid selector
        if (!keySelector) {
          return;
        }

        // Check if the current image exists before trying to hide it
        if (this[hand] && this[hand]['img']) {
          $('.letter-' + this[hand]['img']).addClass('d-none');
        }

        $('.letter-' + keySelector).removeClass('d-none');

        // Make sure hand object exists before setting
        if (this[hand]) {
          this[hand]['img'] = keySelector;
        }
      },
      animationChain: {
        animations: [],
        inProgress: false, // true if there are a series of animations running
        interrupt: false, // if true, the next animation will not run, will cancel future animations, and set this value back to false
      },
    };

    // Animates a series of keystrokes structured as an array:
    // keypresses: [
    //    {"target_key"=>"D", "key_pressed"=>"D", "previous_key"=>nil, "time_between_keys"=>null},
    //    {"target_key"=>"r", "key_pressed"=>"r", "previous_key"=>"D", "time_between_keys"=>214},
    //    ...
    // ]
    // options: {} (see below)
    // Each time a keypress is animated, an event is broadcast (see highlightKey)
    //
    // Example:
    // FingerAnimationsService.simulateKeypresses([
    //  {"target_key": "D", "key_pressed": "D", "previous_key": null, "time_between_keys": null },
    //  {"target_key": "r", "key_pressed":"r", "previous_key":"D", "time_between_keys": 500},
    // ], { timeBetweenKeystrokes: 220, delayStart: 1000, backToHomeRow: true, pauseOnFinalKeystroke: 4000, scaleDelay: 2 });
    this.simulateKeypresses = function (keypresses, options) {
      options = options || {};
      options.delayStart = defaultFor(options.delayStart, 0); //
      options.backToHomeRow = defaultFor(options.backToHomeRow, false); // if true the hands will be reset at the end
      options.pauseOnFinalKeystroke = defaultFor(options.pauseOnFinalKeystroke, 1000); // how long to pause on final keystroke (since no keystrokes follows)
      options.scaleDelay = defaultFor(options.scaleDelay, 1); // [0 -> inf) A value of 1.5 will make keypresses 50% longer

      // transforms into the arguments expected by the method highlightKey
      // ex. [{digit: "W", isShift: false}, {duration: 200, ..}]
      keypresses = transformKeypresses(keypresses, options.pauseOnFinalKeystroke, options.scaleDelay);

      // uses an accumulator that startes with an empty promise, and then chains each keypress together in a promise.
      //  -> After each animation we check _currentAnimation to see if the sequence has been interrupted
      //  -> At the end, we reset if the option.backToHomeRow is set to true
      var messageChain = keypresses
        .reduce(
          function (acc, keypress) {
            return acc.then(function (stats) {
              // check if the animation sequence has been interrupted
              if (!_currentAnimation.animationChain.interrupt) {
                // the offset is used to ensure fidelity by accounting for the compute time of the animations.
                //   because the offset can look like this: [0, 108, 24, 2, 3, 3, ..] we try to smooth it out
                var offsetAdjusted = calculateAnimationDurationOffset(stats, false); // second paramater will turn on debugging
                stats.sumTimeBetweenKeystrokes += keypress[1].duration; // this is where we should be

                keypress[1].duration = Math.max(keypress[1].duration - offsetAdjusted, 50); // make sure an animation is at least 50ms
                return self.highlightKey.apply(null, keypress).then(function () {
                  return stats;
                });
              } else {
                // We prevent future animations be rejecting
                return $q.reject();
              }
            });
          },
          $timeout(function () {
            _currentAnimation.animationChain.inProgress = true;
            return { startTime: Math.floor(performance.now()), sumTimeBetweenKeystrokes: 0 };
          }, options.delayStart)
        )
        .then(function () {
          _currentAnimation.animationChain.inProgress = false;
          _currentAnimation.animationChain.interrupt = false;
          // implement the option to automatically reset the hand positions
          if (options.backToHomeRow) {
            return self.reset();
          }
        })
        .catch(function () {
          // we need to interrupt the animation
          _currentAnimation.animationChain.interrupt = false;
          _currentAnimation.animationChain.inProgress = false;
        });

      return messageChain;
    };

    // wrapper function for simulateKeypresses that accepts a string, broadcasting each character
    //  returns a promise that resolves when animation is copmlete
    // Example: FingerAnimationsService.typeString("Hello World", {timeBetweenKeystrokes: 400})
    // Usage:
    // FingerAnimationsService.typeString(message, {timeBetweenKeystrokes: 220, delayStart: 1000, backToHomeRow: true})
    this.typeString = function (str, options) {
      options = options || {};
      options.timeBetweenKeystrokes = defaultFor(options.timeBetweenKeystrokes, 220);
      options.delayStart = defaultFor(options.delayStart, 0);
      options.backToHomeRow = defaultFor(options.backToHomeRow, false);
      options.pauseOnFinalKeystroke = defaultFor(options.pauseOnFinalKeystroke, 0);
      options.scaleDelay = defaultFor(options.scaleDelay, 1); // [0 -> inf) A value of 1.5 will make keypresses 50% longer

      // we take the string and transform it into keypress objects similar to our database:
      //    {"target_key": "D", "key_pressed": "D", "previous_key": null, "time_between_keys": null }
      var strArr = str.split(''); // Fixed missing var declaration
      var keypresses = []; // Fixed missing var declaration
      for (var i = 0; i < strArr.length; i++) {
        keypresses.push({
          target_key: strArr[i],
          key_pressed: strArr[i],
          previous_key: i > 0 ? strArr[i - 1] : null, // use the previous key in the string
          time_between_keys: options.timeBetweenKeystrokes,
        });
      }

      return self.simulateKeypresses(keypresses, options);
    };

    // highlights the indicated key, with options
    // -> broadcasts an event, animatedKeyboard:keypress with the key that has been pressed
    //      this broadcast event can be used to add text to the UI, for example.
    // key: {digit: "W", isShift: false}
    // options: duration, numBlinksStart, numBlinksEnd
    this.highlightKey = function (key, options) {
      // Validate key exists and has required properties
      if (!key || typeof key !== 'object') {
        return $timeout(function () {}, 0);
      }

      options = options || {};
      var complete = $timeout(function () {}, options.duration || 0);

      // NOTE: To add new images, add the images twice in finger_animated_keyboard, and then remove the letters from the list below
      var keys_not_implemented = ['/', '6', '7', '[', '8', '9', '0'];
      if (key.digit && keys_not_implemented.includes(key.digit.toString().toLowerCase())) {
        console.log('This key is not yet implemented: ' + key.digit);
        return complete;
      }

      // If the key is not supported by our finger mappings, skip it entirely
      if (!FingerAnimationsServiceHelpers.isKeySupported(key)) {
        console.log('Key not mapped to any finger: ' + (key.digit || 'undefined'));
        return complete;
      }

      var rawKey = key.digit;

      // setup variables
      //   computes a bunch of metadata into options
      var augmentedParams = FingerAnimationsServiceHelpers.setupVariablesForHighlightKey(key, options);

      // If we received a null result, the key isn't supported
      if (!augmentedParams) {
        return complete;
      }

      key = augmentedParams.key;
      options = augmentedParams.options;

      // Make sure hand and finger are defined before trying to set properties
      if (!options.hand || !options.finger || !_currentAnimation[options.hand]) {
        return complete;
      }

      _currentAnimation[options.hand][options.finger] = options.finger;

      // timeout is here to make sure the initial digest cycle completes
      $timeout(function () {
        _currentAnimation.showImg(options.hand, key);
      }, options.startBlinkTime);

      // broadcast the event
      $rootScope.$broadcast('animatedKeyboard:keypress', { key: rawKey });

      // stop any active timers that will blink keys for the hand
      if (_currentAnimation[options.hand] && Array.isArray(_currentAnimation[options.hand]['timers'])) {
        _currentAnimation[options.hand]['timers'].forEach(function (timer) {
          $timeout.cancel(timer);
        });
        _currentAnimation[options.hand]['timers'] = [];
      }

      // remove any highlighted keys that we don't want
      if (
        _currentAnimation[options.hand] &&
        typeof _currentAnimation[options.hand]['removePreviousHighlightedKeys'] === 'function'
      ) {
        _currentAnimation[options.hand]['removePreviousHighlightedKeys']();
      }

      // TODO:
      // 1. Separate the tag on the proper image "BACKSPACE" from the innerText that gets captured by
      //      events such as clicks and mousovers.
      // 2. Make the events from #1 consistent with the events from keypresses!
      // if (key.toLowerCase() === "backspace") {
      // key = "←";
      // }
      _currentAnimation[options.hand]['removePreviousHighlightedKeys'] = function () {
        if (options.homerowKey) {
          $(".key:contains('" + options.homerowKey + "')").removeClass('active');
        }
        if (key) {
          $(".key:contains('" + FingerAnimationsServiceHelpers.keyContentForSelector(key) + "')").removeClass('active');
        }
      };

      // the timer promises created by these functions are stored in the
      //    internal _currentAnimation object
      $timeout(function () {
        blinkHomeRowKeys(key, options);
        blinkTargetKey(key, options);
      }, 0);

      return complete;
    };

    this.reset = function () {
      // interrupt an animation sequence if it's active
      if (_currentAnimation.animationChain.inProgress) {
        _currentAnimation.animationChain.interrupt = true; // inProgress will be reset by the animationChain
      }

      ['left', 'right'].forEach(function (hand) {
        // Skip if hand isn't defined in _currentAnimation
        if (!_currentAnimation[hand]) {
          return;
        }

        // cancel timers
        if (Array.isArray(_currentAnimation[hand]['timers'])) {
          _currentAnimation[hand]['timers'].forEach(function (timer) {
            $timeout.cancel(timer);
          });
          _currentAnimation[hand]['timers'] = [];
        }

        if (typeof _currentAnimation[hand]['removePreviousHighlightedKeys'] === 'function') {
          _currentAnimation[hand]['removePreviousHighlightedKeys']();
          _currentAnimation[hand]['removePreviousHighlightedKeys'] = function () {};
        }

        _currentAnimation.showImg(hand, 'home');
      });
    };

    // internal helper functions -----------------------------------------------------------------------

    // accepts an array of keypress objects: {"target_key"=>"D", "key_pressed"=>"D", "previous_key"=>nil, "time_between_keys"=>null}
    // returns an object that's compatible with this.highlightKey
    //    -> Note: To figure out duration, we look to the next object's time_between_keys attribute
    //    ->       By default, we will also use a 1000 ms duration on the last key
    // pauseOnFinalKeystrokeMs will determine how long to leave the final animation on the screen
    // scaleDelay must be number between (0, inf). A value of 1 will be no delay. 1.5 will make the animations 50% longer
    function transformKeypresses(keypresses, pauseOnFinalKeystrokeMs, scaleDelay) {
      pauseOnFinalKeystrokeMs = defaultFor(pauseOnFinalKeystrokeMs, 1000); // how long to pause on final keystroke (since no keystrokes follows)
      scaleDelay = defaultFor(scaleDelay, 1);
      // return an array of objects, like:
      //    { digit: current, isShift: false }, { numBlinksStart: 0, numBlinksEnd: 1, duration: options.timeBetweenKeystrokes }
      var tempArr = []; // Fixed missing var declaration
      var keypress;
      var duration;
      for (var i = 0; i < keypresses.length; i++) {
        keypress = keypresses[i];
        // take the time_between_keys of the next object as the duration, other wise use 1000 (because we're at the end)
        duration = i + 1 === keypresses.length ? pauseOnFinalKeystrokeMs : keypresses[i + 1].time_between_keys;
        tempArr.push([
          {
            digit: keypress.key_pressed,
            isShift: false,
          },
          {
            numBlinksStart: 0,
            numBlinksEnd: 1,
            duration: duration * scaleDelay,
          },
        ]);
      }
      return tempArr;
    }

    function calculateAnimationDurationOffset(stats, debugMode) {
      var offset = Math.floor(performance.now()) - stats.startTime - stats.sumTimeBetweenKeystrokes;
      var offsetAdjusted = Math.min(offset, 40);
      if (debugMode) {
        console.log('Animation offset (in MS):          ' + offset);
        console.log('Adjusted Animation offset (in MS): ' + offsetAdjusted);
        console.log('');
      }
      return offsetAdjusted;
    }

    function blinkHomeRowKeys(key, options) {
      // Validate inputs
      if (!options || !options.hand || !_currentAnimation[options.hand]) {
        return;
      }

      if (!options.homerowKey) {
        return;
      }

      var _timer;
      var blinkTimePerBlink = options.numBlinksStart === 0 ? 0 : Math.floor(options.startBlinkTime / options.numBlinksStart);
      for (var i = 0; i < options.numBlinksStart; i++) {
        _timer = $timeout(function () {
          $(".key:contains('" + options.homerowKey + "')").addClass('active');
        }, i * blinkTimePerBlink);
        _currentAnimation[options.hand]['timers'].push(_timer);
        _timer = $timeout(
          function () {
            $(".key:contains('" + options.homerowKey + "')").removeClass('active');
          },
          blinkTimePerBlink * 0.6 + i * blinkTimePerBlink
        );
        _currentAnimation[options.hand]['timers'].push(_timer);
      }
    }

    function blinkTargetKey(key, options) {
      // Validate inputs
      if (!key || !options || !options.hand || !_currentAnimation[options.hand]) {
        return;
      }

      var blinkTimePerBlink = options.numBlinksEnd === 0 ? 0 : Math.floor(options.endBlinkTime / options.numBlinksEnd);
      var _timer;
      // selectorKey is used since some keys (ex. BACKSPACE) contain non-ASCII characters ("←")
      var selectorKey = FingerAnimationsServiceHelpers.keyContentForSelector(key);

      if (!selectorKey) {
        return;
      }

      for (var i = 0; i < options.numBlinksEnd; i++) {
        _timer = $timeout(
          function () {
            $(".key:contains('" + selectorKey + "')").addClass('active');
          },
          options.startBlinkTime + i * blinkTimePerBlink
        );
        _currentAnimation[options.hand]['timers'].push(_timer);
        if (i + 1 !== options.numBlinksEnd) {
          _timer = $timeout(
            function () {
              $(".key:contains('" + selectorKey + "')").removeClass('active');
            },
            options.startBlinkTime + blinkTimePerBlink * 0.6 + i * blinkTimePerBlink
          );
          _currentAnimation[options.hand]['timers'].push(_timer);
        }
      }
    }

    // allows setting default values for parameters.
    // Ex:
    // foo = defaultFor(foo, 0); // sets a deafult value of 0
    function defaultFor(arg, val) {
      return typeof arg !== 'undefined' ? arg : val;
    }

    return this;
  },
]);
