/* global SERVER_TIME_SYSTEM_TIME_DIFF */
import {
  dateInFuture,
  createFirebaseUpdateObjectForUsers,
} from '../../../components/Navigation/Notifications/FirebaseNotificationHelpers';

import { getDatabase, ref, update, serverTimestamp } from 'firebase/database';

// Animates the line of text
app.controller('InstructorCertificatesController', [
  '$scope',
  '$stateParams',
  'current_user',
  'Certificates',
  '$state',
  '$timeout',
  function ($scope, $stateParams, current_user, Certificates, $state, $timeout) {
    if (!current_user.instructor) $state.go('app.home');

    // adds class to the body element so that we can hide nav, footer etc
    //  for print only in this view. Removes this class when leaving state
    $('body').addClass('transcription-print');
    $scope.$on('$destroy', function () {
      $('body').removeClass('transcription-print');
    });

    $scope.print = function (student) {
      $scope.certificate = student;
      $timeout(function () {
        window.print();
      }, 200); // open the print dialog
    };

    const db = getDatabase();

    $scope.students = $stateParams.students;
    $scope.students = $scope.students.map(function (student) {
      student.view = {};
      if (student.best_attempt) {
        student.best_attempt.duration = String(student.best_attempt.duration_in_ms / 60000) + ' Minutes';
      }
      return student;
    });

    $scope.previewCertificate = function (student) {
      $scope.certificate = student;
    };

    $scope.generateCertificate = function (student) {
      student.view.generateButtonDisabled = true;

      delete student.data; // remove the big data attribute
      delete student.$$hashKey; // remove the big data attribute
      return Certificates.createMultiple([student.id], [student]).then(function () {
        createNotifications([student]);
      });
    };

    $scope.generateCertificateForAll = function () {
      var students = $scope.students.filter(function (student) {
        return student.best_attempt.id && !student.view.generateButtonDisabled;
      });
      students = students.map(function (student) {
        delete student.data; // remove the big data attribute
        delete student.$$hashKey; // remove the big data attribute
        student.view.generateButtonDisabled = true;
        return student;
      });
      var studentIds = students.map(function (s) {
        return s.id;
      });
      return Certificates.createMultiple(studentIds, students).then(() => createNotifications(students));
    };

    function createNotifications(students) {
      const studentIds = students.map((s) => s.id);
      // no notification needed if no students
      if (studentIds.length < 1) return true;
      const message = {
        createdAt: serverTimestamp(),
        sender: current_user.first_name + ' ' + current_user.last_name,
        expiresOn: dateInFuture(0, 12, 0, SERVER_TIME_SYSTEM_TIME_DIFF),
        message: 'created a certificate for you!',
        redirectState: 'app.keyboarding.certificates/view',
        active: true,
        showAlert: true,
        priority: 0,
      };

      const { updateObj } = createFirebaseUpdateObjectForUsers(studentIds, message);
      update(ref(db), updateObj);
    }
  },
]);
