// Directive attached to iframe that sends it a message with iframeOrigin info and documentSubmissionId for using the right assignment to grade
app.directive('messageIframe', [
  function () {
    console.log('message iframe directive');
    function link(scope, element) {
      var iframe = document.getElementById('doc-upload-iframe');
      var payload = {
        documentSubmissionId: scope.documentSubmissionId,
      };

      function onIframeLoad(event) {
        console.log('waiting for iframe to load');
        window.addEventListener('message', onMessage, false);
        iframe.contentWindow.postMessage(payload, scope.iframeOrigin);
      }

      function onMessage(event) {
        if (event.origin !== scope.iframeOrigin && event.origin !== location.protocol + '//' + location.host) {
          console.error(
            'host is not allowed to communicate with this iframe. Iframe removed',
            event.origin,
            scope.iframeOrigin,
            scope.documentSubmissionId
          );
          window.removeEventListener('message', onMessage, false);
          iframe.removeEventListener('load', onIframeLoad, false);
          iframe.remove();
          return;
        }
        if (event.data && event.data.iframeHeight) {
          element.height(event.data.iframeHeight);
        }
      }

      iframe.addEventListener('load', onIframeLoad, false);

      // TODO make sure all event listeners are destroyed
      scope.$on('$destroy', function () {
        window.removeEventListener('message', onMessage, false);
        iframe.addEventListener('load', onIframeLoad, false);
      });
    }

    return {
      link: link,
      scope: {
        iframeOrigin: '=iframeOrigin',
        documentSubmissionId: '=documentSubmissionId',
      },
    };
  },
]);
