app.controller('DocProductionActivityController', [
  '$scope',
  '$sce',
  'DocProductionResultsDataService',
  '$stateParams',
  'permission',
  'documentAssignment',
  'currentUser',
  '$timeout',
  function ($scope, $sce, DocProductionResultsDataService, $stateParams, permission, documentAssignment, currentUser, $timeout) {
    /* *****
     * Variables
     * *****/
    // permission added to scope for use in the child controller, S3UploadController
    $scope.permission = permission;
    $scope.user = currentUser;
    $scope.view = {
      enableStart: false,
      requiresAccommodation: false,
      accommodationPasswordEntered: false,
    };

    // Variable for storing past student submissions
    var pastAssignmentSubmissions = [];

    // permission added to scope for use in the child controller, S3UploadController
    // $scope.permission = permission;
    if (permission && permission.options && permission.options.accommodation) {
      $scope.view.requiresAccommodation = true;
    }

    $scope.checkAccommodationPassword = function (password) {
      $scope.view.accommodationPasswordMessage = '';

      if (password === 'Z324A8R9A') {
        $scope.view.accommodationPasswordMessage =
          'Success! Please ensure you have read the instructions below, and click the Start button below to proceed.';
        $scope.view.accommodationPasswordEntered = true;
        enableStartIfUserHasPermission(pastAssignmentSubmissions);
      } else {
        $scope.view.accommodationPasswordMessage =
          'Password incorrect. Please try again or contact the course instructor for support.';
      }
    };

    //
    $scope.documentAssignment = documentAssignment;
    // $scope.currentStudentInstructions = [];
    $scope.draftSubmissionInProgress = true;

    // debugger;

    // TEMP implementation - the milliseconds passed into _calculateExpiryTime must match the time cutoff that the rails us using to fetch past assignments (see document_submission_controller)
    // temp variable for duration of an assignment in ms. Must match what is defined in rails controller
    if (!currentUser.instructor) {
      var msRemaining = new Date(permission.expires_on);
      // var msRemaining = 20 * 60000; //20 mins
      $scope.draftExpiryTime = msRemaining;
      console.log(msRemaining);
    } else {
      var msRemaining = null;
      $scope.draftExpiryTime = msRemaining;
    }

    // $scope.draftExpiryTime = _calculateExpiryTime($scope.activeDraftSubmission.created_at, msRemaining);
    $scope.currentAssignmentId = $stateParams.document_assignment_id;

    /* *****
     * Initialize
     * *****/

    // download all document submission for current assignment/section
    DocProductionResultsDataService.getPastAssignmentSubmissions($scope.currentAssignmentId, permission.section_id)
      .then(function (pastAssignments) {
        pastAssignmentSubmissions = pastAssignments;
      })
      .then(() => enableStartIfUserHasPermission(pastAssignmentSubmissions));

    /* *****
     * Functions - scope mapping
     * *****/
    $scope.onTimerExpire = onTimerExpire;
    $scope.calculatePastAssignmentDuration = calculatePastAssignmentDuration;
    // Thus function is now accessible by child controllers (namely DocumentProductionPreController)
    $scope.enableStartIfUserHasPermission = enableStartIfUserHasPermission;

    /* *****
     * Functions definitions
     * *****/

    function calculatePastAssignmentDuration(createdAt, updatedAt) {
      var updatedAtTime = new Date(updatedAt).getTime();
      var createdAtTime = new Date(createdAt).getTime();
      var duration = updatedAtTime - createdAtTime;
      if (duration < 60 * 1000) {
        return Math.round(duration / 1000) + ' secs';
      } else if (duration >= 60 * 1000 && duration < 60 * 60 * 1000) {
        return Math.round(duration / (60 * 1000)) + ' mins';
      } else if (duration >= 60 * 60 * 1000 && duration < 60 * 60 * 24 * 1000) {
        return Math.round(duration / (60 * 60 * 1000)) + ' hours';
      } else {
        return Math.round(duration / (60 * 60 * 24 * 1000)) + ' days';
      }
    }

    function onTimerExpire() {
      $scope.draftSubmissionInProgress = false;
    }

    // functions sets the view.enableStart variable to true/false.
    // applys the complex logic to determine if the user is allowed to start
    // their assignment or not. Details are below.
    var disableButtonTimeout;

    function enableStartIfUserHasPermission(pastSubmissions = []) {
      var userIsInstructor = $scope.user.instructor;
      const now = Date.now() - SERVER_TIME_SYSTEM_TIME_DIFF;
      var timeRemaining = new Date($scope.permission.expires_on).getTime() - now;
      var userHasSubmissions = pastSubmissions.length > 0;
      var gradeReleased = false;
      var requiresAccommodation = $scope.view.requiresAccommodation;
      var accommodationPasswordEntered = $scope.view.accommodationPasswordEntered;
      if (userHasSubmissions) {
        gradeReleased =
          pastSubmissions.filter(function (docSub) {
            return docSub.grade_released;
          }).length > 0;
      }
      var assignmentRequiresPermission = $scope.documentAssignment.requires_permission;
      // User can start an assignment if one of the following conditions is true:
      //  1. User is an instructor.
      //  2. Assigment does not require permission
      //  3. User has an active permission AND there are no submissions
      //  4. User has an expired permission AND has submissions AND a submission's grade has been released

      if (requiresAccommodation && !accommodationPasswordEntered) {
        return false;
      }

      // set a timeout to reassess whether to disable the button after time expires.
      if (timeRemaining > 0) {
        if (disableButtonTimeout) $timeout.cancel(disableButtonTimeout);

        disableButtonTimeout = $timeout(function () {
          $scope.$broadcast('documentActivity:timeExpired');
          // call this function again to re-assess whether we need to disable the button
          enableStartIfUserHasPermission(pastAssignmentSubmissions);
        }, timeRemaining + 100);
        $scope.$on('$destroy', function () {
          $timeout.cancel(disableButtonTimeout);
        });
      }

      if (userIsInstructor) {
        $scope.view.enableStart = true;
        return true;
      }

      if (!assignmentRequiresPermission) {
        $scope.view.enableStart = true;
        return true;
      }

      if (timeRemaining > 0 && !userHasSubmissions) {
        $scope.view.enableStart = true;
        $scope.view.startMessage = '';
        return true;
      }

      if (userHasSubmissions && gradeReleased) {
        $scope.view.enableStart = true;
        $scope.view.startMessage =
          'You have submitted your assignment and your instructor has released your grade. You may try this assignment again as practice.';
        return true;
      }

      if (userHasSubmissions && !gradeReleased) {
        $scope.view.enableStart = false;
        $scope.view.startMessage =
          'Your instructor has not yet released your grade for this assignment. They can do so by selecting Release Grade on their instructor grade sheet. Until then, you cannot view these results or practice this assignment.';
        return true;
      }

      // default
      $scope.view.enableStart = false;
    }
  },
]);
