app.controller('UsernamePasswordRegController', [
  '$scope',
  'UsernamePasswordRegFactory',
  '$q',
  '$state',
  'ValidateObjectService',
  '$timeout',
  function ($scope, UsernamePasswordRegFactory, $q, $state, ValidateObjectService, $timeout) {
    $scope.user = $state.params.user;

    var debug = false;
    if (debug) stubUser();

    // validate that the user oject has relevant keys defined
    ValidateObjectService.validate($scope.user, ['instructor', 'organization', 'firstName', 'lastName', 'email'], function () {
      return $state.go('app.home');
    });

    // suggest a username that's the prefix of their email with all non-alphanumeric chars removed.
    // note: we JSON.stringify for these reasons: https://stackoverflow.com/questions/9364400/remove-not-alphanumeric-characters-from-string
    $scope.suggestedUsername = generateUsernameSuggestion($scope.user);

    $scope.parentView.showSubheading = false; // this is inheirited from parent scope. displays: "The typing trainer designed..."

    $scope.helpers = new UsernamePasswordRegFactory();
    $scope.submitButton = $scope.helpers.view;
    $scope.keyboard = $scope.helpers.keyboard;

    // redirect to the next step
    $scope.submit = function (user) {
      if (!user.instructor) {
        return $scope.helpers.validateAvailabilityOfUsername(user).then(function () {
          // take student to payment step
          return $state.go('authentication.5', { user: user });
        });
      } else {
        // register the instructor
        return $scope.helpers
          .registerInstructor(user)
          .then(successMessages)
          .then(function () {
            return $state.go('afterLogin');
          });
      }
    };

    function successMessages(user) {
      return progressMessage('Completing registration...', 4500)
        .then(function () {
          $scope.keyboard.stopKeyboard = true;
          return progressMessage('Registration complete!', 2500);
        })
        .then(function () {
          var message = user.first_name ? 'Welcome to Typist, ' + user.first_name + '!' : 'Welcome to Typist.';
          $scope.keyboard.showKeyboard = false;
          return progressMessage(message, 4500);
        });
    }

    // sets progress message, waits until delay is finished
    // returns a promise
    function progressMessage(message, delayAfter) {
      var delayAfter = delayAfter || 0;
      $scope.helpers.view.progressMessage = message;
      return $timeout(function () {
        console.log(message);
      }, delayAfter);
    }

    function generateUsernameSuggestion(user) {
      // suggest a username that's the prefix of their email with all non-alphanumeric chars removed.
      // note: we JSON.stringify for these reasons: https://stackoverflow.com/questions/9364400/remove-not-alphanumeric-characters-from-string
      const emailPrefix = user.email.slice(0, user.email.indexOf('@'));
      let randomNumber;
      while (true) {
        randomNumber = Math.floor(Math.random() * 90 + 10);
        if (randomNumber != 69) {
          break;
        }
      }
      return JSON.stringify(emailPrefix).replace(/[^0-9a-z]/gi, '') + String(randomNumber);
    }

    function stubUser() {
      var t = Math.round(Math.random() * 10000);
      // username: "peterpan12" + t, password: "foobar1", passwordConfirmation: "foobar1",
      $scope.user = {
        instructor: false,
        organization: '2',
        firstName: 'Peter',
        lastName: 'Pan',
        email: 'debugemail@gmail.com' + t,
        confirmEmail: 'debugemail@gmail.com' + t,
      };
    }
  },
]);
