// designed to be included and accessed through the Instruction class
app.factory('AudioFile', [
  '$http',
  'S3Upload',
  '$q',
  function ($http, S3Upload, $q) {
    var AudioFile = function (AudioFile) {
      var self = this;
    };

    AudioFile.prototype.upload = function (instruction, formSelector) {
      instruction.view = {
        errorMessage: null,
        successMessage: 'Checking Permissions...',
        disableForm: true,
      };

      var s3Upload = new S3Upload({
        formSelectorID: formSelector,
        validFileFormats: ['mp3', 'wav', 'webm', 'aac', 'wav', 'ogg'],
        presignedPostURL: 'api/instructions/set_presigned_post',
        maximumByteSize: 20971520,
        presignedPostParams: {
          id: instruction.id,
          document_assignment_id: instruction.document_assignment_id,
          display_type: instruction.display_type,
          number: instruction.number,
        },
      });

      return s3Upload
        .getPresignedPost()
        .then(function (data) {
          instruction.content = data.content;
          instruction.options.filename = data.options.filename;
          instruction.view.successMessage = 'Permission received! Attempting upload...';
        })
        .catch(function (err) {
          instruction.view = { errorMessage: err.data.errors };
          return $q.reject(err.data.errors);
        })
        .then(s3Upload.sendToS3)
        .then(function (res) {
          instruction.view = {
            errorMessage: null,
            disableForm: true,
            successMessage: 'File uploaded. Updating the reference in our database...',
          };
          return res;
        })
        .then(instruction.update)
        .catch(function (err) {
          if (!instruction.view.errorMessage) {
            instruction.view = {
              errorMessage: 'Oh no -- something went wrong! Better have Matt take a look at this.',
              disableForm: false,
              successMessage: null,
            };
          }
          return $q.reject();
        });
    };

    return AudioFile;
  },
]);
