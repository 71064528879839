app.controller('CreateAccountPaymentController', [
  '$scope',
  'AnimatedLoginKeyboardFactory',
  'AccessCodeFactory',
  'StripePaymentFactory',
  '$q',
  '$state',
  'Auth2',
  '$timeout',
  'ValidateObjectService',
  'org',
  function (
    $scope,
    AnimatedLoginKeyboardFactory,
    AccessCodeFactory,
    StripePaymentFactory,
    $q,
    $state,
    Auth2,
    $timeout,
    ValidateObjectService,
    org
  ) {
    $scope.user = $state.params.user;
    $scope.parentView.showSubheading = false; // this is inheirited from parent scope. displays: "The typing trainer designed..."
    $scope.view = {};

    // set to true to create a valid user object
    // NOTE: IN AUTH_STATES YOU MUST ALSO ENABLE/DISABLE DEBUGMODE
    var debugMode = false;
    if (debugMode) stubOrg();
    if (debugMode) stubUser();

    // Must purchase access card at these organizations:
    //   If students answer 'no' to having an access card, we tell them to go get one.
    //   Organization ids must be enterred as both integers and strings
    // Currently:
    //  SAIT: 12 (removed)
    //  Durham College: 22
    //  Lakeland College: 42
    //  Conestoga: 16
    //  MacEwan: 37 (removed)
    //  Capilano: 123
    //  Eastern Florida: 80
    var organizationsAccessCardMandatory = [22, 16, 42, 123, 80];
    $scope.view.orgAllowsStripe = !(organizationsAccessCardMandatory.indexOf(parseInt($scope.user.organization, 10)) > -1);

    // validate that the user object has relevant keys defined
    ValidateObjectService.validate(
      $scope.user,
      ['organization', 'username', 'password', 'passwordConfirmation', 'firstName', 'lastName', 'email', 'confirmEmail'],
      function () {
        return $state.go('app.home');
      }
    );

    // called either from the UI if we have multiple program_products at an organization,
    //  or if we just have 1 it's called from the controller.
    $scope.setProduct = (product) => {
      $scope.product = product;
      //validate that we have the proper pricing, country and state information for the organization
      if (
        !(typeof $scope.product === 'object') ||
        !(typeof $scope.product.price === 'number') ||
        !(typeof $scope.product.tax === 'object')
      ) {
        console.log('Missing pricing and tax information');
        return $state.go('app.home');
      }

      // Stripe Payment is bound to HTML. When a user submits the Stripe form,
      //  stipePayment intercepts the successful payment, adds the stripeTokens to the
      //  user data, and sends to our server to charge the card and update the account.
      $scope.stripePayment = new StripePaymentFactory('#stripe-form', 'create', $scope.user, $scope.product);
      $scope.stripePayment.paymentSuccess.promise.then(redirectToHomepage); // binds the callback
      $scope.keyboard = $scope.stripePayment.keyboard;
    };

    // org.products is an array that looks like:
    // {program_name:, product: {price: stripe_price: ... } }
    $scope.products = org.products;
    // if we only have a single product that this organization is using, direct them straight to the
    //  payments page
    if ($scope.products.length === 1) $scope.setProduct($scope.products[0].product);

    $scope.org = org; // org.base_price, org.tax.name, org.price

    $scope.accessCode = new AccessCodeFactory($scope.user);
    $scope.helpers = { view: { progressMessage: null } }; // this replaces 'click here to login'

    // we keep the name of this method consistent with the account update page
    //  updates subscription using acccess code
    $scope.updateSubscription = function () {
      $scope.keyboard = $scope.accessCode.keyboard;

      $scope.accessCode
        .createSubscription($scope.user)
        .then(redirectToHomepage)
        .catch(function (obj) {
          $scope.keyboard = $scope.stripePayment.keyboard;
          return $q.reject(obj);
        });
    };

    // used when a card is declined
    //  reloads the state in order to allow the user to try payment again with a different card
    $scope.reloadState = function () {
      var currentState = $state.current.name;
      return $state.go(currentState, { user: $scope.user }, { reload: true });
    };

    // called after the user account has been successfully created
    function redirectToHomepage(user) {
      var credentials = { login: user.username, password: user.password, remember_me: 1 };
      var displayMessages = successMessages(user);
      var loginPromise = Auth2.login(credentials);

      return $q.all([loginPromise, displayMessages]).then(function () {
        return $state.go('afterLogin');
      });
    }

    // manages the animations after successful registration
    function successMessages(user) {
      // keyboard is still animating
      $scope.helpers.view.dim = true;

      return progressMessage('Completing registration...', 4500)
        .then(function () {
          $scope.keyboard.stopKeyboard = true;
          return progressMessage('Registration complete!', 2500);
        })
        .then(function () {
          var message = user.first_name ? 'Welcome to Typist, ' + user.first_name + '!' : 'Welcome to Typist.';
          $scope.keyboard.showKeyboard = false;
          return progressMessage(message, 4500);
        });
    }

    // sets progress message, waits until delay is finished
    // returns a promise
    function progressMessage(message, delayAfter) {
      $scope.helpers.view.progressMessage = message;
      return $timeout(function () {
        return;
      }, delayAfter || 0);
    }

    function stubUser() {
      var t = Math.round(Math.random() * 10000);
      $scope.user = {
        instructor: false,
        organization: String(org.id),
        username: 'mattmcinnis' + t,
        password: 'foobar1',
        passwordConfirmation: 'foobar1',
        firstName: 'Matt',
        lastName: 'McInnis',
        email: 'debugemail@gmail.com' + t,
        confirmEmail: 'debugemail@gmail.com' + t,
      };
    }
    function stubOrg() {
      org = {
        id: 22,
        name: 'Centennial College',
        code_prefix: 'CC',
        country: 'CA',
        state: 'ON',
        active: true,
        timezone: 'America/Toronto',
        program_products: {
          'All Programs': 2,
        },
        products: [
          {
            program_name: 'All Programs',
            product: {
              id: 2,
              name: 'Typist Professional™',
              price: 192.1,
              base_price: 170,
              stripe_price: 19210,
              currency: 'cad',
              tax: {
                name: 'HST',
                rate: 0.13,
                stripe_ids: ['txr_1HzSg4FT94eUUsqYKzNB6fSJ'],
              },
              description:
                'Your subscription to Typist Professional™ includes access to all the keyboarding and document production features in Typist.',
            },
          },
        ],
      };
    }
  },
]);
