app.controller('UploadControllerS3', [
  '$scope',
  'S3UploadService',
  '$q',
  function ($scope, S3UploadService, $q) {
    var user = $scope.$parent.user;
    var permission = $scope.$parent.permission;
    // This is a resolve in the parent state (`app.documents.document_production_results.main`), so it's accessible here
    const documentAssignment = $scope.$parent.documentAssignment;

    var uploadFormSelector = '#document-submission-s3-upload';
    console.log(permission);

    $scope.view = {
      disableForm: false,
      timeExpired: false,
      errorMessage: '',
      successMessage: '',
      allowAnotherUpload: false,
    };

    // root controller is watching for expiry
    $scope.$on('documentActivity:timeExpired', function () {
      $scope.view.timeExpired = true;
    });

    // allows user to upload again
    $scope.refreshUpload = function () {
      s3Helpers.destroy();

      var openAssignment = !$scope.$parent.documentAssignment.requires_permission || user.instructor;
      s3Helpers = S3UploadService.init(uploadFormSelector, permission.id, openAssignment, $scope.$parent.documentAssignment.id);
      $scope.$on('$destroy', s3Helpers.destroy);

      $scope.view.successMessage = '';
      $scope.view.errorMessage = '';
      $scope.view.allowAnotherUpload = false;
      $scope.view.disableForm = false;
    };

    // initializes upload service:
    //   - attaches an event listner on the form with provided id
    var openAssignment = !$scope.$parent.documentAssignment.requires_permission || user.instructor;
    s3Helpers = S3UploadService.init(uploadFormSelector, permission.id, openAssignment, $scope.$parent.documentAssignment.id);
    // Remove any listeners on destroy
    $scope.$on('$destroy', s3Helpers.destroy);

    // Steps:
    // 1. User checks permission to see if upload form should be active. If yes, activate it. If no, redirect.
    // 2. User clicks 'upload'
    // 3. Disable the button. Ensure only a single request can be made.
    // 4. Check the size and file extension. Show an error message accordingly.
    // 5. Send the permission to the server and receive a pre-signed post if the user has permission. If not,
    //      display an error message accordingly.
    // 6. Upload the file to S3. Show a progress bar.
    // 7. If successful, tell our server about the new uploaded file name. This creates the document_submission
    // 8. Show success message.

    // sends the file to S3
    $scope.startUpload = function () {
      // This needs to be re-enabled after certain failures in the promise chain (eg. when validateFile throws an error, before S3 interaction)
      $scope.view.disableForm = true;
      $q.resolve()
        .then(() => {
          // Make sure this is a promise or is wrapped in a .then() so the promise chain captures any errors being thrown
          // (eg. if JQuery returns an error while validateFile is called, we still want to capture that in our catch block at the end)
          return s3Helpers.validateFile();
        })
        // Catching only validation errors (before any communication with S3), at which point we are ok
        // with user to try and choose a file for upload again
        .catch((err) => {
          // Re-enable the form so the user can re-submit
          $scope.view.disableForm = false;
          // Propagate the error to the last catch of the promise chain so that error messages get displayed
          return $q.reject(err);
        })

        .then(s3Helpers.checkPermissionGetPresignedPost)
        // Get permission
        .then(function () {
          // Clear any error mesasges
          $scope.view.errorMessage = '';
          $scope.view.successMessage = 'Permission Obtained. Attempting Upload.';
        })
        // upload the file
        .then(s3Helpers.sendToS3)
        .then(function () {
          $scope.view.successMessage = 'Finalizing Upload.';
        })
        // tell rails everything completed
        // .then(s3Helpers.createDocumentSubmission)
        .then(() => {
          const generated_variable_id =
            documentAssignment.generated_variables_for_user_section && documentAssignment.generated_variables_for_user_section.id;
          return s3Helpers.createDocumentSubmission(generated_variable_id);
        })
        // update the user interface
        .then(function () {
          $scope.view.successMessage = "Assignment Submitted! Press 'Back' in your browser to see your results.";
          if (user.admin) {
            $scope.view.allowAnotherUpload = true;
          }
        })
        .catch(function (err) {
          $scope.view.successMessage = '';
          if (typeof err === 'string') {
            console.log('Error: ' + err);
            var msg = 'Unable to process your submission. Please check your internet connection try again.';
            $scope.view.errorMessage = msg;
          } else if (typeof err === 'object' && err.hasOwnProperty('data') && typeof err.data === 'object') {
            // This captures only special errors that are generated by _s3_upload_service or by S3
            $scope.view.errorMessage = err.data.errors;
          } else {
            // All other errors eg. System errors, etc
            // Display a generic error message
            $scope.view.errorMessage =
              'Something went wrong. Refresh the browser and try again. Please contact Typist support if the problem persists.';
          }

          if (user.admin) {
            $scope.view.allowAnotherUpload = true;
          }
          return true;
        });
    };
  },
]);
