// Maintains data structures about the organization, such as class templates and (later) profiles.
app.service('OrganizationService', [
  '$rootScope',
  '$http',
  '$q',
  function ($rootScope, $http, $q) {
    var self = this;
    this.templates = [];

    // retrieves any class templates that an organization has
    //  uses caching to prevent multiple requests
    var retrievedTemplates = false;
    this.getTemplates = function () {
      if (retrievedTemplates) {
        return $q.resolve(self.templates);
      }

      return $http.get('api/templates.json').then(function (res) {
        retrievedTemplates = true;
        self.templates = res.data;
        return self.templates;
      });
    };
  },
]);
