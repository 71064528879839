// Provides helpers to determine whether to show/skip the home row tech modal
//  -> This service only uses the local cache. It relies on states (in app.js) to update the cache
//        from UserSettings (firebase) when the state loads.
//  -> HomeRowTechModal (factory) updates both the cache and UserSettings when it completes successfully
app.service('SkipHomeRowTechModal', [
  'CacheService',
  function (CacheService) {
    // Returns true || false
    this.shouldSkip = function (activityType, currentUser) {
      var settings = CacheService.get('homeRowTech');
      // skip for a particular set of users, as defined in this function
      if (skipForThisUser(currentUser)) return true;

      if (activityType === 'PracticeTimedWriting') {
        // once every ten minutes
        if (secondsSinceLastCompletion(settings) > 600) return false;
        return true;
      } else if (activityType === 'TimedWriting') {
        // every 20 mintues
        if (secondsSinceLastCompletion(settings) > 1200) return false;
        return true;
      } else if (activityType === 'Lesson') {
        // every 5 minutes
        if (secondsSinceLastCompletion(settings) > 180) return false;
        return true;
      }

      console.log('Warning: Could not find activityType in SkipHomeRowTechModal');
      return false;
    };

    // private functions ----------------------------------------------------

    // Returns integer, milliseconds since last time completing HomeRowTech
    // Calculates the time since the last completion
    function secondsSinceLastCompletion(settings) {
      if (!settings || !settings.lastCompletedAt || typeof settings.lastCompletedAt !== 'number') {
        console.log('Warning: User has not previously completed HomeRowTech.');
        return 108000; // ie 30 hours
      } else {
        var msSinceLastCompletion = Date.now() - settings.lastCompletedAt;
        var secondsSinceLastCompletion = Math.floor(Math.max(msSinceLastCompletion / 1000.0, 0));
        console.log('Last completed HRT: ' + secondsSinceLastCompletion);
        return secondsSinceLastCompletion;
      }
    }

    // allows us to skip HomeRowTech for some users
    // implemented to help a user who does not have all fingers
    function skipForThisUser(currentUser) {
      if (!currentUser | !currentUser.id) return false;

      // 20829=SAIT Student, 21978=Sheridan Student (via Roger), 20212=VCC Student (potentially)
      const skipForTheseUsersIds = [20829, 21978, 20212];
      return skipForTheseUsersIds.includes(currentUser.id);
    }

    return this;
  },
]);
