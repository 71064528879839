app.factory('TimedWritingRequirements', [
  '$http',
  '$q',
  function ($http, $q) {
    // Timed writing requirements factory
    function TimedWritingRequirements() {
      this.view = {
        // in the form of { message: '', error: true}
        flashMessage: null,
        // status variable to display a loading animation if results are not yet ready
        loaded: false,
      };

      // Cached requirements, where key corresponds to id of the stored requirement
      // eg. {1: {speed: 23, id: 1}}
      this.library = {};
    }

    /*************
     *  Methods
     *************/

    /*
     * Promise that fetches requirements definition from backend using timed writing requirements id
     * and adds result to library property
     * If id has been already fetched, returns promise with stored copy
     *
     * param is id of the requirements object
     */
    TimedWritingRequirements.prototype.getRequirementById = function (id) {
      const self = this;
      if (this.library[id]) return $q.resolve(this.library[id]);
      // Fetch from server, return a promise
      return $http.get(`api/timed_writing_requirements/${id}.json`).then((res) => {
        self.library[id] = res.data;
        return res.data;
      });
    };

    /*
     * Promise that gets multiple requirements from the server
     * param is an array of timed writing requirement ids
     */
    TimedWritingRequirements.prototype.getMultipleRequirementsById = function (idArray = []) {
      const self = this;

      // Start the loading animation
      self.view.loaded = false;

      // Even though getRequirementsById function caches results in the .library property
      // the promise.all runs them all simultaneously and async, so that check might not be available
      // therefore getting only unique ids before passing them into the function
      const uniqueIdArray = uniqueValuesArray(idArray);

      return $q.all(uniqueIdArray.map((id) => self.getRequirementById(id))).then(() => {
        self.view.loaded = true;
        // No need to propagate any values with this promise,
        // as fetching requirements completion/failure is all that matters
      });
    };

    /*
     * Creates a new TW requirement or returns the existing one
     * test param is an object with properties for requirements, eg. {accuracy_metric:, speed:}
     */
    TimedWritingRequirements.prototype.create = function (test) {
      const self = this;

      // Start the loading animation
      self.view.loaded = false;
      // Clear existing flash messages
      self.view.flashMessage = null;

      // Params sent to backend server
      let reqParams = {
        accuracy_metric: test.accuracy_metric,
        duration_in_seconds: Number(test.duration) * 60,
        speed: test.speed,
        speed_type: test.type,
        max_attempts: test.max_attempts,
      };

      if (reqParams.accuracy_metric === 'accuracy') {
        reqParams.accuracy = Number(test.accuracy);
      } else if (reqParams.accuracy_metric === 'max_errors') {
        reqParams.max_errors = Number(test.max_errors);
      }

      // Exit early if speed or accuracy have more than one decimal place
      if (
        !withinMaxDecimalPlaces(reqParams.speed, 1) ||
        (reqParams.accuracy_metric === 'accuracy' && !withinMaxDecimalPlaces(reqParams.accuracy, 1))
      ) {
        self.view.flashMessage = {
          message: 'Required Speed and Required Accuracy can have at most 1 decimal place.',
          error: true,
        };
        // Return empty promise
        return $q.reject();
      }

      return $http
        .post('/api/timed_writing_requirements.json', reqParams)
        .then((res) => {
          // Update the library with the newly created requirements
          self.library[res.data.id] = res.data;
          // Stop the loading animation
          self.view.loaded = true;
          return res;
        })
        .catch((err) => {
          // If errors, propagate the promise (so the form doesn't close), but display an error message
          self.view.flashMessage = {
            message: 'Could not update requirements.',
            error: true,
          };
          return $q.reject(err);
        });
    };

    /*************
     *  Private functions
     *************/

    // Function that takes in a number and maxDecimalPlaces
    // and returns Boolean if number has less than or equal to number of decimal places
    function withinMaxDecimalPlaces(num, maxDecimalPlaces = 1) {
      // 1. Throw error if number is invalid.
      // Doing a parseInt check because Number() converts '' to 0
      if (isNaN(parseInt(num))) return false;

      // 2. Convert num to Number for further comparison
      let parsedNum = Number(num);

      // 3. If an integer, return true
      if (Math.floor(parsedNum) === parsedNum) return true;

      // 4. If not, calculate position of decimal from right side and compare
      // NOTE: might not work for very large numbers that are converted to scientific notation
      return parsedNum.toString().split('.')[1].length <= maxDecimalPlaces;
    }

    // Utility function that returns the array with only unique values
    // eg. [1,1,2,2] => [1,2]
    function uniqueValuesArray(arr = []) {
      const result = arr.reduce((acc, id) => {
        return {
          ...acc,
          [id]: id,
        };
      }, {});

      return Object.values(result);
    }

    return TimedWritingRequirements;
  },
]);
