app.controller('TranscriptionController', [
  '$rootScope',
  '$scope',
  '$timeout',
  '$stateParams',
  '$state',
  'TypingInput',
  'UserSettings',
  'transcriptionSettings',
  'keyboardUtilities',
  function (
    $rootScope,
    $scope,
    $timeout,
    $stateParams,
    $state,
    TypingInput,
    UserSettings,
    transcriptionSettings,
    keyboardUtilities
  ) {
    $scope.timer = transcriptionSettings.orDefault({ duration: '3', hideTimer: 0 });

    $scope.TypingInput = TypingInput.newSession({ useCountdownTimer: true, onFinish: finished });
    $scope.TypingInput.updateLineOfTextFromString('some text'); // need anyting here so that the timer starts and doesn't end because the user is finished
    $scope.$on('$destroy', function () {
      $scope.TypingInput.timer.stop();
    }); // kill timer when we go out of scope

    // launch the modal
    //  - Using a timeout here to make sure the page has loaded
    $timeout(function () {
      if ($stateParams['skipModals']) {
        $scope.$broadcast('TranscriptionModals:complete'); // keep one source of truth and trigger the start event
      } else if (!$stateParams['skipModals']) {
        $scope.$broadcast('TranscriptionModals:start', { modalSelector: '#startup', delayListener: 0 }); // delayListener prevents user from accidently hitting space to move through modals
      }
    }, 100);

    $scope.$on('TranscriptionModals:complete', function (a, data) {
      // update settings
      UserSettings.set('transcription', $scope.timer);
      console.log($scope.timer);

      // sets timer to value defined in modal
      $scope.TypingInput.timer.initialize(Number($scope.timer.duration) * 60);
      // autostart the timer
      $scope.TypingInput.timer.start();
      $scope.view.userHasStarted = true;
      $scope.stats.startTime = Math.floor(performance.now());

      $scope.view.modalsComplete = true;
      $timeout(function () {
        $('#typing-area').focus();
      }, 200);
    });

    // adds class to the body element so that we can hide nav, footer etc
    //  for print only in this view. Removes this class when leaving state
    $('body').addClass('transcription-print');
    $scope.$on('$destroy', function () {
      $('body').removeClass('transcription-print');
    });

    $scope.view = {
      modalsComplete: false,
      hideStartMessage: false,
      userHasFinished: false,
    };
    $scope.text = {
      input: '',
    };

    $scope.stats = {
      grossWPM: '',
      name: $rootScope.user.first_name + ' ' + $rootScope.user.last_name,
      startTime: null,
      date: null,
      timeElapsedString: '',
      calculate: function (text) {
        // calculate timeElapsed
        var timeElapsedMS = Math.floor(performance.now()) - this.startTime;
        var minutes = Math.floor(timeElapsedMS / 60000);
        var seconds = Math.floor((timeElapsedMS - minutes * 60000) / 100) / 10; // one decimal place
        this.timeElapsedString = String(minutes) + (minutes == 1 ? ' minute, ' : ' minutes, ') + String(seconds) + ' seconds';

        // update date
        this.date = Date.now();

        // calculate speed
        this.grossWPM = Math.floor((text.length / 5.0 / (timeElapsedMS / 60000.0)) * 10) / 10.0;
      },
    };

    // enable normal word processing behaviour of the tab key, instead of it switching form elements
    enableTabKey();
    disablePaste();

    // keydown event handler
    // $scope.$on("keydown", function(a, e) {
    // Use code below to start on first keystroke.
    // if (!$scope.view.userHasStarted && $scope.view.modalsComplete) {
    //   $scope.TypingInput.addEvent(e);
    //   $scope.view.userHasStarted = true;
    //   $scope.stats.startTime = (new Date()).getTime();
    // }
    // });

    function finished() {
      // dragon dictation seems to not trigger an update to $scope.
      //   Go check manually with jQuery, and alternatively use $scope.
      //   Bug: $scope.text.input won't pick-up leading tab characters.
      //          jQuery does.
      var text = $('textarea#typing-area').val() || $scope.text.input;

      // remove any whitespace characters at the end of the document
      // we keep the white spaces at the start to preserve tabs
      text = text.replace(/\s*$/, '');

      // calculate stats
      $scope.stats.calculate(text);
      $scope.view.userHasFinished = true;

      text = text.replace(/ /g, '\u200B•\u200B');

      // update the input so when students print we see extra spaces
      $scope.text.input = text;

      console.log($scope.stats);
      $('html, body').animate({ scrollTop: 0 }, 'slow'); // scroll user to top

      // Resize textarea to content if it is larger than the default height
      // Note: calling without a timeout, the textbox does not get resized in time for accurate
      // calculations to be made
      $timeout(() => {
        var textElement = document.getElementById('typing-area');
        if (textElement.scrollHeight > textElement.offsetHeight) {
          textElement.style.height = textElement.scrollHeight + 'px';
        }
      }, 500);

      // this prevents the auto-scrolling nature of the keyboard
      // $("#typing-area").click(); // we need to focus on #typing-area otherwise backspace will not block going back
      $scope.$on('keydown', function (a, e) {
        if (keyboardUtilities.blockDefaultBehaviourOfTheseKeys(e)) {
          console.log('prevented');
        } else {
          console.log('not prevented');
        }
      });
    }

    $scope.print = function () {
      $timeout(function () {
        window.print();
      }, 200); // open the print dialog
    };

    $scope.reload = function () {
      // reload page without instructions
      $state.go('app.keyboarding.transcription', { skipModals: false }, { reload: true });
    };
  },
]);

// helper functions ------------------------------------------------------------------
// enable behaviour of tab key (normally, tab would switch form elements)
function enableTabKey() {
  document.querySelector('textarea').addEventListener(
    'keydown',
    function (e) {
      if (e.keyCode === 9) {
        // tab was pressed
        // get caret position/selection
        var start = this.selectionStart;
        var end = this.selectionEnd;

        var target = e.target;
        var value = target.value;

        // set textarea value to: text before caret + tab + text after caret
        target.value = value.substring(0, start) + '\t' + value.substring(end);

        // put caret at right position again (add one for the tab)
        this.selectionStart = this.selectionEnd = start + 1;

        // prevent the focus lose
        e.preventDefault();
      }
    },
    false
  );
}

function disablePaste() {
  // JQuery 1.7 way of doing it (can check version with console.log($.fn.jquery))
  $(function () {
    $('.disablepaste').on('copy paste', function (e) {
      e.preventDefault();
    });
  });
}
