app.factory('TestLinksHelpers', [
  '$q',
  '$timeout',
  function ($q, $timeout) {
    // Helper function that generates weeks of days (js date objects) starting from the week today's date belongs to
    // numWeeksToGenerate specifies number of weeks to generate ahead (including current week)
    // returns an array of objects corresponding to weeks - [{dateObj: Date, days: {dateObj: Date, disabled: false, day: 1, month: 2, year: 2020}},]
    function generateWeekRanges(numWeeksToGenerate = 6) {
      // 1. Accumulator variable for the generated days of multiple weeks. Each array entry represents a week
      // Each week is an array of js date objects for a given day of the week
      // eg. [[Date, Date, Date,...], [Date, Date, Date]]
      let result = [];

      // 2. Get today's date
      const today = new Date();

      // 3. Get the Monday of current weeek (as a starting point to generate the rest of the weeks)
      let startingMonday = getMondayOfCurrentWeek(today);

      // 4. Loop as many timesas specified to generate weeks of day Date objects
      for (let i = 0; i < numWeeksToGenerate; i++) {
        // 5. Update startingMonday in line with current week iteration
        let nextMonday = getDateWeeksFromNow(startingMonday, i);

        // 6. Generate a an array of days for current week iteration
        result.push(generateDaysOfWeek(nextMonday));
      }

      // Transform the results nested array into an array of objects
      // the dateObj in week and day keys is a js Date object, which is used by angular in the view to show formatted dates
      // Extract the day, month and year for each into its own keys (used for sending request to backend)
      // eg. [{dateObj: , days: [{dateObj:, day:1, month: 2, year :3}]}, ...]
      return result.map((week) => {
        return {
          dateObj: week[0], // First day of the days generated is used for labelling the given week in the view
          days: week.map((dateObj) => {
            return {
              dateObj: dateObj,
              // add disabled state to dates that have passed
              disabled: isDateInPast(dateObj),
              day: dateObj.getDate(),
              month: dateObj.getMonth() + 1,
              year: dateObj.getFullYear(),
            };
          }),
        };
      });
    }

    // Function that compares a passed in js date object to current date to see if the date is in the past
    function isDateInPast(dateObj) {
      // Exit early with a default true if dateObj is invalid date
      if (!(dateObj instanceof Date)) return true;

      // Make a copy of passed in date
      let newDateObj = new Date(dateObj.getTime()); // Make a copy of the passed in date object
      // set the time on the date object to 11:59pm of same day (note the timezone remains the same)
      newDateObj.setHours(23, 59, 0, 0);

      // Get today's date and set the hours to 11:59pm for consistency when checking
      let todayDateObj = new Date();
      todayDateObj.setHours(23, 59, 0, 0);

      // Compare the date object to current time (both are expected to be in the same timezone)
      // Must use ms representation of date becase == comparison using Date classes doesn't work
      return todayDateObj.getTime() > newDateObj.getTime();
    }

    // Given a dateObj, returns the earliest Monday of the same week (week is defined as Mon-Sun)
    // passed in param is a js date object
    // returns a js date object
    function getMondayOfCurrentWeek(dateObj) {
      // Determine current day of the week. Sunday = 0, Saturday = 6
      const dayOfWeek = dateObj.getDay();
      let mondayDateObj = new Date(dateObj.getTime()); // Make a copy of the passed in date object
      // reset the time on the date object
      mondayDateObj.setHours(0, 0, 0, 0);

      mondayDateObj
        // Adding 1 to get the Monday since days of week are 0 index based
        // If date passed in is a sunday, need to go a whole week back
        .setDate(mondayDateObj.getDate() - (dayOfWeek === 0 ? dayOfWeek + 7 : dayOfWeek) + 1);

      return mondayDateObj;
    }

    // Returns the js date obj for a date numWeeks away from the passed in dateObj
    // dateObj is a js date object and numWeeks is an integer
    // returns a js date object
    function getDateWeeksFromNow(dateObj, numWeeks) {
      let newDateObj = new Date(dateObj.getTime()); // Make a copy of the passed in date object
      // reset the time on the date object
      newDateObj.setHours(0, 0, 0, 0);

      // Add number of weeks specified to the passedin date object
      newDateObj.setDate(newDateObj.getDate() + 7 * numWeeks);

      return newDateObj;
    }

    // Helper function to get every day of a given week as the passed in param (Week is Mon-Sun)
    // param is a js date object
    function generateDaysOfWeek(dateObj) {
      // Note: using setDate instead of just subtracting milliseconds, as this relies on internal js Date functionality,
      // and takes care of daylight savings for us

      // Accumulator for all the generated weeks
      let result = [];
      // Determine current day of the week. Sunday = 0, Saturday = 6
      const dayOfWeek = dateObj.getDay();

      // Generate every day of the week, starting with Monday
      for (let i = 0; i < 7; i++) {
        let newDateObj = new Date(dateObj.getTime()); // Make a copy of the passed in date object
        newDateObj.setDate(newDateObj.getDate() + i);
        result.push(newDateObj);
      }

      return result;
    }

    return {
      generateWeekRanges,
    };
  },
]);
