// Animates the keyboard
app.controller('PracticeTextController', [
  '$scope',
  '$http',
  '$state',
  '$stateParams',
  '$timeout',
  function ($scope, $http, $state, $stateParams, $timeout) {
    var numIdsToDisplay = 70;
    updateIds(); // gets and lists the range of all ids

    $scope.scrollToRange = function (id) {
      var startingIndex = $scope.ids.indexOf(id);
      $scope.displayIds = $scope.ids.slice(startingIndex, startingIndex + numIdsToDisplay);
    };

    if ($stateParams.id) {
      getPracticeText($stateParams.id);
    }

    $scope.submitPracticeText = function () {
      if ($scope.practice_text.id) {
        updatePracticeText();
      } else {
        createPracticeText();
      }
    };

    var disableDestroy = false;
    $scope.destroyPracticeText = function () {
      // disable button for a few seconds to prevent double click....
      if (!disableDestroy && $scope.practice_text.id) {
        disableDestroy = true;
        destroyPracticeText($scope.practice_text.id);
      } else {
        console.log('Error: Could not destroy practice text.');
      }
    };

    // helper functions -----------------------------------------------
    // update a PracticeText object
    function updatePracticeText() {
      practice_text = {
        text: $scope.practice_text.text,
        source: $scope.practice_text.source,
        dialect: $scope.practice_text.dialect,
        genre: $scope.practice_text.genre,
      };
      return $http
        .put('practice_text/' + $scope.practice_text.id, { practice_text: practice_text })
        .then(function (response) {
          $scope.practice_text = response.data.practice_text;
          $scope.view = response.data.view;
        })
        .catch(function (response) {
          // don't update the practice_text, so that we can fix and resend
          // $scope.practice_text = response.data.practice_text;
          $scope.view = response.data.view;
        });
    }

    // creates a PracticeText object
    function createPracticeText() {
      practice_text = {
        text: $scope.practice_text.text,
        source: $scope.practice_text.source,
        dialect: $scope.practice_text.dialect,
        genre: $scope.practice_text.genre,
      };
      return $http
        .post('practice_text', { practice_text: practice_text })
        .then(function (response) {
          $scope.view = response.data.view;
          $scope.practice_text = response.data.practice_text;
          $state.go('app.admin.practice_text#show', { id: response.data.practice_text.id });
        })
        .catch(function (response) {
          $scope.view = response.data.view;
          // don't update text, so we can fix and resend
          // $scope.practice_text = response.data.practice_text;
        });
    }

    // destroy a PracticeText object
    function destroyPracticeText(id) {
      return $http
        .delete('practice_text/' + id)
        .then(function (response) {
          $scope.view = response.data.view;
          $scope.practice_text = response.data.practice_text;
          $timeout(function () {
            $state.go('app.admin.practice_text#show', { id: id + 1 });
          }, 1000);
        })
        .catch(function (response) {
          $scope.view = response.data.view;
        });
    }

    // retrieves a single PracticeText object
    function getPracticeText(id) {
      return $http.get('practice_text/' + id).then(function (response) {
        $scope.practice_text = response.data;
      });
    }

    // retrieves a list of PracticeText ids from server
    function updateIds() {
      return $http
        .get('practice_text/index')
        .then(function (response) {
          $scope.ids = response.data.ids.sort(function (a, b) {
            return a - b;
          });
        })
        .then(updateIdRange);
    }

    // updates $scope.categories, $scope.displayIds
    function updateIdRange() {
      var numCategories = Math.ceil($scope.ids.length / numIdsToDisplay);
      $scope.categories = [];
      var start;
      var end;
      var current = $stateParams.id || 1;
      for (var i = 0; i < numCategories; i++) {
        start = $scope.ids[i * numIdsToDisplay];
        end = $scope.ids[Math.min((i + 1) * numIdsToDisplay, $scope.ids.length) - 1];
        // used for scrollRange. I.e. if id=82, should show range of 71..140
        if (current >= start && current <= end) {
          current = start;
        }
        $scope.categories.push({
          start: start,
          end: end,
          display: String(start) + '..' + String(end),
        });
      }
      $scope.displayIds = $scope.ids.slice(0, numIdsToDisplay);
      $scope.scrollToRange(current);
    }
  },
]);
