// Data service for getting course builder data
app.service('CourseBuilderDataService', [
  '$q',
  '$http',
  function ($q, $http) {
    // Fetch all the course work templates (necessary for the drop down menu)
    this.getCourseWorkTemplates = function () {
      return $q
        .all([
          $http({
            method: 'GET',
            url: '/levels.json',
          }),
          $http({
            method: 'GET',
            url: '/document_assignments.json?minimal_attrs=true',
          }),
        ])
        .then(function (courseWorkTemplates) {
          // NEW OPTIONS MUST BE ADDED TO BOTH: _cb_course_group_footer_partial.html and CourseBuilderDataService
          const keyboarding_assignments = courseWorkTemplates[0].data;
          let documents = courseWorkTemplates[1].data;

          const allKeyboardingAssignments = keyboarding_assignments.map((ka) => {
            return {
              ...ka,
              type: 'Keyboarding',
              _idStr: String(ka.id),
            };
          });
          const allDocumentAssignments = documents.map((da) => {
            return {
              ...da,
              type: 'Document Production',
              _idStr: String(da.id),
            };
          });

          const allAssignments = [...allKeyboardingAssignments, ...allDocumentAssignments];
          return {
            allAssignments,
          };
        })
        .catch(function () {
          return $q.reject({
            flashMessages: ['Could not retrieve course work templates'],
          });
        });
    };

    this.groupDocumentAssignmentsByCountryAndStyleGuideVersion = function (allDocumentAssignments) {
      // used to filter options in select. structure:
      // groupedAssignments[country][style_guide_version] = [{}, {}, {}, ...]
      // groupedAssignments[null][null] returns all assignemnts
      const groupedAssignments = {};
      allDocumentAssignments.forEach((da) => {
        groupedAssignments[da.country] ||= {};
        groupedAssignments[da.country][da.style_guide_version] ||= [];
        groupedAssignments[da.country][da.style_guide_version].push(da);
      });
      groupedAssignments[null] = { [null]: allDocumentAssignments };
      return groupedAssignments;
    };

    // Saves or updates the courseTemplate
    this.updateCourseTemplate = function (courseTemplate) {
      return $http({
        method: courseTemplate.id ? 'PUT' : 'POST',
        url: courseTemplate.id ? `/api/templates/${courseTemplate.id}.json` : '/api/templates.json',
        data: {
          course_template: {
            name: courseTemplate.name,
            structure: courseTemplate.removeUnderscoreKeysStructure(),
            test_requirements: courseTemplate.test_requirements,
            organization_id: courseTemplate.organization_id,
            ehr: courseTemplate.ehr,
            active: courseTemplate.active,
            creator: { first_name: courseTemplate.creator?.first_name, last_name: courseTemplate.creator?.last_name },
          },
        },
      })
        .then(function (res) {
          const courseTemplate = res.data;
          return $q.resolve({
            courseTemplate: courseTemplate,
            flashMessages: [{ text: 'Success! Course template has been updated', error: false }],
          });
        })
        .catch(handleServerError);
    };

    // Handles server errors when creating or updating
    function handleServerError(res) {
      let errorMessages;
      if (res.data) {
        errorMessages = res.data.flashMessages;
      } else if (res.flashMessages) {
        errorMessages = res.flashMessages;
      }

      if (Array.isArray(errorMessages)) {
        const flashMessages = errorMessages.map(function (msg) {
          return {
            text: msg,
            error: true,
          };
        });
        return $q.resolve({
          flashMessages,
          courseTemplate: null,
        });
      } else {
        console.error(res);
        return $q.resolve({
          flashMessages: [{ text: 'There was an error creating a new course template', error: true }],
          courseTemplate: null,
        });
      }
    }
  },
]);
