import { getDatabase, ref, update, serverTimestamp } from 'firebase/database';

// Provides an interface to join/unjoin, create/destroy classes
app.service('Classes', [
  'Auth2',
  '$http',
  '$q',
  'Level',
  function (Auth2, $http, $q, Level) {
    const db = getDatabase();
    // student methods ------------------------------------------------------------------

    // Joins a section. Returns a promise that resolves to the new section
    //  ie. Classes.join({section: {code: "AB12345"}}).then(function(section){}).catch(function(error){})
    this.join = function (section) {
      // Validate the section code
      if (typeof section === 'undefined' || typeof section.code === 'undefined' || section.code.length < 3) {
        return $q.reject({
          errors: 'Error: This code is not valid. Please verify with your instructor that you have the correct code.',
        });
      }
      // only letters and numbers
      // added this in since if we have a period at the end of a code we get a cryptic routing error from rails
      if (!isAlphaNumeric(section.code)) {
        return $q.reject({ errors: 'Error: Your class code can only contain letters and numbers.' });
      }
      // check if the section has a template, and requires students to reset their lesson progress.
      return $http
        .get('api/template_check/' + section.code + '.json')
        .then(function (response) {
          var agreeToLessonReset;
          if (response.data.message) {
            agreeToLessonReset = confirm(response.data.message);
          } else {
            agreeToLessonReset = true;
          }
          if (agreeToLessonReset) {
            return true;
          } else {
            return $q.reject({
              errors: 'You cannot join this class without resetting your lesson progress as defined by your instructor.',
            });
          }
        })
        .then(function () {
          // Section code is valid
          return $http.post('class/join.json', { section: section }).then(
            function ({ data }) {
              const sectionId = data.id;
              // Push this section to the user model
              Auth2.pushSection(data);
              // Tell instructor to refresh their section (triggers an event in classlist controller)
              update(ref(db, `actions/sections/${sectionId}/studentAdded`), { timestamp: serverTimestamp() });
              // Erase any locally stored lesson data, and force the front-end to retrieve the new lesson progress from the server (since it may have been changed)
              Level.eraseLocallyStoredData();
              return $q.resolve(data);
            },
            function ({ data }) {
              return $q.reject({ errors: data.errors });
            }
          );
        });
    };

    // Unjoins a section. Returns a promise that resolves to the deleted section.
    //  ie. Classes.unjoin({section: {code: "AB12345"}}).then(function(section){}).catch(function(error){})
    this.unjoin = function (section) {
      // validate section code
      if (typeof section === 'undefined' || typeof section.section.code === 'undefined' || section.section.code.length < 3) {
        return $q.reject({ errors: 'Error: Could not remove you from this class. Please try again later.' });
      }
      return $http.post('class/unjoin.json', section).then(
        function (response) {
          Auth2.updateSection(response.data); // updates the section to indicate it should be hidden
          // _currentUser.sections = _currentUser.sections.filter(function(el) { return el.id !== response.data.id });
          return $q.resolve(response.data); // return the section
        },
        function (response) {
          return $q.reject(response.data.errors);
        }
      );
    };

    // instructor methods ------------------------------------------------------------------

    // Creates a section, returns a promise with the new section
    this.create = function (section) {
      if (typeof section === 'undefined' || typeof section.name === 'undefined' || section.name.length < 3) {
        return $q.reject({ errors: 'Error: Your section name must have at least 3 characters.' });
      }
      if (
        section.templateRequired &&
        (typeof section.course_template_id === 'undefined' || section.course_template_id === null)
      ) {
        return $q.reject({ errors: 'Error: Your organization requires you to select a template when creating a class.' });
      }

      return $http.post('class.json', section).then(
        function (response) {
          Auth2.pushSection(response.data);
          return $q.resolve(response.data);
        },
        function (response) {
          return $q.reject(response);
        }
      );
    };

    // Destroys a section, returns a promise with a new section
    this.toggleDestroy = function (section, includeDeletedSections) {
      return $http.delete(`class/${section.id}.json`).then(function () {
        // update sections on success
        var currentUser = Auth2.refreshSections(includeDeletedSections);
        return currentUser;
      });
    };

    // Joins a section. Returns a promise that resolves to the new section
    //  ie. Classes.join({section: {code: "AB12345"}}).then(function(section){}).catch(function(error){})
    this.transfer = function (studentId, newSectionCode, oldSectionId) {
      // Validate the section code
      if (typeof newSectionCode === 'undefined' || newSectionCode.trim().length < 3) {
        return $q.reject({
          errors: 'Error: This code is not valid. Please verify the code with the instructor for this section.',
        });
      }
      // check if the section has a template, and requires students to reset their lesson progress.
      return $http
        .put('class/transfer.json', {
          section: { code: newSectionCode.trim() }, // the code of the new section
          section_id: oldSectionId, // the id of the current section the student is leaving
          user_id: studentId, // student's id
        })
        .catch(function (response) {
          return $q.reject({ errors: response.data.errors });
        });
    };

    // Removes a student from a section. Returns a promise that resolves to the new section
    //  ie. Classes.join({section: {code: "AB12345"}}).then(function(section){}).catch(function(error){})
    this.removeStudent = function (studentId, sectionId) {
      // check if the section has a template, and requires students to reset their lesson progress.
      return $http.delete('class/remove_student/' + studentId + '/' + sectionId + '.json').catch(function (response) {
        return $q.reject({ errors: response.data.errors });
      });
    };

    function isAlphaNumeric(str) {
      return str.match(/^[a-z0-9]+$/i) !== null;
    }

    return this;
  },
]);
