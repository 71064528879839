// TODO:
//  - Should display a message if the user has no sections
//  - Should display a message if the selected section doesn't have a course template (i.e. no tests available)
app.controller('TestCentreControllerCourseSelect', [
  '$scope',
  '$http',
  'user',
  '$q',
  '$state',
  'proctoredTests',
  'PermissionsHelperFunctions',
  function ($scope, $http, user, $q, $state, proctoredTests, PermissionsHelperFunctions) {
    /************************
     *   Define constants (user `const` if using Babel)
     ***********************/
    // Constants to better keep constitency across the controller and views and to faster spot errors
    $scope.studentFullName = user.first_name + ' ' + user.last_name;

    $scope.constants = PermissionsHelperFunctions.constants;
    $scope.tests = PermissionsHelperFunctions.filterPermissionsByStatus(proctoredTests, $scope.constants.TEST_IN_PROGRESS);

    // TODO: Check is this works when the proctoredTest expires. It may not update the view.
    $scope.completedTests = PermissionsHelperFunctions.filterPermissionsByStatus(proctoredTests, $scope.constants.TEST_COMPLETE);

    $scope.sections = user.sections;
    $scope.course_template = {};
    $scope.courseId = null;
    $scope.courseName = null;
    $scope.courseSelectonError = null;
    $scope.verifyingCourse = false;
    $scope.proctorLoggedIn = false;
    $scope.proctorLoginError = null;
    $scope.proctorLoginPendingRequest = false;

    // Checks for a course template and test_requirements when a user selects their class
    var getCourseTemplate = function (sectionId) {
      // get the course template id
      var course = $scope.sections.filter(function (s) {
        return s.id == sectionId;
      })[0];
      var course_template_id = course.course_template_id;
      var course_template_name = course.name;

      // if there's no course template display an error
      if (course_template_id && Number(course_template_id) > -1) {
        return $http.get(`api/templates/${course_template_id}.json`).then(function (response) {
          $scope.course_template = response.data;
          if ($.isEmptyObject($scope.course_template.test_requirements)) {
            return $q.reject(
              'There are no tests available for this course. Please ensure you have selected the correct course, or contact your instructor for help.'
            );
          } else {
            return $q.resolve({
              courseId: sectionId,
              name: course_template_name,
            });
          }
        });
      } else {
        return $q.reject(
          'There are no tests available for this course. Please ensure you have selected the correct course, or contact your instructor for help.'
        );
      }
    };

    $scope.pickCourse = function (course) {
      $scope.verifyingCourse = true;
      getCourseTemplate(course)
        .then(function (course) {
          $scope.courseId = course.courseId;
          $scope.courseName = course.name;
        })
        .catch(function (error) {
          $scope.courseSelectionError = error;
        })
        .then(function () {
          $scope.verifyingCourse = false;
        });
    };

    $scope.clearCourseSelectionErrors = function () {
      $scope.courseSelectionError = null;
    };

    $scope.clearProctorLoginErrors = function () {
      $scope.proctorLoginError = null;
    };

    $scope.proctorLogin = function (proctor) {
      // send the proctor and course id to the server. Populate the pendingTests resolve function with tests if proctor login is correct
      var reqBody = { test_centre: { proctor_id: proctor.id, proctor_code: proctor.code }, section: { id: $scope.courseId } };

      if (!$scope.proctorLoginPendingRequest) {
        $scope.proctorLoginPendingRequest = true;
        return $http
          .post('api/test_centres/proctored_test', reqBody)
          .then(function () {
            // redirect to test_centre page which will retrieve the tests we just created from the server
            // Not clearing proctorLoginPendingRequest here because there is a small gap between Angular clearing disable on the button and actual stage change
            return $state.go('app.keyboarding.invigilation_centre');
          })
          .catch(function (error) {
            $scope.proctorLoginError = error.data.errors;
            $scope.proctorLoginPendingRequest = false;
          });
      }
    };
  },
]);
