import { angularStates } from '../../../statesUrls';

app.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider

      // Create new document production assignments
      .state('assignment_creator', {
        url: angularStates['assignment_creator'],
        abstract: true,
        controller: 'DocumentAssignmentCreatorController',
        templateUrl: 'document_production/assignment_creator/_template.html',
        resolve: {
          user: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  // only gregjebonnell@gmail.com and mattmcinnis (typistapp@gmail.com) and pammurray10@gmail.com
                  if (!(user.admin || user.id === 288 || user.id === 141 || user.id === 7 || user.id === 4814)) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          documentAssignments: [
            '$http',
            'DocumentAssignment',
            function ($http, DocumentAssignment) {
              return $http
                .get('document_assignments.json', {
                  // Make sure these are the same settings as the default values in the $scope.view.currentFilter
                  // in document_assignment_creator_controller.js
                  params: { style_guide_version: '3.0', country: 'CA' },
                })
                .then(function (response) {
                  const documentAssignments = response.data;
                  return documentAssignments.map(function (da) {
                    return new DocumentAssignment(da);
                  });
                });
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Create new document production assignments
      .state('assignment_creator.index', {
        url: angularStates['assignment_creator.index'],
        templateUrl: 'document_production/assignment_creator/index.html',
        resolve: {},
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Create new document production assignments
      .state('assignment_creator.show', {
        url: '/{id}',
        controller: 'AssignmentCreatorShowController',
        params: { id: null }, // must be present for notification_id to show up
        templateUrl: 'document_production/assignment_creator/show.html',
        resolve: {
          documentAssignment: [
            '$stateParams',
            'DocumentAssignment',
            function ($stateParams, DocumentAssignment) {
              var id = $stateParams.id;
              return new DocumentAssignment().init(id);
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      });
  },
]);
