// Provides helper functions primarily to the finger animations controller
app.service('FingerAnimationsServiceHelpers', [
  function () {
    var self = this;

    this.homerowKeys = ['a', 's', 'd', 'f', 'j', 'k', 'l', ';'];
    this.fingers = [
      'leftPinky',
      'leftRing',
      'leftMiddle',
      'leftPointer',
      'rightPointer',
      'rightMiddle',
      'rightRing',
      'rightPinky',
      'rightThumb',
    ];
    this.FingerToKeyMap = {
      leftPinky: ['q', 'a', 'z', 'tab', '`', 'left-shift', '1', 'capslock'],
      leftRing: ['w', 's', 'x', '2'],
      leftMiddle: ['e', 'd', 'c', '3'],
      leftPointer: ['r', 'f', 'v', 't', 'g', 'b', '4', '5'],
      rightPointer: ['y', 'h', 'n', 'u', 'j', 'm', '6', '7'],
      rightMiddle: ['i', 'k', ',', '8'],
      rightRing: ['o', 'l', '.', '9'],
      rightPinky: ['p', ';', '/', 'backspace', '=', "'", '-', '\\', 'enter', '[', ']', 'right-shift', 'shift', '0'],
      rightThumb: ['space'],
    };
    this.fingerStartKey = {
      leftPinky: 'a',
      leftRing: 's',
      leftMiddle: 'd',
      leftPointer: 'f',
      rightPointer: 'j',
      rightMiddle: 'k',
      rightRing: 'l',
      rightPinky: ';',
      rightThumb: 'space',
    };

    // Check if a key is supported for animation
    this.isKeySupported = function (key) {
      if (!key || typeof key !== 'object' || !key.digit) {
        return false;
      }

      var keyDigit = key.digit;
      if (typeof keyDigit !== 'string') {
        return false;
      }

      keyDigit = keyDigit.toLowerCase();
      if (keyDigit === ' ') {
        keyDigit = 'space';
      }

      // Check if key is in any finger mapping
      return self.fingers.some(function (finger) {
        return self.FingerToKeyMap[finger].indexOf(keyDigit) > -1;
      });
    };

    // internal helper functions
    // returns the homerow key associated with the key to indicate.
    //  ex. getHomerowKeyAndHand("w") -> "s"
    this.setupVariablesForHighlightKey = function (key, options) {
      // Early return if key is not supported
      if (!self.isKeySupported(key)) {
        return null; // Return null to indicate unsupported key
      }

      // set default values
      options = options || {};
      options.isShift = defaultFor(options.isShift, false);
      options.duration = defaultFor(options.duration, 2000); // how long the animation will last
      options.numBlinksStart = defaultFor(options.numBlinksStart, 1); // blink the home row key associated with the key to be pressed?
      options.numBlinksEnd = defaultFor(options.numBlinksEnd, 2); // blink the home row key associated with the key to be pressed?

      // format key
      var keyDigit = key.digit;
      keyDigit = keyDigit === ' ' || keyDigit === 'SPACE' ? 'space' : keyDigit.toUpperCase();

      var startInfo = getHomerowKeyAndHand(keyDigit);

      // add metadata to options
      options.finger = startInfo.finger;
      options.homerowKey = startInfo.fingerStartKey.toUpperCase();
      options.hand = startInfo.hand;
      options.keySelector = self.transformToSelector(options.hand, keyDigit);

      options.startBlinkTime = options.numBlinksStart > 0 ? 0.4 * options.duration : 0; // how long the initial blinks have to finish
      options.endBlinkTime = options.numBlinksStart > 0 ? 0.6 * options.duration : options.duration; // how long the final blinks have to finish

      // if we're lighting up a homerow key (i.e. asdf...) just blink it
      if (self.homerowKeys.indexOf(keyDigit.toLowerCase()) > -1) {
        options.numBlinksStart = 0;
      }
      return { key: keyDigit, options: options };
    };

    // determines the finger and hand to use based on the key that's required
    function getHomerowKeyAndHand(key) {
      if (!key) {
        return null;
      }

      var keyLower = key.toLowerCase();
      var fingerToUse;
      var handToUse;

      self.fingers.forEach(function (finger) {
        if (self.FingerToKeyMap[finger].indexOf(keyLower) > -1) {
          fingerToUse = finger;
          handToUse = finger.indexOf('left') > -1 ? 'left' : 'right';
        }
      });

      return {
        finger: fingerToUse,
        fingerStartKey: self.fingerStartKey[fingerToUse],
        hand: handToUse,
      };
    }

    // transforms selectors, since jQuery throws an error if you try to use a semi-colon in a selector
    //  this is used to show/hide the finger animation images
    this.transformToSelector = function (hand, key) {
      if (!hand || !key) {
        return '';
      }

      hand = String(hand);
      key = String(key);

      if (key === 'home' || key === 'HOME') {
        return 'HOME-' + hand.toLowerCase();
      } else if (key === ';') {
        return 'SEMICOLON';
      } else if (key === ',') {
        return 'COMMA';
      } else if (key === '.') {
        return 'PERIOD';
      } else if (key === '?') {
        return 'QUESTION';
      } else if (key === "'") {
        return 'APOSTROPHE';
      } else if (key === '=') {
        return 'EQUAL';
      } else if (key === '-') {
        return 'DASH';
      } else if (key === '\\') {
        return 'BACKSLASH';
      } else if (key === '/') {
        return 'FORWARDSLASH';
      } else if (key === '[') {
        return 'LEFTSQUAREBRACKET';
      } else if (key === ']') {
        return 'RIGHTSQUAREBRACKET';
      } else if (key === '`') {
        return 'BACKTICK';
      } else if (key === 'SHIFT') {
        return hand + '-' + key;
      } // i.e. RIGHT-SHIFT or LEFT-SHIFT
      else if (key === ' ' || key === 'space' || key === 'SPACE') {
        return 'space';
      } else {
        return key.toUpperCase();
      }
    };

    // some keys, such as backspace, use non-traditional ASCII characters.
    // our selectors find these keys by their content (i.e. the backspace key contains "←")
    // this method will override these characters so that the selectors work
    // used in something like: $(".key:contains('" + key + "')").removeClass("active");
    this.keyContentForSelector = function (key) {
      if (!key || typeof key !== 'string') {
        return '';
      }

      var lowCaseKey = key.toLowerCase();
      if (lowCaseKey === 'backspace') {
        return '←';
      } else if (lowCaseKey === 'space') {
        return 'space';
      } else if (lowCaseKey === 'tab') {
        return 'tab';
      } else if (lowCaseKey === "'") {
        return "\\'";
      } else if (lowCaseKey === '\\') {
        return '\\\\';
      } else if (lowCaseKey === 'capslock') {
        return 'caps lock';
      } else {
        return key;
      }
    };

    // allows setting default values for parameters.
    // Ex:
    // foo = defaultFor(foo, 0); // sets a deafult value of 0
    function defaultFor(arg, val) {
      return typeof arg !== 'undefined' ? arg : val;
    }

    return this;
  },
]);
