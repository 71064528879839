// A DocumentSubmission contains an array of students & a test object

app.factory('DocumentSubmission', [
  '$http',
  '$q',
  function ($http, $q) {
    /**
     * Initializes a DocumentSubmission object. DocumentSubmission contains all properties from the database object.
     * Can be initialized by passing in an object of properties, i.e. {id: 2, section_id: 3, ...}, or
     * by calling the init method with an id, where it will get the properties from the server.
     * @param {Object} props - Controller local scope. Used to unload listeners when scope is destroyed.
     * @param {Object} scope - Controller local scope. Used to unload listeners when scope is destroyed.
     * @return {Object} DocumentSubmission - A DocumentSubmission object.
     */
    var DocumentSubmission = function (props, scope) {
      var self = this;
      this.view = {};
      // assign all properties from props to this
      assignAllPropertiesToSelf(props);

      // retireves a DocumentSubmission object
      // BOOL loadJsonFromS3 will also retrieve JSON analysis
      this.init = function (id, loadJsonFromS3) {
        return $http
          .get('document_submissions/' + id)
          .then(function (res) {
            assignAllPropertiesToSelf(res.data);
            return res.data;
          })
          .then(function (res) {
            if (loadJsonFromS3) {
              return $http
                .get(res.json_access_url)
                .then(function (res) {
                  self.grading_details = res.data;
                })
                .catch(function (res) {
                  console.warn('Error: Could not load JSON from S3.');
                  // return $q.reject({data: {errors: "Error: Could not load submission analysis. Please contact Typist Support."}});
                  return $q.reject({
                    data: {
                      errors:
                        'Assignment is still being graded! Please wait a 4-5 seconds, and then refresh the page to see your results!',
                    },
                  });
                });
            } else {
              return res;
            }
          })
          .catch(function (err) {
            var errorMessage = err.data.errors;
            if (err.data && err.data.document_submission) {
              var document_submission = JSON.parse(err.data.document_submission);
            }
            assignAllPropertiesToSelf(document_submission);
            return $q.reject(errorMessage);
          });
      };

      // requires an attribute of newPoints to be set in the view.
      //  we use newPoints to distinquish from .points so that we can enable/disable buttons, etc.
      // Note: The server will verify if this user is an instructor.
      this.updatePoints = function () {
        if (self.newPoints < 0 || self.newPoints > self.base_points) {
          return $q.reject({ errorMessage: 'Error: Grade must be between 0 and ' + String(self.base_points) + '.' });
        }
        self.view.updateInProgress = true;

        return $http
          .put('api/document_submissions/update.json', {
            section_id: self.section_id,
            document_submission_id: self.id,
            document_submission: {
              points: self.newPoints,
            },
          })
          .then(function (res) {
            self.points = self.newPoints;
            self.view.updateInProgress = false;
            self.view.buttonMessage = 'Grade Updated!';
            self.view.gradeUpdated = true;
            return res;
          });
      };

      // geneates a test, along with test files in S3. Only meant for admins to call
      this.generateTest = function (description, deductions) {
        self.view.updateInProgress = true;
        self.view.generateTestMessage = 'Generating..';
        self.view.generatedTestErrorMessage = null;
        return $http
          .post('api/document_submissions/generate_test.json', {
            document_submission_id: self.id,
            description: description || '',
            deductions: deductions,
          })
          .then(function (res) {
            // self.points = self.newPoints;
            self.view.generateTestMessage = 'Saved!';
            self.view.generatedTestJSON = res.data;
            // self.view.buttonMessage = "Grade Updated!";
            // self.view.gradeUpdated = true;
            return res.data;
          })
          .catch(function (err) {
            self.view.generateTestMessage = 'Generate Test';
            self.view.updateInProgress = false;
            self.view.generatedTestErrorMessage = 'Error. Please try again... or fix whatever happened. Preferably the latter.';
            return err;
          });
      };

      this.showUpdateForm = function () {
        self.newPoints = self.points;
        self.view.showUpdateForm = !self.view.showUpdateForm;
        self.view.buttonMessage = 'Save';
        self.view.updateInProgress = false;
        self.view.gradeUpdated = false;
      };

      // Soft delete the document submission (sets the .deleted property to true)
      this.delete = function () {
        return $http.delete(`/document_submissions/${self.id}.json`);
      };

      // assigns all properties retrieved from the server to self
      function assignAllPropertiesToSelf(props) {
        for (var prop in props) {
          if (props.hasOwnProperty(prop)) {
            if (props[prop] !== null && prop === 'created_at') {
              self[prop] = new Date(props[prop]);
            } else {
              self[prop] = props[prop];
            }
          }
        }
      }
    }; // end of constructor

    return DocumentSubmission;
  },
]);
