import BugsnagNotify from '../../../components/BugsnagNotify/BugsnagNotify.js';

// TypingInput
//  Provides a set of helper functions to controllers, and is used to manipulate the view
app.service('TypingInput', [
  '$timeout',
  '$http',
  '$q',
  'keyboardUtilities',
  function ($timeout, $http, $q, keyboardUtilities) {
    this.newSession = function (options) {
      // Options  -----------------------------------------------------------------------------------------------------
      //  enableBackspace : allows the backspace button to be used
      //  blockOnError    : if true, will not allow the user to continue typing until error is corrected
      //  startIf         : defines the start condition
      this.primarySpeedMetric = options.primarySpeedMetric || 'netWPM'; // "netWPM" || "grossWPM" - Will affect whether main view displays either
      this.enableBackspace = options.enableBackspace || false;
      var divider = options.divider || '\u00a0'; // the default divider is a non-breaking space char
      var enterDivider = options.enterDivider || '↵'; // This is the char that represents the enter key press in the string. Make sure it is the same as the key being substituted for Enter in keyboard_utility_services
      this.blockOnError = options.blockOnError || false;
      this.startIf =
        options.startIf ||
        function () {
          return true;
        };
      this.onStart =
        options.onStart ||
        function () {
          return true;
        };
      var useCountdownTimer = options.useCountdownTimer || false; // must call initialize before start
      this.onFinish = options.onFinish || function () {}; // callback in lineComplete
      var updateStatsOnTimer = options.updateStatsOnTimer || false;
      var updateStatsOnKeystroke = options.updateStatsOnKeystroke || false;
      this.eachSecond = options.eachSecond || function () {}; // runs on each timer cycle
      this.activity_type = options.activity_type || ''; // 'TimedWriting' etc. Used with autosave and save() methods
      this._eventList = [];

      // Initialize Timer  ---------------------------------------------------------------------------------------------
      if (useCountdownTimer) {
        this.timer = {
          // val is duration in seconds
          initialize: function (val) {
            if (val > 0) this.initialized = true;
            this.duration = val;
            this.countdown = val;
            this.countdownString = timeToString(val);
          },
          initialized: false,
          duration: null,
          countdown: null,
          decrease: function (delta) {
            if (this.countdown - delta <= 0) {
              this.countdown = 0;
            } else {
              this.countdown -= delta;
            }
            this.countdownString = timeToString(this.countdown);
            this.progressBarWidth = Math.ceil((1 - (this.countdown * 1.0) / (this.duration * 1.0)) * 100);
          },
          countdownString: null,
          start: startTimer,
          stop: function () {
            this.decrease(this.countdown);
            if (this.timerRef) {
              $timeout.cancel(this.timerRef);
            } /* decrease the counter all the way */
          },
          progressBarWidth: null,
          timerRef: null,
        };
      }

      // Public variables   -------------------------------------------------------------------------------------------
      this.prevent_typing = false; // prevents typing
      this.events = []; // array of event objects
      this.view = {
        // exposes needed variables to the view
        linesOfText: [[]], // used in view to display text (chunked in words)
        lineOfTextObjects: [], // used to manage the class of objects (ie active, etc.)
        lineOfTextWordLengths: [], // used in view to display text (in iteration)
        divider: divider, // character used to replace 'space'. from options
        enterDivider: enterDivider, // This is the char that represents the enter key press in the string.
        maxCharsPerLine: 52, // maximum number of characters before line wraps
        // Used to display a "Saving" message above the progress bar when attempting to save typing activity
        saving: {
          inProgress: false,
          message: null, // { text: '...', error: false}
        },
        // Used to display message above progress bar when
        resetProgressMessage: null, // { text: '...', error: false}
        voiceover: '', // announces instructions for voiceover software
      };
      this.stats = {
        // calculated and updated in updateStats & calculateStats
        netWPM: 0,
        accuracy: 0,
        grossWPM: 0,
        totalChars: 0, // total number of keys pressed
        totalNonbackspacedChars: 0, // only includes keys that were not erased by a backspace ( < totalChars)
        correctChars: 0, // total number of correct chars (including those that were backspaced)
        correctNonbackspacedChars: 0, // total number correct - correct chars erased
        errors: 0, // total number of inorrect chars
        errorsNonbackspaced: 0, // incorrect chars - erased incorrect chars
        timeElapsed_ms: 0,
        practice_time_ms: 0, // total effective practice time
      };
      const selfEU = this;

      // Private variables
      var digitIndex = 0; // tracks the current digit
      var lineOfText = ''; // update from ctrl using updateLineOfTextFromString();
      var lastKeyPressedAt = null; // used to calculate time between keys
      var lineStartTime = null; // used to calculate time between keys
      var errorOnKey = false; // used to update views
      var activeLines = [];
      var nonBackspacedChars = []; // counts the number of chars (not including backspace, etc.)
      var lineCharIndex = []; // used to determine whether a line is finished. [{firstIndex: , lastIndex:}, ...]
      var linesToDisplay = 3; // maximum number of lines to display
      var activeLine = 0; // active line
      var doNotUpdateStatsAgain = false; // prevents double update of stats when line finishes
      var runLineCompleteOnce = false; // prevents lineComplete function from executing twice
      var badKeysToTransform = {
        '—': '-', // weird dash -> regular dash
        '–': '-', // weird dash -> regular dash
        '‘': "'", // weird single quote -> single quote
        '’': "'", // weird single quote -> single quote
        '“': '"', // weird double quote -> double quote
        '”': '"', // weird double quote -> double quote
      };

      // Helper functions   ---------------------------------------------------------------------------------------------
      // functionName                      -> return type             description
      //
      // addEvent(event)                         (null)          Adds events into the event log
      // updateLineOfTextFromString(string)      (null)          Updates view and service variables
      // isCorrectKey(event)                   -> BOOL           Was the correct key pressed?
      // isLineFinished()                      -> BOOL           Is the line finished?
      // nextChar()                              (null)          Increases digitIndex
      // noOutputFromThisKey(event)            -> BOOL           Blocks output from listed keys
      // calculateStats                        -> Float          Calculates WPM and accuracy from events
      // calculateErrors                       -> Integer        Calculates number of errors from events
      // updateErrorClass(bool)                  (null)          Adds classes to DOM: pressed-incorrect, pressed-correct
      // save(options)                         -> Promise        Saves the session to activity#create (post activity)
      // updateLineOfTextFromUrl               -> Promise        Retrieves text to type, returns promise when complete
      // subdivideWordsArrayByDelimiter        -> Array          Further subdivides an array of strings by a provided delimiter char

      // Adds events into the event log
      this.addEvent = function (e) {
        // prevent browser scrolling or navigating while typing
        keyboardUtilities.blockDefaultBehaviourOfTheseKeys(e);

        // if progress has been reset due to inactivity, clear message
        this.view.resetProgressMessage = null;

        // adding for debugging purposes
        this._eventList.push({
          key_pressed: e.key_pressed,
          key_pressed_lowcase: e.key_pressed_lowcase,
          timeStampAdjusted: e.timeStampAdjusted,
          timeStamp: e.timeStamp,
          performanceNow: e.performanceNow,
          performanceNowInt: e.performanceNowInt,
          type: e.type,
          shiftKey: e.shiftKey,
          keyCode: e.keyCode,
          code: e.code,
        });

        // Abort condition ---------------------------------------
        // don't allow user to start unless external conditions are methods
        if (!selfEU.startIf()) return digitIndex;
        // don't start unless if timer is used and not initialized
        if (useCountdownTimer && !selfEU.timer.initialized) {
          console.log('Timer is not initialized.');
          return digitIndex;
        }
        // don't add event if non-modifying character (shift, ctrl, etc.)
        if (selfEU.noOutputFromThisKey(e)) {
          return digitIndex;
        } // Ie shift, ctrl, etc
        // don't add event if the line is finished
        if (isLineFinished()) {
          return digitIndex;
        }
        // don't start if the first keypress is a space (to avoid accidental errors in lessons)
        if (selfEU.events.length === 0 && e.key_pressed === ' ') {
          return digitIndex;
        }

        // Record the keypress -----------------------------------
        // run once on start - start timer, call external methods
        if (selfEU.events.length === 0) {
          if (useCountdownTimer && !selfEU.timer.timerRef) {
            selfEU.timer.timerRef = $timeout(selfEU.timer.start, 1000);
            selfEU.onStart();
          }
        }

        const lastEvent = selfEU.events[selfEU.events.length - 1];
        var eventData = calculateEventData(e, lastEvent); // returns {targetKey: keyPressed: previousKey: timeBetweenKeys}

        updateStatsCounters(eventData.target_key, eventData.key_pressed); // updates the stats counter variables (not netWPM, grossWPM or accuracy)
        if (updateStatsOnKeystroke) {
          updateStats();
        }

        selfEU.events.push(eventData);
        // console.log(selfEU.events); // log event to console

        // update view, digitIndex then exit if backspace
        if (selfEU.enableBackspace && isBackspace(e)) {
          selfEU.view.voiceover = selfEU.view.lineOfTextObjects[digitIndex].voiceover;
          return digitIndex;
        }

        // updates view
        if (isCorrectKey(eventData)) {
          nextChar();
          if (digitIndex < lineOfText.length) selfEU.view.voiceover = selfEU.view.lineOfTextObjects[digitIndex].voiceover;
        } else if (!selfEU.blockOnError) {
          nextChar(); // be non-blocking on error
          if (digitIndex < lineOfText.length)
            selfEU.view.voiceover = `Error. ${selfEU.view.lineOfTextObjects[digitIndex].voiceover}`;
        }

        if (digitIndex >= lineOfText.length) {
          selfEU.lineComplete('End of text');
        }

        return digitIndex;
      };

      // Splits the line into words so that they don't split between lines
      this.updateLineOfTextFromString = function (lineOfTextString) {
        // Split at each space occurrence
        var l = lineOfTextString.split(' ');

        // eg. ['my', 'first', 'word.↵second', 'word.'] -> ['my␣', 'first␣', 'word.↵second␣', 'word.']
        l = l.map(function (word) {
          return word + selfEU.view.divider;
        });
        var lastWord = l[l.length - 1];
        l[l.length - 1] = lastWord.slice(0, lastWord.length - 1); // Get rid of the trailing divider

        // Further subdivide the array of words by the enter key char
        // eg. ['my␣', 'first␣', 'word.↵second␣', 'word.'] -> ['my␣', 'first␣', 'word.', '↵', 'second␣', 'word.']
        l = subdivideWordsArrayByDelimiter(l, selfEU.view.enterDivider);

        // [3, 4, 5, 5, ....]
        var wordLengths = l.map(function (word) {
          return word.length;
        });
        // [3, 7, 12, 17, ...]
        for (let i = 1; i < wordLengths.length; i++) {
          wordLengths[i] = wordLengths[i] + wordLengths[i - 1];
        }
        wordLengths.unshift(0); // add a '0' element to front

        lineOfText = lineOfTextString.replace(/ /g, selfEU.view.divider);
        selfEU.view.linesOfText = splitWordsIntoLines(l);
        selfEU.view.lineOfTextWordLengths = wordLengths;
        return;
      };

      this.noOutputFromThisKey = function (e) {
        // MAC     /      WINDOWS  -  KEYCODE   (reference list: http://unixpapa.com/js/key.html)
        // left command  / left windows  -  91
        // start         / right windows -  92
        // right command / windows       -  93
        // Firefox: Right Command Key
        // "" / ""                       -  255 (null key. various uses)
        const noOutputKeys = [9, 16, 17, 18, 20, 91, 92, 93, 224, 255];
        if (noOutputKeys.indexOf(e.keyCode) >= 0) return true;
        const noOutputKeyPrefixes = ['Audio', 'Microphone'];
        // AudioVolume Mute              -  173
        // AudioVolume Down              -  174
        // AudioVolume Up                -  175
        const isAudioKey = noOutputKeyPrefixes.some((prefix) => e.key.startsWith(prefix));
        if (isAudioKey) return true;

        // Lets check if the user is zooming in/out
        //  Note: metaKey corresponds to 'command' on a mac, windows key on a pc.
        //          so we have to check for both ctrl and metaKey
        if (e.key_pressed === '=' && (e.metaKey || e.ctrlKey)) return true;
        if (e.key_pressed === '-' && (e.metaKey || e.ctrlKey)) return true;
        return false;
      };

      // creates an Activity on the server. Returns a promise.
      this.save = function (options) {
        // console.log(selfEU.stats);
        if (selfEU.activity_type === '') {
          console.log(
            "ERROR: Cannot save data. TypingInputService must be initialized with a 'name' property to use save() or autosave."
          );
          return;
        }

        // transforms to an array of arrays: [["a", "b", ...], ..]
        var keystrokes_transformed = selfEU.events.map(function (el) {
          return [el['target_key'], el['key_pressed'], el['previous_key'], el['time_between_keys']];
        });

        var activity = {
          activity_type: selfEU.activity_type,
          keystrokes: keystrokes_transformed,
          netWPM: selfEU.stats.netWPM,
          grossWPM: selfEU.stats.grossWPM,
          accuracy: selfEU.stats.accuracy,
          duration_in_ms: selfEU.stats.timeElapsed_ms,
          practice_time_ms: selfEU.stats.practice_time_ms,
          errors_nonbackspaced: selfEU.stats.errorsNonbackspaced,
          data: selfEU.stats,
        };
        // in .extend, 'true' allows us to do a deep merge, so we can: .save({data: {myProperty: true}})
        $.extend(true, activity, options);

        // Clear the message above the progress bar
        selfEU.view.saving = { inProgress: true, message: null };

        // TODO: Scan _eventList for key_pressed values that are weird. Maybe use keyCode and see if it's outside a range?

        return httpWithRetries({ url: 'activity.json', data: { activity: activity }, method: 'post' }, activity)
          .then((res) => {
            // async delay outside of this promise chain so that the message doesn't flash
            $timeout(() => (selfEU.view.saving = { inProgress: false, message: { text: 'Saved!', error: false } }), 500);
            return res;
          })
          .catch((err) => {
            // async delay outside of this promise chain so that the message doesn't flash
            $timeout(
              () => (selfEU.view.saving = { inProgress: false, message: { text: 'Could not save progress.', error: true } }),
              500
            );
            return $q.reject(err);
          });
      };

      // Retrieves text to type, updates in view
      //  -> Returns a promise that resolves when complete.
      // params object is optional, and is turned into url queries that get sent over to the backend (eg. duration_in_s)
      this.updateLineOfTextFromUrl = function (url, params = {}) {
        return $http
          .get(
            url,
            { params: params } // Params may contain keys like 'duration_in_s'
          )
          .then(
            function (response) {
              // $timeout(function() {
              selfEU.updateLineOfTextFromString(response.data.lineOfText);
              // });
            },
            function () {}
          );
      };

      // -------------------------------------------------------------------------------------------
      // Line of Text helpers -----------------------------------------------------------------------------
      // -------------------------------------------------------------------------------------------

      // Helper function to subdivide an array of strings by splitting at a specified delimiter
      // eg. delimeter = '↵'
      // eg. arrayOfWords ['my␣', 'first␣', 'word.↵second␣', 'word.']
      // eg. returns ['my␣', 'first␣', 'word.', '↵', 'second␣', 'word.']
      function subdivideWordsArrayByDelimiter(arrayOfWords, delimiter) {
        return arrayOfWords.reduce((acc, word) => {
          // eg. 'word.↵second␣' -> ['word.', '↵', 'second␣']
          const wordSplitArray = word
            // Special usecase of split, where delimiter inside a capture group is included in the resultsray
            .split(new RegExp(`(${delimiter})`, 'g'))
            .filter((splitWord) => splitWord !== ''); // Get rid of any empty strings that may result from splitting a string with consecutive delimiters in the string

          // Merge the subarray of word split into wordSplitArray, with the previous words already in the accumulator
          return [...acc, ...wordSplitArray];
        }, []);
      }

      // -------------------------------------------------------------------------------------------
      // End of Line of Text helpers -----------------------------------------------------------------------------
      // -------------------------------------------------------------------------------------------

      // -------------------------------------------------------------------------------------------
      // Stats trackers ----------------------------------------------------------------------------
      // -------------------------------------------------------------------------------------------
      // called from addEvent and from timer.
      //   -> Updates the calculated statistics
      function updateStats(endTime, sessionCompleteReason) {
        if (doNotUpdateStatsAgain) return;
        if (isLineFinished()) doNotUpdateStatsAgain = true;

        const lastEvent = selfEU.events[selfEU.events.length - 1];
        const lastKeyPressedAt = lastEvent ? lastEvent.performanceNowInt : null;

        // don't start calculating until at least one second has passed
        if (lastKeyPressedAt - lineStartTime <= 1000 && !sessionCompleteReason) {
          return selfEU.stats;
        }

        // if the session has not ended (i.e. endTime is false or undefined) then
        // we calculate stats based on how much time has passed. If we're using a countdown
        // timer, we set endTime based on how much time has passed.
        // Note: this is a really shitty if statement written by Matt when he sucked at coding :(
        if (endTime === undefined || endTime === false) {
          endTime = lastKeyPressedAt;
        } else if (useCountdownTimer) {
          // use the lesser of the session end time and the current time
          endTime = Math.min(lineStartTime + selfEU.timer.duration * 1000, Math.floor(performance.now()));
        } else {
          endTime = Math.floor(performance.now());
        }

        // calculate the time in minutes
        var timeInMinutes = (endTime - lineStartTime) / 1000 / 60;
        var netWords = selfEU.stats.correctNonbackspacedChars / 5.0; // a word is 5 chars
        var totalWords = selfEU.stats.totalNonbackspacedChars / 5.0; // a word is 5 chars

        selfEU.stats.accuracy = Math.round((1.0 - (selfEU.stats.errors * 1.0) / selfEU.stats.totalChars) * 1000) / 10 || 0; // need the || 0 in case the user has only hit a backspace key
        selfEU.stats.netWPM = Math.round((netWords / timeInMinutes) * 10) / 10;
        selfEU.stats.grossWPM = Math.round((totalWords / timeInMinutes) * 10) / 10;
        // Update how much time is remaining in line
        let timeElapsed_ms;
        if (sessionCompleteReason && sessionCompleteReason == 'Time expired') {
          timeElapsed_ms = selfEU.timer.duration * 1000;
        } else {
          timeElapsed_ms = endTime - lineStartTime;
        }
        selfEU.stats.timeElapsed_ms = timeElapsed_ms;
        selfEU.stats.timeElapsedString = timeElapsedToString(timeElapsed_ms);
        // console.log(selfEU.stats);
        // console.log(selfEU._eventList);

        return;
      }

      // Called from addEvent on each keystroke.
      //   -> updates stats counters only (not calculated stats: netWPM, grossWPM, accuracy)
      function updateStatsCounters(target_key, key_pressed) {
        if (target_key === undefined && key_pressed === undefined) return;
        // update the count objects only if we are recording keypresses

        if (key_pressed === 'Unidentified') {
          BugsnagNotify.notify(new Error('Unidentified key pressed'), (event) => {
            event.context = 'Typing Input Service - Unidentified key pressed';
            event.addMetadata('eventList', selfEU._eventList);
            event.addMetadata('events', selfEU.events);
          });
        }

        // nonBackspacedChars is an array of all characters that were not backspaced over (ie erased)
        // If backspace is enabled, it's pressed, and we have a character to backspace
        if (
          selfEU.enableBackspace &&
          (key_pressed === 'Backspace' || key_pressed === 'backspace') &&
          nonBackspacedChars.length > 0
        ) {
          var popped = nonBackspacedChars.pop();

          // Decrement totalNonbackspacedChars, and one of correctNonbackspacedChars or errorsNonbackspaced
          if (popped.key_pressed !== 'Backspace' && popped.key_pressed !== 'backspace') {
            selfEU.stats.totalNonbackspacedChars--;
            if (popped.key_pressed === popped.target_key) {
              selfEU.stats.correctNonbackspacedChars--;
            } else {
              selfEU.stats.errorsNonbackspaced--;
            }
          }

          // If backspace is disabled we treat it as any other key. If it's enabled, we don't run this code
        } else if (!selfEU.enableBackspace || (key_pressed !== 'Backspace' && key_pressed !== 'backspace')) {
          // Increase total chars, and one of:
          // (correctChars & correctNonbackspacedChars) or
          // (errors & errorsNonbackspaced)
          selfEU.stats.totalChars++;
          selfEU.stats.totalNonbackspacedChars++;
          nonBackspacedChars.push({ target_key: target_key, key_pressed: key_pressed });
          if (key_pressed === target_key) {
            selfEU.stats.correctChars++;
            selfEU.stats.correctNonbackspacedChars++;
          } else {
            selfEU.stats.errors++;
            selfEU.stats.errorsNonbackspaced++;
          }
        }
        return true;
      }

      // used by addEvent method to calculate times
      // e is the new raw JS event, lastEvent is the last raw JS event
      function calculateEventData(e, lastEvent) {
        // supports addEvent
        // time between keys

        let time_between_keys = lastEvent ? e.performanceNowInt - lastEvent.performanceNowInt : null;

        // ----- UNCOMMENT TO LOG LONG KEYSTROKES IN BUGSNAG
        // Trying to catch an elusive error where time_between_keys is negative
        // if (time_between_keys < 0 || time_between_keys > 71000) {
        //   const errorMsg = time_between_keys < 0 ? 'negative' : 'greater than 1 minute';

        //   BugsnagNotify.notify(new Error(`${selfEU.activity_type}: Time between keystrokes is ${errorMsg}`), (event) => {
        //     event.context = 'Typing Input Service - calculateEventData()';
        //     event.addMetadata('raw_event_data', e); // capture raw event data
        //     event.addMetadata('other_data', {
        //       lastKeyPressedAt: lastKeyPressedAt,
        //       time_between_keys: time_between_keys,
        //     });
        //     event.addMetadata('keystrokes', {
        //       keystrokes: selfEU.events,
        //     });
        //     event.addMetadata('eventList', { eventList: selfEU._eventList });
        //   });
        // }

        lastKeyPressedAt = e.timeStampAdjusted;

        if (lineStartTime === null) {
          lineStartTime = e.performanceNowInt;
        }

        // get keys. convert divider -> " "
        var target_key = lineOfText[digitIndex] === selfEU.view.divider ? ' ' : lineOfText[digitIndex];
        var previous_key = digitIndex > 0 ? lineOfText[digitIndex - 1] : null;
        previous_key = previous_key === selfEU.view.divider ? ' ' : previous_key;
        var key_pressed = e.key_pressed;

        // normalize nasty characters that cause issues
        if (badKeysToTransform[target_key]) {
          target_key = badKeysToTransform[target_key]; // transform if crazy key
        }
        if (badKeysToTransform[previous_key]) {
          previous_key = badKeysToTransform[previous_key]; // transform if crazy key
        }
        if (badKeysToTransform[key_pressed]) {
          key_pressed = badKeysToTransform[key_pressed]; // transform if crazy key
        }

        return {
          target_key: target_key,
          key_pressed: key_pressed,
          previous_key: previous_key,
          time_between_keys: time_between_keys,
          // Note: performanceNowInt is not saved. It's filtered out when we
          // reformat the array of event objects.
          performanceNowInt: e.performanceNowInt,
        };
      }

      // used by addEvent
      function isCorrectKey(eventData) {
        // gets the correct key from the line of text
        var correct_key = eventData.target_key;

        if (correct_key === selfEU.view.divider) {
          correct_key = ' ';
        }
        errorOnKey = eventData.key_pressed !== correct_key; // errorOnKey used in view helper functions
        updateErrorClass(errorOnKey);

        return !errorOnKey;
      }
      // -------------------------------------------------------------------------------------------
      // End of Stats trackers ---------------------------------------------------------------------
      // -------------------------------------------------------------------------------------------

      // -------------------------------------------------------------------------------------------
      // View Helpers ------------------------------------------------------------------------------
      // -------------------------------------------------------------------------------------------

      // These functions add and remove classes from the view
      function splitWordsIntoLines(words) {
        // var words = ["It ", "is ", "a ", "long ", "established ", .., "end."]
        var lines = [[]];
        var lineCount = 0;
        var charCount = 0;

        // Maps words into lines [["matt ", "is ", ...], ["here ", "and "], ...]
        // Split at either a line end (based on number chars per line) or a previous enter key char
        for (let i = 0; i < words.length; i++) {
          if (
            // maxCharsPerLine is generally 52 (set above)
            charCount + words[i].length <= selfEU.view.maxCharsPerLine &&
            words[i - 1] !== selfEU.view.enterDivider
          ) {
            // push the word.
            lines[lineCount].push(words[i]);
          } else {
            // add to next line. reset variables
            lineCount += 1;
            charCount = 0;
            if (lines[lineCount] === undefined) {
              lines[lineCount] = [];
            }
            lines[lineCount].push(words[i]);
          }
          charCount += words[i].length;
        }
        // Map each word into an array of objects: {letter: "a", index: 0}
        var charIndex = 0;
        selfEU.view.voiceover = getVoiceover(lines[0][0][0], null); // set the voiceover to the first letter
        for (let i = 0; i < lines.length; i++) {
          // each line
          for (var j = 0; j < lines[i].length; j++) {
            // Each word
            var wordObjectArray = [];
            for (var k = 0; k < lines[i][j].length; k++) {
              const previousCharacter = charIndex > 0 ? selfEU.view.lineOfTextObjects[charIndex - 1].letter : null;
              // Each letter
              var letter = {
                letter: lines[i][j][k],
                index: charIndex,
                class: '',
                voiceover: getVoiceover(lines[i][j][k], previousCharacter),
              };
              wordObjectArray.push(letter);
              selfEU.view.lineOfTextObjects.push(letter); // used to add/remove classes
              charIndex++;
            }
            // Overwrite word with object array
            lines[i][j] = wordObjectArray;
          }
          // Record the first index, so that we can hide/show lines
          lineCharIndex.push({
            firstIndex: lines[i][0][0].index,
            lastIndex: lines[i][j - 1][k - 1].index,
          }); // [{0, 78}, {79, 158}, ...]
        }
        selfEU.view.lineOfTextObjects[0].class = 'active';
        return lines; // maps to selfEU.view.linesOfText
      }

      // More helpers
      function isLineFinished() {
        var outOfText = digitIndex > lineOfText.length - 1;
        var outOfTime = useCountdownTimer ? selfEU.timer.countdown <= 0 : false;
        return outOfText || outOfTime; // || outOfTime; // ie line is finished if there's no more text or time
      }
      this.showLine = function (lineNum) {
        if (activeLines.length === 0) {
          for (let i = 0; i < linesToDisplay; i++) {
            activeLines.push(i);
          }
        }
        var activePosition = activeLines.indexOf(lineNum);
        if (activePosition > -1) {
          return true;
        }
        return false;
      };
      function nextChar() {
        digitIndex++;
        updateActiveClass();
        updateActiveLine();
        return;
      }
      function removeActiveClass(i) {
        selfEU.view.lineOfTextObjects[i].class = selfEU.view.lineOfTextObjects[i].class.replace('active', '').trim();
      }

      function updateErrorClass(error) {
        // adds classes to DOM
        var letterClass = selfEU.view.lineOfTextObjects[digitIndex].class;
        if (error) {
          if (letterClass.indexOf('pressed-incorrect') > -1) {
            return;
          }
          letterClass = letterClass.replace('pressed-correct', '');
          letterClass += ' pressed-incorrect';
        } else {
          if (letterClass.indexOf('pressed-correct') > -1) {
            return;
          }
          letterClass = letterClass.replace('pressed-incorrect', '');
          letterClass += ' pressed-correct';
        }
        selfEU.view.lineOfTextObjects[digitIndex].class = letterClass;
        return;
      }
      function updateActiveClass() {
        if (selfEU.view.lineOfTextObjects[digitIndex - 1] !== undefined) {
          selfEU.view.lineOfTextObjects[digitIndex - 1].class = selfEU.view.lineOfTextObjects[digitIndex - 1].class.replace(
            'active',
            ''
          );
        }
        if (selfEU.view.lineOfTextObjects[digitIndex] !== undefined) {
          selfEU.view.lineOfTextObjects[digitIndex].class += ' active';
        }
      }
      function updateActiveLine() {
        if (digitIndex > lineCharIndex[activeLine].lastIndex) {
          activeLine++;
          var lastSpot = activeLines.indexOf(activeLine) === activeLines.length - 1;
          var anotherLine = selfEU.view.linesOfText[activeLine + 1] !== undefined;
          if (lastSpot && anotherLine) {
            activeLines.push(activeLine + 1);
          }
          if (activeLines.length > linesToDisplay) {
            activeLines = activeLines.splice(1);
          }
        }
      }
      function isBackspace(e) {
        if (e.key_pressed === 'Backspace' || e.key_pressed === 'backspace') {
          // remove active class from current element
          removeActiveClass(digitIndex);

          // decrease digitIndex (if > 0)
          if (digitIndex > 0) {
            digitIndex--;
          }

          // remove error/pressed class from current key
          selfEU.view.lineOfTextObjects[digitIndex].class = '';

          // update active class
          updateActiveClass();
          return true;
        }
        return false;
      }

      this.lineComplete = function (reason) {
        if (runLineCompleteOnce) return;
        runLineCompleteOnce = true;
        if (useCountdownTimer) selfEU.timer.stop();
        // console.log("complete!");
        updateStats(true, reason);
        // calculate practice time. We add to the stats object so we can access in the view and
        // so it gets saved to the database. adds stats.practice_time_ms and stats.practice_time_str
        calculatePracticeTime(selfEU.events, selfEU.stats.accuracy);
        // round to the nearest second so we don't have "23.82 seconds" in the view
        calculateLearningRate(selfEU.stats.accuracy);

        // debugger;
        selfEU.onFinish();
      };
      // -------------------------------------------------------------------------------------------
      // End of view helpers -----------------------------------------------------------------------
      // -------------------------------------------------------------------------------------------

      // -------------------------------------------------------------------------------------------
      // Timer helpers -----------------------------------------------------------------------------
      // -------------------------------------------------------------------------------------------
      function timeToString(time) {
        // Hours, minutes and seconds
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = '';

        if (hrs > 0) ret += '' + hrs + ':' + (mins < 10 ? '0' : '');

        ret += '' + mins + ':' + (secs < 10 ? '0' : '');
        ret += '' + secs;
        return ret;
      }

      function timeElapsedToString(timeMs) {
        if (timeMs === 0) return '0 seconds';

        // time in seconds
        var time = timeMs / 1000.0;

        // Minutes and seconds
        var hrs = ~~(time / 3600); // ~~ is shortcut for Math.floor
        var mins = ~~((time % 3600) / 60);
        var secs = Math.round((time % 60) * 100) / 100.0;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = '';

        if (hrs > 0) ret += '' + hrs + (hrs > 1 ? ' hours' : ' hour');

        if (mins > 0) ret += (hrs > 0 ? ', ' : '') + mins + (mins > 1 ? ' minutes' : ' minute');

        if (secs > 0) ret += (hrs > 0 || mins > 0 ? ', ' : '') + secs + (secs > 1 ? ' seconds' : ' second');

        return ret;
      }
      function startTimer() {
        // Don't continue if timer is expired or contest is done
        // if (selfEU.timer.countdown <= 0) { console.log("here"); return; }

        // Always check if the contest is finished
        // if (logic.isContestFinished('Time has expired')) { return; }

        // Normalize our timer to account for computational delays

        // If there's no lineStartTime, then we're likely calling this without a keystroke
        //  wait a second, kick off the timer.
        if (!lineStartTime) {
          lineStartTime = Math.floor(performance.now());
          selfEU.timer.timerRef = $timeout(selfEU.timer.start, 1000);
          selfEU.onStart();
          return;
        }

        const performanceNowInt = Math.floor(performance.now());
        var timeSinceStart = performanceNowInt - (lineStartTime || performanceNowInt);
        var timeCounted = (selfEU.timer.duration - selfEU.timer.countdown) * 1000;
        var timeDiff = timeSinceStart - timeCounted - 1000; // should be close to zero
        var timeDiffInSec = Math.floor(timeDiff / 1000);
        // console.log(timeDiff);

        // update the stats if option is true
        if (updateStatsOnTimer) {
          updateStats(true);
        }

        // Run optional parameter function
        selfEU.eachSecond();

        if (timeDiffInSec > 1) {
          // number of seconds that have passed, rounded down. should be 0 (unless something weird)
          selfEU.timer.decrease(timeDiffInSec);
          timeDiff = timeDiff - timeDiffInSec * 1000;
        } else {
          // this is just for the onscreen timer
          selfEU.timer.decrease(1); // decrease by 1 second as normal, unless it's the first second
        }

        if (selfEU.timer.countdown <= 0) {
          selfEU.lineComplete('Time expired');
          return;
        }

        // $scope.countdownTimerString = $scope.timer.countdownString; // not sure if this will work
        selfEU.timer.timerRef = $timeout(startTimer, 1000 - timeDiff);
      }
      // -------------------------------------------------------------------------------------------
      // End of timer helpers ----------------------------------------------------------------------
      // -------------------------------------------------------------------------------------------

      /**
       * Uses keystroke data to calculate practice time in ms.
       * Some use cases this function should protect against:
       * 1. a user holds down the space key
       * 2. a user types a few keys then let's the timer run for 10 minutes
       */
      function calculatePracticeTime(events, accuracy) {
        // return 0 if we have no events or a single event
        // if the overall accuracy is below this threshold return 0;
        if (events.length < 2 || accuracy < 80) {
          selfEU.stats.practice_time_ms = 0;
          selfEU.stats.practice_time_str = timeElapsedToString(0);
          return selfEU.practice_time_ms;
        }

        // remove any keystrokes with a delay of more than 3 seconds. sum.
        selfEU.stats.practice_time_ms = events
          .filter((keystroke) => keystroke.time_between_keys < 3000)
          .reduce((previous, current) => previous + current.time_between_keys, 0);

        const practiceTimeRounded = Math.ceil(selfEU.stats.practice_time_ms / 1000.0) * 1000;
        selfEU.stats.practice_time_str = timeElapsedToString(practiceTimeRounded);

        return selfEU.practice_time_ms;
      }

      /**
       * Uses accuracy to assign a learning rate
       * @param {number} accuracy  - used to determine letter grade e.g. B+
       * @returns {string} - letter grade e.g. B+
       */
      function calculateLearningRate(accuracy) {
        let grade = '';

        if (accuracy < 95) {
          grade = 'F';
        } else if (accuracy < 96) {
          grade = 'D-';
        } else if (accuracy < 96.5) {
          grade = 'D';
        } else if (accuracy < 96.9) {
          grade = 'D+';
        } else if (accuracy < 97.3) {
          grade = 'C-';
        } else if (accuracy < 97.7) {
          grade = 'C';
        } else if (accuracy < 98) {
          grade = 'C+';
        } else if (accuracy < 98.3) {
          grade = 'B-';
        } else if (accuracy < 98.5) {
          grade = 'B';
        } else if (accuracy < 98.7) {
          grade = 'B+';
        } else if (accuracy < 98.9) {
          grade = 'A-';
        } else if (accuracy < 99.1) {
          grade = 'A';
        } else {
          grade = 'A+';
        }

        selfEU.stats.learning_rate = grade;
        return grade;
      }

      /**
       * Updates an aria-live span so that VoiceOver reads each character aloud.
       * Handles special cases like spaces and common punctuation marks.
       * If the character is the same as the previous character, adds a random number of null characters to the end of the returned value.
       *
       * @param {string} character - The character to be read by VoiceOver.
       * @param {string} previousCharacter - The preceding character.
       * @returns {string} - The character itself or a descriptive word for special characters.
       */
      function getVoiceover(character, previousCharacter) {
        let description;
        switch (character) {
          case selfEU.view.divider:
            description = 'space';
            break;
          case '\t':
            description = 'tab';
            break;
          case '\n':
            description = 'newline';
            break;
          case '.':
            description = 'period';
            break;
          case ',':
            description = 'comma';
            break;
          case '!':
            description = 'exclamation mark';
            break;
          case '?':
            description = 'question mark';
            break;
          case ';':
            description = 'semicolon';
            break;
          case ':':
            description = 'colon';
            break;
          case '-':
            description = 'dash';
            break;
          case '_':
            description = 'underscore';
            break;
          case '/':
            description = 'slash';
            break;
          case '\\':
            description = 'backslash';
            break;
          case '(':
            description = 'left parenthesis';
            break;
          case ')':
            description = 'right parenthesis';
            break;
          case '[':
            description = 'left bracket';
            break;
          case ']':
            description = 'right bracket';
            break;
          case '{':
            description = 'left brace';
            break;
          case '}':
            description = 'right brace';
            break;
          case '"':
            description = 'double quote';
            break;
          case "'":
            description = 'single quote';
            break;
          case '&':
            description = 'ampersand';
            break;
          case '*':
            description = 'asterisk';
            break;
          case '@':
            description = 'at sign';
            break;
          case '#':
            description = 'hash';
            break;
          case '$':
            description = 'dollar sign';
            break;
          case '%':
            description = 'percent sign';
            break;
          case '^':
            description = 'caret';
            break;
          case '+':
            description = 'plus';
            break;
          case '=':
            description = 'equals';
            break;
          case '<':
            description = 'less than';
            break;
          case '>':
            description = 'greater than';
            break;
          default:
            description = character;
            break;
        }

        if (character === previousCharacter) {
          // Add a random number (1 to 5) of null characters to the end of the description
          const numNulls = Math.floor(Math.random() * 5) + 1;
          description += '\0'.repeat(numNulls);
        }

        return description;
      }

      /**
       * A wrapper function for $http call that incorporates multiple retries if request fails.
       * @param {object} options  - an object of config params to go into $http call
       * @param {object} bugsnagData - object of data that gets sent with a Bugsnag report (in this case the activity object)
       * @param {number} retryAttemptsLeft - number of attempts left. Since this function gets recursively update, with each subsequent call, this number decreases. If 0, this funciton returns a rejection promise
       * @param {object[]} resHistory - array of response objects (both error and success) that are returned by $http call
       * @returns - promise
       */
      function httpWithRetries(options, bugsnagData, retryAttemptsLeft = 3, resHistory = []) {
        // retry attempts maxed out, so return an error
        if (!retryAttemptsLeft) {
          BugsnagNotify.notify(new Error('Maximum attempts exceeded'), (event) => {
            event.context = 'Typing Input Service - save()';
            event.addMetadata('relevantData', bugsnagData);
            event.addMetadata('retryAttempts', {
              retryAttemptsLeft: retryAttemptsLeft,
              resHistory: resHistory,
            });
          });
          // return the last response that failed (array should be non-empty if in this block)
          return $q.reject(resHistory[resHistory.length - 1]);
        }

        console.log(`Save Attempt ${resHistory.length + 1}`);
        return $http(options)
          .then(function (res) {
            console.log(`Saved!`);
            // If previous failed attempts have been made,
            // want to report this to bugsnag, including the success response
            if (resHistory.length > 0) {
              BugsnagNotify.notify(new Error('Multiple failed attempts before a successful one'), (event) => {
                event.context = 'Typing Input Service - save()';
                // breaking this out into its own Bugsnag tab for readability in Bugsnag
                event.addMetadata('numRetries', {
                  totalAttempts: resHistory.length,
                  retryAttemptsLeft,
                });
                event.addMetadata('relevantData', bugsnagData);
                event.addMetadata('retryAttempts', {
                  retryAttemptsLeft: retryAttemptsLeft,
                  resHistory: [...resHistory, res],
                });
              });
            }
            return res;
          })
          .catch(function (err) {
            // Retry recursively sending the $http request again
            console.log(`Save attempts left (${retryAttemptsLeft - 1}/3)`);
            // if there's only one attempt left, let's wait 3s instead of 1.5s
            return $timeout(retryAttemptsLeft === 1 ? 3000 : 1500).then(function () {
              return httpWithRetries(options, bugsnagData, retryAttemptsLeft - 1, [...resHistory, err]);
            });
          });
      }

      return this;
    };
  },
]);
