app.factory('AnimatedLoginKeyboardFactory', [
  function () {
    // should be bonded to element id
    var AnimatedLoginKeyboard = function (element_id) {
      this.showKeyboard = false;
      this.stopKeyboard = false;

      // function to animate keyboard
      this.animate = function (delay) {
        this.showKeyboard = true;
        this.stopKeyboard = false;
        $(element_id).removeClass('stop-animation');

        if ($(element_id).length > 1) {
          console.warn('Warning: More than 1 keyboard detected in loading keyboard.');
        }

        var delay = delay || 180;
        var keepGoing = true;
        var charactersUntilSpace = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
        var loopCount = 0;
        var elementHidden = false;

        var animateRandomKey = function (delay, charCount, callback) {
          // Check whether to stop

          // Stop the animation if 5 loops have gone by and the element is still hidden
          if ($(element_id).length < 1) {
            loopCount++;
          }
          if (loopCount > 5) {
            elementHidden = true;
          }

          if (elementHidden || $(element_id).hasClass('stop-animation')) {
            keepGoing = false;
            return true;
          }
          // $(element_id).length < 1 ? console.log("nothing") : console.log("something");

          if (charCount == charactersUntilSpace) {
            random_key = $(element_id).find('#center-key');
            charCount = -1;
            charactersUntilSpace = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
          } else {
            var rows = ['.top-row', '.middle-row'];
            var random_row = rows[Math.floor(Math.random() * rows.length)];
            var keys = $(element_id).find(random_row).find('li');
            var random_key = keys[Math.floor(Math.random() * keys.length)];
          }
          charCount++;
          var rndDelay = Math.floor(Math.random() * (delay * 0.1 - -delay * 0.1 + 1)) - delay * 0.1;

          $(element_id).find(random_key).addClass('pressed');
          setTimeout(function () {
            $(element_id).find(random_key).removeClass('pressed');
            if (keepGoing) {
              setTimeout(function () {
                callback(delay, charCount, animateRandomKey);
              }, Math.floor(180 * 0.2));
            }
          }, delay + rndDelay);
        };
        return animateRandomKey(delay, 0, animateRandomKey);
      };
    }; // end of class definition

    return AnimatedLoginKeyboard;
  },
]);
