/* global SERVER_TIME_SYSTEM_TIME_DIFF */
import {
  dateInFuture,
  createFirebaseUpdateObjectForUsers,
} from '../../../../components/Navigation/Notifications/FirebaseNotificationHelpers';
import { getDatabase, ref, update, serverTimestamp } from 'firebase/database';

app.factory('Test', [
  'Permissions',
  '$q',
  function (Permissions, $q) {
    var Test = function (section, testSettings) {
      var self = this;
      this.db = getDatabase();
      this.section = section;
      this.duration = testSettings.duration; // "5"
      this.speed = testSettings.speed; // 60
      this.type = testSettings.type; // "netWPM"
      this.accuracy_metric = testSettings.accuracy_metric; // "accuracy" || "max_errors"
      this.accuracy = testSettings.accuracy; // 98
      this.max_errors = testSettings.max_errors; // 3
      this.max_attempts = testSettings.max_attempts; // "2"
      this.expiresAfterMinutes = testSettings.expiresAfterMinutes; // "20"
      // criteria is used to filter test results (ie for checkmarks)
      this.criteria = {
        duration: testSettings.duration,
        speed: testSettings.speed,
        type: testSettings.type,
        accuracy_metric: testSettings.accuracy_metric,
        accuracy: testSettings.accuracy,
        max_errors: testSettings.max_errors,
      };
      this.view = {
        promptUserToUpdateResults: false,
        updateResultsMessage: 'Press Enter To Update Test Results!',
        disableTestAllButton: true,
        testAllButtonMessage: 'Testing',
      };

      // test helpers -------------------------------------------------------------------------------
      // options should be: {studentIds: [123, 234]}   (an array of at least 1)
      // creates a notification for the student and returns: {notificationIds: [], studentIds: []}
      this.createNotification = function (options) {
        let studentIds = options.studentIds || [];

        if (studentIds.length < 1) return Promise.reject();

        // Notification object data to be created in firebase
        const message = {
          sender: self.section.instructor.first_name + ' ' + self.section.instructor.last_name,
          expiresOn: dateInFuture(0, 0, Number(self.expiresAfterMinutes), SERVER_TIME_SYSTEM_TIME_DIFF),
          createdAt: serverTimestamp(),
          message: 'created a new Timed Writing for you.',
          studentOnly: true,
          redirectState: 'app.keyboarding.supervised_timed_writing',
          active: true,
          showAlert: true,
          priority: 0,
        };

        const { updateObj, notificationIds } = createFirebaseUpdateObjectForUsers(studentIds, message);

        // we avoid promise chaining with firebase as it won't update the digest cycle
        update(ref(self.db), updateObj);
        return $q.resolve({
          notificationIds,
          studentIds,
        });
      };

      // args should contain:
      //  {notificationIds: [], studentsIds: []}  (must be same length)
      // creates an array of permissions and saves them
      this.createPermission = function (args) {
        var notificationIds = args.notificationIds;
        var studentIds = args.studentIds; // an array of student ids

        var p;
        var options;
        p = {
          userId: studentIds,
          sectionId: self.section.id,
          duration: { days: 0, hours: 0, minutes: Number(self.expiresAfterMinutes) },
          action: 'TimedWriting',
          notification_id: notificationIds,
        };
        options = {
          duration_in_seconds: Number(self.duration) * 60,
          speed: self.speed,
          accuracy_metric: self.accuracy_metric,
          type: self.type, // "netWPM" || "grossWPM"
          max_attempts: self.max_attempts,
        };
        // add either accuracy or max_errors to options object
        if (self.accuracy_metric === 'accuracy') {
          options.accuracy = Number(self.accuracy);
        } else if (self.accuracy_metric === 'max_errors') {
          options.max_errors = Number(self.max_errors);
        }
        p.options = options;
        console.log(options);

        // See PermissionService for documentation on the method below
        return Permissions.create_timed_writings(p);
      };

      this.disableTestingButton = function (student) {
        student.view.buttonDisabled = true;
      };

      this.enableTestingButton = function (student) {
        student.view.buttonDisabled = false;
        student.view.numTestsGiven = 0;
        student.view.buttonMessage = 'Give Test';
      };

      this.disableTestAllButton = function () {
        self.view.disableTestAllButton = true;
        self.view.testAllButtonMessage = 'Testing';
      };

      this.enableTestAllButton = function () {
        self.view.disableTestAllButton = true;
        self.view.testAllButtonMessage = 'Testing';
      };
    };

    // Give tests to students -----------------------------------------------------------------
    // Give a test to one student in a section
    Test.prototype.giveTest = function (student) {
      var self = this;
      // 2. Disable the button, change button's text
      this.disableTestingButton(student);

      // If all the students are testing, disable the 'Test All' button
      var studentsNotAssigned = this.section.students.filter(function (s) {
        return !s.view.buttonDisabled;
      });
      if (studentsNotAssigned.length === 0) {
        this.disableTestAllButton();
      }

      return this.createNotification({ studentIds: [student.id] })
        .then(this.createPermission)
        .then(function (res) {
          const newPermissions = res.data;
          self.view.enableTestAllSelectedButton = true; // enables the 'repeat' button
          addNewTimedWritingPermissionsToStudents([student], newPermissions);
          return res;
        })
        .catch(function (error) {
          this.enableTestingButton(student);
          throw error;
        });
    };

    // Gives a test to all students in a section
    //  note: students is an optional argument that works with 'testAllSelected'
    Test.prototype.testAll = function (students) {
      var self = this;
      // if students were provided, use those. Other wise, give a test to the remaining students.
      var studentsNotAssigned;
      if (typeof students !== 'undefined') {
        studentsNotAssigned = students;
      } else {
        studentsNotAssigned = this.section.students.filter(function (s) {
          return !s.view.buttonDisabled;
        });
      }
      var studentIds = studentsNotAssigned.map(function (student) {
        return student.id;
      });
      // 2. Disable the button, change button's text
      studentsNotAssigned.map(this.disableTestingButton);
      this.disableTestAllButton();
      // don't assign tests if no students
      if (studentsNotAssigned.length === 0) {
        return;
      }

      return this.createNotification({ studentIds: studentIds })
        .then(this.createPermission)
        .then(function (res) {
          const newPermissions = res.data;
          addNewTimedWritingPermissionsToStudents(studentsNotAssigned, newPermissions);
          self.view.enableTestAllSelectedButton = true; // enables the 'repeat' button
          return res;
        })
        .catch(function (error) {
          studentsNotAssigned.map(this.enableTestingButton);
          this.enableTestAllButton();
          throw error; // pass along
        });
    };

    // Attached to the repeat button beside 'test all'
    //  Clicking this button should give another test to all selected students
    Test.prototype.testAllSelected = function () {
      var self = this;

      self.view.disableTestAllAgainButton = true;
      var studentsWithTest = this.section.students.filter(function (s) {
        return s.view.numTestsGiven > 0;
      });
      return this.testAll(studentsWithTest).then(function () {
        self.view.disableTestAllAgainButton = false;
      });
    };

    // view helpers --------------------------------------------------------------------------------
    // Shows/Hides the update button when the filter criteria changes
    Test.prototype.testCriteriaChanged = function () {
      var t = this;
      var pT = this.criteria;

      if (
        t.duration == pT.duration &&
        t.speed == pT.speed &&
        t.type == pT.type &&
        t.accuracy == pT.accuracy &&
        t.accuracy_metric == pT.accuracy_metric &&
        t.max_errors == pT.max_errors
      ) {
        // nothing is different -> don't prompt user to update
        this.view.promptUserToUpdateResults = false;
      } else {
        // something is different -> show the update button
        this.view.promptUserToUpdateResults = true;
      }
    };

    // User creates a filter - update the previous test criteria
    Test.prototype.updateCriteriaFromTestParams = function () {
      this.criteria.duration = this.duration;
      this.criteria.speed = this.speed;
      this.criteria.type = this.type;
      this.criteria.accuracy_metric = this.accuracy_metric;
      this.criteria.accuracy = this.accuracy;
      this.criteria.max_errors = this.max_errors;
    };

    // When a user filters the gradesheet with new criteria, this updates the
    //  criteria in the Create Test modal
    Test.prototype.updateTestParamsFromCriteria = function () {
      this.duration = this.criteria.duration;
      this.speed = this.criteria.speed;
      this.type = this.criteria.type;
      this.accuracy_metric = this.criteria.accuracy_metric;
      this.accuracy = this.criteria.accuracy;
      this.max_errors = this.criteria.max_errors;
    };

    // Counts the number of tests given to each student
    //  requires an array of students
    function addNewTimedWritingPermissionsToStudents(students, newTimedWritingPermissions) {
      // this code powers the existing grade sheet
      students.map(function (student) {
        student.view.numTestsGiven = (student.view.numTestsGiven || 0) + 1;
        student.view.buttonMessage = 'x' + String(student.view.numTestsGiven) + ' Testing';
      });

      // this code powers the experimental timed writing grade sheet
      students.map(function (student) {
        // note: our statistics service pulls timed_writing_permissions into student.timed_writing_permissions
        // let's add all the new timed writing permissions to the existing ones
        const newPermissions = newTimedWritingPermissions.filter((p) => p.user_id === student.id);
        student.addTimedWritingPermissions(newPermissions);
      });
    }

    return Test;
  },
]);
