// Profile chart creator
app.service('ProfileChart', [
  '$rootScope',
  '$http',
  function ($rootScope, $http) {
    // Colours
    var blue = {
      // blue
      fillColor: 'rgba(54,171,227,0.4)',
      strokeColor: 'rgba(54,171,227,1)',
      pointColor: 'rgba(54,171,227,1)',
      pointStrokeColor: '#fff',
      pointHighlightFill: '#fff',
      pointHighlightStroke: 'rgba(54,171,227,0.8)',
    };
    var red = {
      // red 229,132,151
      fillColor: 'rgba(229,132,151,0.2)',
      strokeColor: 'rgba(229,132,151,0.6)',
      pointColor: 'rgba(229,132,151,0.6)',
      pointStrokeColor: '#fff',
      pointHighlightFill: '#fff',
      pointHighlightStroke: 'rgba(229,132,151,0.4)',
    };
    var black = {
      // red 229,132,151
      fillColor: 'rgba(0,0,0,0)',
      strokeColor: 'rgba(0,0,0,1)',
      pointColor: 'rgba(0,0,0,0)',
      pointStrokeColor: '#fff',
      pointHighlightFill: '#fff',
      pointHighlightStroke: 'rgba(0,0,0,1)',
    };

    var chartOptions = {
      // http://www.chartjs.org/docs/#getting-started-global-chart-configuration
      //Number - Radius of each point dot in pixels
      pointDotRadius: 2, // 3

      //Number - Pixel width of point dot stroke
      pointDotStrokeWidth: 2, // 1

      //Number - amount extra to add to the radius to cater for hit detection outside the drawn point
      pointHitDetectionRadius: 5,

      //Boolean - Whether to show a dot for each point
      pointDot: false,

      //Boolean - Whether to show labels on the scale
      scaleShowLabels: false,

      // Boolean - If we want to override with a hard coded scale
      scaleOverride: false,
      // ** Required if scaleOverride is true **
      // Number - The number of steps in a hard coded scale
      scaleSteps: 8,
      // Number - The value jump in the hard coded scale
      scaleStepWidth: 10,
      // Number - The scale starting value
      scaleStartValue: 0,
      // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container
      maintainAspectRatio: false,

      // animateScale: true,
      animationSteps: 200,

      // customTooltips: function(tooltip){ debugger; },
      // String - Template string for single tooltips
      multiTooltipTemplate: function (label) {
        // label = ChartElement {value: 93.27203976381915, label: "u", datasetLabel: undefined, x: 119.7228179606994, y: 199.0383655170
        // debugger;
        if (label.datasetLabel.indexOf('Speed') > -1) {
          // percentage
          return (Math.round(label.value * 100) / 100).toString();
        } else {
          return (Math.round((label.value / 500) * 10000) / 100).toString();
        }
        // return label.label + ': ' +
      },
    };

    this.radarChart = function (labelCallback, params) {
      var user_id = params.user_id;
      var section_id = params.section_id;
      var optional_section = '';
      if (section_id !== null && typeof section_id !== 'undefined') {
        optional_section = '/section/' + section_id;
      }

      var chart = {};
      chart.data = [[], []];
      chart.labels = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
      ];
      chart.series = ['Average Speed Per Letter', 'Error Rate per Letter'];
      chart.colours = [blue, red];
      chart.options = chartOptions;
      chart.options.customTooltips = labelCallback;

      return $http.get('/api/profile/' + user_id + optional_section + '.json').then(function (data) {
        // console.log(data);
        var overall_avg = data.data.overall_statistics.wpm;
        var overall_error_rate = 1 - data.data.overall_statistics.accuracy;

        // Minimum weight - Some weights, such as q, are 0.0008.
        // set to 0 if you don't want to use this.
        var min_weight = 0.0001;
        var min_sample_size = 3;

        // Find the maximum speed - we use this to normalize speeds.
        var max_wpm = calculateMaxSpeed();

        // Adds data to the chart object, dealing with scaling etc.
        //  for example, since error rates are so small, we scale them out to be proportional with speeds
        normalizeChartData();

        // calculate scores
        //
        // Scores are calculated as follows:
        //   1. Speed per letter is normlized between 0 and 1 linearly (1 is better)
        //   2. Accuracy per letter is normalized between 0 and 1 on a curve (1 is better)
        //   3. A score is computed by combinined the speed and accuracy score in a weighted fasion
        //   4. The average score is computed, each score is subtracted by the average in order to normalize around 0, and the scores are multiplied by a key frequence.
        //        This ensures that when multiple by key frequencies, low scores will get smaller, and big scores will get bigger (relatively)
        //   5. The best and worst key is computed based on the highest and lowest scores.
        //   6. a minimum sample size is used to ensure we only consider keys with a minimum sample.
        //
        var eligible_score_sum = 0;
        var eligible_score_count = 0;
        var eligible_score_avg = 0;
        for (var i = 0; i < chart.labels.length; i++) {
          var letter = chart.labels[i];
          var error_scale_factor = 2.0;
          var sample_size = data.data[letter]['sample_size'] || 0;
          var avg_wpm = data.data[letter]['avg_wpm'];
          var error_rate = data.data[letter]['error_rate'];
          var sample_size = data.data[letter]['sample_size'];

          // strongest and weakest letters - compute score
          // take the speed, and subtract 4 keystrokes required to correct the error
          var weight = data.data[letter]['weight'] > min_weight ? data.data[letter]['weight'] : min_weight;
          // weight = Math.sqrt(weight);

          if (avg_wpm > 0) {
            // debugger;
            // Transforms error rate, usually between 80-100% into a score from 0 to 1
            var error_delta = getScoreForErrorRate(error_rate);

            // Some of the numbers produced above are biasing things, so this just normalizes it:
            var error_delta_adj = Math.max(error_delta, 0.15);

            // Some of the larger weights were massively affecting things, such as the strongest letter is always 'e'
            var weight_adj = Math.min(weight, 0.05);

            // var speed_delta = (avg_wpm - overall_avg) / overall_avg;
            var speed_delta = avg_wpm / max_wpm;
            var score = speed_delta + error_delta_adj * 2;

            // data.data[letter]["error_delta"] = error_delta;
            data.data[letter]['error_delta_adj'] = error_delta_adj;
            data.data[letter]['speed_delta'] = speed_delta;
            data.data[letter]['weight_adj'] = weight_adj;
            data.data[letter]['score'] = score;

            if (data.data[letter].sample_size > min_sample_size) {
              eligible_score_sum = eligible_score_sum + score;
              eligible_score_count = eligible_score_count + 1;
            }
            // var error_adjustment = 4 * error_rate*error_rate/1000 * avg_wpm;
            // var score = (avg_wpm - error_adjustment - overall_avg) * weight;
            // data.data[letter]["avg_wpm"] = avg_wpm;
            // data.data[letter]["overall_avg"] = overall_avg;
            // data.data[letter]["error_adjustment"] = error_adjustment;
          } else {
            var score = 0;
          }

          data.data[letter]['score'] = score;
        }
        // calculate the average score or letters that have at least the min sample size
        eligible_score_avg = (eligible_score_sum * 1.0) / eligible_score_count;

        // Normalize scores. center them around 0. multiply by weight
        for (var i = 0; i < chart.labels.length; i++) {
          var letter = chart.labels[i];
          if (data.data[letter]['sample_size'] >= min_sample_size) {
            data.data[letter]['score'] =
              (data.data[letter]['score'] - eligible_score_avg) * data.data[letter]['weight_adj'] * 1000;
            // console.log(data.data[letter]["score"]);
            data.data[letter]['score_form'] =
              letter +
              ': (' +
              data.data[letter]['speed_delta'] +
              ' + ' +
              data.data[letter]['error_delta_adj'] +
              ' *2) *' +
              data.data[letter]['weight_adj'] +
              ' = ' +
              data.data[letter]['score'] +
              '         (normalization occurs after this calculation)';
            console.log(data.data[letter]['score_form']);
          }
        }

        // Determines the strongest and weakest letters based on score,
        //   and that are above the minimum sample size
        strongestWeakestLetters();

        // helper functions --------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        // Transforms an error rate into a score from [0, 1]
        function getScoreForErrorRate(error_rate) {
          // This equations normalizes the error rate to a percentage score.
          // Use this to calculate a score of learning effectiveness (i.e. take an average)
          // The points used to calculate this were: [error_rate, percentage_score]
          // [0, 1], [0.01, 0.9], [0.03, 0.8], [0.04 ,0.7], [0.05, 0.6], [0.1, 0.3], [0.2, 0.1], [0, 0]
          return -0.01225674 + 0.97894584 / (1 + Math.pow(error_rate / 0.06672959, 1.818456));
        }

        function calculateMaxSpeed() {
          var max_wpm = 0;
          var letter_data;
          for (var i = 0; i < chart.labels.length; i++) {
            letter_data = data.data[chart.labels[i]];
            if (letter_data.avg_wpm && letter_data.avg_wpm > max_wpm && letter_data.sample_size >= min_sample_size) {
              max_wpm = letter_data.avg_wpm;
            }
          }
          return max_wpm;
        }

        // Adds data to the chart object, and takes care of scaling issues
        function normalizeChartData() {
          for (var i = 0; i < chart.labels.length; i++) {
            var letter = chart.labels[i];
            var error_scale_factor = 2.0;
            var sample_size = data.data[letter]['sample_size'] || 0;

            // This code normalizes the data for the chart
            if (data.data[letter] === undefined || sample_size === undefined || sample_size === 0) {
              var avg_wpm = 0;
              var error_rate = 0;
              console.log('no data for letter: ' + letter);
            } else {
              var avg_wpm = data.data[letter]['avg_wpm'];
              var error_rate = data.data[letter]['error_rate'] * 100 * error_scale_factor;
            }

            chart.data[0].push(avg_wpm);
            chart.data[1].push(error_rate);
          }
        }

        // Determine the strongest and weakest letters
        function strongestWeakestLetters() {
          var strongest_letter = { letter: '', wpm: 0, error_rate: 0, score: -Infinity };
          var weakest_letter = { letter: '', wpm: 0, error_rate: 0, score: Infinity };
          for (var i = 0; i < chart.labels.length; i++) {
            var letter = chart.labels[i];
            var sample_size = data.data[letter]['sample_size'] || 0;
            var score = data.data[letter]['score'];
            var avg_wpm = data.data[letter]['avg_wpm'];
            var error_rate = data.data[letter]['error_rate'] * 100 * error_scale_factor;

            // must have at least two data points
            if (score > strongest_letter.score && sample_size >= min_sample_size) {
              strongest_letter.wpm = Math.round(avg_wpm * 100) / 100;
              strongest_letter.letter = letter;
              strongest_letter.error_rate = Math.round((error_rate / 100 / error_scale_factor) * 10000) / 10000;
              strongest_letter.score = score;
            }
            if (score < weakest_letter.score && sample_size >= min_sample_size) {
              weakest_letter.wpm = Math.round(avg_wpm * 100) / 100;
              weakest_letter.letter = letter;
              weakest_letter.error_rate = Math.round((error_rate / 100 / error_scale_factor) * 10000) / 10000;
              weakest_letter.score = score;
            }
          }

          // console.table(data.data);
          if (strongest_letter.score === -Infinity) strongest_letter.score = 0;
          if (weakest_letter.score === -Infinity) weakest_letter.score = 0;

          data.data.overall_statistics.wpm = parseFloat(Math.round(data.data.overall_statistics.wpm * 100) / 100).toFixed(2);
          data.data.overall_statistics.estimated_wpm = parseFloat(
            Math.round(data.data.overall_statistics.wpm * 0.9 * 100) / 100
          ).toFixed(2);
          data.data.overall_statistics.accuracy = parseFloat(
            Math.round(data.data.overall_statistics.accuracy * 10000) / 100
          ).toFixed(2);
          chart.overall_statistics = data.data.overall_statistics;

          weakest_letter.error_rate = parseFloat(weakest_letter.error_rate * 100).toFixed(2);
          strongest_letter.error_rate = parseFloat(strongest_letter.error_rate * 100).toFixed(2);

          if (strongest_letter.wpm >= 100) {
            strongest_letter.wpm = parseFloat(strongest_letter.wpm).toFixed(1);
          }
          if (weakest_letter.wpm >= 100) {
            weakest_letter.wpm = parseFloat(weakest_letter.wpm).toFixed(1);
          }

          chart.weakest_letter = weakest_letter;
          chart.strongest_letter = strongest_letter;
        }

        return chart;
      });
    };
  },
]);
