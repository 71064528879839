import { angularStates } from '../../statesUrls';

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'AuthInterceptProvider',
  function ($stateProvider) {
    // var scrollToTop = [function () {
    //     window.scrollTo(0, 0); // scrolls to top of page
    //     return true;
    // }];

    $stateProvider
      // Temporary DEV state for locating the student list of assignments

      .state('course_builder', {
        url: angularStates['course_builder'],
        abstract: true,
        templateUrl: 'course_builder/_template.html',
        resolve: {
          user: [
            'Auth2',
            '$state',
            function (Auth2, $state) {
              return Auth2.currentUser().then(
                (user) => {
                  if (!user.admin) {
                    $state.go('app.home');
                  }
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          organizations: [
            '$http',
            function ($http) {
              return $http.get('api/organizations.json').then((res) => res.data);
            },
          ],
          allAssignments: [
            'CourseBuilderDataService',
            function (CourseBuilderDataService) {
              // returns an object specified in the service below
              return CourseBuilderDataService.getCourseWorkTemplates().then((res) => res.allAssignments);
            },
          ],
        },
      })

      .state('course_builder.new', {
        url: '/new',
        templateUrl: 'course_builder/course_builder_show_v2.html',
        controller: 'CourseBuilderControllerV2',
        resolve: {
          // must include this here, because this is being injected into CourseBuilderController regardless, and needs to have some value
          courseTemplate: [
            function () {
              return undefined;
            },
          ],
        },
      })

      .state('course_builder.index', {
        url: angularStates['course_builder.index'],
        templateUrl: 'course_builder/index/index.html',
        controller: 'CourseBuilderIndexController',
        resolve: {
          courseTemplates: [
            '$http',
            function ($http) {
              return $http.get('api/templates.json').then(function (res) {
                return res.data;
              });
            },
          ],
        },
      })

      .state('course_builder.show', {
        url: '/:id',
        params: {
          id: null,
          flashMessages: [],
        },
        templateUrl: 'course_builder/course_builder_show_v2.html',
        controller: 'CourseBuilderControllerV2',
        resolve: {
          courseTemplate: [
            '$stateParams',
            '$http',
            function ($stateParams, $http) {
              return $http.get(`api/templates/${$stateParams.id}.json`).then(function (res) {
                return res.data;
              });
            },
          ],
        },
      })

      // admin view ---------------------
      .state('admin_course_templates', {
        url: angularStates['admin_course_templates'],
        templateUrl: 'course_builder/course_templates_admin_index.html',
        controller: 'CourseTemplatesAdminController',
        resolve: {
          user: [
            'Auth2',
            '$state',
            function (Auth2, $state) {
              return Auth2.currentUser().then(
                function (user) {
                  // 141 = mattmcinnis
                  if (!user.admin) {
                    $state.go('app.home');
                  }
                  return user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
      });
  },
]);
