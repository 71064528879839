// Shows classlist (ie student names), allows instructors to give tests.
app.controller('StudentViewAsInstructorController', [
  '$scope',
  '$stateParams',
  'Classes',
  '$state',
  '$timeout',
  'CacheService',
  'LastNameCodeForm',
  '$http',
  function ($scope, $stateParams, Classes, $state, $timeout, CacheService, LastNameCodeForm, $http) {
    $scope.view = {
      disableTestCriteriaForm: true,
      showTransfer: false,
      showLoginInfo: false,
      transferSuccessMessage: '',
      transferErrorMessage: '',
      emailChange: {
        confirmation1: false, // first confirmation checkbox
        confirmation2: false, // second confirmation checkbox
        inProgress: false,
        errorMessage: '',
        successMessage: '',
      },
    };

    // Data models
    // Note, for use in ng-model, the value must be part of an object, so $scope.newEmail would not work
    $scope.emailChange = { newEmail: '' };

    // Structure:
    // A section has a code, students, and an instructor
    // Create the section object
    // $scope.section = CacheService.get($stateParams.code);
    $scope.$parent.section
      .onInit()
      .then(function () {
        // if the student has been transferred and is no longer in this section, return true.
        if ($scope.$parent === null) {
          return true;
        }
        $scope.section = $scope.$parent.section;

        // $scope.test = CacheService.get("currentTest" + $stateParams.code);
        $scope.student = $scope.section.getStudent($stateParams.student_id);
        // make a copy of lastNameCode for editing
        $scope.lastNameCode = $scope.student.last_name_code;
        // Retrieve more than the 5 requisite tests that are pulled in by the gradesheet.
        return $scope.student.getStatsForSection($scope.section, ['timed_writings'], { tw_limit: 200 });
      })
      // get the Document Production Information. These allow us to iterate through
      //  this student's course work
      .then(pullDocumentProductionInformation);

    // transfering the student

    function pullDocumentProductionInformation() {
      // If we don't have documentProduction, no need to do anything
      if (!$scope.section.hasDocumentProduction) return true;

      $scope.courseTemplate = $scope.section.course_template;
      // filter out for only the weeks with document production assignments
      $scope.courseTemplate.weeksWithDocProd = $scope.courseTemplate.structure.filter(function (week) {
        return (
          week.courseWork.filter(function (cw) {
            return cw.type === 'Document Production';
          }).length > 0
        );
      });
      // console.log($scope.courseTemplate);
      // console.log($scope.student);
    }

    $scope.setLastNameCode = function (lastNameCode) {
      $scope.view.setLastNameCodeInProgress = true;
      $scope.view.transferSuccessMessage = '';
      $scope.view.transferErrorMessage = '';

      return LastNameCodeForm.submit({ user_id: $scope.student.id, last_name_code: lastNameCode || null })
        .then(function () {
          // $scope.view.transferInProgress = false;
          $scope.view.lastNameCodeSuccessMessage = "Success! This student's last name code has been set.";
          $scope.student.last_name_code = lastNameCode;
          $scope.view.setLastNameCodeInProgress = false;
        })
        .catch(() => {
          const errorMsg =
            'Error: Could not update last name code. Please refresh your browser and try again, and if the problem persists please contact Typist Support.';
          $scope.view.setLastNameCodeInProgress = false;
          $scope.view.lastNameCodeErrorMessage = errorMsg;
        });
    };

    $scope.transferStudent = function (newSectionCode) {
      // The text input field has an uppercase style applied, but it doesn't affect the actual text fetched from the form
      // before using toUpperCase(), must ensure newSectionCode is a string
      var newSectionCodeUppercase = typeof newSectionCode === 'string' ? newSectionCode.toUpperCase() : newSectionCode;
      $scope.view.transferInProgress = true;
      $scope.view.transferSuccessMessage = '';
      $scope.view.transferErrorMessage = '';

      return Classes.transfer($scope.student.id, newSectionCodeUppercase, $scope.section.id)
        .then(function () {
          // $scope.view.transferInProgress = false;
          $scope.view.transferSuccessMessage =
            'Success! This student has been transferred. You may press Back in your browser to return to your gradesheet.';
          CacheService.clear();
          $scope.$parent.section.updateClasslist().then(function () {
            $scope.$parent.section.updateStatistics();
          });
        })
        .catch(function (data) {
          $scope.view.transferInProgress = false;
          $scope.view.transferErrorMessage = data.errors;
        });
    };

    $scope.changeStudentEmail = function () {
      $scope.view.emailChange.inProgress = true;
      $scope.view.emailChange.errorMessage = '';
      $scope.view.emailChange.successMessage = '';

      if ($scope.student.email === $scope.emailChange.newEmail) {
        $scope.view.emailChange.inProgress = false;
        $scope.view.emailChange.errorMessage = `Student's email was not changed. The new email is the same as the one currently in the system.`;
        return;
      }

      return $http
        .put(`/users/${$scope.student.id}/section/${$scope.section.id}/instructor_update_student_email`, {
          user: { email: $scope.emailChange.newEmail },
        })
        .then((res) => res.data)
        .then(function () {
          $scope.view.emailChange.successMessage = `Success! This student's email was changed.`;
          // If we need to properly update the parent scope objects, we should reload the page,
          // but since in the instructor view it's just one line that needs to change and it's for rendering purposes only,
          // we can just directly update the student email here
          $scope.student.email = $scope.emailChange.newEmail;
          $scope.view.emailChange.inProgress = false;
          // Reset the input field
          $scope.emailChange.newEmail = '';
        })
        .catch(function (res) {
          // Generating a generic message because the rails errors coming from the controller could be under different keys (eg. the one we send to bugsnag
          // which might have less meaningfull messages)
          // Also, our validation for whether instructor is in correct section returns a slightly different object
          $scope.view.emailChange.errorMessage = Array.isArray(res.data)
            ? res.data.join('<br/>')
            : `Could not change the student's email address.`;
          $scope.view.emailChange.inProgress = false;
        });
    };

    $scope.removeStudent = function (confirmation) {
      if (!confirmation || typeof confirmation !== 'string' || confirmation.trim().toUpperCase() !== 'REMOVE') {
        $scope.view.transferErrorMessage =
          'Did not remove student from section. Please read the instructions below carefully, as this action cannot be reverse.';
        return false;
      }

      $scope.view.transferInProgress = true;
      $scope.view.transferSuccessMessage = '';
      $scope.view.transferErrorMessage = '';

      return Classes.removeStudent($scope.student.id, $scope.section.id)
        .then(function () {
          // $scope.view.transferInProgress = false;
          $scope.view.transferSuccessMessage =
            'Success! This student has been removed from this class. You may press Back in your browser to return to your gradesheet.';
          CacheService.clear();
          $scope.$parent.section.updateClasslist().then(function () {
            $scope.$parent.section.updateStatistics();
          });
        })
        .catch(function (data) {
          $scope.view.transferInProgress = false;
          $scope.view.transferErrorMessage = data.errors;
        });
    };
  },
]);
