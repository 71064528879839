app.controller('ContactsDirectoryAboutController', [
  '$scope',
  'currentUser',
  function ($scope, currentUser) {
    // Only making the relevant information available
    $scope.view = {
      flashMessage: null,
    };
    // Angular model for the submission form
    $scope.nameSubmission = {
      firstName: currentUser.first_name,
      lastName: currentUser.last_name,
      agree: false,
    };

    // Function for submitting the name to the server
    $scope.submitName = function () {
      // Reset the flash messages
      $scope.view.flashMessage = null;

      // Make sure the names on the form were not modified
      if (
        $scope.nameSubmission.firstName === currentUser.first_name &&
        $scope.nameSubmission.lastName === currentUser.last_name
      ) {
        // TODO: submit to server
        console.log($scope.nameSubmission.first_name, ' ', $scope.nameSubmission.last_name);
        $scope.view.flashMessage = {
          message: 'Thank you! Your name has been submitted.',
          error: false,
        };
        // Uncheck the agree button
        $scope.nameSubmission.agree = false;
        // Scroll to top
        window.scrollTo(0, 0); // scrolls to top of page
      } else {
        $scope.view.flashMessage = {
          message: 'Error submitting name',
          error: true,
        };
        // Scroll to top
        window.scrollTo(0, 0); // scrolls to top of page
      }
    };
  },
]);
