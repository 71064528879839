// This directive focuses user on the destination element (specified by element's id).
// It also makes sure that tabbed navigation resumes after that element
// eg. <a href="#content" class="text-white fw-normal" jump-to-hash-link destination="content" prevent-scroll="true">Skip to main content</a>
app.directive('jumpToHashLink', [
  function () {
    return {
      restrict: 'A',
      scope: {
        // the string corresponding to the id of the element we should focus to
        destination: '@destination',
        // on focus, should the page scroll the target element into view or not
        preventScroll: '@preventScroll',
      },
      link: function (scope, element, _attrs) {
        element.on('click', function (event) {
          event.preventDefault();
          // https://docs.angularjs.org/api/ng/service/$anchorScroll
          // Set the location.hash to the id of the element you wish to scroll to.
          // $anchorScroll would monitor this variable and navigate to the passed in hash
          // however, this changes the url to something like '/#/profile#content' which works on initial click
          // but is a bit broken on refresh, so omitting this approach for now
          // $location.hash(scope.destination);

          // Additional functionality can be to add a scrollIntoView js call (turning off preventScroll in focus below)
          // since it gives more control, however because of our fixed positioned navbar, scrolling to the <main> element still
          // results in the focused element being overlapped

          const targetElement = document.getElementById(scope.destination);
          // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex#sect2
          // "If an HTML element renders and has tabindex attribute with any valid integer value, the element can be focused with JavaScript (by calling the focus() method) or visually by clicking with the mouse"
          // "may be useful for elements that should not be navigated to directly using the Tab key, but need to have keyboard focus set to them"
          //    - meaning if we want to focus on a non-link/input element using JS, that element should have tabindex attribute.
          //      and the most appropriate value for our <main> tag is -1, since it should not be part of the normal tab navigation (when not using this directive to focus with JS)
          // This is required so that after focus on the #content tag (which is normally not navigated to with tab key because it is not a link),
          // user can keep pressing tab and move to the next element
          if (!targetElement.hasAttribute('tabindex')) {
            targetElement.setAttribute('tabindex', '-1');
          }
          targetElement.focus({ preventScroll: scope.preventScroll || false });
        });
      },
    };
  },
]);
