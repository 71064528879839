// Watches for user activity and properly destroys all timers
//
// After being instantiated:
//  -> Starts counting when 'lesson:started' is called
//  -> Updates a timestamp anytime a key is pressed
//  -> Sets an interval (3s). Everytime interval runs, pauses stopwatch if user has been
//    inactive for more than 4 seconds.
//  -> (most importantly) binds everything to scope. When scope is destroyed, $interval and listeners are destroyed
//
// Usage:
// activeUserTimer = new ActiveUserTimerFactory();
//
// Stopwatch can be controlled directly:
// activeUserTimer.stopwatch.start();
//
// IMOPRTANT: If multiple instances of this class or created, they must be manually destroyed:
// activeUserTimer.destroy();
app.factory('ActiveUserTimerFactory', [
  '$rootScope',
  'StopwatchFactory',
  '$interval',
  function ($rootScope, StopwatchFactory, $interval) {
    function ActiveUserTimer(scope) {
      var self = this;
      this.stopwatch = new StopwatchFactory();

      var lessonStarted = false;
      var lessonStartedListener = scope.$on('lesson:started', function () {
        lessonStarted = true;
      });

      // interval detects pauses in user activity and pauses the timer.
      //  it is destroyed when with scope or when this.destroy() is called
      // Note: The interval loop time and time since last keystroke are different so that
      //  pausing is a little more accurate.
      var lastKeyStrikeTimestamp = 0;
      var intervalPromise = $interval(function () {
        // if more than 4 seconds have passed since a keystroke
        if (
          lastKeyStrikeTimestamp > 0 &&
          self.stopwatch.isRunning() &&
          Math.floor(performance.now()) > lastKeyStrikeTimestamp + 4000
        ) {
          console.log('pausing stopwatch');
          self.stopwatch.pause();
        }
      }, 3000);

      var intervalListener = scope.$on('$destroy', function () {
        $interval.cancel(intervalPromise);
      });

      // when a key is pressed, make sure this.stopwatch is running (to indicate active user behaviour)
      var keyupListener = scope.$on('keyup', function (a, e) {
        lastKeyStrikeTimestamp = Math.floor(performance.now()); // every 5 seconds, $interval checks when last activity was using this value
        if (!self.stopwatch.isRunning() && lessonStarted) {
          // console.log("starting2");
          self.stopwatch.start();
        }
      });

      // cleans up listeners, callbacks, intervals
      this.destroy = function () {
        // interval occurs every 5000 seconds and checks whether user has pressed a key during that time
        $interval.cancel(intervalPromise);
        // listens for a lesson being started
        lessonStartedListener();
        // makes sure the interval is destroyed if the scope is destroyed
        intervalListener();
        // called on keypress. updates a timestamp that's in the $interval to check whether use has been active in last 5000 ms
        keyupListener();
      };
    }

    return ActiveUserTimer;
  },
]);
