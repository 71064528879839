app.controller('AssignmentCreatorShowController', [
  '$scope',
  'documentAssignment',
  'Instruction',
  'markdownit',
  'user',
  'DocumentVariablesList',
  function ($scope, documentAssignment, Instruction, markdownit, user, DocumentVariablesList) {
    // md is used to convert markdown -> html
    md = markdownit;

    $scope.documentAssignment = documentAssignment;
    $scope.user = user;

    // Creates a new instruction.
    $scope.newInstruction = function (displayType) {
      // Get rid of the instruction if we already have one
      if ($scope.instruction && $scope.instruction.display_type === displayType) {
        $scope.instruction = null;
        return true;
      }

      // Find the maximum instruction number and make the new instruction
      //  one greater
      var maxNumber = 0;
      documentAssignment.instructions.forEach(function (el) {
        if (parseInt(el.number, 10) > maxNumber) maxNumber = parseInt(el.number, 10);
      });
      // here ... create a case statement to instantiate different types of instructions

      const generatedVariables = documentAssignment.generated_variables_for_user_section
        ? documentAssignment.generated_variables_for_user_section.variables
        : undefined;

      $scope.instruction = new Instruction(
        {
          document_assignment_id: documentAssignment.id,
          display_type: displayType,
          number: maxNumber + 1,
          options: {},
        },
        documentAssignment.country,
        generatedVariables,
        documentAssignment
      );
    };

    // watch textfields for markdown changes and re-render the HTML
    $scope.change = function (instruction) {
      instruction.contentHTML = md.render(instruction.content);
    };

    $scope.uploadImage = function (instruction, formSelector) {
      return instruction.uploadImage(formSelector).then(addInstructionToDocumentAssignment);
    };

    $scope.uploadAudioFile = function (instruction, formSelector) {
      return instruction.audioFile.upload(instruction, formSelector).then(addInstructionToDocumentAssignment);
    };

    $scope.updateInstruction = function (instruction) {
      // update the instruction and then make sure it exists in documentAssignments
      instruction.update().then(addInstructionToDocumentAssignment);
    };

    $scope.destroyInstruction = function (instruction) {
      $scope.documentAssignment.instructions;
      var id = instruction.id;
      var index = $scope.documentAssignment.instructions.indexOf(instruction);
      instruction.destroy().then(function () {
        if (index > -1) {
          $scope.documentAssignment.instructions.splice(index, 1);
        }
      });
    };

    // Helper function that adds the instruction to add a new Instruction to the Instructions array
    function addInstructionToDocumentAssignment() {
      var instruction = $scope.instruction;
      // only add if we're updating an assignment
      if (instruction === null || typeof instruction === 'undefined') return true;

      var id = instruction.id;
      var needToAddInstruction =
        $scope.documentAssignment.instructions.filter(function (inst) {
          return inst.id === id;
        }).length === 0;
      if (needToAddInstruction) {
        $scope.documentAssignment.instructions.push(instruction);
        // remove the new instruction
        $scope.instruction = null;
      }
    }

    $scope.insertKeypress = function (instruction, type) {
      instruction.content = instruction.content || '';

      if (type === 'keypress') instruction.content += '<span class="keyboard-key">A</span>';
      if (type === 'shift-enter')
        instruction.content +=
          '<span class="key-combination"><span class="keyboard-key">Shift</span>+<span class="keyboard-key">Enter</span></span>';
      if (type === 'user-select-none') instruction.content += '<span class="user-select-none">Type this in.</span>';
      if (type === 'docx-input') instruction.content += '<span class="docx-input">Type this in.</span>';
      if (type === 'hard-return-char') instruction.content += '¶';
      if (type === 'soft-return-char') instruction.content += '¶';

      return $scope.change(instruction);
    };

    $scope.markdownInstructions = [
      md.render('Rendered: Press the <span class="key">Shift</span> key.'),
      md.render('Rendered: Press the <span class="key">A</span> key.'),
      md.render(
        'Rendered: Press <span class="key-combination"><span class="key">Shift</span>+<span class="key">Enter</span></span> to create a manual return.'
      ),
      md.render('Rendered: At the bottom of your document, type the filename <span class="docx-input">myfile.docx</span>.'),
      md.render('Rendered: For this assignment, use the typist initials <span class="user-select-none">mwm</span>.'),
    ];
  },
]);
