// a unified object that provides default settings to different areas in the app for consistency
app.service('DefaultSettings', [
  function () {
    // Returns default test settings if they're not set
    this.accountSettings = function () {
      return {
        dyslexicMode: false,
      };
    };

    // Returns default test settings if they're not set
    this.testSettings = function () {
      return {
        duration: '3',
        speed: 45,
        type: 'netWPM',
        accuracy_metric: 'accuracy',
        accuracy: 98,
        max_errors: '3',
        max_attempts: '2',
        expiresAfterMinutes: '20',
      };
    };

    // Returns default classlist view settings if they're not set
    this.classlistViewSettings = function () {
      return {
        sortType: 'last_name',
        sortReverse: false,
        swapableColumnSelector: 'top_scores',
        swapableLessonCol0: 'basics1',
        swapableLessonCol1: 'basics2',
        documentProduction: {
          activeWeekIndex: 0,
          useTimeLimit: false, // prefer expiry date/time instead
          // Optional additional stats to display in the gradesheet table
          gradeDisplaySettings: {
            best: false, // will display the best score from all the submissions
            bestOfTwo: false,
            avgOfTwo: false,
            secondSubmission: false,
          },
          documentExpiresOn: {
            minute: '59',
            hour: '11',
            period: 'pm',
          },
          documentTimeLimit: {
            minutes: '110',
            hours: '0',
            days: '0',
          },
        },
        // Min value at which to display a striped bar in the daily practice bar graph in the gradesheet
        // overwritten by firebase or value in course_template
        minPracticeTimeMinutes: 30,
        // tracks whether the instructor has answered all questions
        testLinksTutorial: {
          q1: false,
          q2: false,
          q3: false,
          q4: false,
          collapsed: false,
        },
      };
    };

    return this;
  },
]);
