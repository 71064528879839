app.factory('ContactsList', [
  'ContactsDirectory',
  '$q',
  '$timeout',
  function (ContactsDirectory, $q) {
    // Class that loads a contacts directory and provides methods to
    // run custom operations on it (eg. filter for contacts by company and industry, etc)
    function ContactsList() {
      this.contactsDirectory = new ContactsDirectory();
      this.view = {
        loaded: false,
      };
    }

    // This function loads the contactsDirectory and returns a promise
    ContactsList.prototype.loadContactsDirectory = function (contactsDirectoryUrl, document_assignment_id) {
      // This snippet of code deals with the `Error: Invalid contactsList` that we kept getting
      // Currently, we are calling this function for all assignments, regardless if they use contacts directory or not
      // Here is a description of what happens:
      // 1. in assignment_creator_states when we reach assignment_creator.show, a new DocumentAssignment() is created
      // and .init(id) is called
      // 2. In the document assignment factory in the init method, we create a new instance of DocumentVariablesList (even for
      // assignments that does not have document variables defined, the instance is needed for forms, in case the user will want to
      // add new document variables
      // 3. in the document variables list factory (init method) we run loadContactsDirectory on an instance of ContactsList (i.e. this method)
      // It is here that the url we pass in is the property `contactsDirectoryUrl` which is null by default (this property is typically absent
      // from assignments that do not have a contacts directory widget, but since we still instantiate the contacts directory, we do so with a null value for url)
      // NOTE: a potential alternative solution is to just set a default contacts directory url for all assignments, but that could be confusing to see
      // a url on an assignment that doesnt use the directory
      // 4. In previous version of this code, the contactsDirectory.init threw and caught the error, logging the error in the console,
      // but it still kept going down the promise chain in this method and returned $q.reject() anyways
      // because self.view.errorLoadingContactsList was set to true in the ContactsDirectory
      // In conclusion, the reason for this write-up is because it was a pain to track this error down (a similar error pops up if
      // we ARE using a contacts directory but forgot to set the url)
      // and the goal was to make sure everything else behaves as before (because it works)
      // and we are just simply not getting the error message when it doesnt make sense to get it (only in the case
      // when there is no actual contact directory widget)
      if (!contactsDirectoryUrl) {
        this.contactsDirectory.view.errorLoadingContactsList = true;
        return $q.reject();
      }
      // Passing default value of empty string so that the http request doesn't fail due to a js error
      return this.contactsDirectory.init(contactsDirectoryUrl, document_assignment_id).then(() => {
        if (this.contactsDirectory.view.errorLoadingContactsList) {
          return $q.reject();
        }
        this.view.loaded = true;
        return $q.resolve();
      });
    };

    // This function generates a sorted and grouped list of all company names in the contacts directory for populating the dropdown menu
    // Returns an array of objects with company names (eg. [{value: 'Alanis Shaw', label: '(LEGAL) Alanis Shaw'}])
    ContactsList.prototype.generateInternalCompanyOptions = function () {
      const companiesAcc = {};

      // 1. Get all unique company name/industry combinations into an object. eg. {'Alanis Shaw': 'law_firm', }
      this.contactsDirectory.directoryListing.forEach((contact) => {
        if (contact.internal && !companiesAcc.hasOwnProperty(contact.company)) {
          companiesAcc[contact.company] = contact.industry;
        }
      });

      // 2. Convert the unique company/industry object to an array and sort
      return (
        Object.entries(companiesAcc)
          // Sort alphabetically
          .sort((a, b) => {
            const [companyA, _industryA] = a;
            const [companyB, _industryB] = b;
            if (companyA < companyB) return -1;
            if (companyA > companyB) return 1;
            return 0;
          })
          .map(([company, industry]) => {
            return {
              label: industry === 'law_firm' ? `(LEGAL) ${company}` : company,
              value: company,
            };
          })
      );
    };

    // This funcion generates a list of jobTitle names for populating the dropdown menu, based on other constraints (ie. industry)
    // If no constraints to filter by, default option is to return all jobTitles
    // Returns a sorted array of strings, eg. ['CEO', 'Assistant']
    // options object contains keys industry (string), companies (array, default []) and internal (boolean, default false)
    ContactsList.prototype.generateJobTitleOptions = function (options) {
      // If no options are provided, return a list of all positions as a default
      if (!options) {
        return uniqueArray(
          this.contactsDirectory.directoryListing
            .filter((contact) => contact.jobTitle)
            .map((contact) => contact.jobTitle)
            .sort()
        );
      }

      const { industry, companies = [], internal = false } = options;

      const allJobTitles = this.contactsDirectory.directoryListing
        .filter((contact) => {
          const companiesFilter = companies.length > 0 ? companies.includes(contact.company) : true;
          // Only filter using industries field if companies are not provided
          const industryFilter = industry && !companies.length ? contact.industry === industry : true;
          // Only filter using internal field if companies are not provided
          const internalFilter = companies.length > 0 ? true : contact.internal === internal;
          return contact.jobTitle && industryFilter && companiesFilter && internalFilter;
        })
        .map((contact) => {
          return contact.jobTitle;
        })
        .sort();
      return uniqueArray(allJobTitles);
    };

    // This function generates a list of Address Features used for populating the dropdown menu for a RandomContact
    // isCorporate is a boolean which determines which options to generate.
    // Note: this is not the full list of options in the dropdown menu (theres also the default value '' for 'Any' option)
    ContactsList.prototype.generateAddressFeaturesOptions = function (isCorporate) {
      if (isCorporate) {
        return [
          { label: 'No Unit or Suite', value: [] },
          { label: 'Has Suite', value: ['Suite'] },
          { label: 'Has Unit', value: ['Unit'] },
          { label: 'Has Suite or Unit', value: ['Unit', 'Suite'] },
        ];
      } else {
        return [
          { label: 'No Apartment', value: [] },
          { label: 'Has Apartment', value: ['Apt'] },
        ];
      }
    };

    // Goes through the contacts dir and gets a list of all industries used.
    // Returns a label/value array of objects used for populating the dropdown menu for industries
    // i.e. [{value: 'health_care_suppliers, label: 'Health Care Suppliers}, ]
    ContactsList.prototype.generateIndustryOptions = function () {
      const allIndustries = this.contactsDirectory.directoryListing
        .filter((contact) => contact.industry)
        .map((contact) => contact.industry);

      // Get a unique list of items in the array and add labels
      return uniqueArray(allIndustries).map((value) => {
        let label;
        switch (value) {
          case 'law_firm':
            label = 'Law Firm';
            break;
          case 'marketing':
            label = 'Marketing';
            break;
          case 'human_resources':
            label = 'Human Resources';
            break;
          case 'technology':
            label = 'Technology';
            break;
          case 'accounting':
            label = 'Accounting';
            break;
          case 'facilities':
            label = 'Facilities';
            break;
          case 'hotels_and_venues':
            label = 'Hotels and Venues';
            break;
          case 'health_care_suppliers':
            label = 'Health Care Suppliers';
            break;
          case 'banks':
            label = 'Banks';
            break;
          case 'construction':
            label = 'Construction';
            break;
          case 'office_suppliers':
            label = 'Office Suppliers';
            break;
          case 'realty':
            label = 'realty';
            break;
          case 'property':
            label = 'property';
            break;
          case 'insurance':
            label = 'insurance';
            break;
          default:
            // The dashes around the values is for manually
            // identifying any non-covered industry names
            label = `--${value}--`;
            break;
        }
        return {
          value,
          label,
        };
      });
    };

    // This function returns an array of contacts from this.contactsDirectory.directoryListing
    // filtered based on paramaters passed in. companies is an array of strings, internal boolean for internal/external company,
    // industry is a string, jobTitles is an array of strings, and nonCompany is a boolean that if true will filter on contacts
    // that do not belong to companies
    // addressFeatures is '' or an array of ['Suite', 'Apt', or 'Unit']
    ContactsList.prototype.filterContacts = function ({
      companies = [],
      internal = false,
      industry,
      jobTitles = [],
      nonCompany = false,
      addressFeatures,
    } = {}) {
      // Method 1 - a single filter
      return this.contactsDirectory.directoryListing.filter((contact) => {
        // Address features filter applies to both corporate and non-corporate contacts
        let addressFeaturesFilter;
        // If value is an array, means ONLY select addresses with address features in the array
        // eg. if ['Apt'], then only select addresses with Apt, if [], then select addresses without
        // any address features like 'Apt', 'Suite' or 'Unit'
        if (addressFeatures && Array.isArray(addressFeatures)) {
          if (addressFeatures.length > 0) {
            // filtering for addresses with at least one of the address features in the array
            const featureFound = addressFeatures.findIndex((addressFeature) => {
              if (!contact.apartmentSuite) return false;
              return contact.apartmentSuite.toLowerCase().includes(addressFeature.toLowerCase());
            });
            addressFeaturesFilter = featureFound > -1;
          } else {
            // filtering only for addresses without address features
            addressFeaturesFilter = !contact.apartmentSuite;
          }
        } else {
          // All addresses are ok
          addressFeaturesFilter = true;
        }

        // If none is selected for company, then just sample from individuals only
        if (nonCompany) {
          return !contact.company && addressFeaturesFilter;
        }

        // Otherwise, get filters for each type of randomization parameter
        // Make sure if companies is 'any', we are still filtering for contacts that have a company defined
        const companiesFilter = companies.length > 0 ? companies.includes(contact.company) : contact.company != null;
        // Only filter using industries field if companies are not provided
        const industryFilter = industry && !companies.length ? contact.industry === industry : true;
        // Only filter using internal field if companies are not provided
        const internalFilter = companies.length > 0 ? true : contact.internal === internal;
        const jobTitlesFilter = jobTitles.length > 0 ? jobTitles.includes(contact.jobTitle) : true;
        return companiesFilter && industryFilter && internalFilter && jobTitlesFilter && addressFeaturesFilter;
      });
    };

    /*-----------------
     * Helper functions
    --------------------*/

    // Returns the passed in array of items without duplicates.
    // Items in original array must be primitives, otherwise uniqueness is not guaranteed
    function uniqueArray(arrWithDups) {
      return [...new Set(arrWithDups)];
    }

    return ContactsList;
  },
]);
