// Provides an interface to join/unjoin, create/destroy classes
//   Note: this is an erb file so that lines complete MUCH faster in dev than production!
app.factory('LessonFactory', [
  'TypingInput',
  '$rootScope',
  'ActiveUserTimerFactory',
  '$interval',
  '$stateParams',
  function (TypingInput, $rootScope, ActiveUserTimerFactory, $interval, $stateParams) {
    function Lesson(lesson, scope) {
      var self = this;
      this.lesson = lesson;
      this.points = 0;
      // TODO Look at the ratio of lineFailures to lineSuccesses and figure out what it means (sort by ratio?)
      // TODO Could we give an alert to students who are failing lines too often?
      this.lineFailures = 0; // is increased if a penalty was assigned by calculatePoints()
      this.lineSuccesses = 0; // is increased if some errors were present in line
      this.linesPerfect = 0; // is increased if the line was typed perfectly
      this.last_practice_time_ms = 0;
      this.practice_time_ms = 0;
      var completionRequirements = calculateCompletionRequirements(lesson.nextLevel);
      var activeUserTimer = new ActiveUserTimerFactory(scope); // measures how long user is active

      // Create a timeout that will check every 10 secs and will restart a line if user has been inactive for too long
      const checkUserInactivityInterval = $interval(function () {
        const events = self.TypingInput.events;

        // Only reset line if user started typing already
        if (events.length > 0) {
          // Note this time is relative to start of document life, not system time
          const currentTime = Math.floor(performance.now());
          const lastEventTime = events[events.length - 1].performanceNowInt;

          // Checking progress bar width so that we don't reset a lesson while activity is saving
          if (currentTime - lastEventTime > 60000 && !(TypingInput.stats && TypingInput.stats.progressBarWidth >= 100)) {
            // note we only reset the current line, not the blue bar.
            self.TypingInput = newSession();
            self.TypingInput.view.resetProgressMessage = { text: 'Line was reset due to inactivity.', error: false };
          }
        }
      }, 10000);

      this.cancelCheckUserInactivityInterval = function () {
        $interval.cancel(checkUserInactivityInterval);
      };

      // Called after each line of text is complete
      this.lineFinished = function () {
        // 1st: Destoy $interval used for stopwatch
        // var timeInMS = stopwatch.pause().timeInMS();
        // console.log("time: " + timeInMS);

        // 2. Calculate points
        TypingInput.stats.points = calculatePoints(TypingInput, completionRequirements);
        self.points += TypingInput.stats.points;

        // 3. Track practice time
        self.practice_time_ms += TypingInput.stats.practice_time_ms;
        // console.log(self.practice_time_ms);

        TypingInput.stats.progressBarWidth = Math.min(100, Math.ceil((self.points / 100000.0) * 100));
        if (TypingInput.stats.progressBarWidth >= 100) {
          // Make sure to cancel the interval so that line does not get cleared right
          // before a save request to the server
          self.cancelCheckUserInactivityInterval();

          // stop the timer and destroy callbacks
          const timeInMS = activeUserTimer.stopwatch.pause().timeInMS();
          activeUserTimer.destroy();

          // add extra stats
          TypingInput.stats.lineFailures = self.lineFailures;
          TypingInput.stats.lineSuccesses = self.lineSuccesses;
          TypingInput.stats.linesPerfect = self.linesPerfect;

          // lesson complete
          TypingInput.save({
            // here, we add/overwrite attributes (time_in_ms) with more accuracy versions
            activity_id: self.lesson.id,
            duration_in_ms: timeInMS,
            practice_time_ms: self.practice_time_ms,
            data: {
              practice_time_ms: self.practice_time_ms,
              practice_time_str: timeElapsedToString(self.practice_time_ms),
              timeElapsed_ms: timeInMS, // overwrites default, since we need to aggregate multiple lines of text
              timeElapsedString: timeElapsedToString(timeInMS), // overwrites default for reason above
            },
          }).then(function () {
            $rootScope.$broadcast('lesson:lessonComplete', TypingInput.stats);
          });
          return;
        } else {
          // show another line
          $rootScope.$broadcast('lesson:lineComplete', TypingInput.stats);
          self.TypingInput = newSession();
        }
      };

      this.TypingInput = newSession();

      // private methods -------------------------------------------------------------------------------------

      // Returns a newly initialized TypingInput object
      function newSession() {
        var ti = TypingInput.newSession({
          activity_type: 'Lesson', // Used with save, autosave
          primarySpeedMetric: 'netWPM', // grossWPM || netWPM, affects view
          enableBackspace: false, // enable the backspace button?
          blockOnError: false, // stop cursor on an error?
          useCountdownTimer: false, // must call: TypingInput.timer.initialize( seconds ) before start
          updateStatsOnTimer: true, // update view stats every second?
          updateStatsOnKeystroke: false, // update view stats each keystroke?
          // Divider for the enter key ('Downwards Arrow with Corner Leftwards'), u21b5.
          // This should match the character that is returned by keyboard utilities
          // servce when enter key is pressed
          enterDivider: '↵',

          startIf: function () {
            return true;
          }, // start conditions
          onStart: function () {
            // run on start
          },
          eachSecond: function () {
            // if timer is enabled, this is run each second until expiry
            // if (TypingInput.timer.countdown%3 === 0) {
            // firebase.userStats().update({netWPM: TypingInput.stats.netWPM || 0, accuracy: TypingInput.stats.accuracy || 0});
            // }
          },
          onFinish: self.lineFinished, // callback once contest is complete (line of text finished, or time expired)
        });
        ti.updateLineOfTextFromString(randomStringFromWords(ti));
        // ti.updateLineOfTextFromString("matt");
        return ti;
      }

      // Calculates completion requirements
      function calculateCompletionRequirements(level) {
        // Assumes the student will finish an average of 10 WPM above requirement
        // THIS WAS 10
        var scaleFactor = 0; // wpm. Adds this to compensate for students going above minimum speed requirements
        // Points system:
        // a. If below WPM criteria OR accuracy criteria = 10% factor
        // b. If above in both:
        // var s_r = required speed(@bronze, silver or gold) + 10 WPM
        //
        // To finish a lesson: s_r (wpm) * # of minutes * 5 = # of characters required to finish a lesson
        //
        // Assume 100k points finishes a lesson.
        // a. For each line, calculate what % of the total required characters the line represents.
        // If they get exactly s_r and exactly the number of allowable errors = Give them the above % of the total points
        // Assign a 10% bonus for every 10 wpm or every extra error they didn't use.
        // TODO: We're manually multiplying the time by two! Update this attribute at the database level
        var minsRequired = (self.lesson.length_in_s * 2) / 60.0;
        var wpmRequired = self.lesson.requirements[level].speed + scaleFactor;
        var maxErrors = self.lesson.requirements[level].max_errors;
        var charsRequired = wpmRequired * minsRequired * 5;
        return { chars: charsRequired, wpm: wpmRequired, max_errors: maxErrors, mins: minsRequired };
      }

      // Idea:
      //  Calculate the percentage of keystrokes, based on required speed and how many characters were typed (with time 1 min),
      //    that were typed. Assume 100,000 points are required. So what is the max points they could have earned? (basePoints)
      //  The number of points received should be such that it takes between 30 seconds and 3 minutes to complete a line.
      //  If a student makes more than the maximum number of errors, they should receive 0 points.
      //  Compute points and return.
      // IN THE FUTURE:
      //  Ideally, this would take into account the proficiency typing each word. Graph it, and show calculate progress that way.
      function calculatePoints(TypingInput, requirements) {
        var stats = TypingInput.stats;
        var errors = stats.errors;
        var maxErrors = requirements.max_errors; // from lesson
        var netWPM = stats.netWPM;
        var targetWPM = requirements.wpm;

        // basePoints = 100,000 * % chars completed
        //   -> If a student earned this amount of points on each line, it would take them one minute to complete the drill
        var basePoints = Math.min(1, (TypingInput.view.lineOfTextObjects.length * 1.0) / requirements.chars) * 100000; // Should be 100k in production. Added this to make debugging faster

        // ADDED January 9th, 2019. This effectively doubles the lesson times
        basePoints = basePoints / 2;

        var debug = false;
        if (debug) {
          // console.log("# Errors vs Allowed Max: " + errors + ", " + maxErrors);
          // console.log("WPM vs Target WPM: " + netWPM + ", " + targetWPM);
          // console.log("Base Points to be earned (100k completes a line): " + basePoints);
        }

        // Errors modifier
        var points;
        if (errors > maxErrors + 1) {
          self.lineFailures += 1;
          // They should receive 0 points
          points = 0;
        } else if (errors > maxErrors) {
          self.lineFailures += 1;
          points = basePoints / 5.0; // Takes 5x longer (i.e. 3 minutes) to complete a line.
        } else if (errors > 0) {
          self.lineSuccesses += 1;

          if (netWPM >= targetWPM) {
            points = basePoints * 1.3; // Takes 1.3x less time (i.e. 45 seconds) to complete a line
          } else {
            points = basePoints / 2.0; // Takes 2x longer (i.e. 2 minutes) to complete a line
          }
        } else {
          self.linesPerfect += 1;

          if (netWPM >= targetWPM) {
            points = basePoints * 3.0; // Takes 3x less time (i.e. 20 seconds) to complete a line
          } else {
            points = basePoints; // Takes 1 minute to complete a line
          }
        }

        if (debug) {
          console.log('Points Earned (Base Points): ' + points + '(' + basePoints + ')');
        }

        return Math.ceil(points);
      }

      // Takes the array of lesson words ["jj", "ff", ...], scrambles them and then
      //  joins the words to form a string of text
      function randomStringFromWords(TypingInput) {
        // scramble the array
        var array = self.lesson.words;
        for (let i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }

        // First, we join all the words that are provided and if the
        //   length is still less than the max, we just use that.
        // Other wise, we take the first element in the array and continue adding words until it's above 45

        // Use enter divider char to join characters instead of the default empty string if dealing with numpad lessones
        // (hard coded, corresponding to level number 27)
        let joiningChar = parseInt($stateParams.level, 10) === 27 ? TypingInput.view.enterDivider : ' ';

        var lineOfText = array.join(joiningChar);
        // Maximum length for a line is 52 characters
        for (let i = 0; i < array.length; i++) {
          if (lineOfText.length < 52) break; // break if it's less than the max length anyways (ie small number of combinations)
          if (array.slice(0, i + 1).join(joiningChar).length > 45 /* 52 is max length */) {
            // expand only if it isn't huge, or if the first "word" is actually really big
            if (array.slice(0, i + 1).join(joiningChar).length > 65 && array.slice(0, i).join(joiningChar).length !== 0) {
              // if it's super big, don't expand
              lineOfText = array.slice(0, i).join(joiningChar);
            } else {
              lineOfText = array.slice(0, i + 1).join(joiningChar);
            }
            break;
          }
        }

        // Next, we make sure the line is at least 43 characters. If not, add random words from the original array
        var randomWord;
        var expandedLineOfText;
        while (lineOfText.length < 43) {
          randomWord = array[Math.floor(Math.random() * array.length)];
          // add a random word unless it would exceed 52 characters
          expandedLineOfText = lineOfText + joiningChar + randomWord;
          if (expandedLineOfText <= 52) {
            lineOfText = expandedLineOfText;
          } else {
            break;
          }
        }

        return lineOfText;
      }

      // borrowed from TypingInputService
      //  used here to overwrite calculated value in TypingInputService
      function timeElapsedToString(timeMs) {
        if (timeMs === 0) return '0 seconds';

        // time in seconds
        var time = timeMs / 1000.0;

        // Minutes and seconds
        var hrs = ~~(time / 3600); // ~~ is shortcut for Math.floor
        var mins = ~~((time % 3600) / 60);
        var secs = Math.round((time % 60) * 100) / 100.0;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = '';

        if (hrs > 0) ret += '' + hrs + (hrs > 1 ? ' hours' : ' hour');

        if (mins > 0) ret += (hrs > 0 ? ', ' : '') + mins + (mins > 1 ? ' minutes' : ' minute');

        if (secs > 0) ret += (hrs > 0 || mins > 0 ? ', ' : '') + secs + (secs > 1 ? ' seconds' : ' second');

        return ret;
      }
    }

    return Lesson;
  },
]);
