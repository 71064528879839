// TODO:
//  - Should display a message if the user has no sections
//  - Should display a message if the selected section doesn't have a course template (i.e. no tests available)
app
  .controller('TestCentreControllerMain', [
    '$scope',
    'proctoredTests',
    'PermissionsHelperFunctions',
    '$timeout',
    function ($scope, proctoredTests, PermissionsHelperFunctions, $timeout) {
      /************************
       *   Define constants (user `const` if using Babel)
       ***********************/
      // Constants to better keep constitency across the controller and views and to faster spot errors

      // Use one source of truth for constants. Later, preferably move to its own Angular provider so can be injected and used by any controller
      $scope.constants = PermissionsHelperFunctions.constants;
      $scope.instructionsExpanded = false;

      // Tests are represented in the database as two objects:
      //   1. Permission: This is created by the instructor. If an instructor creates a test for all his/her
      //                    students, each student will have a Permission object created for them. These contain
      //                    metadata such as the type of test, required speed, etc.
      //   2. Activity:   This is created when a student completes an attempt at a test. It contains information about
      //                    how well they did on the tests.
      //
      // Here, we reformat the permissions/activities for a user to pull out the relevant information.

      $scope.tests = PermissionsHelperFunctions.filterPermissionsByStatus(proctoredTests, $scope.constants.TEST_IN_PROGRESS);
      $scope.completedTests = PermissionsHelperFunctions.filterPermissionsByStatus(
        proctoredTests,
        $scope.constants.TEST_COMPLETE
      );

      var timer;
      proctoredTests.forEach(function (permission) {
        var msRemaining = new Date(permission.expires_on) - Date.now();
        if (msRemaining > 0) {
          timer = $timeout(function () {
            permission.status = $scope.constants.TEST_COMPLETE;
            // Had to put these in here too so that the view updates when one of these expires.
            $scope.tests = PermissionsHelperFunctions.filterPermissionsByStatus(
              proctoredTests,
              $scope.constants.TEST_IN_PROGRESS
            );
            $scope.completedTests = PermissionsHelperFunctions.filterPermissionsByStatus(
              proctoredTests,
              $scope.constants.TEST_COMPLETE
            );
          }, msRemaining);
          $scope.$on('$destroy', function () {
            console.log('killed timer');
            return $timeout.cancel(timer);
          }); // we need to cancel the timer if we go out of scope.
        }
      });

      $scope.deCamelizeTitle = PermissionsHelperFunctions.deCamelizeTitle;
    },
  ])
  .directive('testCardProgressBar', [
    '$interval',
    function ($interval) {
      function calculatePercentProgress(createdAt, expiresOn) {
        return ((expiresOn - Date.now()) / (expiresOn - createdAt)) * 100;
      }

      function calculateTimeLeft(expiresOn) {
        return expiresOn - Date.now();
      }

      function link(scope, element) {
        // current timer threshold (i.e. clock appears when <= 24 hours left)
        var timeCutoff = 60 * 60 * 24 * 1000; // 24 hours in milliseconds
        var updateProgressInterval = $interval(function () {
          var timeLeft = calculateTimeLeft(new Date(scope.expiresOn));
          scope.percentProgress = calculatePercentProgress(new Date(scope.createdAt), new Date(scope.expiresOn));
          scope.timeLeft = timeLeft;
          scope.timerActive = timeLeft <= timeCutoff;
        }, 1000);

        element.on('$destroy', function () {
          console.log('Killed interval timer');
          return $interval.cancel(updateProgressInterval);
        });
      }

      return {
        restrict: 'A',
        link: link,
        scope: {
          expiresOn: '=expiresOn',
          createdAt: '=createdAt',
        },
        templateUrl: 'test_centre/components/_test_centre_test_card_progress.html',
      };
    },
  ]);
