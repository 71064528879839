app.controller('NameEmailRegController', [
  '$scope',
  'AnimatedLoginKeyboardFactory',
  'NameEmailRegFactory',
  '$q',
  '$state',
  'ValidateObjectService',
  function ($scope, AnimatedLoginKeyboardFactory, NameEmailRegFactory, $q, $state, ValidateObjectService) {
    $scope.user = $state.params.user;
    $scope.parentView.showSubheading = false; // this is inheirited from parent scope. displays: "The typing trainer designed..."

    var debug = false;
    if (debug) stubUser();

    // Ensure the user object has the needed values
    ValidateObjectService.validate($scope.user, ['instructor', 'organization'], function () {
      return $state.go('app.home');
    });

    $scope.helpers = new NameEmailRegFactory();
    $scope.submitButton = $scope.helpers.view;
    $scope.keyboard = $scope.helpers.keyboard;

    // redirect to the next step
    $scope.submit = function (user) {
      // if (!user.instructor) {
      return $scope.helpers.validateNameAndEmail(user).then(function () {
        return $state.go('authentication.4', { user: user });
      });
    };

    function stubUser() {
      var t = Math.round(Math.random() * 10000);
      // username: "peterpan12" + t, password: "foobar1", passwordConfirmation: "foobar1",
      $scope.user = {
        instructor: false,
        organization: '2',
        firstName: 'Peter',
        lastName: 'Pan',
        email: 'debugemail@gmail.com' + t,
        confirmEmail: 'debugemail@gmail.com' + t,
      };
    }
  },
]);
