app.controller('InstrOrgRegController', [
  '$scope',
  'AnimatedLoginKeyboardFactory',
  'InstrOrgRegFactory',
  '$q',
  '$state',
  function ($scope, AnimatedLoginKeyboardFactory, InstrOrgRegFactory, $q, $state) {
    var debug = false; // change to true to develop each reg. state independently
    if (debug) stubUser();

    var helpers = new InstrOrgRegFactory();

    $scope.parentView.showSubheading = false; // this is inheirited from parent scope. displays: "The typing trainer designed..."

    // get the list of organizations
    helpers.getOrganizations().then(function (orgs) {
      $scope.organizations = orgs;
    });

    $scope.keyboard = new AnimatedLoginKeyboardFactory('#loading-keyboard-auth');

    // This filters out the organization, 'My Organization is Not List' which we will
    //    put as the last item in the view.
    //    had to make two of these since I couldn't negate the first one in the view
    $scope.filterMyOrganizationNotListed = function (item) {
      return item.id !== 8;
    };
    $scope.noOrganization = function (item) {
      return item.id === 8;
    };

    $scope.submit = function (user) {
      $scope.validations = {};

      helpers
        .localValidations(user)
        .catch(function (validations) {
          $scope.validations = validations;
          return $q.reject();
        })
        .then(function () {
          return $state.go('authentication.1', { user: user });
        });
    };

    function stubUser() {
      $scope.user = { instructor: true };
    }
  },
]);
