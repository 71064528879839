import { getDatabase, ref, onValue, off, update, serverTimestamp, get } from 'firebase/database';

// CompetitionController for Keyboarding Competition Participants
//  -> Allows user to start once the modals are satisfied, and the text is ready (see $q.all)
//  -> Modals are handled in CompetitionModalController
//  -> Updates user stats every 3 seconds to firebase
app.controller('CompetitionController', [
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  'TypingInput',
  '$timeout',
  '$q',
  'contest',
  'keyboardUtilities',
  function ($scope, $rootScope, $state, $stateParams, TypingInput, $timeout, $q, contest, keyboardUtilities) {
    // debugMode();
    // Ensure we have a competition
    if (!$stateParams.contest_id) $state.go('app.home');
    $scope.contest = contest;
    const db = getDatabase();

    var allowStart = false;
    const userStatsRef = ref(db, `games/contests/${$stateParams.contest_id}/participants/${$scope.user.id}`);

    // push the user details
    update(userStatsRef, { first_name: $scope.user.first_name, last_name: $scope.user.last_name });

    // watch for placement info (for results modal)
    onValue(userStatsRef, (snapshot) => {
      if (snapshot.val().placement && snapshot.val().placementSuffix) {
        // Adding a small timeout so that the loading animation does not flash too fast
        setTimeout(() => {
          $scope.$applyAsync(() => {
            $scope.placement = snapshot.val().placement;
            $scope.placementSuffix = snapshot.val().placementSuffix;
          });
        }, 700);
      }
    });

    // start the new session
    $scope.TypingInput = TypingInput.newSession({
      activity_type: 'Contest', // Used with save, autosave
      primarySpeedMetric: 'netWPM', // grossWPM || netWPM, affects view
      enableBackspace: true, // enable the backspace button?
      blockOnError: false, // stop cursor on an error?
      useCountdownTimer: true, // must call: TypingInput.timer.initialize( seconds ) before start
      updateStatsOnTimer: true, // update view stats every second?
      updateStatsOnKeystroke: false, // update view stats each keystroke?
      startIf: function () {
        return allowStart;
      }, // start conditions
      onStart: function () {
        // run on start
        update(userStatsRef, {
          started: serverTimestamp(),
          finished: false,
          updatedAt: serverTimestamp(),
        });
      },
      eachSecond: function () {
        // if timer is enabled, this is run each second until expiry
        if (TypingInput.timer.countdown % 3 === 0) {
          update(userStatsRef, {
            netWPM: this.stats.netWPM,
            grossWPM: this.stats.grossWPM,
            accuracy: this.stats.accuracy,
            updatedAt: serverTimestamp(),
          });
        }
      },
      onFinish: function () {
        // callback once contest is complete (line of text finished, or time expired)
        update(userStatsRef, {
          netWPM: this.stats.netWPM,
          grossWPM: this.stats.grossWPM,
          accuracy: this.stats.accuracy,
          finished: true,
          finishedAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        // postgresId is needed to build the relationship in the database
        if (!contest.postgresId) {
          throw 'Error in CompetitionController. No postgresId.';
        }
        $scope.TypingInput.save({ activity_id: contest.postgresId });
        // broadcast to the modal controller
        $rootScope.$broadcast('contestModals:showResults');
      },
    });

    // stop the timer if we leave the contest.
    $scope.$on('$destroy', () => {
      if ($scope.TypingInput) {
        $scope.TypingInput.timer.stop();
      }
      off(userStatsRef);
    });

    // initialize the timer
    $scope.TypingInput.timer.initialize(contest.durationInSeconds);

    $scope.$on('keydown', function (a, e) {
      // prevent default behaviour of space key while modals are active
      keyboardUtilities.blockScrollingWithSpaceKey(e);

      // Create the event log
      if (allowStart) $scope.TypingInput.addEvent(e); // Store the event
    });

    // Start Conditions ----------------------------------------------------------------------------
    // A user may start the competition when the following conditions are met:
    //  -> The text is downloaded and ready (TypingInput.updateLineOfTextFromUrl returns a promise)
    //  -> The user has not started the contest previously. Prevents refresh. (firebase?)
    //  -> (The contest was started more than 8 seconds earlier. Taken care of in modal controller).
    //  -> The modals are complete (broadcasted from modal controller)
    var textReady = $scope.TypingInput.updateLineOfTextFromUrl('contest/get_text/' + contest.postgresId);
    var modalsComplete = $q.defer();
    var firstTimeStarting = $q.defer();

    // just resolve the modals
    // eslint-disable-next-line no-unused-vars
    function debugMode() {
      modalsComplete.resolve();
      firstTimeStarting.resolve();
    }

    // start the contest once all conditions are met
    $q.all([modalsComplete.promise, textReady.promise, firstTimeStarting.promise]).then(function () {
      $timeout(() => document.getElementById('line-of-text').focus(), 0);
      allowStart = true;
      // give students one second to start, then start timer
      $timeout(function () {
        $scope.TypingInput.timer.start();
      }, 1000);
    });

    // Wait for the contest modals to complete
    $scope.$on('contestModals:complete', function () {
      modalsComplete.resolve();
    });

    // Don't allow a student to start if they have already
    get(userStatsRef).then((snapshot) => {
      if (snapshot.val().started) {
        firstTimeStarting.reject();
        console.log('student has already started or completed contest.');
      } else {
        firstTimeStarting.resolve();
      }
    });
  },
]);
