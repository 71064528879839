app.factory('AdminContactsDirectoryCompanies', [
  '$timeout',
  '$http',
  '$q',
  function ($timeout, $http, $q) {
    // Class Constructor for internal and external companies contacts directory (admin view)
    // Provides ability to sort contacts by company name, then by position
    // Expect an array of contacts to be passed in
    // Sort by industry will further sort the company names by industry -> company name -> city. Used by External companies
    // Internal companies will sort company names by company name -> city
    function ContactsDirectory({ contacts = [], sortByIndustry = false } = {}) {
      // assign all properties to self so that we can instantiate
      this.assignAllPropertiesToSelf({
        contacts,
      });

      // Variables affecting the view
      this.view = {
        // Variables used for visually tracking selections
        selectedCompanyName: null,
        selectedCityName: null,
        selectedPositionName: null,
        // Variables used for holding unique list of names
        companyNamesList: getUniqueValues(contacts, 'company'),
        positionNamesList: [],
        // Subset of contacts filtered by a parameter
        contactsFilteredByCompanyAndCity: [],
        companyAndCityContactsFilteredByPosition: [],
        // Custom view object for hierarchaly showing companies sorted by
        // Industry, company, and city branches. Used by external companies
        // e.g. {'human_resources': { 'ABC Company': ['Toronto', 'Ontario']}}
        nestedIndustryCompanyCityNamesList: [],
        // Custom view object for hierarchaly showing companies sorted by
        // Company, and city branches. Used by internal companies
        // e.g. { 'ABC Company': ['Toronto', 'Ontario']}
        nestedCompanyCityNamesList: [],
      };

      // This will create the nestedIndustryCompanyCityNamesList or nestedCompanyCityNamesList,
      // depending on boolean value of sortByIndustry (true for external companies, false for internal)
      // This will render a different nested structure in the first column of the contacts directory
      this.init(sortByIndustry);
    }

    // Class Methods

    // On click event when a company and city are selected.
    // Main purpose of function is to filter the original contacts list to those belonging to the company in a specific city
    // And generate a list of position names that are available at the given company
    // Since the list of companies is much longer than list of positions, if 3rd param is passed in, upon clicking
    // the browser should scroll to the element with that id
    ContactsDirectory.prototype.selectCompanyAndCity = function (companyName, cityName, scrollToAnchor) {
      // Reset previous selected state
      this.view.selectedPositionName = null;
      this.view.companyAndCityContactsFilteredByPosition = [];

      // save the current company selection name
      this.view.selectedCompanyName = companyName;
      this.view.selectedCityName = cityName;
      // Filter out the companies
      this.view.contactsFilteredByCompanyAndCity = this.contacts.filter(
        (contact) => contact.company === companyName && contact.city === cityName
      );
      // Now that company has been selected, updaate the list of position names at
      // given company
      this.view.positionNamesList = getUniqueValues(this.view.contactsFilteredByCompanyAndCity, 'jobTitle');

      // Scroll list of positions into view
      if (scrollToAnchor) {
        const scrollToElement = document.getElementById(scrollToAnchor);
        if (scrollToElement) scrollToElement.scrollIntoView();
        // Add an additional scroll due to header covering the top
        window.scroll(0, window.scrollY - 100);
      }
    };

    // On click event when a position is selected.
    // Main purpose of function is to filter the company filtered contacts list to those belonging to a chosen position
    ContactsDirectory.prototype.selectPositionFromCompanyContacts = function (positionName) {
      // save the current position selection name
      this.view.selectedPositionName = positionName;
      // Filter out the positions from the already filtered list of contacts belonging to a company
      this.view.companyAndCityContactsFilteredByPosition = this.view.contactsFilteredByCompanyAndCity
        .filter((contact) => contact.jobTitle === positionName)
        // Sort alphabetically
        .sort((a, b) => {
          if (a.lastName > b.lastName) {
            return 1;
          } else if (a.lastName < b.lastName) {
            return -1;
          } else {
            return 0;
          }
        });
    };

    // Perform some initial sorting of company names by industry and with city information
    ContactsDirectory.prototype.init = function (sortByIndustry) {
      // Make sure to attach it to the "scope" as a separate step, directly adding to the
      // keys directly does not appear to trigger Angular's digest cycle

      // Sorty by industry -> company -> city, or company -> city
      if (sortByIndustry) {
        this.view.nestedIndustryCompanyCityNamesList = this.createNestedIndustryCompanyCityNamesList();
      } else {
        this.view.nestedCompanyCityNamesList = this.createNestedCompanyCityNamesList();
      }
    };

    // Function for creating a 3 level nested object structure, reorganizing contacts by industry -> company -> cities
    // Returns a structure like this {'human_resources':{'Google': ['Toronto', 'Calgary']}}
    ContactsDirectory.prototype.createNestedIndustryCompanyCityNamesList = function () {
      // Original contacts list
      const contacts = this.contacts;
      let self = this;
      // Accumulator for the nested re-organizing of contacts based on industry, company name, and city
      let nestedIndustryCompanyCityNamesList = {};

      getUniqueValues(contacts, 'industry')
        .sort()
        .forEach((companyIndustry) => {
          // Initialize the first level
          nestedIndustryCompanyCityNamesList[companyIndustry] = {};
          const industryContacts = contacts.filter((contact) => contact.industry === companyIndustry);
          getUniqueValues(industryContacts, 'company')
            .sort()
            .forEach((companyName) => {
              // Get subset of contacts belonging to the company
              const companyContacts = contacts.filter((contact) => contact.company === companyName);
              nestedIndustryCompanyCityNamesList[companyIndustry][companyName] = getUniqueValues(companyContacts, 'city');
            });
        });
      return nestedIndustryCompanyCityNamesList;
    };

    // Function for creating a 2 level nested object structure, reorganizing contacts by company -> cities
    // Returns a structure like this {'Google': ['Toronto', 'Calgary']}
    ContactsDirectory.prototype.createNestedCompanyCityNamesList = function () {
      // Original contacts list
      const contacts = this.contacts;
      let self = this;
      // Accumulator for the nested re-organizing of contacts based on company name, and city
      let nestedCompanyCityNamesList = {};

      getUniqueValues(contacts, 'company')
        .sort()
        .forEach((companyName) => {
          // Get subset of contacts belonging to the company
          const companyContacts = contacts.filter((contact) => contact.company === companyName);
          nestedCompanyCityNamesList[companyName] = getUniqueValues(companyContacts, 'city');
        });
      return nestedCompanyCityNamesList;
    };

    // // assigns all properties retrieved from the server to self
    ContactsDirectory.prototype.assignAllPropertiesToSelf = function (props) {
      var self = this;
      for (var prop in props) {
        if (props.hasOwnProperty(prop)) {
          self[prop] = props[prop];
        }
      }
    };

    // Private class functions

    /*
     * Given an array of objects, return an array of all
     * possible values at obj[key] within the objects array
     * eg. getUniqueValues([{num: 1}, {num: 2}, {num: 1}], 'num') => [1,2]
     *     -> All the possible values of the 'num' property of the objects
     *        inside the array
     */
    function getUniqueValues(objectsArray = [], key) {
      let acc = {};
      objectsArray.forEach((obj) => {
        // only accept keys that exist on the object and have non-empty string values
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].length > 0) {
          acc[obj[key]] = obj[key];
        }
      });
      return Object.keys(acc);
    }

    return ContactsDirectory;
  },
]);
