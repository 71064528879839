/**
 * This function takes an array of document assignments and returns an array of unique category names,
 * sorted according to a predefined category order.
 *
 * @param {Array} documentAssignments - An array of document assignment objects. Each object should have a `category` property.
 * @returns {Array} - An array of unique category names, sorted by the predefined order and alphabetically for those not in the order.
 *
 * Example:
 * const documentAssignments = [
 *   { category: 'Tutorial - JavaScript' },
 *   { category: 'Business Letter - Sales' },
 *   { category: '2pg Business Letter - Marketing' },
 *   { category: 'Guide - Node.js' },
 *   { category: 'Tutorial - CSS' }
 * ];
 *
 * const sortedCategories = setDocumentAssignmentCategories(documentAssignments);
 * // sortedCategories will be:
 * // ['Tutorial - JavaScript', 'Tutorial - CSS', 'Business Letter - Sales', '2pg Business Letter - Marketing', 'Guide - Node.js']
 */
export default function sortDocumentAssignmentCategories(documentAssignments) {
  const categoryOrder = [
    'Introduction to Typist Documents',
    'Email Simulations',
    'Business Letter Tutorials: Date Line, Salutation',
    'Business Letter Tutorials: Inside Address (Part 1)',
    'Business Letter Tutorials: Inside Address (Part 2)',
    'Business Letter Tutorials: Inside Address',
    'Business Letter Tutorials: Closing and Signature Lines, Reference Initials and Filename',
    'Business Letter Tutorials: Enclosures and Copied Recipients',
    'Business Letter Simulations',
    'Memorandum Tutorials',
    'Memorandum Simulations',
    'Report Headers Tutorials',
    'Report Simulations',
    'Multi-Page Headers Tutorials',
    'Multi-Page Business Letter Simulations',
    'Business Personal Letter Simulations',
  ];
  const categories = [...new Set(documentAssignments.map((filter) => filter.category))];

  // Custom sort function
  return categories.sort((a, b) => {
    const indexA = categoryOrder.findIndex((order) => a === order);
    const indexB = categoryOrder.findIndex((order) => b === order);

    if (indexA === -1 && indexB === -1) {
      // console.warn('Document Assignment does not have one of the predefined categories: ', categoryOrder);
      return a.localeCompare(b);
    }
    if (indexA === -1) {
      return 1; // a is not in categoryOrder, so it should be ranked last
    }
    if (indexB === -1) {
      return -1; // b is not in categoryOrder, so it should be ranked last
    }
    return indexA - indexB;
  });
}
