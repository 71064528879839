/* global SERVER_TIME_SYSTEM_TIME_DIFF */

import { getDatabase, ref, update, serverTimestamp, push, child, set } from 'firebase/database';

import {
  dateInFuture,
  createFirebaseUpdateObjectForUsers,
} from '../../../components/Navigation/Notifications/FirebaseNotificationHelpers';

// Creates a new contest object
app.factory('Contest', [
  '$http',
  'Auth',
  '$rootScope',
  function ($http, Auth, $rootScope) {
    // pass a firebaseId to create from Firebase
    var Contest = function (data) {
      this.db = getDatabase();
      this.data = {};
      data = data || {};

      this.data.duration = data.duration || '3';
      this.data.type = data.type || 'netWPM';
      this.data.accuracy = data.accuracy || 0;
      this.data.status = data.status || 'observer';
      this.data.expiresAfterMinutes = data.expiresAfterMinutes || '20';

      this.firebaseContestRef = ref(this.db, `games/contests`);
      this.firebaseId = null;
    };

    // Saves data to firebase. Will overwrite by default
    Contest.prototype.set = function (section) {
      var self = this;
      self.section = section;
      self.section_id = section.id;

      // set the data, and then save it to firebase
      return Auth.currentUser()
        .then(function (user) {
          return {
            instructor: user.first_name + ' ' + user.last_name,
            user_id: user.id,
            section_id: self.section_id,
            durationInSeconds: self.data.duration * 60,
            type: self.data.type,
            accuracy: self.data.accuracy,
            expiresAfterMinutes: self.data.expiresAfterMinutes,
            imageUrl: 'https://placehold.it/80x80',
            startTime: null,
            started: false, // will get replaced by timestamp when instructor clicks 'start contest'
            finished: false,
            participants: [], // will not actually get set
            createdAt: String(new Date()),
          };
        })
        .then(function (data) {
          // push the object to firebase
          self.firebaseId = push(self.firebaseContestRef).key;
          set(child(self.firebaseContestRef, self.firebaseId), data);
          return self.firebaseId;
        })
        .then(function (firebaseId) {
          // save the object in postgres
          const practice_text_ids = self.data.practice_text_ids ? JSON.parse(self.data.practice_text_ids) : [];
          return $http.post('contest.json', {
            contest: { firebase_id: firebaseId, practice_text_ids },
          });
        })
        .then(function (contest) {
          // update this object
          self.code = contest.data.code;
          return contest.data;
        })
        .then(function (contest) {
          // update firebase object with code received from postgres
          return update(child(self.firebaseContestRef, self.firebaseId), { code: contest.code, postgresId: contest.id }).then(
            function () {
              return contest;
            }
          );
        });
    };

    // private methods ---------------------------------------------------------------------------------------
    Contest.prototype.createNotification = function (section, contest) {
      if (!section || !section.id) throw 'Error: section_id must be present to create notification';
      const studentIds = section.students.map((s) => s.id);
      // no notification needed if no students
      if (studentIds.length < 1) return true;

      // TODO: double check that createdAt is being created
      const message = {
        createdAt: serverTimestamp(),
        active: true,
        priority: 0,
        sender: $rootScope.user.first_name + ' ' + $rootScope.user.last_name,
        expiresOn: dateInFuture(0, 0, this.data.expiresAfterMinutes, SERVER_TIME_SYSTEM_TIME_DIFF),
        message: 'started a contest for you!',
        studentOnly: true,
        redirectState: 'app.keyboarding.competition',
        contestFirebaseId: contest.firebase_id,
      };

      const { updateObj } = createFirebaseUpdateObjectForUsers(studentIds, message);

      update(ref(this.db), updateObj);
    };

    return Contest;
  },
]);
