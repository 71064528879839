app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'AuthInterceptProvider',
  function ($stateProvider, $urlRouterProvider, AuthInterceptProvider) {
    var scrollToTop = [
      function () {
        window.scrollTo(0, 0); // scrolls to top of page
        return true;
      },
    ];

    // check if the user
    var registrationOnEnter = [
      'Auth2',
      '$state',
      function (Auth2, $state) {
        return Auth2.currentUser().then(function (user) {});
      },
    ];

    $stateProvider

      // Acts as the parent state for all states below.
      //  child states use the '.' operator. Great article here: https://github.com/angular-ui/ui-router/wiki/Nested-States-%26-Nested-Views
      .state('authentication', {
        // url: '/welcome/1',
        templateUrl: 'auth/auth.html',
        abstract: true, // this state will not have a url
        controller: 'AuthenticationParentController',
        onEnter: [
          'Auth2',
          '$state',
          function (Auth2, $state) {
            return Auth2.currentUser().then(function () {
              return $state.go('app.home');
            });
          },
        ],
      })

      .state('authentication.sign_in', {
        url: '/welcome',
        templateUrl: 'auth/sign_in/0/username_password.html',
        controller: 'SigninController',
      })

      // This state is called after a login or signup
      //    Note: It has no template or url
      .state('afterLogin', {
        params: {
          action: null,
          url_code: null,
        },
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$stateParams',
            function (Auth2, $state, $stateParams) {
              return Auth2.currentUser().then(
                function (user) {
                  // in certain cases we direct the user to a different page, such as if
                  //  they're using a test link
                  hardRedirectAfterLogin($stateParams);

                  if (user.instructor) {
                    $state.go('app.instructor.class');
                  } else {
                    if (user.sections && user.sections.length > 0) {
                      $state.go('app.home');
                    } else {
                      $state.go('app.settings/my_classes');
                    }
                  }
                },
                function () {
                  $state.go('app.home');
                }
              );
            },
          ],
          scrollToTop: scrollToTop,
        },
      })

      // account has expired
      .state('authentication.account_expired', {
        url: '/account_expired',
        templateUrl: 'auth/sign_up/payment/_create_account_payment.html',
        controller: 'AccountExpiredController',
        params: {
          userCredentials: null,
        },
        resolve: {
          // gets the pricing information for the particular school
          org: [
            '$stateParams',
            '$http',
            '$state',
            function ($stateParams, $http, $state) {
              // userCredentials: {username: "", password: ""}
              return $http
                .post('organization/get_id', $stateParams.userCredentials)
                .then(function (org) {
                  return org.data;
                })
                .catch(() => {
                  $state.go('app.home');
                });
            },
          ],
        },
      })

      // create account
      .state('authentication.0', {
        url: '/create_account',
        templateUrl: 'auth/sign_up/instructor_org/instructor_organization.html',
        controller: 'InstrOrgRegController',
        params: {
          user: null,
        },
        onEnter: registrationOnEnter,
      })

      .state('authentication.1', {
        url: '/create_account/1',
        templateUrl: 'auth/sign_up/terms_and_conditions/_terms_and_conditions.html',
        controller: 'TermsAndConditionsRegController',
        params: {
          user: null,
        },
      })

      .state('authentication.2', {
        url: '/create_account/2',
        templateUrl: 'auth/sign_up/terms_and_conditions/_privacy_policy.html',
        controller: 'PrivacyPolicyRegController',
        params: {
          user: null,
        },
      })

      .state('authentication.3', {
        url: '/create_account/3',
        templateUrl: 'auth/sign_up/name_email/_name_email.html',
        controller: 'NameEmailRegController',
        params: {
          user: {},
        },
      })

      .state('authentication.4', {
        url: '/create_account/4',
        templateUrl: 'auth/sign_up/username_password/_username_password.html',
        controller: 'UsernamePasswordRegController',
        params: {
          user: {},
        },
      })

      .state('authentication.5', {
        url: '/create_account/5',
        templateUrl: 'auth/sign_up/payment/_create_account_payment.html',
        controller: 'CreateAccountPaymentController',
        params: {
          user: {},
        },
        resolve: {
          // gets the pricing information for the particular school
          org: [
            '$stateParams',
            '$http',
            '$q',
            function ($stateParams, $http, $q) {
              var debug = false; // this needs to be turned on in the controller as well

              var org_id;
              if (debug) {
                org_id = '1';
              } else {
                org_id = $stateParams.user.organization;
              }

              if (org_id) {
                return $http.get('organization/' + org_id).then(function (org) {
                  return org.data;
                });
              } else {
                return $q.resolve({}); // no org_id is defined, let controller send us home
              }
            },
          ],
        },
      })

      .state('authentication.password_recovery', {
        url: '/password_recovery',
        templateUrl: 'auth/password_recovery/_forgot_password.html',
        controller: 'PasswordRecoveryController',
      })

      .state('authentication.set_new_password', {
        url: '/password_recovery/:reset_password_token',
        templateUrl: 'auth/password_recovery/_set_new_password.html',
        controller: 'NewPasswordController',
      });

    // this function hard redirects the user and reloads the page
    function hardRedirectAfterLogin(params) {
      if (params.action === 'timed_writing' && params.url_code) {
        window.location = `/t/${params.url_code}`;
      }
    }
  },
]);
