app.factory('UserPreference', [
  '$http',
  '$timeout',
  function ($http, $timeout) {
    // This class is for creating instances of user preferences that each manage its edit forms and saving functionality
    // props is the user_preference object fetched from server (eg. {id: 3, typist_initials: 'aa', skin_tone: null})
    // property is the key/column in props object that contains the user preference this factory should be initialized on (eg. 'typist_initials')
    // name is a user friendly name of the property for displaying in the view and in error messages (eg. 'Typist Initials')
    function UserPreference(props, property, name) {
      this.name = name;
      this.property = property;
      this.editForm = { [property]: props[property] };

      this.view = {
        flashMessage: null,
        showEditForm: false,
        waitingForServer: false,
      };

      this.assignAllPropertiesToSelf({
        ...props,
      });
    }

    // This function saves the curent user preference on the server
    UserPreference.prototype.save = function () {
      this.view.waitingForServer = true;
      this.view.flashMessage = null;

      // 1. Update or create a user preference object in the database for the current property (eg. typist_initials or skin_tone)
      $http({
        method: 'PATCH',
        url: `/user_preference.json`,
        data: {
          user_preference: this.editForm,
        },
      })
        .then((res) => res.data)
        .then((updatedUserPreference = {}) => {
          // a. Clear the form and collapse
          this.view.showInitialsEditForm = false;
          // b. Display success message
          this.view.flashMessage = {
            message: `${this.name} have been successfully updated, the page will refresh in 3s.`,
            error: false,
          };

          // c. Temporarily swap the user preferences in display until reload happens
          this.assignAllPropertiesToSelf(updatedUserPreference || {});

          // d. Reload browser in 3 sec so that the user object gets updated across the app
          // Note: if user navigates away, the reload will still happen
          // Therefore, to ensure the reload happens, do not destroy the timeout
          // Make sure the amount of seconds here reflects the flash message being displayed
          // Not clearing $scope.view.waitingForServer here because expect the page reload to happen anyways
          $timeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch(() => {
          // a. Re-enable form as no longer waiting for response from server
          this.view.waitingForServer = false;
          // n. Display success message and scroll to the top
          // Devise errors are strings in an array, while general system errors are objects
          // Display the first deviser error, otherwise a generic error message
          this.view.flashMessage = {
            message: `Could not update ${this.name}.`,
            error: true,
          };
        });
    };

    // Cancel the edit form. Collapses the form and resets any values in the input fields
    UserPreference.prototype.cancelEditForm = function () {
      this.view.flashMessage = null;
      this.view.showEditForm = false;
      // Reset the edit form (the server-fetched data is on the class instance, so the following line is copying it to the editForm)
      this.editForm = { [this.property]: this[this.property] };
    };

    // Open/close the edit form
    UserPreference.prototype.toggleEditForm = function () {
      this.view.showEditForm = !this.view.showEditForm;
      this.view.flashMessage = null;
    };

    // assigns all properties retrieved from the server to self
    UserPreference.prototype.assignAllPropertiesToSelf = function (props) {
      var self = this;
      for (var prop in props) {
        if (Object.prototype.hasOwnProperty.call(props, prop)) {
          self[prop] = props[prop];
        }
      }
    };
    return UserPreference;
  },
]);
