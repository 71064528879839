/* global app, angular */
app.factory('RandomDate', [
  '$filter',
  function ($filter) {
    // Class Constructor - this class is an instance of a 'date' type Document Variable
    // all the properties passed in 'props' get copied over to the instance.
    // If Document Assignment is initialized by a student from a permission, then the assignmentStartDate will be a date string
    function RandomDate(props) {
      this.view = {
        flashMessage: null, // eg. null or {message: '', error: false}
        dateFormatOptions: generateDateFormatOptions(),
      };

      this.name = null;
      this.type = 'date';

      // ng-model for the form. Used to specify the randomization parameters. Parameters are initialized to empty strings
      // because that is the format the forms will use to define them anyways (though null/undefined also works as all are falsy)
      this.params = {
        anchorDate: {
          type: '', // can be either '' ('Assignment Start Date'), 'reference', or 'specific'
          ref: '', // If type is 'reference', than this property will be used - a string refering to the name of another date variable
          specificDate: null, // if type is 'specific', then this value will be used. Note: HTML5 date input stores dates as a string in format 'yyyy-MM-dd', but angular appears to store the JS Date object internally.
        },
        // If rangeMin and rangeMax are not equal, the random date is randomly picked from the range created by these endpoints
        // If rangeMin and rangeMax are equal, then date picked is a fixed date.
        // If rangeMin and rangeMax are both 0, date picked is equal to anchorDate
        rangeMin: 0, // defines first range endpoint, defined as # days relative to anchorDate. Value can be negative.
        rangeMax: 0, // defnes second range endpoint, defined as # days relative to anchorDate. Value can be negative.
        dateFormat: '', // date display format (passed in to Angular's Date filter). eg. `YYYY mm`
      };

      // Used to keep track of previous param values
      // Useed in the onInputChange() function
      this._previousParams = this.params;

      // instantiate variable with existing properties if props are passed in
      this.assignAllPropertiesToSelf(props);
    }

    // Validate function that can be run before generate or on save.
    // Will check for specific combinations of params and throw errors if there are inconsistencies
    // returns true if validations pass
    RandomDate.prototype.validate = function () {
      if (!Number.isInteger(this.params.rangeMin) || !Number.isInteger(this.params.rangeMax)) {
        throw new Error(`"${this.name}" variable: range min and max must be integers.`);
      }

      // This is a way to check if a Date is invalid
      if (isNaN(new Date(this.params.anchorDate.specificDate).getTime())) {
        throw new Error(`"${this.name}" variable: specific Anchor Date must be a valid date.`);
      }
      return true;
    };

    // This function will generate a randomized instance of this date, using the randomization parameters.
    // referenceDate is an object of a generated RandomDate that was already created due to being higher up
    // the document variables list
    // return object is  {dateISO: '2021/10/02', dayInMonth: '3'}
    // With all values stringified (including null -> '', false -> 'false', 1 -> '1', etc)
    RandomDate.prototype.generate = function (referenceDate) {
      this.view.flashMessage = null;
      // 1. Extract variables
      // AngularJS stores a JS date object in the model connected to date input field, however according to spec
      // it should be ISO date-format string. Make sure to run this filter without timezone specified (this way AngularJS uses local timezone)
      // so that the correct date gets extracted
      let anchorDate = $filter('date')(this.params.anchorDate.specificDate, 'yyyy-MM-dd');
      // Since the values of rangeMin and rangeMax can go negative and the order
      // is not enforced in the form, sort these values and determine actual min and max endpoints
      const [rangeMin, rangeMax] = [this.params.rangeMin, this.params.rangeMax].sort();
      const customDateFormat = this.params.dateFormat;

      if (this.params.anchorDate.type === 'reference' && !referenceDate) {
        throw new Error(`"${this.name}" variable: This variable is referencing a date that cannot be generated.`);
      }

      // 1b. If anchor date is referencing another date, use it to override anchor date for this variable
      if (referenceDate) {
        // This date will be stored in the ISO format when read from server (eg. '2021/10/02')
        anchorDate = referenceDate.anchorDate;
      }

      // 1c. If assignment start date
      if (!this.params.anchorDate.type) {
        anchorDate = $filter('date')(new Date(), 'yyyy-MM-dd');
      }

      // This is a way to check if a Date is invalid
      if (!anchorDate || isNaN(new Date(anchorDate).getTime())) {
        throw new Error(`"${this.name}" variable: Anchor Date must be a valid date.`);
      }

      // 3. Generate a random date
      // Note: according to Date.parse() MDN docs (this is what Date constructor uses to parse strings),
      // date-only ISO formats (eg. 2011-10-10) are interpreted as UTC time,
      // while date-time formats (eg. 2011-10-10T00:00:00), without timezone offset, are interpreted as local time
      // we need a Date instance that has the same date in every timezone, ie. need to pass a date-time format
      // so the browser adds the local timezone, but the date still gets preserved
      // Make sure to set the time to more than an hour after midnight, this way the date won't be affected by daylight savings
      anchorDate = new Date(`${anchorDate}T03:00:00`);
      // Generate a random date within the given range
      const randomDate = new Date(
        anchorDate.getTime() + (rangeMin + Math.round(Math.random() * (rangeMax - rangeMin))) * 1000 * 60 * 60 * 24 // difference between rangeMax and rangeMin is being converted to ms, just like the units of anchorDate.getTime()
      );

      // 4. Return object should have all keys stringified
      // Note: by not setting a timezone, Date will automatically use the browser's timezone,
      // which ensures that all users will get the same date displayed, no matter what timezone they are in
      let generatedRandomDate = {
        anchorDate: $filter('date')(randomDate, 'yyyy-MM-dd'), // ISO date that is required for html5 date input fields eg. "2021-01-02"
        dayInMonth: $filter('date')(randomDate, 'd'), // eg. 1
        monthNumber: $filter('date')(randomDate, 'M'), // eg. 1 (January)
        monthWord: $filter('date')(randomDate, 'MMMM'), // eg. January
        dayOfWeek: $filter('date')(randomDate, 'EEEE'), // eg. Wednesday
        year4Digits: $filter('date')(randomDate, 'yyyy'), // eg. 2021
        year2Digits: $filter('date')(randomDate, 'yy'), // eg. 21 (of year 2021)
        fullDate: $filter('date')(randomDate, 'fullDate'), // eg. Thursday, May 6, 2021
        dateLine: $filter('date')(randomDate, 'longDate'), // eg. May 6, 2021
      };

      // 4b. If user defines a custom format in the form, include it in the return object
      if (customDateFormat) {
        generatedRandomDate['customDate'] = $filter('date')(randomDate, customDateFormat);
      }

      return generatedRandomDate;
    };

    // Generate a flattened js object representing this variable
    RandomDate.prototype.toObj = function () {
      const paramsCopy = angular.merge({}, this.params);
      // HTML5 date input form takes a specific date format string (ISO format). Angular internally stores the full JS Date object,
      // but that also includes timezones, etc. Converting to the ISO format at this point (i.e. before it gets sent to backend server),
      // ensures that the date being used when generating variables is exactly the same, and is not affected by timezones.
      // (see AngularJs docs for more info)
      if (paramsCopy['anchorDate']['specificDate']) {
        paramsCopy['anchorDate']['specificDate'] = $filter('date')(paramsCopy['anchorDate']['specificDate'], 'yyyy-MM-dd');
      }

      return {
        name: this.name,
        type: this.type,
        params: paramsCopy,
      };
    };

    // This function will check validty of the form and make changes to the form
    // on every input field change
    RandomDate.prototype.onInputChange = function () {
      if (this.params.anchorDate.type === '' || this.params.anchorDate.type === 'reference') {
        this.params.anchorDate.specificDate = null;
      }

      if (this.params.anchorDate.type === '' || this.params.anchorDate.type === 'specific') {
        this.params.anchorDate.ref = '';
      }

      // Need a deep merge of this.params because there are nested object structures
      this._previousParams = angular.merge({}, this.params);
    };

    // this function will copy over every key-value pair in props and
    // assign it to self
    RandomDate.prototype.assignAllPropertiesToSelf = function (props) {
      var self = this;
      for (var prop in props) {
        if (Object.prototype.hasOwnProperty.call(props, prop)) {
          self[prop] = props[prop];
        }
      }

      // Convert date string to a date object
      if (self['params']['anchorDate']['specificDate']) {
        const specificDate = self['params']['anchorDate']['specificDate'];
        // See note in generate() method for why date-time formate of the date is passed in
        // to the Date constructor
        // Make sure to set the time to more than an hour after midnight, this way the date won't be affected by daylight savings
        self['params']['anchorDate']['specificDate'] = new Date(`${specificDate}T03:00:00`);
      }
    };

    // Helper functions

    // This function returns the pre-generated labels/values that the 'Custom Format' dropdown input uses
    // the values are stored in this.view.dateFormatOptions
    function generateDateFormatOptions() {
      return [
        { label: 'Year 4 digits (eg. 2021)', value: 'yyyy' },
        { label: 'Year 2 digits (eg. 21)', value: 'yy' },
        { label: 'Day in month (eg. 2)', value: 'd' },
        { label: 'Day in month padded (eg. 02)', value: 'dd' },
        { label: 'Month in year (eg. 2)', value: 'M' },
        { label: 'Month in year padded (eg. 02)', value: 'MM' },
      ];
    }

    return RandomDate;
  },
]);
