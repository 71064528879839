import InteractiveKeyboard from '../../../components/InteractiveKeyboard/InteractiveKeyboard';

// Animates the keyboard
app.controller('KeyboardController', [
  '$scope',
  '$rootScope',
  'keyboardUtilities',
  '$timeout',
  'ReactComponent',
  function ($scope, $rootScope, keyboardUtilities, $timeout, ReactComponent) {
    // Get/create instance of a ReactComponent with id 'react-keyboard'. Since this controller
    // usually runs in the scope of a larger page controller, eg. PracticeTimedWritingController, the initial props
    // are passed in there, since that controller loads first
    // the updateReactKeyboard and removeReactKeyboard functions act specifically on the react component with id 'react-keyboard'
    const { update: updateReactKeyboard, remove: removeReactKeyboard } = ReactComponent.init(
      'react-keyboard',
      InteractiveKeyboard
    );

    $scope.keysdown_lowcase = [];

    $scope.$on('keydown', function (a, e) {
      $scope.add_to_keysdown_lowcase(e.key_pressed_lowcase);
    });

    $scope.$on('blur', function () {
      $scope.keysdown_lowcase = [];
    });

    $scope.$on('$destroy', function () {
      // Make sure to remove the react component from ReactComponent factory and to unmount the actual react component
      removeReactKeyboard();
    });

    $scope.isKeyDown = function (key_to_check) {
      return $.inArray(key_to_check, $scope.keysdown_lowcase) > -1;
    };

    // keysdown_lowcase
    $scope.add_to_keysdown_lowcase = function (key) {
      if (!$scope.isKeyDown(key)) {
        $scope.keysdown_lowcase.push(key);
      } else if (key == 'capslock') {
        $scope.remove_from_keysdown_lowcase(key); // firefox needs this to unlight the caps lock key
      }
    };
    $scope.remove_from_keysdown_lowcase = function (key) {
      var index_of_key = $.inArray(key, $scope.keysdown_lowcase);
      if (index_of_key > -1) {
        $scope.keysdown_lowcase.splice(index_of_key, 1);
      }
      if (key === 'Meta') {
        $scope.keysdown_lowcase = [];
      }
    };

    // Hands ------------------------------------------------------------------------------------------
    // showHands();
    // var typingTimer = $timeout(function() { showHands(); }, 2000);
    var typingTimer;
    var activateHands = false;
    $scope.$on('keyup', function (a, e) {
      $scope.remove_from_keysdown_lowcase(e.key_released_lowcase);
      // also checks if command was released. If yes, clears keysdown

      if (activateHands) {
        $timeout.cancel(typingTimer);
        hideHands();
        typingTimer = $timeout(function () {
          showHands();
        }, 2000);
      }
    });

    $scope.$on('keyboard:show-hands', function () {
      activateHands = true;
      $timeout.cancel(typingTimer);
      // $timeout(showHands, 500);
      typingTimer = $timeout(function () {
        showHands();
      }, 2000);
    });

    $scope.$on('keyboard:hide-hands', function () {
      activateHands = false;
      $timeout.cancel(typingTimer);
    });

    var handsTopCss = parseInt($('#hands-over-keyboard').css('top'), 10);
    var slideAmount = 30;
    function showHands() {
      updateReactKeyboard({ hideHands: false });
      // CODE BELOW CAN BE REMOVED IF WE DEPRECATE THE ORIGINAL KEYBOARD
      if (!$('#hands-over-keyboard').is(':visible')) {
        $('#hands-over-keyboard').css('top', handsTopCss + slideAmount);
        $('#hands-over-keyboard')
          .show()
          .animate({ top: '-=' + String(slideAmount) });
      }
      // END OF REMOVAL ----------
    }

    function hideHands() {
      updateReactKeyboard({ hideHands: true });
      // CODE BELOW CAN BE REMOVED IF WE DEPRECATE THE ORIGINAL KEYBOARD
      if ($('#hands-over-keyboard').is(':visible')) {
        $('#hands-over-keyboard').fadeOut(120);
      }
      // END OF REMOVAL ----------
    }
  },
]);
