// adds some functionality around course templates
//  ** Not widely adopted in the application yet
app.factory('CourseTemplateFactory', [
  function () {
    var CourseTemplate = function (courseTemplate) {
      var self = this;
    };

    CourseTemplate.prototype.doSomething = function (student) {};

    return CourseTemplate;
  },
]);
