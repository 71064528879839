/* global SERVER_TIME_SYSTEM_TIME_DIFF */
import { getDatabase, ref, get, child } from 'firebase/database';

app.factory('Student', [
  'StudentHelpers',
  '$q',
  'StatisticService',
  'DefaultSettings',
  '$http',
  function (StudentHelpers, $q, StatisticService, DefaultSettings, $http) {
    var Student = function (studentParams) {
      var self = this;
      self.db = getDatabase();
      // StudentHelpers contains functions to:
      //   clean data from server
      //    apply default values,
      //    etc.

      // deep copies studentParams to Student. first_name, last_name, id
      $.extend(true, self, studentParams);

      // compute product expiry dates
      const currentTime = new Date() - SERVER_TIME_SYSTEM_TIME_DIFF;
      if (this.keyboarding_expires_on) {
        this.keyboarding_expires_on = new Date(this.keyboarding_expires_on);
        this.keyboardingExpired = this.keyboarding_expires_on < currentTime;
      } else {
        this.keyboardingExpired = true;
      }
      if (this.documents_expires_on) {
        this.documents_expires_on = new Date(this.documents_expires_on);
        this.documentsExpired = this.documents_expires_on < currentTime;
      } else {
        this.documentsExpired = true;
      }
      if (this.ehr_expires_on) {
        this.ehr_expires_on = new Date(this.ehr_expires_on);
        this.ehrExpired = this.ehr_expires_on < currentTime;
      } else {
        this.ehrExpired = true;
      }

      var obj = {
        height: 1,
        minutes: 0,
      };
      this.practice_time = [
        {
          user_id: this.id,
          practice_time: [obj, obj, obj, obj, obj, obj, obj, obj, obj, obj],
        },
      ];

      // var lessonTemp = { bronze: { percentage: 0 }, silver: { percentage: 0 }, gold: { percentage: 0 } };
      // this.lessonProgress = {basics: lessonTemp, punctuation: lessonTemp };

      // takes a response from /statistic filters it, and adds it to the student
      this.update = function (serverResponse) {
        var data = serverResponse.data;
        this.requirements = data['requirements'];
        // data should be structured:
        //  {
        //    avg_top_3: [{user_id: __, speed: __, tooltip: ___}, ....],
        //    ...
        //    timed_writings: [{id: __, user_id: __, duration_in_ms: __, speed: ___, etc.}, ...],
        //    limit: 10  <- max number of timings per students, defined in request
        //    offset: 0  <- also defined in request
        //  }

        // 1. Get list of keys in response that are arrays (avg_top_3, timed_writings, etc.)
        var keys = [];
        $.each(data, function (k, v) {
          if (Array.isArray(v)) keys.push(k);
        });

        // 2. Loop through the data, select those relevant to this student and assign
        var key;
        for (var i = 0; i < keys.length; i++) {
          key = keys[i];
          self[key] = data[key].filter(function (obj) {
            return obj.user_id === self.id;
          });
          self[key] = StudentHelpers.setDefaults(self[key], key); // can set default values for returned objects here.
          // can set default callbacks to add tooltips, transform data, etc.
          //  -> some data, such as lesson_progress is modified by statistic service
          self[key] = StudentHelpers.augmentData(self[key], key, self);
        }
      };

      // called from _test_factory when we give a new test to students.
      // -> when the classlist is first loaded, the statistics services pulls down all the recent timed_writing_permissions
      // -> when the instructor gives new timings (_test_factory.js) we add those new permissions to the existing ones
      this.addTimedWritingPermissions = function (newTimedWritingPermissions) {
        self.timed_writing_permissions ||= []; // if we haven't loaded them, initialize an empty array
        self.timed_writing_permissions.push(newTimedWritingPermissions);
        // Note: If either of these change, we should update the _student_helpers_service callback that also
        // updates these values after the statistics services loads the data
        self.view.activeTimedWritingPermissions = self.timed_writing_permissions.filter((p) => p.ms_to_expiry > 0);
        self.view.expiredTimedWritingPermissions = self.timed_writing_permissions.filter((p) => p.ms_to_expiry <= 0);
      };

      // updatePracticeTimeStatsThis function adds a stat stummary to practice_time stats attached to the student instance
      // The stat is a summation of days with practice times
      // that are greater than minPracticeTimeMinutes currently defined in the classlist view
      // if the summation happens, a new property 'numPracticeDaysOverMin' gets added to the object in practice_time array
      // This function gets called by _section_factory when stats are pulled from server and when 'Highlight practice' dropdown
      // gets update in the classlist view (see classlist controller)
      this.tabulatePracticeTimeStats = function (minPracticeTimeMinutes) {
        if (
          minPracticeTimeMinutes == undefined ||
          !Array.isArray(self.practice_time) ||
          !Array.isArray(self.practice_time[0].practice_time)
        ) {
          return;
        }
        // Updating every day object with a boolean variable whether minutes exceeds minPracticeTimeMinutes
        self.practice_time[0].practice_time = self.practice_time[0].practice_time.map((day) => {
          return {
            ...day,
            aboveMinPracticeTime: minPracticeTimeMinutes != undefined && minPracticeTimeMinutes <= day.minutes,
          };
        });

        self.practice_time[0].numPracticeDaysOverMin = self.practice_time[0].practice_time.filter(
          (day) => day.aboveMinPracticeTime
        ).length;
      };

      // retrieves the test requirements set by instructor and adds them to 'test' in the section
      this.getTestRequirementsForSection = function (section) {
        // first, get the course template if we have one
        return getCourseTemplateAndAttachToSection(section).then((section) => {
          var classRef = ref(self.db, `users/${section.creator_id}/settings/class/${section.code}`);

          var getTest = get(child(classRef, 'test'));
          var getViewOptions = get(child(classRef, 'view'));

          return $q.all([getTest, getViewOptions]).then(function (responses) {
            var testSettings = responses[0].val() || {};
            var viewSettings = responses[1].val() || {};

            // This code is mirrored in SectionFactory. If you change it here, change it there, too!
            // overwrite any values here from course_template
            if (
              !viewSettings.minPracticeTimeMinutes &&
              section.course_template &&
              section.course_template.min_practice_time_minutes
            ) {
              viewSettings.minPracticeTimeMinutes = section.course_template.min_practice_time_minutes;
              console.log('Using practice time minutes from course template');
            } else if (!viewSettings.minPracticeTimeMinutes) {
              console.log('Using default practice time minutes');
            } else {
              console.log('Using practice time mins from firebase');
            }

            // extend test options with default values incase some fields are missing
            //  here, the second object recieves prescedence
            section.test = $.extend(DefaultSettings.testSettings(), testSettings);
            section.view = $.extend(DefaultSettings.classlistViewSettings(), viewSettings);

            return section;
          });
        });
      };

      // accepts a courseTemplate object, and requires permissions and document_submissions
      //  to be set on self. adds the document_assignment object to self:
      // Each DocumentAssignment looks like:
      // { document_assignment_id:
      //    {
      //      selected: false
      //      permissions: []
      //      document_submissions: []
      //    }
      // }
      this.mapDocumentAssignmentsToCourseTemplate = function (courseTemplate) {
        if (!courseTemplate) return true;
        var documentAssignments = getDocAssFromCourseTemplate(courseTemplate);
        self.document_submissions = self.document_submissions || [];
        self.permissions = self.permissions || [];
        var obj = {};
        var docSubs = [];
        var perms = [];
        var docSubsTooltip;
        var hasPerfect;

        documentAssignments.map(function (da) {
          var docSubsBestGradeSubmission = {
            points: null,
            base_points: null,
            tooltip: null,
          };
          var docSubsBestOfTwoGradeSubmission = {
            points: null,
            base_points: null,
            tooltip: null,
          };
          var docSubsAvgOfTwoGradeSubmission = {
            points: null,
            base_points: null,
            tooltip: null,
          };
          var docSubsSecondGradeSubmissionbmission = {
            points: null,
            base_points: null,
            tooltip: null,
          };

          docSubs = self.document_submissions
            .filter(function (docsub) {
              return docsub.document_assignment_id == da.id;
            })
            .sort((a, b) => a.created_at - b.created_at);

          // if we have submissions, check if there's a perfect one
          // and also figure out the best graded submission
          if (docSubs.length > 0) {
            hasPerfect =
              docSubs.filter(function (ds) {
                return parseInt(ds.points, 10) === parseInt(ds.base_points);
              }).length > 0;

            // Determine additional stats that can be displayed in the gradesheet
            docSubsBestGradeSubmission = findBestGradeSubmission(docSubs);
            docSubsBestOfTwoGradeSubmission = findBestOfTwoGradeSubmission(docSubs);
            docSubsAvgOfTwoGradeSubmission = findAvgOfTwoGradeSubmission(docSubs);
            // Move this one to a function if the calculation gets more complex
            docSubsSecondGradeSubmissionbmission = docSubs.length >= 2 ? docSubs[1] : {};
            docSubsSecondGradeSubmissionbmission.tooltip = 'Second submission uploaded by the student.';
          } else {
            hasPerfect = false;
          }

          if (docSubs.length > 1) {
            docSubsTooltip =
              'All Submissions: ' +
              docSubs
                .filter((ds) => isInt(ds.points))
                .map((ds) => {
                  return `${ds.points}/${ds.base_points}`;
                })
                .join('; ');
          } else if (docSubs.length === 1) {
            docSubsTooltip = 'No Practice Scores';
          } else {
            docSubsTooltip = '';
          }
          perms = self.permissions.filter(function (perm) {
            return perm.document_assignment_id == da.id;
          });
          obj[da.id] = {
            document_assignment_id: da.id,
            user_id: self.id,
            selected: false,
            document_submissions: docSubs,
            documentSubmissionTooltip: docSubsTooltip,
            bestGradeSubmission: docSubsBestGradeSubmission,
            bestOfTwoGradeSubmission: docSubsBestOfTwoGradeSubmission,
            avgOfTwoGradeSubmission: docSubsAvgOfTwoGradeSubmission,
            secondGradeSubmission: docSubsSecondGradeSubmissionbmission,
            hasPerfectDocumentSubmission: hasPerfect,
            hasSubmission: docSubs.length > 0,
            gradeReleased: docSubs.length > 0 && docSubs[0].grade_released,
            permissions: perms,
            hasActivePermission:
              perms.filter(function (p) {
                return p.inFuture;
              }).length > 0,
          };
        });
        self.document_assignments = obj;
        return self;
      };

      // retrieves student statistics for a particular section
      // statistics is an optional array parameter.
      // requirements is an optional object parameter
      this.getStatsForSection = function (section, statistics, requirements) {
        // default arguments for statistics
        statistics = statistics || ['timed_writings', 'tw_metadata', 'practice_time'];
        requirements = requirements || {};

        // Optional, default arguments for statistics and requirements can be placed here
        // If we have timed_writings or timed_writing metadata...
        if (statistics.indexOf('timed_writings') > -1 || statistics.indexOf('tw_metadata') > -1) {
          var timedWritingDefaults = timedWritingDefaultRequirements(requirements, section);
          requirements = $.extend(timedWritingDefaults, requirements);
        }

        // Add the timezone from the user's organization
        requirements.timezone = self.organization.timezone;

        // add user_id to scope
        var scope = {
          section_id: section.id,
          user_id: self.id,
        };

        // var statistics = ["timed_writings", "tw_metadata", "practice_time"];
        return StatisticService.get({
          scope: scope,
          requirements: requirements,
          statistics: statistics,
        }).then(function (response) {
          var statsResponse = response;
          self.update(statsResponse);
        });
      };
    };

    // helper functions ---------------------------------------------------

    // Takes an array of document submissions, eg. [ {,points: 10, base_points: 20, },]
    // Returns the document submission object with the best points score and a custom tooltip, eg. {, points: 10, base_points: 20, tooltip: ''}
    // Or returns {} if best grade cannot be determined (eg. invalid point values)
    function findBestGradeSubmission(docSubs = []) {
      // The reduce function determines the largest score amongst all submitted documents
      let bestGrade = findDocSubWithBestGrade(docSubs);

      // Quick check if bestGrade is empty
      if (!Object.prototype.hasOwnProperty.call(bestGrade, 'points')) return bestGrade;

      // Add tooltip if a best score was found
      const allSubmissions = docSubs
        .filter((ds) => isInt(ds.points))
        .map((ds) => {
          return `${ds.points}/${ds.base_points}`;
        })
        .join('; ');

      return {
        ...bestGrade,
        tooltip: `This is the best grade from all submissions: ${allSubmissions}`,
      };
    }

    // Takes an array of document submissions, eg. [ {,points: 10, base_points: 20, },]
    // Returns the document submission object with the best points score from the first two submissions and a custom tooltip,
    // eg. {, points: 10, base_points: 20, tooltip: ''}
    // Or returns {} if best grade cannot be determined (eg. invalid point values)
    function findBestOfTwoGradeSubmission(docSubs = []) {
      // The reduce function determines the largest score amongst first 2 submitted documents
      let bestGrade = findDocSubWithBestGrade(docSubs.slice(0, 2));

      // Quick check if bestGrade is empty
      if (!Object.prototype.hasOwnProperty.call(bestGrade, 'points')) return bestGrade;

      // Add tooltip if a best score was found
      const firstTwoSubmissions = docSubs
        .slice(0, 2)
        .filter((ds) => isInt(ds.points))
        .map((ds) => {
          return `${ds.points}/${ds.base_points}`;
        })
        .join('; ');

      return {
        ...bestGrade,
        tooltip: `This is the best grade from the first two submissions: ${firstTwoSubmissions}`,
      };
    }

    // Takes an array of document submissions, eg. [ {,points: 10, base_points: 20, },]
    // Returns the document submission object with the aveerage points score from the first
    // two submissions and a custom tooltip, eg. {, points: 10, base_points: 20, tooltip: ''}
    // If only one submission is present, the points score for that submission is returned
    // Or returns {} if best grade cannot be determined (eg. invalid point values)
    function findAvgOfTwoGradeSubmission(docSubs = []) {
      const firstTwoSubmissions = docSubs.slice(0, 2).filter((ds) => isInt(ds.points));

      const firstTwoSubmissionsTooltip = firstTwoSubmissions
        .map((ds) => {
          return `${ds.points}/${ds.base_points}`;
        })
        .join('; ');

      if (firstTwoSubmissions.length === 1) {
        return {
          points: firstTwoSubmissions[0].points,
          base_points: firstTwoSubmissions[0].base_points,
          tooltip: `Only one submission received: ${firstTwoSubmissionsTooltip}. Student could upload a second submission to try to improve their grade.`,
        };
      } else if (firstTwoSubmissions.length >= 2) {
        return {
          points: (firstTwoSubmissions[0].points + firstTwoSubmissions[1].points) / 2,
          base_points: firstTwoSubmissions[0].base_points,
          tooltip: `Average of the first two submissions: ${firstTwoSubmissionsTooltip}`,
        };
      } else {
        return {};
      }
    }

    // Pulls all the DocumentAssignments from within the course_template object, flattens and returns them
    function getDocAssFromCourseTemplate(courseTemplate) {
      var assignmentsArr = courseTemplate.structure.map(function (week) {
        if (!week.courseWork) return [];
        return week.courseWork.filter(function (cw) {
          return cw.type === 'Document Production';
        });
      });

      // need to flatten the array
      const flattenedAssignments = [].concat.apply([], assignmentsArr);

      // flattenedAssignments is an array of all document assignments in the course template
      return flattenedAssignments;
    }

    function timedWritingDefaultRequirements(requirements, section) {
      var acc = section.test.accuracy_metric === 'accuracy' ? section.test.accuracy : section.test.max_errors;
      requirements = {
        type: section.test.type, // type is speed_metric
        speed: section.test.speed,
        accuracy: acc,
        accuracy_metric: section.test.accuracy_metric,
        duration_in_ms: Number(section.test.duration) * 60000,
        practice_days: requirements.practice_days || 10,
        tw_limit: requirements.tw_limit || 200,
      };
      return requirements;
    }

    // Checks if the provided value is an integer
    function isInt(value) {
      return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
    }

    // Takes an array of document submissions, eg. [ {,points: 10, base_points: 20, },]
    // Returns the document submission object with the best points score and a custom tooltip, eg. {, points: 10, base_points: 20, tooltip: ''}
    // Or returns {} if best grade cannot be determined (eg. invalid point values)
    function findDocSubWithBestGrade(docSubs = []) {
      return docSubs.reduce(function (acc, ds) {
        const currentPoints = parseInt(ds.points, 10);
        const currentBasePoints = parseInt(ds.base_points, 10);
        // Make sure the value being compared is not NaN, otherwise will get unexpected behaviour when comparing numbers
        if (!isNaN(currentPoints) && !isNaN(currentBasePoints)) {
          // Only return the new points if they are greater than what's already in the acc variable
          // Setting default of -1 on acc.points because initially acc is {}, which means acc.points is undefined, and we still want to allow assignments that have 0 points score to pass through
          // Comparisons in js with undefined, converts it to NaN, which would return false in comparisons
          return currentPoints > (acc.points || -1) ? ds : acc;
        }
        return acc;
      }, {});
    }

    function getCourseTemplateAndAttachToSection(section) {
      if (!section.course_template_id) {
        return $q.resolve(section);
      }
      // return if we already have the course template
      if (section.course_template) {
        return $q.resolve(section);
      }

      // get the course template
      return $http.get(`api/templates/${section.course_template_id}.json`).then(function (res) {
        section.course_template = res.data;
        return section;
      });
    }

    return Student;
  },
]);
